<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col-12 col-lg-12">
            <h1 class="Privacy-Policy-heading">PRIVACY POLICY</h1>
            <p>We at My Tutor Platform LLC (“My Tutor Platform” or “we” or “us” or “our”) know that your privacy is
                important.
                This privacy policy (“Policy”) is designed to assist you in understanding how we will collect and use
                the
                information you provide when you use our publicly available website at mytutorplatform.com, our sales and marketing software and service platform (our
                “Platform”),
                and other related services on which we post a direct link to this privacy policy (collectively, the
                “Services”).</p>
            <p>Please read this Policy to learn more about the ways in which we collect and use your information. If we
                make any
                material changes to this Policy, we will notify you in accordance with the process described in the
                “Revisions or
                Changes” section of this Policy, below. By using our Services, you consent to our collection, use, and
                disclosure of
                your information as described in this Policy.</p>
            <p>This Policy is not exclusive. In particular, if you use our Platform, the data that you and your
                customers submit as
                you use our Platform will be further governed by the applicable My Tutor Platform agreement and as
                required by
                law.</p>
            <p>For the purposes of our Policy, when we refer to “you” or to a “client” we mean any past, current or
                prospective
                client or customer of My Tutor Platform, including any visitor to one of our Sites.</p>
            <p class="italic">Everyday language summaries are provided for your benefit but are not legally binding.</p>
            <h2>This is our Privacy Policy. It applies to our websites and our marketing platform.</h2>
            <h3>Collection of Personal Information</h3>
            <p>
                <span>What We Collect:</span>In some situations you may provide us with your personally identifiable
                information,
                which
                could include your name, street address, telephone number, email address, job title, payment card
                number, other
                financial account number(s), mother’s maiden name or other family names, date and place of birth,
                photographic
                image,
                and any other information we collect about you that by itself is not personally identifiable information
                but if
                combined
                with personally identifiable information could be used to personally identify you.<br>
                You may provide us with personally identifiable information when you complete a survey; make a purchase;
                request
                customer support; subscribe to certain services, email notifications and/or newsletters; register for
                our Platform,
                or register and/or set up an account/profile to access, visit and/or use certain portions of our Sites;
                provide
                comments, reviews, feedback, or testimonials about our products or Services; and any other transactions
                between you
                and us on or in relation to our Services.
                <br>
                We may use your email and other addresses and contact information for customer support, required
                notifications,
                product and policy updates, and sales and marketing purposes, but we will not use this information for
                anything else
                not described in this Policy.
            </p>
            <h2>We may collect your name and other information that identifies you. We will use that information for
                customer
                support, required notifications, product and policy updates, and sales and marketing purposes.</h2>
            <p>
                <span>Information We Automatically Collect:</span>
                When you access our Sites whether by computer, mobile phone or other device, we automatically collect
                certain
                information about your use of our Sites (this information may include without limitation: geographical
                location and
                IDs of your computer, mobile or other device; bandwidth used; system and connection performance; browser
                type and
                version; operating system; referral source; length of visit; page views; IP address or other unique
                identifier for
                your computer, mobile phone or other device; and your mobile carrier) using technologies such as cookies
                and web
                beacons.
            </p>
            <h2>We automatically collect technical and other information when you visit our Sites.</h2>
            <p>
                <span>Data Integrity and Security:</span>We use reasonable and diligent efforts to maintain accurate
                information on
                our customers, and have adopted and implemented reasonable industry standard security procedures to
                protect any such
                information collected. We constantly monitor our systems, which are protected using industry-standard
                security
                measures. Although My Tutor Platform will use reasonable efforts to secure network communications and
                our Sites,
                we cannot guarantee that the information submitted to, maintained on, or transmitted from our systems
                will be
                completely secure.
            </p>
            <h2>We try hard to protect your information but cannot guaranty its security.</h2>
            <p><span>Access to Client Information; Updating Client Information:</span>A client may (i) access any of
                their
                personally identifiable information that we collect and maintain, or (ii) correct, update or delete
                their personally
                identifiable information maintained by us, in each case by contacting us by telephone or mail using the
                contact
                information provided below. We will try and respond to your request promptly and in all cases within 30
                days. To
                protect client privacy and security, we reserve the right to take reasonable steps to verify a client’s
                identity
                prior to granting access to or processing changes or corrections to such information. We will retain a
                client’s
                personal information for so long as its account is active or as reasonably needed by us to provide our
                Services. We
                may also retain and use such information as necessary to comply with our legal obligations, resolve
                disputes, and
                enforce our agreements.</p>
            <h2>Contact us if you need to access, change or delete your information.</h2>
            <h3>How We Use Personal Information</h3>
            <p><span>General:</span>My Tutor Platform may use personally identifiable information to: respond to your
                inquiries,
                and/or address other requests or questions; enable your use of the Services; contact you regarding your
                purchase;
                consider and respond to your job application; collect payments from you; deliver Services purchased or
                ordered by
                you, including through our Platform; send you information and updates related to your purchases; invoice
                you; email
                notifications that you have specifically requested; email marketing communications relating to our
                business that we
                think may be of interest to you; and email messages containing company news, product or service
                information. We may
                also use information collected through our Sites for research regarding the effectiveness of the Sites,
                the
                Services, and related marketing, advertising and sales efforts. We may disclose your personally
                identifiable
                information (i) to the extent <br>
                required by law or if we have a good-faith belief that such disclosure is necessary in order to comply
                with official
                investigations or legal proceedings initiated by governmental and/or law enforcement officials, or
                private parties,
                including but not limited to: in response to subpoenas, search warrants, or court orders; (ii) if we
                sell or
                transfer all or a portion of our company’s business interests, assets, or both, or in connection with a
                corporate
                merger, consolidation, restructuring, or other company change, or (iii) as otherwise disclosed in the
                terms and
                conditions of our agreements with clients, including the separately provided Service Terms applicable to
                our
                Services.
            </p>
            <h2>We may use your personal information to conduct business with you, market our business to you, and
                generally improve
                our business. We may also disclose your information as required by law, if we sell our business, or as
                we otherwise
                agree with you.</h2>
            <p><span>Email:</span> We may communicate with a client through email using the client’s personal contact
                information on
                file in our systems to inform the client of new products, important industry news or information about
                Services, and
                upgrades provided by My Tutor Platform.</p>
            <h2>We may email you about our business or relevant news.</h2>
            <p>
                <span>Disclosures to Third Parties - General:</span> We may disclose information collected from a client
                to our
                independent contractors and business partners who have agreed to (i) hold this information in confidence
                and (ii)
                not use it for any purpose except to carry out the services they are providing for My Tutor Platform.
                Such
                contractors and business partners include those hosting our Services or other data; managing the
                functionality of
                our Services; hosting client relationship management, marketing automation, customer service and other
                software
                platforms on our behalf; processing credit or other payment card payments; collecting delinquent
                accounts;
                fulfilling and processing orders; delivering products you have ordered; conducting background checks if
                you submit
                an application for employment; assisting with marketing and promotions; and enabling us to send you
                email.
            </p>
            <h2>We may disclose your information to our contractors and business partners who have agreed to hold it in
                confidence
                and use it only for the services they provide to us.</h2>
            <p>
                <span>Disclosures to Third Parties - Specific:</span>My Tutor Platform has a number of partner
                programs designed
                to maximize our clients’ ability to use our Services and to purchase or use complementary products and
                services that
                may be used in tandem with our Services. We may disclose information collected from a client to one or
                more trusted
                partners for the purpose of such partner(s) providing assistance to the client in its use of the
                Services and for
                the purpose of marketing complementary or value-add products and services to the client. In each case
                such trusted
                partner will have agreed to (i) hold this information in confidence, and (ii) not use it for any purpose
                except for
                the purposes described above.
            </p>
            <h2>We may also disclose your information to business partners in connection with your use of our Services
                and for
                marketing additional products and services to you.</h2>
            <p>
                <span>Disclosures of Aggregate Information:</span>My Tutor Platform may also disclose aggregate,
                anonymous data
                based on information collected from clients. Should a sale of My Tutor Platform’s business ever occur,
                collected
                client information may be transferred along with the sale but the information will be treated in the
                same manner as
                set forth in this Policy. In the event of any significant changes to the manner in which your
                information is
                handled, we would notify you by a prominent notice on the Sites or by email.
            </p>
            <h2>We may use your information on an anonymous basis when aggregating data. We may transfer your
                information if we sell
                our business.</h2>
            <p>
                <span>Disclosures Made by You within Interactive Areas of a Site:</span> If you use a bulletin board,
                blog, comment
                or discussion forum or chat room (collectively, “forums”) made available on a Site or on Facebook, you
                should be
                aware that any personally identifiable information you submit there can be read, collected, or used by
                other users
                of these forums, and could be used to send you unsolicited messages, and could be potentially searchable
                through
                search engines. We are not responsible for the potential misuse by third parties of any personally
                identifiable
                information that you choose to submit in these various forums.
            </p>
            <h2>Forums or similar portions of our Sites or third-party sites may have different, looser rules regarding
                your
                personal information.</h2>
            <h3>Other General Policies Relating to Privacy</h3>
            <p>
                <span> Disclosures by Children:</span>We believe that protecting the privacy of the very young is
                especially
                important and for that reason we will never collect or maintain information on our Sites from those we
                actually know
                are under the age of 13, and no part of our Sites is structured to attract anyone under 13.
            </p>
            <h2>We do not design our Sites to attract children under 13 and will not collect or maintain information
                from them.</h2>
            <p>
                <span>Third Party Sites:</span> Our Sites may contain links to third party websites. Please note that
                the privacy
                and
                other policies of any such third-party website may differ materially from this Policy. We strongly
                recommend that
                clients review the privacy policies of any such third party prior to submitting personally identifiable
                information.
                My Tutor Platform has no control over and is not responsible for the information practices of other
                websites,
                including those to which we may link and those which may link to us.
            </p>
            <h2>Third party sites are governed by their own privacy policies.</h2>
            <p>
                <span>Use of Widgets:</span>We may provide widgets on our Sites that make it possible to share
                information on
                third-party platforms. These widgets do not store your personally identifiable information. Our Sites
                may also
                include social media features, such as the Facebook® or Twitter® button and similar widgets and
                interactive
                mini-programs that run on our Sites (each, a “Feature”). To the extent we use these Features, they may
                collect your
                IP address, which page you are visiting on our Sites, and may set a cookie to enable the Feature to
                function
                properly. Social media Features and widgets are either hosted by a third party or hosted directly on our
                Sites. Your
                interactions with these Features are governed by the privacy statement of the company providing the
                Feature or
                widget.
            </p>
            <h2>Any features, widgets or mini-programs provided by or for social media sites or other third parties may
                collect
                personal information and are subject to the privacy polies of those third parties</h2>
            <p>
                <span>Use of Cookies:</span>When you interact with the Sites, we try to make that experience simple and
                meaningful.
                When you visit our Sites, our web server sends a cookie to your computer. Cookies are files that web
                browsers place
                on a computer’s hard drive and are used to indicate whether you have visited the Sites previously. The
                data
                collected may include how you navigate around a web page and the most commonly clicked links on a
                specific web page.
                Certain technical information, such as your browser version and operating system, are also collected.
                You can
                configure your browser to accept all cookies, reject all cookies, or notify you when a cookie is set
                (each browser
                is different, so check the “Help” menu of your browser to learn how to change your cookie preferences).
                We use
                cookies for a number of purposes, including to: access a client’s account information when a client logs
                into a
                Site, so that we can provide the client with customized content, keep track of preferences you specify
                while you are
                using the Services, and to estimate and report total audience size (unique users) and traffic for our
                Sites, and to
                conduct research to improve the content and services of our Sites.
            </p>
            <h2>Our Sites use cookies. Your browser probably lets you control whether it allows the use of cookies.</h2>
            <p>
                <span>Web Beacons:</span>We may use web beacons to access cookies and count users who visit our Sites or
                open our
                HTML-formatted email messages. Web beacons are single-pixel graphic files with a unique identifier,
                similar to
                cookies. Web beacons are embedded invisibly on web pages and emails and are stored on a user’s hard
                drive. You can
                modify your browser settings to control web beacons.
            </p>
            <h2>Our Sites may use web beacons. Your browser probably lets you control web beacons.</h2>
            <p>
                <span>Third-Party Cookies:</span> We may also from time to time engage third parties to track and
                analyze
                non-personally identifiable usage and volume statistical information from visitors to our Sites to help
                us
                administer our Sites and improve their quality. Such third parties may use cookies and web beacons to
                help track
                visitor behavior. Such cookies and web beacons may track how you interact with a Site through the use of
                cookies and
                the data collected may include how you navigate around a web page and the most commonly clicked links on
                a specific
                web page. Certain technical information, such as your browser version and operating system, may also be
                collected.
                If collected, this information will be used by us to evaluate and, if necessary, modify the relevant
                Site to improve
                the functions and ultimately, make it easier for clients to use. None of your personally identifiable
                information,
                that is, your name, address, or phone number, is collected through such collections.
            </p>
            <h2>We may engage third parties to help us run our Sites. These third parties may also use cookies and web
                beacons.</h2>
            <p>
                <span>Behavioral Targeting/Re-Targeting:</span>We may partner with third party ad networks to either
                display
                advertising on our Sites or to manage our advertising on other websites. Any such ad network partners
                may use
                cookies and web beacons to collect non-personally identifiable information about your activities on our
                Sites and
                other websites to provide you targeted advertising based upon your interests. To opt out of this type of
                cookies,
                visit the Network Advertising Initiative (NAI) website by visiting
                http://www.networkadvertising.org/choices/. Even
                if you opt out through the NAI website, you can still receive communications you have signed up to
                receive from us
                unless you opt out of those by using the information in the “Opting Out” section of this Policy.
            </p>
            <h2>Our advertising partners may also use cookies and web beacons to provide you targeted advertising.</h2>
            <p>
                <span>Use of Testimonials:</span>We may select and post client testimonials on our Services, which may
                contain
                personally identifiable information such as your name and/or the city, state, or country in which you
                live. We will
                obtain your consent prior to posting any testimonial with your name. If you post such content, it will
                be available
                to other users of the Services. Your posting may also become public and we cannot prevent such
                information from
                being used in a manner that may violate this Policy or the law. If you would like us to remove or delete
                your name
                or testimonial from our Services, you may contact My Tutor Platform using the information in the “How
                to Contact
                Us” section of this Policy. Note that copies of information that you have updated, modified, or deleted
                may remain
                viewable in cached and archived pages of our Sites for a period of time.
            </p>
            <h2>If we post a client testimonial with your name or other identifying information, it will become visible
                by third
                parties or even become public.</h2>
            <p>
                <span>Reviews:</span>We may post client reviews on our Sites or Services, which may contain personally
                identifiable
                information such as your name and/or the city, state, or country in which you live. When submitting your
                review you
                are giving us permission to post your review on our Sites or Services. If you post such content, it will
                be
                available to other users of the Sites or Services. Your posting may also become public and we cannot
                prevent such
                information from being used in a manner that may violate this Policy or the law. If you would like us to
                remove your
                name or review from our Sites or Services, you may contact My Tutor Platform using the information in
                the “How to
                Contact Us” section of this Policy. Note that copies of information that you have updated, modified, or
                deleted may
                remain viewable in cached and archived pages of our Sites for a period of time.
            </p>
            <h2>If we post a client review with your name or other identifying information, it will become visible by
                third parties
                or even become public.</h2>
            <p>
                <span>Governing Law; Superseding Contract Terms:</span>By choosing to visit and interact with Tutor
                World Connect,
                you agree that your visit and any dispute regarding the protection of your privacy during such visit is
                subject to
                this Policy and the choice of law and dispute resolution provisions in our separate terms of use and/or
                Service
                Terms applicable to the Services relating to the dispute. To the extent a client enters into other
                contractual
                agreements with My Tutor Platform, the privacy terms and conditions of such agreements (including the
                separate
                Service Terms applicable to the use of our Platform) supplement or supersede portions of this Policy to
                the extent
                of any inconsistency between this Policy and such agreements. Please note that the nature of the
                marketing and sales
                services provided by our Platforms means that clients using the Platform will frequently and routinely
                make your
                personally identifying information available to third parties or the public as part of the ordinary
                operation of the
                Platform. This disclosure may take place on Facebook and other social media channels.
            </p>
            <p>
                <span>Jury Trial Waiver:</span>You and Coworking Space Tech acknowledge and agree that we are each
                waiving the right
                to a trial by jury as to all arbitrable Disputes.
            </p>

            <p>
                <span>No Class Actions or Representative Proceedings:</span>You and Coworking Space Tech acknowledge and
                agree that,
                to the fullest extent permitted by law, we are each waiving the right to participate as a plaintiff or
                class member
                in any purported class action lawsuit, class-wide arbitration, private attorney general action, or any
                other
                representative or consolidated proceeding. Unless we agree in writing, the arbitrator may not
                consolidate more than
                one party’s claims and may not otherwise preside over any form of any class or representative
                proceeding. If there
                is a final judicial determination that applicable law precludes enforcement of the waiver contained in
                this
                paragraph as to any claim, cause of action or requested remedy, then that claim, cause of action or
                requested
                remedy, and only that claim, cause of action or requested remedy, will be severed from this agreement to
                arbitrate
                and will be brought in a court of competent jurisdiction. In the event that a claim, cause of action or
                requested
                remedy is severed pursuant to this paragraph, then you and we agree that the claims, causes of action or
                requested
                remedies that are not subject to arbitration will be stayed until all arbitrable claims, causes of
                action and
                requested remedies are resolved by the arbitrator.
            </p>
            <p>
                <span>Severability:</span> Except as provided in Section 23.11, in the event that any portion of this
                Arbitration
                Agreement is deemed illegal or unenforceable, such provision will be severed and the remainder of the
                Arbitration
                Agreement will be given full force and effect
            </p>
            <h2>Our other terms and agreements with you control dispute resolution and may have different privacy terms.
                If you use
                our software Platform, you will be making your name and other personally identifying information
                available to the
                public.</h2>
            <p>
                <span>Revisions or Changes:</span>My Tutor Platform reserves the right to change this Policy and other
                policies
                prospectively at any time, subject to applicable laws and regulations, by posting a revised Policy on
                the Sites.
                Regardless of later updates or changes to our Policy, we will never use the information you submit under
                our current
                Policy in a new way without first providing you an opportunity to opt out or otherwise prevent such use.
            </p>
            <h2>We can change this Policy from time to time.</h2>
            <p>
                <span>Opting Out:</span>You may opt out of having your personally identifiable information shared with
                third parties
                for reasons other than for the express purpose of delivering the purchased product or service requested
                and except
                as required by law. To do this, you may contact our Privacy Officer using the information in the “How to
                Contact Us”
                section of this Policy. You may opt out of receiving future promotional/marketing messages from us by
                contacting our
                Privacy Officer as described above or by clicking the “Unsubscribe” link found in our email messages.
                Please note
                that if you opt out of promotional/marketing messages, you may continue to receive certain
                communications from us,
                such as administrative and services announcements and/or messages about your account/profile.
            </p>
            <h2>You can opt out of promotional and marketing messages from us.</h2>
            <p>
                <span>Third-Party Services:</span> The Coworking Space Tech Platform may contain links to third-party
                websites,
                applications, services or resources (“Third-Party Services”) that are subject to different terms and
                privacy
                practices. Coworking Space Tech is not responsible or liable for any aspect of such Third-Party Services
                and links
                to such Third-Party Services are not an endorsement.
            </p>
            <p>
                <span>Google Terms:</span>Some translations on the Coworking Space Tech Platform are powered by Google.
                Google
                disclaims all warranties related to the translations, express or implied, including any warranties of
                accuracy,
                reliability, and any implied warranties for merchantability, fitness for a particular purpose and
                non-infringement.
                Some areas of the Coworking Space Tech Platform implement Google Maps/Earth mapping services, including
                Google Maps
                API(s). Your use of Google Maps/Earth is subject to the Google Maps/Google Earth Additional Terms of
                Service.
            </p>
            <p>
                <span>Force Majeure:</span>Coworking Space Tech shall not be liable for any delay or failure to perform
                resulting
                from causes outside its reasonable control, including, but not limited to, acts of God, natural
                disasters, war,
                terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, pandemics,
                epidemics or
                disease, strikes or shortages of transportation facilities, fuel, energy, labor or materials
            </p>
            <p>
                <span>Emails and SMS:</span>You will receive administrative communications from us using the email
                address or other
                contact information you provide for your Coworking Space Tech account. Enrollment in additional email
                subscription
                programs will not affect the frequency of these administrative emails, though you should expect to
                receive
                additional emails specific to the program(s) to which you have subscribed. You may also receive
                promotional emails
                from us. No fee is charged for these promotional emails, but third-party data rates could apply. You can
                control
                whether you receive promotional emails using the notification preferences in your account settings.
                Please note that
                you will not be able to take advantage of certain promotions if you disable certain communication
                settings or do not
                have an Coworking Space Tech account. In the U.S. if you consent to receive SMS (text messages) from us,
                you will be
                subject to our SMS Terms.
            </p>
            <p> <span>How To Contact Us</span>
                If you have any questions or concerns about this Policy or the way your information is being used by
                My Tutor Platform, or should you wish to contact us for any other reason described in this Policy, you can contact
                us (i) by
                email directed to <a href="mailto:compliance@My Tutor Platform.com">compliance@My Tutor Platform.com</a> or
                (ii) by mail addressed to ADDRESS , Attn: My Tutor Platform.
            </p>
            <p>
                <span>Effective Date of Policy</span>This Policy was last updated as of the last date set forth above.
                If you would
                like to see an earlier version of this Policy, please contact us using one of the methods set forth
                above and
                specify the applicable date or time period for the prior Policy version requested.
            </p>
            <h6 class="text-center mt-5 mb-5">© 2022 My Tutor Platform LLC. Any rights not expressly granted herein
                are reserved by My Tutor Platform.</h6>

        </div>
    </div>
</div>

<app-footer></app-footer>