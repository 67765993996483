import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatatableService } from '../datatable.service';
import { Model } from '../../../core/types/models/Model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-datatable-header-search-input',
  templateUrl: './datatable-header-search-input.component.html',
  styleUrls: ['./datatable-header-search-input.component.css']
})
export class DatatableHeaderSearchInputComponent implements OnInit {
    public searchControl = new FormControl(this.route.snapshot.queryParams.query);
    @Input() placeholder: string;
    @Input() hideIcon = false;

  constructor(
        public datatable: DatatableService<Model>,
        private route: ActivatedRoute) { }

  ngOnInit(): void {
  	        this.searchControl.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
              console.log(query)
                this.datatable.addFilter('query', query);
            });
  }

}
