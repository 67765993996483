import {Injectable} from '@angular/core';
import {Bootstrapper} from './core/bootstrapper.service';

@Injectable({
    providedIn: 'root'
})
export class TutorPlatformBootstrapper extends Bootstrapper {
    protected handleData(encodedData: string) {
        const data = super.handleData(encodedData);
        return data;
    }
}