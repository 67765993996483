import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SuccessStory} from '../core/types/models/SuccessStory';

@Injectable({
    providedIn: 'root'
})
export class SuccessStories {
	public successStories$ = new BehaviorSubject<Partial<SuccessStories[]>>([]);

	 public init(params) {
        this.successStories$.next(params);
    }

    public successStoriesLinks(){
    	return this.successStories$.value;
    }

}