import { Directive } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appCurrencyRangeValidator]',
    providers: [{
    provide: NG_VALIDATORS,
    useExisting: CurrencyRangeValidatorDirective,
    multi: true
  }]
})
export class CurrencyRangeValidatorDirective implements Validator {
	validate(control: AbstractControl) : {[key: string]: any} | null {
    if (control.value && control.value.length != 10) {
      return { 'phoneNumberInvalid': true };
    }
    return null;
  }

  constructor() { }

}
