<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col-12 col-lg-12">
            <h1 class="Privacy-Policy-heading">WEBSITE TERMS OF USE</h1>
            <h6>Version of: June 22, 2022</h6>
            <p>My Tutor Platform (“Business Name”) operates each website (“Site”) that links to these Terms of Use to
                provide online access to information about My Tutor Platform and our products, services, and
                opportunities. Use of our software and service platform (“Platform”) is also governed by our separately
                provided Service Terms.</p>
            <p>By accessing and using the Site, you agree to these Terms of Use.</p>
            <p>My Tutor Platform reserves the right to modify these Terms of Use at any time without giving you prior
                notice. Your use of the Site following any such modification constitutes your agreement to follow and be
                bound by these Terms of Use as modified. The last date these Terms of Use were revised is set forth
                above.</p>
            <p class="italic">Everyday language summaries are provided for your benefit but are not legally binding.</p>
            <h1 style="float:left;">Mission:</h1><br><br>
            <p>Our mission is to provide a technology platform that connects Tutors to Students to help learn and grow
                their skills and knowledge. </p>
            <h3>1. Permitted Use of the Site:</h3>
            <p>You may use the Site, and the information, writings, images, videos, and/or other works that you see,
                hear or otherwise experience on the Site (singly or collectively, the “Content”) solely for your
                non-commercial, personal purposes, for your own educational purposes, and/or to learn about My Tutor Platform products and services, and solely in compliance with these Terms of Use.</p>
            <h3>2. Prohibited Use of the Site:</h3>
            <p>By accessing the Site, you agree that you will not:</p>
            <ul>
                <li>Use the Site in violation of these Terms of Use;</li>
                <li>Use the Site in violation of the terms of My Tutor Platform's Acceptable Use Policy attached
                    hereto;</li>
                <li>Copy, modify, create a derivative work from, reverse engineer or reverse assemble the Site, or
                    otherwise attempt to discover any source code, or allow any third party to do so;</li>
                <li>Sell, assign, sublicense, distribute, commercially exploit, grant a security interest in or
                    otherwise transfer any right in, or make available to a third party, the Content or any Site, our
                    Platform, or other products and services we provide (collectively, the Site, Platform, and other
                    products and services are our “Service”) in any way;</li>
                <li>Use or launch any automated system, including without limitation, “robots,” “spiders,” or “offline
                    readers,” that accesses the Site in a manner that sends more request messages to the My Tutor Platform servers in a given period of time than a human can reasonably produce in the same period by
                    using a conventional on-line web browser;</li>
                <li>Use the Site in any manner that damages, disables, overburdens, or impairs any My Tutor Platform
                    website or interferes with any other party's use and enjoyment of the Site;</li>
                <li>Mirror or frame the Site or any part of it on any other web site or web page;</li>
                <li>Attempt to gain unauthorized access to the Site;</li>
                <li>Access the Site by any means other than through the interface that is provided by My Tutor Platform for use in accessing the Site;</li>
                <li>Use the Site for any purpose or in any manner that is unlawful.</li>
            </ul>
            <p>Any unauthorized use of any Content or the Site may violate patent, copyright, trademark, and other laws.
            </p>
            <h2>Don’t use our Content or Site illegally or abusively. Comply with our Acceptable Use Policy.</h2>
            <h3>3. Copyrights and Trademarks:</h3>
            <p>The Site is based upon proprietary My Tutor Platform technology and includes the Content. The Site is
                protected by applicable intellectual property and other laws, including trademark and copyright laws.
                The Site, including all intellectual property rights in the Site, belongs to and is the property of
                My Tutor Platform or its licensors (if any). My Tutor Platform owns and retains all copyrights in
                the Content. Except as specifically permitted on the Site as to certain Content, the Content may not be
                copied, reproduced, modified, published, uploaded, posted, transmitted, performed, or distributed in any
                way, and you agree not to modify, rent, lease, loan, sell, distribute, transmit, broadcast, or create
                derivative works based on the Content or the Site, in whole or in part, by any means. My Tutor Platform, the My Tutor Platform logos, and other marks used by My Tutor Platform from time to time
                are trademarks and the property of My Tutor Platform. The appearance, layout, color scheme, and design
                of the Site are protected trade dress. Customer does not receive any right or license to use the
                foregoing. My Tutor Platform may use and incorporate into the Site or our Service any suggestions or
                other feedback you provide, without payment or condition.<br>
                Pursuant to Title 17, United States Code, Section 512(c)(2), notifications of claimed copyright
                infringement on the Site or the Service should be sent to My Tutor Platform’s designated Copyright
                Agent. See the Claims of Copyright Infringement instructions below.</p>
            <h2>The Site and Content belong to us. You are not allowed to re-use it for your own purposes unless we give
                permission. If you think we have infringed any of your copyrights, see the Claims of Copyright
                Infringement section below.</h2>
            <h3>4. Information and Materials You Post or Provide:</h3>
            <p>
                You represent that you have all right, title, and interest to materials you post on the Site or provide
                to
                My Tutor Platform (“Materials”), including but not limited to any consent, authorization, release,
                clearance or license from any third party (such as, but not limited to, any release related to rights of
                privacy or publicity) necessary for you to provide, post, upload, input or submit the Materials, and<br>
                that posting such Materials does not violate or constitute the infringement of any patent, copyright,
                trademark, trade secret, right of privacy, right of publicity, moral rights, or other intellectual
                property
                right recognized by any applicable jurisdiction of any person or entity, or otherwise constitute the
                breach
                of any agreement with any other person or entity. You further represent and warrant that you are who you
                say
                you are, that you have not submitted fictitious, false or inaccurate information about yourself, and
                that
                all information contained in the posted Materials is true and your own work or work you are authorized
                to
                submit, and that the posted Materials do not contain any threatening, harassing, libelous, false,
                defamatory, offensive, obscene, or pornographic, material, or other material that would violate any
                other
                applicable law or regulation. You agree that you will not knowingly and with intent to defraud provide
                material and misleading information. You represent and warrant that the Materials you supply do not
                violate
                these Terms of Use.
            </p>
            <h2>You have the rights to provide any Material you post to the Site or provide to us. You will not supply
                any Material that is false, illegal or offensive. </h2>
            <h3>5. Links to Third-Party Web Sites:</h3>
            <p>Links on the Site to third party web sites or information are provided solely as a convenience to you. If
                you use these links, you will leave the Site. Such links do not constitute or imply an endorsement,
                sponsorship, or recommendation by My Tutor Platform of the third party, the third-party web site, or
                the information there. My Tutor Platform is not responsible for the availability of any such web
                sites. My Tutor Platform is not responsible or liable for any such web sites or the content thereon.
                If you use the links to the web sites of My Tutor Platform affiliates or service providers, you will
                leave the Site and will be subject to the terms of use and privacy policy applicable to those websites.
            </p>
            <H2>My Tutor Platform might provide convenience links to third party sites, but we are not responsible for
                them.</H2>
            <h3>6. Downloading Files:</h3>
            <p>My Tutor Platform cannot and does not guarantee or warrant that files available for downloading through
                the Site will be free of infection by software viruses or other harmful computer code, files or
                programs.</p>
            <h2>Files you download from us are not guaranteed as virus-free.</h2>
            <h3>7. Disclaimers; Limitations of Liability:</h3>
            <p>My Tutor Platform AND ITS SERVICE PROVIDERS, LICENSORS AND SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE
                SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, SECURITY OR ACCURACY OF THE SITE OR THE CONTENT FOR
                ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE,
                PRODUCTS, SERVICE AND RELATED GRAPHICS ARE PROVIDED “AS IS” WITHOUT WARRANTY OR CONDITION OF ANY KIND.
                My Tutor Platform AND ITS SERVICE PROVIDERS, LICENSORS AND SUPPLIERS HEREBY DISCLAIM <br>
                ALL WARRANTIES AND CONDITIONS OF ANY KIND WITH REGARD TO THE SITE AND THE CONTENT, INCLUDING ALL IMPLIED
                WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
                NON-INFRINGEMENT. NO STATEMENT OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM My Tutor Platform IN ANY MEANS OR FASHION SHALL CREATE ANY WARRANTY NOT EXPRESSLY AND EXPLICITLY SET FORTH IN
                THESE TERMS OF USE. THE CONTENT MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS.<br>
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL My Tutor Platform AND ITS SERVICE
                PROVIDERS, LICENSORS OR SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
                CONSEQUENTIAL, EXEMPLARY OR OTHER TYPE OF DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR COVER OR
                LOSS OF USE, DATA, REVENUE OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR
                PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE, OR FOR ANY CONTENT, OR OTHERWISE
                ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, THE
                FAILURE OF ANY LIMITED REMEDY TO ACHIEVE ITS ESSENTIAL PURPOSE, OR OTHERWISE, EVEN IF My Tutor Platform OR ANY OF My Tutor Platform’S SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.
                BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
                INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.<br>
                IF, NOTWITHSTANDING THE OTHER TERMS OF THESE TERMS OF USE, My Tutor Platform IS DETERMINED TO HAVE ANY
                LIABILITY TO YOU OR ANY THIRD PARTY FOR ANY LOSS, HARM OR DAMAGE, YOU AGREE THAT THE AGGREGATE LIABILITY
                OF My Tutor Platform AND ITS OFFICERS, DIRECTORS, MANAGERS, OWNERS, EMPLOYEES, AFFILIATES, AGENTS,
                CONTRACTORS, SERVICE PROVIDERS, LICENSORS OR SUPPLIERS SHALL IN ALL CASES BE LIMITED TO ONE HUNDRED
                DOLLARS.
            </p>
            <h2>We provide the Site and the Content on an “as is” basis and broadly disclaim liability to you.</h2>
            <h3>8. Indemnification:</h3>
            <p>You understand and agree that you are personally responsible for your behavior on the Site. You agree to
                indemnify, defend and hold harmless My Tutor Platform, its parent companies, subsidiaries, affiliated
                companies, joint venturers, business partners, licensors, employees, agents, and any third-party
                information providers from and against all claims, losses, expenses, damages and costs (including, but
                not limited to, direct, incidental, consequential, exemplary and indirect damages), and reasonable
                attorneys’ fees, resulting from or arising out of your use, misuse, or inability to use the Site or the
                Content, or any violation by you of these Terms of Use.</p>
            <h2>You will hold us harmless from any damages arising out of your use of the Site or the Content or
                violation of these Terms of Use.</h2>
            <h3>9. Privacy:</h3>
            <p>Your use of the Site is subject to My Tutor Platform's Privacy Policy, available at the link provided
                on the Site. </p>
            <h2>Your use of the Site is subject to our Privacy Policy.</h2>
            <h3>10. Additional Terms of Service:</h3>
            <p>If you are a customer of My Tutor Platform or an employee, representative or agent of a My Tutor Platform customer, your use of the My Tutor Platform Service is subject to My Tutor Platform’s
                Service Terms or other separate agreement with My Tutor Platform.</p>
            <h2>Our customers also enter into a separate customer agreement with us. Others may also do so.</h2>
            <h3>11. General Provisions:</h3>
            <p>
                <span>Entire Agreement/No Waiver/Order of Precedence:</span>These Terms of Use constitute the entire
                agreement of the parties with respect to the subject matter hereof. No waiver by My Tutor Platform of
                any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or
                default. To the extent you and My Tutor Platform have entered into Service Terms or another separate
                written agreement for the Platform, such separate agreement shall prevail in the event of any
                inconsistency with these Terms of Use.
            </p>
            <h2>These Terms of Use are our entire agreement with you unless you have also entered into a separate
                agreement with us.</h2>
            <p>
                <span>b. Correction of Errors and Inaccuracies:</span>The Content may contain typographical errors or
                other errors or inaccuracies and may not be complete or current. My Tutor Platform therefore reserves
                the right to correct any errors, inaccuracies or omissions and to change or update the Content at any
                time without prior notice. My Tutor Platform does not, however, guarantee that any errors,
                inaccuracies or omissions will be corrected.
            </p>
            <h2>We may correct errors in the Content but are not obligated to do so.</h2>
            <p>
                <span>c. Enforcement/ Choice of Law/ Choice of Forum:</span>If any part of these Terms of Use is
                determined by a court of competent jurisdiction to be invalid or unenforceable, it will not impact any
                other provision of these Terms of Use, all of which will remain in full force and effect. Any and all
                disputes relating to these Terms of Use, My Tutor Platform’s Privacy Policy, your use of the Site, any
                other My Tutor Platform website or the Content are governed by, and will be interpreted in accordance
                with, the laws of the State of Florida, without regard to any conflict of laws provisions. You agree to
                the sole and exclusive jurisdiction and venue of the federal or state courts in Orange County, Florida
                in the event of any dispute of any kind arising from or relating to these Terms of Use, My Tutor Platform's Privacy Policy, your use of the Site, any other My Tutor Platform web site or the Content,
                except that My Tutor Platform reserves the right to seek or enforce injunctive or other equitable
                relief in any court with jurisdiction.
            </p>
            <h2>These Terms of Use and your use of the Site and Content are subject to Florida law and courts.</h2>
            <p class="text-center">(c) 2021 My Tutor Platform LLC. All rights reserved</p>
            <h2>Claims of Copyright Infringement</h2>
            <h3>DMCA Notices:</h3>
            <p>My Tutor Platform respects the intellectual property rights of others, and we ask our users to do the
                same. My Tutor Platform may, in its sole discretion, suspend the access or terminate the accounts of
                users who violate others’ intellectual property rights.<br>
                If you believe that your work has been copied in a way that constitutes infringement on My Tutor Platform’s website, please provide the following information to My Tutor Platform’s Copyright Agent:
            </p>
            <ul style="list-style:none;">
                <li>i.A physical or electronic signature of a person authorized to act on behalf of the owner of an
                    exclusive right that is allegedly infringed (“Complaining Party”);</li>
                <li>ii.Identification of the copyrighted work claimed to have been infringed, or if multiple copyrighted
                    works at a single online site are covered by a single notification, a representative list of such
                    works at that site;</li>
                <li>
                    iii.Identification of the material that is claimed to be infringing or to be the subject of
                    infringing activity and that is to be removed or access to which is to be disabled, and information
                    reasonably sufficient to permit My Tutor Platform to locate the material;
                </li>
                <li>
                    iv.Information reasonably sufficient to permit My Tutor Platform to contact the Complaining Party,
                    such as an address, telephone number, and if available, an electronic mail address at which the
                    complaining party may be contacted;
                </li>
                <li>
                    v.A statement that the Complaining Party has a good faith belief that use of the material in the
                    manner complained of is not authorized by the copyright owner, its agent, or the law; and
                </li>
                <li>
                    vi.A statement that the information in the notification is accurate, and under penalty of perjury,
                    that the Complaining Party is authorized to act on behalf of the owner of an exclusive right that is
                    allegedly infringed.
                </li>
            </ul>
            <p>
                The My Tutor Platform Copyright Agent for notice of claims of copyright infringement on or relating to
                this website (“Notifications”) can be reached either by sending an e-mail to <a
                    href="mailto:compliance@mytutorplatform.com">compliance@mytutorplatform.com</a>
                or by sending a letter via U.S. Mail to: 7635 Ashley Park Court,
                Orlando, FL 32835, Attn: My Tutor Platform Copyright Agent.
            </p>
            <h3>Acceptable Use Policy:</h3>
            <p>
                This Acceptable Use Policy (“AUP”) applies to the use of any website, Platform or other Service provided
                by us (My Tutor Platform), whether we provide it directly or use another party to provide it to you.
                This AUP is designed to ensure compliance with the laws and regulations that apply to the Service. This
                AUP also protects the interests of all of our clients and their customers, as well as our goodwill and
                reputation. These terms are so important that we cannot provide the Service unless you agree to them. By
                using the Service, you are agreeing to these terms.<br>
                If you are using any Service, this AUP applies to you. You are not allowed to assist or engage others in
                a way that would violate this AUP. We will enforce and ensure compliance with this AUP by using methods
                we consider to be appropriate, such as complaint and email failure monitoring.
            </p>
            <h3>No SPAM Permitted:</h3>
            <p>You may not use our Service in any way (directly or indirectly) to send, transmit, handle, distribute or
                deliver: (a) unsolicited email (“spam” or “spamming”) in violation of the CAN-SPAM Act (referenced
                below) or any other law; (b) email to an address obtained via Internet harvesting methods or any
                surreptitious methods (e.g., scraping or harvesting); (c) email to an address that is incomplete,
                inaccurate and/or not updated for all applicable opt-out notifications, using best efforts and best
                practices in the industry, or (d) commercial electronic messages in violation of Canada’s Anti-Spam
                Legislation (referenced below). </p>
            <h3>Prohibited Email Content and Formatting; Email Best Practices:</h3>
            <p>Email sent, or caused to be sent to or through our Service may not: (a) use or contain invalid or forged
                headers; (b) use or contain invalid or non-existent domain names; (c) employ any technique to otherwise
                misrepresent, hide or obscure any information in identifying the point of origin or the transmission
                path; (d) use other means of deceptive addressing; (e) use a third party's internet domain name without
                their consent, or be relayed from or through a third party's equipment without the third party’s
                permission; (f) contain false or misleading information in the subject line or otherwise contain false
                or misleading content; or (g) unless otherwise authorized by us, use our trademark(s), tagline(s), or
                logo(s) without our prior written consent and only then pursuant to our trademark usage guidelines. You
                are prohibited from using the Service to email purchased lists.</p>
            <h3>Email Opt-out Requirements:</h3>
            <p>You warrant that each email you send or is sent for you using our Service will contain: (a) header
                information that is not false or misleading; (b) an advisement that the recipient may unsubscribe,
                opt-out or otherwise demand that use of its information for unsolicited, impermissible and/or
                inappropriate communication(s) as described in this AUP be stopped (and how the recipient can notify you
                that it wants to unsubscribe, opt-out, or stop this use of its information); and (c) all information
                required by Canada’s Anti-Spam Legislation. These requirements may not apply if the email sent is a
                transactional email and these requirements are not otherwise required by law. You warrant that you will
                promptly comply with all opt-out, unsubscribe, “do not call” and “do not send” requests.
            </p>
            <h3>Telephone Marketing:</h3>
            <p>You must comply with all laws relating to telephone marketing, including without limitation those
                specifically referenced in the ‘Proper Usage of Our Service’ section below. You must comply with all
                laws related to the recording of phone calls and ensure all proper consent to record is obtained prior
                to making any such recording. If you use our Service to place telephone calls, you must also comply with
                all applicable industry standards.</p>
            <h3>No Disruption:</h3>
            <p>You agree not to use our Service in a way that impacts the normal operation, privacy, integrity or
                security of another's property. Another’s property includes another’s account(s), domain name(s),
                URL(s), website(s), network(s), system(s), facilities, equipment, data, other information, or business
                operations. You also agree not to use our Service to gain unauthorized access to, use, monitor, make an
                unauthorized reference to, another’s property, unless you have the appropriate express prior consent to
                do so. Examples of prohibited actions include (without limitation): hacking, spoofing, denial of
                service, mailbombing and/or sending any email that contains or transmits any virus or propagating
                worm(s), or any malware, whether spyware, adware or other such file or program. These restrictions apply
                regardless of your intent and whether or not you act intentionally or unintentionally.</p>
            <h3>Proper Usage of Our Service:</h3>
            <p>In addition, and without limiting the other requirements in this AUP, you may not (directly or
                indirectly) use our Service with content, or in a manner that:</p>
            <ul>
                <li>is threatening, abusive, harassing, stalking, or defamatory;</li>
                <li>is deceptive, false, misleading or fraudulent;</li>
                <li>is invasive of another's privacy or otherwise violates another’s legal rights (such as rights of
                    privacy and publicity);</li>
                <li>contains vulgar, obscene, indecent or unlawful material;</li>
                <li>infringes a third party's intellectual property right(s);</li>
                <li>publishes, posts, uploads, or otherwise distributes any software, music, videos, or other material
                    protected by intellectual property laws (or by rights of privacy or publicity) unless you have all
                    necessary rights and consents to do so;</li>
                <li>uploads files that contain viruses, corrupted files, or any other similar software or programs that
                    may damage the operation of another person's computer;</li>
                <li>downloads any file that you know, or reasonably should know, cannot be legally distributed in that
                    way;</li>
                <li>falsifies or deletes any author attributions, legal or proprietary designations, labels of the
                    origin or source of software, or other material contained in a file that is uploaded;</li>
                <li>restricts or inhibits any other user of our Service from using and enjoying our website and/or our
                    Service;</li>
                <li>harvests or otherwise collects information about others, including e-mail addresses, without their
                    consent;</li>
                <li>violates the usage standards or rules of an entity affected by your use, including without
                    limitation any ISP, ESP, or news or user group (and including by way of example and not limitation
                    circumventing or exceeding equipment use rights and restrictions and/or location and path
                    identification detail);</li>
                <li>is legally actionable between private parties; and/or</li>
                <li>is in violation of any applicable local, state, national or international law or regulation,
                    including all export laws and regulations and without limitation the Controlling the Assault of
                    Non-Solicited Pornography and Marketing Act (CAN-SPAM Act) (15 U.S.C. § 7701 et seq.), the U.S
                    Telephone Consumer Protection Act of 1991 (47 U.S.C. § 227), the Do-Not-Call Implementation Act of
                    2003 (15 U.S.C. § 6152 et seq.; originally codified at § 6101 note), Canada’s Anti-Spam Legislation
                    (CASL) (S.C. 2010, c. 23), and any regulations having the force of law or laws in force in your or
                    your email recipient's country of residence.</li>
            </ul>
            <p>You will use our Service for your internal business purposes and will not: (i) willfully tamper with the
                security of our Service or tamper with our customer accounts; (ii) access data on our Service not
                intended for you; (iii) log into a server or account on our Service that you are not authorized to
                access; (iv) attempt to probe, scan or test the vulnerability of our Service or to breach the security
                or authentication measures without proper authorization; (v) willfully render any part of our Service
                unusable; (vi) lease, distribute, license, sell or otherwise commercially exploit our Service or make
                our Service available to a third party other than as contemplated in your subscription to our Service;
                (vii) use our Service for timesharing or service bureau purposes or otherwise for the benefit of a third
                party; or (viii) provide to third parties any evaluation version of our Service without our prior
                written consent.</p>
            <h3>My Tutor Platform Trademark Use:</h3>
            <p>Unless you have our express prior written permission, you may not use any name, logo, tagline or other
                mark of ours or our Service, or any identifier or tag generated by our Service, including without
                limitation: (a) as a hypertext link to any website or other location (except as provided for or enabled
                expressly by us); (b) to imply identification with us as an employee, contractor, agent or other similar
                representative capacity. You also agree not to remove or alter any of these items as we may have
                provided or enabled.</p>
            <h3>General Terms:</h3>
            <p>If you breach of this AUP we may immediately suspend your access to our Service. We may also terminate
                your and our subscription agreement for cause if you breach this AUP. You acknowledge we may disclose
                information regarding your use of any Service to satisfy any law, regulation, government request, court
                order, subpoena or other legal process. If we make this type of required disclosure we will notify you,
                unless we are required to keep the disclosure confidential.<br>
                We are not obligated to, but may choose to, remove any prohibited materials and deny access to any
                person who violates this AUP. We further reserve all other rights.<br>
                We may update and change any part or all of this AUP. If we update or change this AUP, we will let you
                know by posting the revised copy on our website. The updated AUP will become effective and binding on
                the next business day after it is posted. When we change this AUP, the “Version of” date above will be
                updated to reflect the date of the most recent version. We encourage you to review this AUP
                periodically.</p>
            <h6 class="text-center">© 2022 My Tutor Platform LLC. Any rights not expressly granted herein are reserved by My Tutor Platform.</h6>
        </div>
    </div>
</div>
<app-footer></app-footer>