<div class="contact-box">
   <form id="contact-form" [formGroup]="aboutForm">
      <div class="row">
         <div class="col-12 col-md-12">
            <p class="tabs-below-text" trans="Tell us about yourself">Tell us about yourself</p>
         </div>
      </div>
      <div class="row">
         <div class="col-12 col-md-6">
            <div class="form-group">
               <input formControlName="first_name" type="text" class="form-control" placeholder="First Name"
                  trans-placeholder="First Name *">
               <div *ngFor="let error of errorMessages.first_name">
                  <ng-container *ngIf="first_name.hasError(error.type) && (first_name.touched)">
                     <small class="error-message">{{error.message}}</small>
                  </ng-container>
               </div>
            </div>
         </div>
         <div class="col-12 col-md-6">
            <div class="form-group">
               <input formControlName="last_name" type="text" class="form-control" placeholder="Last Name"
                  trans-placeholder="Last Name *">
               <div *ngFor="let error of errorMessages.last_name">
                  <ng-container *ngIf="last_name.hasError(error.type) && (last_name.touched)">
                     <small class="error-message">{{error.message}}</small>
                  </ng-container>
               </div>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-12 col-md-6">
            <div class="form-group show_eye2">
               <input autocomplete="new-password" type="password" (blur)="selectPassword()" class="form-control"
                  formControlName="password" id="passwordAbout" placeholder="Password *" trans-placeholder="Password *">
               <span (click)="togglePassword('passwordAbout')" toggle="#password"
                  class="fa fa-fw fa-eye-slash passwordAbout"></span>
               <div *ngFor="let error of errorMessages.password">
                  <ng-container *ngIf="password.hasError(error.type) && (password.touched)">
                     <small class="error-message">{{error.message}}</small>
                  </ng-container>
               </div>
            </div>
         </div>
         <div class="col-12 col-md-6">
            <div class="form-group show_eye2">
               <input type="password" class="form-control" (blur)="selectPassword()" formControlName="confirmPassword"
                  id="confirmPassword" placeholder="Password *" trans-placeholder="Re-enter Password *">
               <span (click)="togglePassword('confirmPassword')" toggle="#password"
                  class="fa fa-fw fa-eye-slash confirmPassword"></span>
               <div class="error-message" *ngIf="aboutForm.hasError('notSame')"><small>{{mobile_error_message}}</small>
               </div>
               <div *ngFor="let error of errorMessages.confirmPassword">
                  <ng-container *ngIf="confirmPassword.hasError(error.type) && (confirmPassword.touched)">
                     <small class="error-message">{{error.message}}</small>
                  </ng-container>
               </div>
            </div>
         </div>
      </div>
      <div class="row">
         <!-- <div class="col-12 col-md-6">
            <div class="form-group">
               <select formControlName="country" class="form-control ">
                  <option value="" trans="Please select">Please select</option>
                  <option [value]="count.name" *ngFor="let count of countriesdata$ | async">
                     {{count.name}}({{count.dial_code}})</option>
               </select>
               <div *ngFor="let error of errorMessages.country">
                  <ng-container *ngIf="country.hasError(error.type) && (country.touched)">
                     <small class="error-message">{{error.message}}</small>
                  </ng-container>
               </div>
            </div>
         </div> -->
         <div class="col-12 col-md-6">
            <div class="form-group">
               <input formControlName="email" type="email" (blur)=selectEmail(email) class="form-control" id="email"
                  placeholder="Email *" trans-placeholder="Email *">
               <div class="error-message" *ngIf="status=='danger'"><small>{{error_message}}</small></div>
               <div *ngFor="let error of errorMessages.email">
                  <ng-container *ngIf="email.hasError(error.type) && (email.touched)">
                     <small class="error-message">{{error.message}}</small>
                  </ng-container>
               </div>
            </div>
         </div>
         <div class="col-12 col-md-6">
            <div class="form-group">
               <input (keydown)="validateNumber($event)" formControlName="phone" type="number"
                  (blur)=selectNumber(phone) class="form-control numbers" placeholder="Mobile"
                  trans-placeholder="Mobile Number *" minlength="10">

               <div class="error-message" *ngIf="status=='phonedanger'"><small>{{error_message}}</small></div>
               <div *ngFor="let error of errorMessages.phone">
                  <ng-container *ngIf="phone.hasError(error.type) && (phone.touched)">
                     <small class="error-message">{{error.message}}</small>
                  </ng-container>
               </div>
            </div>
         </div>
      </div>
      <!-- <div class="row">
         
         <div class="col-12 col-md-6">
            <div class="form-group">
               <select formControlName="gender" class="form-control ">
                  <option value="" trans="Please select"> Please select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
               </select>
               <div *ngFor="let error of errorMessages.gender">
                  <ng-container *ngIf="gender.hasError(error.type) && (gender.touched)">
                     <small class="error-message">{{error.message}}</small>
                  </ng-container>
               </div>
            </div>
         </div> 
      </div> -->

      <div class="row">
         <div class="col-12 col-md-6">
            <div class="form-group">
               <!-- <label for="last" trans="Display Name">
                  Display Name
               </label> -->
               <input (input)="countDisplayName($event.target.value)" maxlength="35" formControlName="display_name"
                  type="text" class="form-control" placeholder="Display Name" trans-placeholder="Display Name *"
                  id="last">
               <div *ngFor="let error of errorMessages.display_name">
                  <ng-container *ngIf="display_name.hasError(error.type) && (display_name.touched)">
                     <small class="error-message">{{error.message}}</small>
                  </ng-container>
               </div>
               <div>
                  <span>20-35 .{{givenDisplayName}} </span> <span trans="Characters Currently.">Characters Currently.</span>
               </div>
            </div>
         </div>
         <div class="form-group">
            <div class="col-12 col-md-6">
      
               <textarea class="form-control" maxlength="500" (input)="countHighlightCharacters($event.target.value)"
                  formControlName="highlight" placeholder="Subject Expertise"
                  trans-placeholder="Subject Expertise*" style="height: 50px !important;"></textarea>
               <div *ngFor="let error of errorMessages.highlight">
                  <ng-container *ngIf="highlight.hasError(error.type) && (highlight.touched)">
                     <small class="error-message">{{error.message}}</small>
                  </ng-container>
               </div>
               <div>
                  <span>50-500 . {{givenHighlightChracters}} </span> <span trans="Characters Currently.">Characters Currently.</span>
               </div>
            </div>
         </div>
         <!-- <div class="col-12 col-md-6">
            <div class="form-group">
               <select formControlName="default_currency" class=" form-control Currency_drop">
                  <option value="" trans="Please select">Please select</option>
                  <option [value]="currency.code" *ngFor="let currency of popularCurrencies">
                     {{currency.code}}-{{currency.symbol}}</option>
               </select>
               <div *ngFor="let error of errorMessages.default_currency">
                  <ng-container *ngIf="default_currency.hasError(error.type) && (default_currency.touched)">
                     <small class="error-message">{{error.message}}</small>
                  </ng-container>
               </div>
            </div>
         </div> -->

      </div>

      <div class="row">

         

         <div class="form-group">
            <div class="col-12 col-md-6">
               <!-- <label for="last" trans="About the tutor">
                  About the tutor
               </label> -->
               
               <textarea class="form-control" maxlength="500" (input)="countCharacters($event.target.value)"
                  formControlName="description" placeholder="About The Tutor *"
                  trans-placeholder="About The Tutor *"></textarea>
               <div *ngFor="let error of errorMessages.description">
                  <ng-container *ngIf="description.hasError(error.type) && (description.touched)">
                     <small class="error-message">{{error.message}}</small>
                  </ng-container>
               </div>
               <div>
                  <span>80-500 . {{givenChracters}} </span><span trans="Characters Currently.">Characters Currently.</span>
               </div>
            </div>
         </div>
         
      </div>



      <div *ngIf=aboutForm.valid?func():false>
      </div>
      <div *ngIf=!aboutForm.valid?aboutFormInComplete():false>
      </div>

   </form>
</div>