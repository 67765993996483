import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CurrentUser } from '../../../auth/current-user';

@Component({
  selector: 'app-sub-pageheader',
  templateUrl: './sub-pageheader.component.html',
  styleUrls: ['./sub-pageheader.component.css']
})
export class SubPageheaderComponent implements OnInit {

  constructor(private router: Router,public currentUser: CurrentUser) { }

  ngOnInit(): void {
    if(this.router.url == "/join-as-tutor"){
    $(".main-header-area").removeClass("nav-white");
    $(".navLogo").attr("src","assets/images/logo_white.png");
   }else{
    $(".main-header-area").addClass("nav-white");
    $(".navLogo").attr("src","assets/images/logo_white.png");
   }
  }

    private dashboard(){
    if(this.currentUser.hasRole('Tutor')){
      this.router.navigateByUrl('tutor');
    }else if(this.currentUser.hasRole('Student')){      
      this.router.navigateByUrl('student');
    }else{
      console.log('checkAdmin');
      this.router.navigateByUrl('admin');
    }
  }

}
