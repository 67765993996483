<div class="m-auto row">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
   <form [formGroup]="notificationbSettings">
	<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
	  <h4 trans="Auto-confirmation" class="TT-AccSettings">Auto-confirmation</h4>
	 </div>

	<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
	  <p class="TT-paraText">
		Tutorplatform will confirm your lessons automatically 72 hours after the scheduled end time unless you report an issue. When a lesson is confirmed, your tutor gets paid.</p>

      <p class="TT-paraText">Choose which lessons Tutorplatform should confirm automatically:</p>						
	 </div>

	  <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-12">
	    <label class="Radcontainer confirmation_txt" trans="Only lessons scheduled by you">Only lessons scheduled by you
		 <input type="radio" name="auto_confirm" formControlName="auto_confirm" value="lessons_by_you">
		 <span class="checkmark"></span>
	   </label>
	  </div>

	  <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-12">
	    <label class="Radcontainer confirmation_txt" trans="All your lessons, including weekly lessons and lessons scheduled or rescheduled by your tutor">
		  All your lessons, including weekly lessons and lessons scheduled or rescheduled by your tutor
		  <input type="radio" name="auto_confirm" formControlName="auto_confirm" value="lessons_all">
		    <span class="checkmark"></span>
		</label>
	  </div>

	  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 form-group TT-formGroup clearfix">
		<button type="submit" class="btn TT-primaryBTN TT-autoWidth TT-topZero pull-right" (click)="updateAutoconfirmSettings()" trans="Save changes">Save changes</button>
	  </div>

	  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group TT-formGroup">
		<p class="TT-paraText">Autoconfirmation for lessons in the Tutorplatform Space Autoconfirm tracks the time you spend connected with your tutor in the Tutorplatform Space. 15 minutes after a lesson is completed, it is confirmed automatically and we release your	tutor's payment. If there are any issues, please contact us right after the lesson and we'll help you find a solution</p>
	   </div>
     </form>
  </div>
</div>