import { Injectable } from '@angular/core';
import { AppHttpClientService } from '../core/http/app-http-client.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class WebSiteService {

  constructor(private http: AppHttpClientService) { }

  
      public tutorPostRegistration(payload: object){
        return this.http.post('tutorRegistration', payload);
      }

      public emailVailidation(payload: object){
        return this.http.post('emailVailidation', payload);
      } 

      public phoneVailidation(payload: object){
        return this.http.post('phoneVailidation', payload);
      } 
     
      public getPageData(title){
        return this.http.get('more/'+title);
      }

      public getPageCategories() {
        return this.http.get('getPageCategories');
      }

      public getPagesList(){
        return this.http.get('getPagesList');
      }

      public getHomepageData(){
        return this.http.get('getHomepageData');
      }
}
