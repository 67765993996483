<header class="section header-area">
   <div id="appo-header" class="main-header-area nav-white main-header-area main-header-area_bacground_color">
      <div class="container-fluid">
         <nav class="navbar navbar-expand-md navbar-light">
            <!-- Logo -->
            <a class="navbar-brand cursor mobile_res">
            <img routerLink="/online-tutoring" id="logo" class="logo navLogo" src="assets/images/logo_white.png">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#appo-menu">
            <span class="navbar-toggler-icon"></span>
            </button>
            <!-- Appo Menu -->
            <div class="collapse navbar-collapse" id="appo-menu">
               <!-- Header Items -->
               <ul class="navbar-nav header-items ml-auto ">
                  <li  *ngIf="!PWAStatus"  class="nav-item active ">

                     <a class="nav-link scroll"  href="#home" trans="HOME">HOME </a>

                  </li>
                  <li  *ngIf="!PWAStatus" class="nav-item">
                     <a class="nav-link scroll" href="javascript:void(0);" (click)="findTeacher()" trans="Need a Tutor?">Need a Tutor?</a>
                  </li>
                  <li *ngIf="currentUser.isLoggedIn()" class="nav-item">
                     <a (click)="dashboard()" class="nav-link scroll cursor" trans="Dashboard">Dashboard </a>
                  </li>
                  <li *ngIf="!currentUser.isLoggedIn()" class="nav-item">
                     <a class="nav-link scroll cursor" (click)="tutorRegistration()" trans="Join as Tutor">Join as Tutor </a>
                  </li>
                  <li *ngIf="!currentUser.isLoggedIn()" class="nav-item">
                     <a class="nav-link scroll cursor" (click)="pricing()" trans="Pricing">Pricing </a>
                  </li>
                  <li *ngIf="!currentUser.isLoggedIn()" class="nav-item">
                     <a id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel" class="nav-link scroll cursor" data-toggle="modal" data-target="#loginModal" trans="Login">Login</a>
                  </li>
               </ul>
            </div>
         </nav>
      </div>
   </div>
</header>

<app-login></app-login>
