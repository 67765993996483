import {Injectable} from '@angular/core';
import {User} from '@core/types/models/User';
import {Role} from '@core/types/models/Role';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Permission} from '../core/types/models/Permission';
import {TutorTeachingService} from '../tutor/teaching/teaching.service';
import { AuthService } from '@auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class CurrentUser {
    public model$ = new BehaviorSubject<Partial<User>>(null);
    public isLoggedIn$ = this.model$.pipe(map(u => !!u.id));
    private guestsRole: Role;
    private permissions: {[key: string]: Permission} = {};

    /**
     * Uri user was attempting to open before
     * redirect to login page, if any.
     */
    public redirectUri?: string;

    public get<K extends keyof User>(prop: K): Partial<User>[K]  {
        return this.model$.value && this.model$.value[prop];
    }

    public getModel(): Partial<User> {
        return {...this.model$.value};
    }

    public set(key: string, value: any): void {
        this.model$.next({...this.model$.value, [key]: value});
    }

    public setObject(user){
     Object.keys(user).forEach(key => {
      this.model$.next({...this.model$.value, [key]: user[key]});
});
    }

    public assignCurrent(model?: Partial<User>) {
        if ( ! model) {
            // guest model
            model = {roles: [this.guestsRole], permissions: this.guestsRole?.permissions};
        }
        this.setPermissions(model);
        this.model$.next(model);
    }

    public hasPermissions(permissions: string[]): boolean {
        return permissions.filter(permission => {
            return !this.hasPermission(permission);
        }).length === 0;
    }

    public hasPermission(permission: string): boolean {
        return !!this.permissions['admin'] || !!this.permissions[permission];
    }

    public hasRole(role: string): boolean {
        return this.model$.value.roles && !!this.model$.value.roles.find(r => r?.name === role);
    }

    public getRestrictionValue(permissionName: string, restrictionName: string): number|null {
        const permission = this.permissions[permissionName];
        let restrictionValue = null;
        if (permission) {
            const restriction = permission.restrictions.find(r => r.name === restrictionName);
            restrictionValue = restriction ? restriction.value : null;
        }
        return restrictionValue;
    }

    public isLoggedIn(): boolean {
        return this.get('id') > 0;
    }

    public isValid(): string {
        let is_valid = 'no';
        if ( (this.model$.value['free_trial_days_left'] > 0) ) {
            is_valid = 'yes';
        }
        return is_valid;
    }

    public userId(){
        return this.model$.value['id'];
    }

    public userName(){
        let name = this.model$.value['username'];
        return name;
    }
    public firstName(){
        let first_name = this.model$.value['first_name'];
        return first_name;
    }
    public lastName(){
        let last_name = this.model$.value['last_name'];
        return last_name;
    }

    public fullName(){
        let first_name = this.model$.value['first_name'];
        let last_name = this.model$.value['last_name'];
        return first_name+' '+last_name;
    }

    public isImpersonating(){
     let isImpersonating = this.model$.value['isImpersonating'];
     return isImpersonating;
    }

    public country(){
        if(this.model$.value['country']){
        let country = this.model$.value['country'];
        return country;
        }else{
            return;
        }
    }

    public countryFlag(){
        return this.model$.value['resume'].flag;
    }
    
    public dashboardcountryFlag(){
         if(this.model$.value['flag']){
            let country = this.model$.value['flag'];
            return country;
            }else{
                return;
            }
    }

    public userAvater(){
        let avatar;
        if ( this.model$.value['avatar'] ) {
            avatar = this.model$.value['avatar'];
        } else {
            avatar = this.model$.value['avatar_url'];
        }
        return avatar;
    }

    public userEmail(){
        let email = this.model$.value['email'];
        return email;
    }

    public userCountry(){
        let country = this.model$.value['country'];
        return country;
    }

    public userLanguages(){
    if(this.model$.value['video']){
        return this.model$.value['video']['language'];
    }else{
        return false;
    }
    }

    public nativeLang(){
      if(this.model$.value['video']){
        return this.model$.value['video']['nativelanguage'];
       }else{
        return false;
       }
    }

    public userVideoURL(){
    if(this.model$.value['video']){
        return this.model$.value['video']['video_URL'];
    }else{
        return false;
    }
    }

    public hourlyPrice(){
        if(this.model$.value['resume']){
        let hourlyRate = this.model$.value['resume']['hourly_rate'];
        if(!this.model$.value['resume']['hourly_rate']){
            return '0';
        }else{
            let hourlyRate = this.model$.value['resume']['hourly_rate'];
            return hourlyRate;
        }
    }

    }

    public rating(){
        if(this.model$.value['resume']){
            let Rating = this.model$.value['resume']['rating'];
            if(!this.model$.value['resume']['rating']){
                return '0';
            }else{
                let Rating = this.model$.value['resume']['rating'];
                return Rating;
            }
        }
    }

    public review(){
        if(this.model$.value['resume']){
            let Review = this.model$.value['resume']['over_all_reviews'];
            if(!this.model$.value['resume']['over_all_reviews']){
                return '0';
            }else{
                let Review = this.model$.value['resume']['over_all_reviews'];
                return Review;
            }
        }
    }

    public defaultCurrency(){
     if(this.model$.value['resume']){
      let currency = this.model$.value['resume']['default_currency'];
      return currency;
     }else{
         return;
     }
    }

    public isRole(){
        return this.model$.value.roles['0']['name'];
    }

    public isTutor()
    {
        if(this.model$.value.roles){
            // console.log(this.model$.value?.roles[0])
        if(this.model$.value?.roles?.[0]?.['name']==='Tutor'){
            return true;
        }else{
            return false;
        }
        }
    }

    public isAdmin(): boolean {
        if(this.model$.value.roles){
        if(this.model$.value.roles?.['0']?.['name']==='Admin'){
            return true;
        }else{
            return false;
        }
        }
    }
    public isStudent(): boolean {
        if(this.model$.value.roles){
        if(this.model$.value.roles?.['0']?.['name']==='Student'){
            return true;
        }else{
            return false;
        }
        }
    }

    public init(params: { user?: User, guestsRole: Role }) {
        this.guestsRole = params.guestsRole;
        this.assignCurrent(params.user);
    }


    private setPermissions(model: Partial<User>) {
        this.permissions = {};
        (model.permissions || []).forEach(permission => {
            this.permissions[permission.name] = permission;
        });
    }

    public isRegistrationCompleted(){
        
        if(this.model$.value.registration_process == 'Completed' && this.model$.value.email_confirmed == 'yes'){
           return true;
        }else{
            return false;
        }
    }

        public isResumeUpdated(){
        if(this.model$.value['registration_status']['resume_status'] === 'Completed'
            || this.model$.value['registration_status']['resume_status'] === 'Under-Review'){
           return true;
        }else{
            return false;
        }
    }
        public isAvailabilityVerified(){
        if(this.model$.value['registration_status']['availability_status'] === 'Completed'
            || this.model$.value['registration_status']['video_status'] === 'Under-Review'){
           return true;
        }else{
            return false;
        }
    }
        public isVideoVerified(){
        if(this.model$.value['registration_status']['video_status'] === 'Completed'
            || this.model$.value['registration_status']['video_status'] === 'Under-Review'){
           return true;
        }else{
            return false;
        }
    }


        public registrationStatus(){
            if(this.model$.value.registration_process==='IN-Complete'){
            let tutorStatus = this.model$.value.registration_process;
        return tutorStatus;
            }
    }

    public resumeStatus(){
        if(this.model$.value['registration_status']['resume_status']){
            let resumeStatus = this.model$.value['registration_status']['resume_status'];
            return resumeStatus;
        }
    }

    public availabilityStatus(){
        if(this.model$.value['registration_status']['availability_status']){ 
            let availability_status = this.model$.value['registration_status']['availability_status'];
            return availability_status;
        } 
        
    }

    public videoStatus(){
        if(this.model$.value['registration_status']['video_status']){
            let video_status = this.model$.value['registration_status']['video_status'];
            return video_status;
        }    
    }

    public emailStatus(){        
        return this.model$.value.email_confirmed;
    }

    public sendVerifyEmail() {
        
    }
}
