import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Payment} from '../core/types/models/Payment';

@Injectable({
    providedIn: 'root'
})
export class PaymentDetail {

    public paymentDetails$ = new BehaviorSubject<Partial<Payment[]>>([]);
    changepaymentDetails$ = this.paymentDetails$.asObservable();

	 public init(params) {
     this.paymentDetails$.next(params);
    }


}