import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutors-list',
  templateUrl: './tutors-list.component.html',
  styleUrls: ['./tutors-list.component.css']
})
export class TutorsListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
