
                                 <div *ngIf="ratingValue == '0'" id="starRating001" class="flex">
                                    <svg height="24" viewBox="0 0 24 24" width="24">
                                       <defs>
                                          <linearGradient id="grad">
                                             <stop offset="0%" stop-color="#ffffff" />
                                             <stop offset="100%" stop-color="#ffffff" />
                                          </linearGradient>
                                       </defs>
                                       <path d="M0 0h24v24H0z" fill="none"/>
                                       <path
                                          fill="url(#grad)"
                                          stroke="black"
                                          stroke-width="0.4"
                                          d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
                                          />
                                       <path d="M0 0h24v24H0z" fill="none"/>
                                    </svg>
                                    <span class="lesson-star pt-2 pt-0">
                                    {{ratingValue}} ({{reviewValue}})
                                    </span>
                                 </div>
                                 <div *ngIf="ratingValue == '1'" id="starRating002" class="flex">
                                    <svg height="24" viewBox="0 0 24 24" width="24">
                                       <defs>
                                          <linearGradient id="grad1">
                                             <stop offset="0%" stop-color="#ffd055" />
                                             <stop offset="30%" stop-color="#ffd055" />
                                             <stop offset="30%" stop-color="#ffffff" />
                                             <stop offset="100%" stop-color="#ffffff" />
                                          </linearGradient>
                                       </defs>
                                       <path d="M0 0h24v24H0z" fill="none"/>
                                       <path
                                          fill="url(#grad1)"
                                          stroke="black"
                                          stroke-width="0.4"
                                          d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
                                          />
                                       <path d="M0 0h24v24H0z" fill="none"/>
                                    </svg>
                                    <span class="lesson-star">
                                    {{ratingValue}} ({{reviewValue}})
                                    </span>
                                 </div>
                                 <div *ngIf="ratingValue == '2'" id="starRating003" class="flex">
                                    <svg height="24" viewBox="0 0 24 24" width="24">
                                       <defs>
                                          <linearGradient id="grad2">
                                             <stop offset="0%" stop-color="#ffd055" />
                                             <stop offset="50%" stop-color="#ffd055" />
                                             <stop offset="50%" stop-color="#ffffff" />
                                             <stop offset="100%" stop-color="#ffffff" />
                                          </linearGradient>
                                       </defs>
                                       <path d="M0 0h24v24H0z" fill="none" />
                                       <path
                                          fill="url(#grad2)"
                                          stroke="black"
                                          stroke-width="0.4"
                                          d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
                                          />
                                       <path d="M0 0h24v24H0z" fill="none"/>
                                    </svg>
                                    <span class="lesson-star">
                                    {{ratingValue}} ({{reviewValue}})
                                    </span>
                                 </div>
                                 <div *ngIf="ratingValue == '3'" id="starRating003" class="flex">
                                    <svg height="24" viewBox="0 0 24 24" width="24">
                                       <defs>
                                          <linearGradient id="grad2">
                                             <stop offset="0%" stop-color="#ffd055" />
                                             <stop offset="60%" stop-color="#ffd055" />
                                             <stop offset="60%" stop-color="#ffffff" />
                                             <stop offset="100%" stop-color="#ffffff" />
                                          </linearGradient>
                                       </defs>
                                       <path d="M0 0h24v24H0z" fill="none" />
                                       <path
                                          fill="url(#grad2)"
                                          stroke="black"
                                          stroke-width="0.4"
                                          d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
                                          />
                                       <path d="M0 0h24v24H0z" fill="none"/>
                                    </svg>
                                    <span class="lesson-star">
                                    {{ratingValue}} ({{reviewValue}})
                                    </span>
                                 </div>
                                 <div *ngIf="ratingValue == '4'" id="starRating004" class="flex">
                                    <svg height="24" viewBox="0 0 24 24" width="24">
                                       <defs>
                                          <linearGradient id="grad3">
                                             <stop offset="0%" stop-color="#ffd055" />
                                             <stop offset="70%" stop-color="#ffd055" />
                                             <stop offset="70%" stop-color="#ffffff" />
                                             <stop offset="100%" stop-color="#ffffff" />
                                          </linearGradient>
                                       </defs>
                                       <path d="M0 0h24v24H0z" fill="none" />
                                       <path
                                          fill="url(#grad3)"
                                          stroke="black"
                                          stroke-width="0.4"
                                          d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
                                          />
                                       <path d="M0 0h24v24H0z" fill="none"/>
                                    </svg>
                                    <span class="lesson-star">
                                    {{ratingValue}} ({{reviewValue}})
                                    </span>
                                 </div>
                                 <div *ngIf="ratingValue == '5'" id="starRating005" class="flex">
                                    <svg height="24" viewBox="0 0 24 24" width="24">
                                       <defs>
                                          <linearGradient id="grad5">
                                             <stop offset="0%" stop-color="#ffd055" />
                                             <stop offset="100%" stop-color="#ffd055" />
                                          </linearGradient>
                                       </defs>
                                       <path d="M0 0h24v24H0z" fill="none" />
                                       <path
                                          fill="url(#grad5)"
                                          stroke="black"
                                          stroke-width="0.4"
                                          d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
                                          />
                                       <path d="M0 0h24v24H0z" fill="none"/>
                                    </svg>
                                    <span class="lesson-star pt-2">
                                    {{ratingValue}} ({{reviewValue}})
                                    </span>
                                 </div>