import { Component, OnInit, ViewChild , ElementRef, Injector } from '@angular/core';
import { CurrentUser } from '../../auth/current-user';
import { Echo } from 'laravel-echo-ionic';
import { TutorLessonsService } from '../tutorlessons/tutorlessons.service';
import { Bootstrapper } from '../../core/bootstrapper.service';
import { Router } from '@angular/router';
import { Currencies } from '../../auth/currencies';
import { LocaleCurrency } from '../../auth/localecurrency';

import { BehaviorSubject, Observable } from 'rxjs';
import { LocalizationWithLines } from '../../core/types/localization-with-lines';
import { Translations } from '../../core/translations/translations.service';
import { Localizations } from '../../core/translations/localizations.service';
import { distinctUntilChanged, finalize } from 'rxjs/operators';

declare var $;

@Component({
  selector: 'app-tutor-sidenav',
  templateUrl: './tutor-sidenav.component.html',
  styleUrls: ['./tutor-sidenav.component.css'],
  host: {
    "(window:click)": "onClick()"
  }
})
export class TutorSidenavComponent implements OnInit {
  @ViewChild('closeSuccessModal') closeSuccessModal: ElementRef;
  @ViewChild('alertModal') alertModal: ElementRef;
  public host_socket;
  public usernotifications;
  public usernotifications_read;
  public usernotifications_read_count;
  public usernotifications_unread;
  public usernotifications_unread_count = 0;
  public alertMessage;
  public popularCurrencies;
  public localeCurrency;
  public usermessages_unread_count;
  public firstPageRoute:any;
  public isMenuOpen = false;
  public menuActive : any='';
  public title :string;
  public body :string;
  public payloadPush = Object();
  public languages$ = new BehaviorSubject<LocalizationWithLines[]>([]);
  public selectedLanguage:any;
  public loading$ = new BehaviorSubject<boolean>(false);

  echo: any = null;
  constructor(
  	      public currentuser: CurrentUser,
          public service: TutorLessonsService,
          public bootstrapper: Bootstrapper,
          private router: Router,
          public currencies: Currencies,
          public localecurrency: LocaleCurrency,
          protected i18n: Translations,
          protected injector: Injector,
          private localizationsApi: Localizations) {
this.localecurrency.changelocaleCurrency$.subscribe(changeCurrency => this.changeCurrency(changeCurrency));
this.host_socket = this.bootstrapper.data.host_socket;

            this.localeCurrency = this.localecurrency.localeCurrencyCode();
            this.popularCurrencies = this.currencies.currencies();

            this.i18n = this.injector.get(Translations);
            // console.log(this.i18n)
        }

  ngOnInit(): void {
        this.service.getFirstMoreOrder().subscribe(response=>{
              let data = response['data'];
              this.firstPageRoute = data['categoryFirst'];
       });
    this.getNotifications();
    
    $(document).on('click', function (event) {
      if (!$(event.target).closest('#alerts').length) {
              $("#alert-box-alerts").removeClass("activeAlert");
              $("#alerts").removeClass("active");
        }
    });

    $('.collapseToggle').on('click', function() {
      $(".tt-sideArea").toggleClass('sidebar--Collapse');
      $('.pageSectionBg').toggleClass('pageSectionBg-collapsable');	
      $(".tt-sideArea li").click(function(){
        $(".tt-sideArea").removeClass("sidebar--Collapse");
      });
    });

    this.localizationsApi.all()
                .pipe(finalize(() => this.loading$.next(false)))
                .subscribe(response => {
                this.setLocalizations(response.localizations);
     });
    this.selectedLanguage = localStorage.getItem('defaultLanguage');
    if(!this.selectedLanguage || this.selectedLanguage == 'undefined'){
            this.selectedLanguage = 'english';
            this.selectLang('english');
    }
  }

    public changeCurrency(currency){
     if (currency) {
      this.localeCurrency = currency.code;
      $("#currencyDropDown").val(currency.code);
     }
  }

      public removeAlert(){
        if($("#alert-box-alerts").hasClass("activeAlert")){
          $("#alert-box-alerts").removeClass("activeAlert");
          $("#alerts").removeClass("active");
        }
        this.getNotifications();
    }

    public getNotifications(){
      this.service.getNotification().subscribe(response=>{

      if ( response['status'] == 'authfailed' ) {
        location.reload();
      } else {
        let data =JSON.parse(atob(response['notifications']));

        this.usernotifications = data['notifications'];

        this.usernotifications_read = this.usernotifications.is_read;
        // this.usernotifications_read_count = this.usernotifications.is_read.length;
        this.usernotifications_read_count = (this.usernotifications.is_read) ? this.usernotifications.is_read.length : 0;
        
        this.usernotifications_unread = this.usernotifications.is_unread;
        this.usernotifications_unread_count = this.usernotifications.is_unread.length;

        this.usermessages_unread_count = data['inboxCount'];
      }

      });
    }

    public reloadCurrentRoute() {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);
        });
    }

   // alerts
    public openAlerts(){

        if($("#alert-box-alerts").hasClass("activeAlert")){
          $("#alerts").removeClass("active");
          $("#alert-box-alerts").removeClass("activeAlert");
        }else{
          $("#alert-box-alerts").addClass("activeAlert");
          $("#alerts").addClass("active");
        }

       this.getNotifications();
  }

    public viewSchedules(){
    this.closeSuccessModal.nativeElement.click();
    this.router.navigate(['tutor/tutor-lessons']);
  }

  public validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  }

  public viewAlert(notification){

    (notification.redirect_url && this.validateUrl(notification.redirect_url)) ? this.router.navigate([notification.redirect_url]) : this.router.navigate(['tutor/tutor-lessons/booking-requests']);

   if(notification.is_read == 0){
        this.service.readNotification(notification.id).subscribe(response=>{
        this.usernotifications =JSON.parse(atob(response['notifications']));
        this.usernotifications_read = this.usernotifications.is_read;
        this.usernotifications_read_count = this.usernotifications.is_read.length;
        
        this.usernotifications_unread = this.usernotifications.is_unread;
        this.usernotifications_unread_count = this.usernotifications.is_unread.length;
      });
      }
   this.removeAlert();
  }

    public selectedCurrency(value){
    this.popularCurrencies.find(x => {
      if(x.code == value){
         return this.localecurrency.init(x);
      }
    });
    if(this.currentuser.isLoggedIn$){
    let currency = Object();
    Object.assign(currency , {default_currency:value});
    this.service.updateCurrency(currency).subscribe(response =>{
      if(response.status == 'success'){
        this.currentuser.model$.value['default_currency'] = value;
      }
    });
  }
  }

  public more(){
    if(this.firstPageRoute){
    this.router.navigate(['tutor/more/'+this.firstPageRoute]);
    }else{
       this.router.navigate(['more']);
    }
  }

   public moreOption(){
  if($('.dropup').hasClass('open')){
     $('.dropup').removeClass('open')
  }else{
     $('.dropup').addClass('open')
  }
 }

 public humanize(str) {
  var i, frags = str.split('_');
  for (i=0; i<frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

public displayTextLimit(text) {
  if ( text.length > 150 ) {
    return text.substring(0, 150) + '...';
  } else {
    return text;
  }
}

 public toggleMenu($event){
  $event.stopPropagation();
  this.isMenuOpen = !this.isMenuOpen;
  this.menuActive = 'more';
 }

 onClick(){
   this.isMenuOpen = false;
 }

 public menuSelect(id:any){
  this.menuActive = id;

}

public pushNotification()
{

  this.title = 'Sample title';
  this.body = 'Sample body';
  
  this.payloadPush = {title:this.title,body:this.body};

  this.service.pushNotification(this.payloadPush).subscribe(response=>{
              console.log(response)
              //let data = response['data'];
             // this.firstPageRoute = data['categoryFirst'];
       });
}

public selectLang(langId){
  localStorage.setItem('defaultLanguage' , langId);
  this.bootstrapper.changeLanguage(langId);

  setTimeout(() => this.getFooterLinks(), 1000);
 }
 public getFooterLinks() {

 }
 private setLocalizations(localizations: LocalizationWithLines[]) {
  this.languages$.next(localizations);
}

}