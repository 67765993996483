import { Injectable } from '@angular/core';
import { AppHttpClientService } from '../core/http/app-http-client.service';
import { CurrentUser } from './current-user';
import { Settings } from '../core/config/settings.service';
import { ActivatedRoute, NavigationEnd , Router} from '@angular/router';
import { Bootstrapper } from '../core/bootstrapper.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  public profile:any;
  public currentRoute;
  constructor(
        protected httpClient: AppHttpClientService,
        protected currentUser: CurrentUser,
        protected config: Settings,
        protected router: Router,
        private bootstrapper: Bootstrapper) { 

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const urlTree = this.router.parseUrl(val.urlAfterRedirects);
        const urlSegments = urlTree.root.children['primary'].segments.map(segment => segment.path);
        if(urlSegments[3]){
        this.currentRoute = (`/${urlSegments[0]}/${urlSegments[1]}/${urlSegments[2]}/${urlSegments[3]}`);
        }else{
        this.currentRoute = (`/${urlSegments[0]}/${urlSegments[1]}/${urlSegments[2]}`);
        }
      }
    });
   }

      /**
     * Log user in with specified credentials.
     */
    public login(credentials: Object){
        return this.httpClient.post('auth/login', credentials);
    }

    public conformotp(credentials: Object){
        return this.httpClient.post('auth/confirmotp', credentials);
    }

    public resetotp(credentials: Object){
        return this.httpClient.post('auth/resetotp', credentials);
    }

        /**
     * Get URI user should be redirect to after login.
     */
    public getRedirectUri(): string {
       

        if(this.currentUser.hasRole('Student')){
            if(this.currentRoute == '/student/find-tutor/tutor-details'){
            return '/student/find-tutor/tutor-details';
            }else{
            return '/student/home';
            }
        }

        if(this.currentUser.hasRole('Tutor')){
            return '/tutor/home';

        }
        
        if (this.currentUser.redirectUri) {
            const redirectUri = this.currentUser.redirectUri;
            this.currentUser.redirectUri = null;
            return redirectUri;
        } else if (this.currentUser.isAdmin()) {
            return this.config.get('vebto.auth.adminRedirectUri');
        } else {
            return this.config.get('vebto.auth.redirectUri');
        }
    }

        public logOut() {
            
        this.httpClient.post('auth/logout').subscribe((response) => {
            if(response['data']){

      this.profile = JSON.parse(atob(response['data']));
      this.bootstrapper.bootstrap(this.profile.languageChange);
                //    this.router.navigate([this.getRedirectUri()]).then(() => {});
                   this.router.navigate(['/login']);
            }else{
            this.currentUser.assignCurrent();
            this.router.navigate(['/online-tutoring']);
          }
        });
    }

    public backToAdmin(){
            this.httpClient.get('tutorLogout').subscribe((response) => {
            if(response['data']){

      this.profile = JSON.parse(atob(response['data']));
      this.bootstrapper.bootstrap(this.profile.languageChange);
                   this.router.navigate([this.getRedirectUri()]).then(() => {
                   });

            }
        });    
    }

}
