import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { CurrentUser } from '../../auth/current-user';

declare var $;

@Component({
  selector: 'app-student-header',
  templateUrl: './student-header.component.html',
  styleUrls: ['./student-header.component.css']
})
export class StudentHeaderComponent implements OnInit {
  public PWAStatus:any;
  
  constructor(public auth: AuthService,
    public currentUser: CurrentUser) { 
      this.PWAStatus = this.isPWA();
    }

  ngOnInit(): void {
    $('.collapseToggle').on('click', function() {
      $(".tt-sideArea").toggleClass('sidebar--Collapse');
      $('.pageSectionBg').toggleClass('pageSectionBg-collapsable');	
      $(".tt-sideArea li").click(function(){
        $(".tt-sideArea").removeClass("sidebar--Collapse");
      });
    });
  }

  public isPWA() {
    return ["fullscreen", "standalone", "minimal-ui"].some(
        (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
    );
  }

}
