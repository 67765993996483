<div class="page-size">
    <div class="page-size-label" trans="Items per page">Items per page</div>
    <div class="input-container">
        <select class="page-size-input" [formControl]="perPageControl">
            <option [ngValue]="10">10</option>
            <option [ngValue]="15">15</option>
            <option [ngValue]="20">20</option>
            <option [ngValue]="50">50</option>
            <option [ngValue]="100">100</option>
        </select>
    </div>
</div>
<div class="range-actions">
    <div class="range-label">
        {{from}} - {{to}} {{'of'}} {{totalRecords}}
    </div>
    <button type="button" [disabled]="!havePrevious || (datable.paginator.loading$ | async)" class="range-button" (click)="previousPage()" mat-icon-button>
        <i class="fa fa-chevron-left" aria-hidden="true"></i>

    </button>
    <button type="button" [disabled]="!haveNext || (datable.paginator.loading$ | async)" class="range-button" (click)="nextPage()" mat-icon-button>
        <i class="fa fa-chevron-right" aria-hidden="true"></i>

    </button>
</div>
