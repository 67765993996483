<body data-spy="scroll" data-target="#navsec">
   <div class="container-fluid padding0">
      <div class="menus">
         <div *ngIf="!currentUser.isLoggedIn()"  (click)="checkForLogin()" class="left">
            <a class="userLogin whiteLabel text-uppercase togglemenu" data-toggle="modal" data-target="#loginModal">
            <i class="fa fa-user" aria-hidden="true"></i>
            <span trans="Login">
            Login
            </span>
            </a>
         </div>
         <div *ngIf="currentUser.isLoggedIn()" class="left">
            <a (click)="home()" class="userLogin whiteLabel text-uppercase togglemenu" >
               <i class="fa fa-home" aria-hidden="true"></i>
            <span trans="Home">
            Home
            </span>
            </a>
         </div>
         <div class="right">
            <nav id="nav" class="navbar navbar-default" data-spy="affix" data-offset-top="197">
               <div class="container-fluid">
                  <div class="navbar-header">
                     <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navsec">
                     <span class="icon-bar">
                     </span>
                     <span class="icon-bar">
                     </span>
                     <span class="icon-bar">
                     </span>
                     </button>
                  </div>
                  <div>
                     <div class="collapse navbar-collapse" id="navsec">
                        <ul class="nav navbar-nav">
                           <li page="welcome" class="active">
                              <a href="landing-page#welcome">Why My Tutor Platform</a>
                           </li>
                           <li page="how-it-works">
                              <a trans="How it Works" href="landing-page#how-it-works">
                              How it Works
                              </a>
                           </li>
                           <li page="find-a-tutor">
                              <a trans="Find a Tutor" href="landing-page#find-a-tutor">
                              Find a tutors
                              </a>
                           </li>
                           <li page="success-story">
                              <a trans="Success Stories" href="landing-page#success-story">
                              Success Stories
                              </a>
                           </li>
                           <li page="start-training">
                              <a trans="Get started Training" href="landing-page#start-training" >
                              Get started Training
                              </a>
                           </li>
                           <li *ngIf="!currentuser.isLoggedIn()" page="become-tutor" >
                              <a trans="Become a tutor" href="landing-page#become-tutor" >
                              Become a tutor
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </nav>
         </div>
      </div>
         <div id="pt-main" class="pt-perspective">
            <section id="welcome" class="pageSection ">
               <div class="content web-view">
                  <div class="col-md-12 text-center main-head-title">
                     <div class="">
                        <img src="assets/images/logo_white.png" alt="main-logo" height="100">
                     </div>
                     <h1>
                        Welcome to my tutor platform!
                     </h1>
                     <h3 class="textLine">
                        <span trans="Are you seeking a tutor for yourself or you children? Watch the">
                        Are you seeking a tutor for yourself or you children? Watch the
                        </span>
                        <br>
                        <span routerLink="/online-tutoring" trans="following video to learn the benifits of having 1 to 1 learning">
                        following video to learn the benifits of having 1 to 1 learning
                        </span>
                     </h3>
                     <div class="welcome-actions">

                        <form  class="searchBar searchDD">
                           <input (input)="inputSubject($event.target.value)" class="form-control form-control-find-tuter selectionSubject1" type="text" placeholder="what do you want to learn today?"
                           trans-placeholder="what do you want to learn today?">
                              <ul id="selectionSubject1" class="results">
                                 <li *ngFor="let subject of activeSubjects" (click)="selectSubject(subject.subject_name , 'selectionSubject1')">
                                    <a>{{subject.subject_name}}</a>
                                 </li>
                              </ul>
                           <button (click)="findTeacher()" disabled id="findATeacherBtn" class="btn btn-primary searchBtn">
                           <span trans="Find a Teacher">Find a Teacher</span>
                           </button>
                        </form>
                        <div id='intro_vid'>
                           <p trans="Watch Quick Introduction">Watch Quick Introduction</p>
                           <img src="assets/images/3.png" class="img-video" (click)="video(this.welcome_video)" id="video_Btn">
                        </div>
                  </div>
                  </div>
               </div>
               <!-- Mobile View start 1st section -->

               <div class="content mobile-view">
                  <div class="col-md-12 text-center main-head-mobile-title">
                     <div class="">
                        <img src="assets/images/logo_white.png" alt="main-logo" height="100">
                     </div>
                     <h1>Welcome to my tutor platform</h1>
                     <h3 class="mobile-textLine" trans="Are you seeking a tutor for yourself or you children? Watch the following video to learn the benifits of having 1 to 1 learning">
                        Are you seeking a tutor for yourself or you children? Watch the
                        following video to learn the benifits of having 1 to 1 learning
                     </h3>
                     <div class="welcome-actions">

                        <form  class="searchBar searchDD">
                           <input id="input_92_31"(input)="inputSubject($event.target.value)" class="form-control form-control-find-tuter selectionSubject1Mobile" type="text" placeholder="what do you want to learn today?"
                           trans-placeholder="what do you want to learn today">
                              <ul id="selectionSubject1Mobile" class="results">
                                 <li *ngFor="let subject of activeSubjects" (click)="selectSubject(subject.subject_name , 'selectionSubject1Mobile')">
                                    <a>{{subject.subject_name}}</a>
                                 </li>
                              </ul>
                           <button (click)="findTeacher()" disabled id="findATeacherBtnMobile" class="btn btn-primary searchBtn" trans="Find a Teacher">
                           Find a Teacher
                           </button>
                        </form>
                        <div id='intro_vid'>
                           <p trans="Watch Quick Introduction">Watch Quick Introduction</p>
                           <img src="assets/images/3.png" class="img-video" (click)="video(this.welcome_video)" id="video_Btn">
                        </div>
                  </div>
                  </div>
               </div>

               <!-- Mobile View end -->
               <video autoplay muted loop id="welcome-video">
                  <source src="assets/images/1-1-tutoring_web.mp4" type="video/mp4">
               </video>
            </section>
            <section id="how-it-works" class="pageSection">
               <div class="container content web-view">
                  <div class="col-md-12 text-left main-head-title">
                     <div class="howItWork_text">
                        <h2 trans="How it Works">
                           How it Works
                        </h2>
                        <p trans="It's easy. It's Simple. It's Fun.">
                           It's easy. It's Simple. It's Fun.
                        </p>
                     </div>
                  </div>
                  <div class="col-md-12 text-left tutorList "><!-- web-view -->
                     <ul>
                        <li>
                           <h1>
                              1
                              <sup>
                              <img src="assets/images/tutor.png" class="tutor-img">
                              </sup>
                              <span trans="Find a Tutor">
                              Find a Tutor
                              </span>
                           </h1>
                           <p trans="Pellentesque habitant morbi tristique senectuset netuset malesuada fames ac turpis egestas">
                              Pellentesque habitant
                              morbi tristique senectus
                              et netus et malesuada
                              fames ac turpis egestas.
                           </p>
                        </li>
                        <li>
                           <h1>
                              2
                              <sup>
                              <img src="assets/images/clock.png" class="tutor-img">
                              </sup>
                              <span trans="Schedule Time">
                              Schedule Time
                              </span>
                           </h1>
                           <p trans="Pellentesque habitant morbi tristique senectuset netuset malesuada fames ac turpis egestas">
                              Pellentesque habitant
                              morbi tristique senectus
                              et netus et malesuada
                              fames ac turpis egestas.
                           </p>
                        </li>
                        <li>
                           <h1>
                              3
                              <sup>
                              <img src="assets/images/wallet.png" class="tutor-img">
                              </sup>
                              <span trans="Pay">
                              Pay
                              </span>
                           </h1>
                           <p trans="Pellentesque habitant morbi tristique senectuset netuset malesuada fames ac turpis egestas">
                              Pellentesque habitant
                              morbi tristique senectus
                              et netus et malesuada
                              fames ac turpis egestas.
                           </p>
                        </li>
                        <li>
                           <h1>
                              4
                              <sup>
                              <img src="assets/images/attend.png" class="tutor-img">
                              </sup>
                              <span trans="Attend">
                              Attend
                              </span>
                           </h1>
                           <p trans="Pellentesque habitant morbi tristique senectuset netuset malesuada fames ac turpis egestas">
                              Pellentesque habitant
                              morbi tristique senectus
                              et netus et malesuada
                              fames ac turpis egestas.
                           </p>
                        </li>
                     </ul>
                  </div>

                  <div class="col-md-12 text-left how-it-works-watch-more">
                     <h4 trans="Watch this short video to explain how our platform works.">
                        Watch this short video to explain how our platform works. &nbsp;
                        <img src="assets/images/3.png" class="img-video" id="video_Btn" (click)="video(this.how_it_works_video)">
                     </h4>
                  </div>
               </div>

               <!-- Mobile view 2nd section start-->
                <div class="container content mobile-view">
                  <div class="col-md-12 text-left main-head-mobile-title">
                     <div class="howItWork_text">
                        <h2 trans=" How it Works">
                           How it Works
                        </h2>
                        <p trans="It's easy. It's Simple. It's Fun.">
                           It's easy. It's Simple. It's Fun.
                        </p>
                     </div>
                  </div>
                  <div class="col-md-12 mobileList ">
                     <ul>
                        <li>
                           <h1>1</h1>
                              <img src="assets/images/tutor.png" class="tutor-img">
                              <span trans="Find a Tutor">
                              Find a Tutor
                              </span>
                           <p trans="Pellentesque habitant morbi tristique senectuset netuset malesuada fames ac turpis egestas">
                              Pellentesque habitant
                              morbi tristique senectus
                              et netus et malesuada
                              fames ac turpis egestas.
                           </p>
                        </li>
                        <li>
                           <h1>2</h1>
                              <img src="assets/images/clock.png" class="tutor-img">
                              <span trans="Schedule Time">
                              Schedule Time
                              </span>
                           <p trans="Pellentesque habitant morbi tristique senectuset netuset malesuada fames ac turpis egestas">
                              Pellentesque habitant
                              morbi tristique senectus
                              et netus et malesuada
                              fames ac turpis egestas.
                           </p>
                        </li>
                        <li>
                           <h1>3</h1>
                              <img src="assets/images/wallet.png" class="tutor-img">
                              <span trans="Pay">
                              Pay
                              </span>
                           <p trans="Pellentesque habitant morbi tristique senectuset netuset malesuada fames ac turpis egestas">
                              Pellentesque habitant
                              morbi tristique senectus
                              et netus et malesuada
                              fames ac turpis egestas.
                           </p>
                        </li>
                         <li>
                           <h1>4</h1>
                              <img src="assets/images/attend.png" class="tutor-img">
                              <span trans="Attend">
                               Attend
                              </span>
                           <p trans="Pellentesque habitant morbi tristique senectuset netuset malesuada fames ac turpis egestas">
                              Pellentesque habitant
                              morbi tristique senectus
                              et netus et malesuada
                              fames ac turpis egestas.
                           </p>
                        </li>
                     </ul>
                  </div>

                  <div class="col-md-12 text-left how-it-works-watch-more last_para">
                     <h4 trans=" Watch this short video to explain how our platform works">
                        Watch this short video to explain how our platform works. &nbsp;
                        <img src="assets/images/3.png" class="img-video" id="video_Btn" (click)="video(this.how_it_works_video)">
                     </h4>
                  </div>
               </div>

               <!-- Mobile View end -->
            </section>
            <section id="find-a-tutor" class="pageSection">
               <div class="content web-view">
                  <div class="col-md-12 text-center">
                     <div class="middleText text-center main-head-title">
                        <h4 class="textLine text-right">
                           <span trans="boost your knowledge and skills today">
                           boost your knowledge and skills today
                           </span>
                           <br>
                           <span trans="with the best online tutors">
                           with the best online tutors
                           </span>
                        </h4>
                        <form  class="searchBar searchDD">
                           <input (input)="inputSubject($event.target.value)" class="form-control form-control-find-tuter selectionSubject2" type="text" placeholder="Select subject..." trans-placeholder="Select subject..">
                              <ul id="selectionSubject2" class="results">
                                  <li *ngFor="let subject of activeSubjects" (click)="selectSubject(subject.subject_name , 'selectionSubject2')" ><a>{{subject.subject_name}}</a></li>
                               </ul>
                           <button (click)="findTeacher()" disabled id="findATeacherBtn1" class="btn btn-primary searchBtn">
                           <span trans="Find a Teacher">Find a Teacher</span>
                           </button>
                        </form>
                     </div>
                  </div>
               </div>

               <!-- mobile View 3rd section start-->

               <div class="content mobile-view">
                  <div class="col-md-12 text-center">
                     <div class="middleText text-center main-head-title">
                        <h1 trans="private tuition">
                           private tuition
                        </h1>
                        <h4 class="textLine text-right" trans="boost your knowledge and skills today">
                           boost your knowledge and skills today
                           <br>
                           <span trans="with the best online tutors">with the best online tutors</span>
                        </h4>
                        <form  class="searchBar searchDD">
                           <input (input)="inputSubject($event.target.value)" class="form-control form-control-find-tuter selectionSubject2Mobile" type="text" placeholder="what do you want to learn today?"
                           trans-placeholder="what do you want to learn today">
                              <ul id="selectionSubject2Mobile" class="results">
                                  <li *ngFor="let subject of activeSubjects" (click)="selectSubject(subject.subject_name , 'selectionSubject2Mobile')" ><a>{{subject.subject_name}}</a></li>
                               </ul>
                           <button (click)="findTeacher()" disabled id="findATeacherBtn1Mobile" class="btn btn-primary searchBtn" trans="Find a Teacher">
                           Find a Teacher
                           </button>
                        </form>
                     </div>
                  </div>
               </div>

               <!-- mobile view end -->
            </section>
            <section id="success-story" class="pageSection">
               <div class="container content web-view">
                  <div class="col-md-12 text-left main-head-title">
                     <div class="success-title">
                        <h2 trans="Success Stories">
                           Success Stories
                        </h2>
                        <p trans="See how other students have benfited from using our platforms">
                           See how other students have benfited from using our platforms
                        </p>
                     </div>
                  </div>
                     <div id="successStories" class="videos container Video-Sec_scroll">
                     <div *ngFor="let link of storylinks; let i = index">
                        <div id="successStory{{i}}" class="col-sm-4 text-left">
                           <h6>{{link.user_name}}</h6>
                           <img src="assets/images/video.png" (click)="video(link.link)" class="img-responsive playBtn">
                           <img src="{{link.thumbnaillink}}" class="thumbnail">
                        </div>
                     </div>
                     </div>
                  <div class="col-md-12 loadMoreText text-center">
                     <a (click)="loadMoreStories()" class="linkLabel" trans="Load More">Load more..</a>
                  </div>
                  <div class="searchbox text-left">
                     <div trans="Start your own success story today" class="col-lg-4 success_text col-xs-3">
                        Start your own success story today
                     </div>
                     <div class="col-lg-8 col-xs-9">
                        <form class="searchBar searchDD">
                           <input (input)="inputSubject($event.target.value)" class="form-control form-control-find-tuter selectionSubject3" type="text" placeholder="what do you want to learn today?"
                           trans-placeholder="what do you want to learn today?">
                               <ul id="selectionSubject3" class="results">
                                  <li *ngFor="let subject of activeSubjects" (click)="selectSubject(subject.subject_name , 'selectionSubject3')" ><a>{{subject.subject_name}}</a></li>
                               </ul>
                           <button (click)="findTeacher()" disabled id="findATeacherBtn2" class="btn btn-primary searchBtn">
                           <span trans="Find a Teacher">Find a Teacher </span>
                           </button>
                        </form>
                     </div>
                  </div>
               </div>
               <!-- mobile view start-->

               <div class="container content mobile-view">
                  <div class="col-md-12 text-left main-head-title">
                     <div class="success-title">
                        <h2 trans="Success Stories">
                           Success Stories
                        </h2>
                        <p trans="See how other students have benfited from using our platforms">
                           See how other students have benfited from using our platforms
                        </p>
                     </div>
                  </div>


               <div class="wrapper_Video">
                 <div *ngFor="let link of storylinks; let i = index">
                    <div class="videoplay videotitle">
                           <h6>{{link.user_name}}</h6>
                           <img src="assets/images/video.png" (click)="video(link.link)" class="img-responsive Video_img playBtn_video">
                           <img src="{{link.thumbnaillink}}" class="img-responsive Video_img">
                        </div>
                 </div>
               </div>
               <div class="loadMoreText text-center">
                     <a (click)="loadMoreStories()" class="linkLabel">Load more..</a>
                  </div>
                  <div class="searchbox text-left">
                     <div trans="Start your own success story today" class="col-md-4 success_text ">
                        Start your own success story today
                     </div>
                     <div class="col-md-12">
                        <form class="searchBar searchDD">
                           <input (input)="inputSubject($event.target.value)" class="form-control form-control-find-tuter selectionSubject3Mobile" type="text" placeholder="what do you want to learn today?"
                           trans-placeholder="what do you want to learn today">
                               <ul id="selectionSubject3Mobile" class="results">
                                  <li *ngFor="let subject of activeSubjects" (click)="selectSubject(subject.subject_name , 'selectionSubject3Mobile')" ><a>{{subject.subject_name}}</a></li>
                               </ul>
                           <button (click)="findTeacher()" disabled id="findATeacherBtn2Mobile" class="btn btn-primary searchBtn" trans="Find a Teacher">
                           Find a Teacher
                           </button>
                        </form>
                     </div>
                  </div>
               </div>

               <!-- Mobile view End -->
            </section>
            <section id="start-training" class="pageSection">
               <div class="container content web-view">
                  <div class="col-md-12 text-left main-head-title">
                     <div class="success-title">
                        <h1 trans="Get Started">
                           Get Started
                        </h1>
                        <p trans="Learn how to make most of our platform">
                           Learn how to make most of our platform
                        </p>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-9 videos">
                        <!-- <iframe class="col-sm-12 videos" height="400" [src]="activeGetStartedVideoLink" frameborder="0" allowfullscreen></iframe> -->
                        <br><br>
                        <div class="col-sm-12 margintop_20 text-left">
                           <h4>{{activeContent}}</h4>
                           <p>{{activeDescription}}</p>
                        </div>
                        <div class="col-sm-12 text-left">
                           <button type="button" class="btn btn-primary new">
                           <span trans="Get Started">Get Started</span>
                           </button>
                        </div>
                     </div>
                     <div class="col-sm-3 text-left menue">
                        <ul class="become-tutor-list">
                           <li (click)="viewVideo('createAnAccount')" id="createAnAccount" class="active">
                              <a trans="Create an account">Create an account</a>
                           </li>
                           <li (click)="viewVideo('findATutor')" id="findATutor">
                              <a trans="Find a tutors">Find a tutor</a>
                           </li>
                           <li (click)="viewVideo('manageSchedule')" id="manageSchedule">
                              <a trans="Manage Schedule">Manage Schedule</a>
                           </li>
                           <li (click)="viewVideo('manageWallet')" id="manageWallet">
                              <a trans="Manage Wallet">Manage wallet</a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <!-- Mobile view start -->
                     <div class="container content mobile-view">
                  <div class="col-md-12 text-left main-head-title">
                     <div class="success-title text-center">
                        <h1 trans="Get Started">
                           Get Started
                        </h1>
                        <p trans="Learn how to make most of our platform">
                           Learn how to make most of our platform
                        </p>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-12 videos">
                        <!-- <iframe class="col-sm-12 videos" height="300" [src]="activeGetStartedVideoLink" frameborder="0" allowfullscreen></iframe> -->

                     <div class="col-sm-3 text-left menue">
                        <ul class="become-tutor-list">
                           <li (click)="viewVideo('createAnAccount')" id="createAnAccount" class="active">
                              <a trans="Create an account">Create an account</a>
                           </li>
                           <li (click)="viewVideo('findATutor')" id="findATutor">
                              <a trans="Find a tutors">Find a tutor</a>
                           </li>
                           <li (click)="viewVideo('manageSchedule')" id="manageSchedule">
                              <a trans="Manage Schedule">Manage Schedule</a>
                           </li>
                           <li (click)="viewVideo('manageWallet')" id="manageWallet">
                              <a trans="Manage Wallet">Manage wallet</a>
                           </li>
                        </ul>
                     </div>
                     <div class="col-sm-12 text-right text_padd-20">
                               <h4 trans="How to create your own account">How to create your own account</h4>
                              <p trans="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat">
                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                        </div>
                        <div class="col-sm-12 text-center">
                           <button type="button" class="btn btn-primary " trans="Get Started">
                           Get Started
                           </button>
                        </div>
                         </div>
                  </div>
               </div>
               <!-- Mobile view end -->
            </section>
            <app-become-a-tutor *ngIf="!currentuser.isLoggedIn()"></app-become-a-tutor>
         </div>
         <div class="col-md-12 footer footer-br">
            <div class="col-xs-12 col-sm-6 col-md-4 text-center">
               <span class="select-wrapper">
               <select [(ngModel)]="selectedLanguage" (input)="selectLang($event.target.value)" id="language" class="selectpicker show-tick">
               <option [value]="lang.model.name" *ngFor="let lang of languages$ | async">{{lang.model.name}}</option>
               </select>
               </span>
               &nbsp;&nbsp;&nbsp;|
               <span page="find-a-tutor">
               <a trans="Find tutors" style="cursor: pointer;" (click)="gotoSection('find-a-tutor')">
               Find tutors
               </a>
               </span>
               <span *ngIf="!currentuser.isLoggedIn()" page="become-tutor">
               &nbsp;&nbsp;&nbsp;|
               <a trans="Become a tutor" class="margineft10" style="cursor: pointer;" (click)="gotoSection('become-tutor')">
               Become a tutor
               </a>
               </span>
            </div>
            <div class="col-md-4">
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 text-center">
               <a trans="CopyRights (C)">
               CopyRights (C)
               </a>
               <a trans="Terms & Conditions." class="margineft10">
               Terms & Conditions.
               </a>
               <a trans="Privacy Policy." class="margineft10">
               Privacy Policy.
               </a>
            </div>
         </div>
   </div>
   <app-login></app-login>

   <div id = "lightbox">
      <div id ="video-wrapper">
         <i id = "close-btn" class="fa fa-times"></i>
<!--          <iframe src="" id ="video" width="700" height="450"  frameborder = "0" allowfullscreen></iframe> -->
      </div>
   </div>
