import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatatableHeaderComponent } from './datatable-header/datatable-header.component';
import { DatatableHeaderSearchInputComponent } from './datatable-header-search-input/datatable-header-search-input.component';
import { TableBodyCheckboxComponent } from './selection/table-body-checkbox.component';
import { DatatableFooterComponent } from './datatable-footer/datatable-footer.component';
import { DatatableFooterNewComponent } from './datatable-footer-new/datatable-footer-new.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatatableNorecordsComponent } from './datatable-norecords/datatable-norecords.component';
// import { NgxPaginationModule } from 'ngx-pagination';
@NgModule({
  declarations: [TableBodyCheckboxComponent,DatatableHeaderComponent, DatatableHeaderSearchInputComponent, DatatableFooterComponent, DatatableFooterNewComponent, DatatableNorecordsComponent],
  imports: [
    CommonModule,
            FormsModule,
        ReactiveFormsModule,
        
  ],
  exports: [
    DatatableHeaderComponent,
    TableBodyCheckboxComponent,
    DatatableHeaderSearchInputComponent,
    DatatableFooterComponent,
    DatatableFooterNewComponent,
            FormsModule,
        ReactiveFormsModule,
        DatatableNorecordsComponent,
  ]
})
export class DatatableModule { }
