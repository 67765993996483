import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProfileService } from '../profile.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Bootstrapper } from '../../../core/bootstrapper.service';
import { PaymentDetail } from '../../../auth/paymentdetail';


@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.css']
})
export class PaymentMethodsComponent implements OnInit {
    public payment_methods;
    public years = [];
    public paymentMethods = this.fb.group({
        card_number: ['',[Validators.required,Validators.minLength(16)]],
        year: ['',[Validators.required]],
        month: ['',[Validators.required]],
        security_code: ['',[Validators.required,Validators.minLength(3)]],
    });


  get card_number(){ return this.paymentMethods.get('card_number');}
  get month(){ return this.paymentMethods.get('month');}
  get year(){ return this.paymentMethods.get('year');}
	get security_code(){ return this.paymentMethods.get('security_code');}

	public errorMessages = {
	  card_number :   [ { type:'required',message:'Card number is required'}, { type:'minlength',message: 'Minimum length should be 16'}],
	  month :   [ { type:'required',message:'Month is required'} ],
    year :   [ { type:'required',message:'Year is required'} ],
	  security_code : [ { type:'required',message:'Security code is required'}, { type:'minlength',message: 'Minimum 3 digits'}],
   }

public checkLuhn(cardNo) {
    var s = 0;
    var doubleDigit = false;
    for (var i = cardNo.length - 1; i >= 0; i--) {
        var digit = +cardNo[i];
        if (doubleDigit) {
            digit *= 2;
            if (digit > 9)
                digit -= 9;
        }
        s += digit;
        doubleDigit = !doubleDigit;
    }
    return s % 10 == 0;
}

public validateCardNo(no) {
    return (no && this.checkLuhn(no) &&
        no.length == 16 && (no[0] == 4 || no[0] == 5 && no[1] >= 1 && no[1] <= 5 ||
        (no.indexOf("6011") == 0 || no.indexOf("65") == 0)) ||
        no.length == 15 && (no.indexOf("34") == 0 || no.indexOf("37") == 0) ||
        no.length == 13 && no[0] == 4)
}

  public tax_settings;
  public role;
  public taxSettings = this.fb.group({
    vat_country: [''],
    vat_number: [''],
    no_vat_reason: [''],
  });

  constructor(private fb: FormBuilder,
  	          public profileService: ProfileService,
  	          private router: Router,
              private toastr: ToastrService,
              private bootstrapper: Bootstrapper,
              public payment: PaymentDetail) {

    this.role = this.bootstrapper.data.user_role;
  }

  ngOnInit(): void {

 this.getYears();
$("#expiryDate").on("keydown keyup", function(event) { 
   alert(event);
});

$('#expiryDate').bind('input keypress', function(event) { 
   alert(event);
  });

  this.payment.changepaymentDetails$.subscribe(response=>{
   this.payment_methods = response;
  });
  
  }

  validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46 , 86];

    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }

  public monthRegex = /^\d\d$/; // regex to match "MM"
  public addSlash(e) {
      let value = e.target.value; 
      if(value.length>=2 && value.length<=3){
      if (e.key >= 0 && e.key <= 9) {
        var output = [value.slice(0, 2), "/", value.slice(2)].join('');
        e.target.value = output;
      }
    }
  }

  monthAndSlashRegex= /^\d\d\/$/; // regex to match "MM/"

  public removeSlash(e) {
    var isMonthAndSlashEntered = this.monthAndSlashRegex.exec(e.target.value);
    if (isMonthAndSlashEntered && e.key === 'Backspace') {
      e.target.value = e.target.value.slice(0, -3);
    }
  }

   public updatePaymentMethods(){

      this.paymentMethods.markAllAsTouched();
      if(this.paymentMethods.valid){
       this.paymentMethods.value.expiry_date = this.paymentMethods.value.month + '/' + this.paymentMethods.value.year.substr(-2);
       this.profileService.updatePaymentMethods(this.paymentMethods.value)
        .subscribe(response => {
            let res = JSON.parse(atob(response['data']));

           if ( res.status == 'success' ) {
             this.payment_methods = res.payment_methods;
             this.payment.init(res.payment_methods);
             this.paymentMethods.reset();
             this.toastr.success('Details Updated Sucessfully');
           } else {
             this.toastr.error(res.message);
           }
        }, err => {
          this.toastr.error('Invalid Card Details');
        });
     }
  }

  public Delete(id){
    let payment_id = Object();
    Object.assign(payment_id , {id:id});
    this.profileService.PaymentDelete(payment_id).subscribe(response =>{
      let res = JSON.parse(atob(response['data']))
      this.payment_methods = res;
      
      this.payment.init(this.payment_methods);
      this.toastr.success('Record Deleted Sucessfully');
    });
  }

  public makePrimary(id) {
    let payment_id = Object();
    Object.assign(payment_id , {id:id});
    this.profileService.paymentMakePrimary(payment_id).subscribe(response =>{
      let res = JSON.parse(atob(response['data']))
      if ( response.status == 'success') {
        this.payment_methods = res;
        this.toastr.success('Details Updated Sucessfully');
      } else {
        this.toastr.error(res);
      }
    });
  }

  public updateTaxSettings(){
    this.profileService.updateTaxSettings(this.taxSettings.value)
    .subscribe(response => {
      response = JSON.parse(atob(response['data']));
      this.toastr.success('Details Updated Sucessfully');
    }, err => {
      this.toastr.error('Something went wrong');
    });
  }


  /*Get Next 50 Years From Current Tear*/
  getYears(){
      let years_to_add = 50;
      var startYear = new Date().getFullYear();
      var endyear = new Date(new Date().setFullYear(new Date().getFullYear() + years_to_add)).getFullYear();
      while ( startYear <= endyear ) {
        this.years.push(startYear++);
      }   
  }



}
