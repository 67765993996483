import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CurrentUser } from '@auth/current-user';
import { ToastrService } from 'ngx-toastr';
import { RouteConstantsService } from '@services/routeConstants/route-constants.service';
import { Localizations } from '../../../core/translations/localizations.service';
import { distinctUntilChanged, finalize } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalizationWithLines } from '../../../core/types/localization-with-lines';
import { Bootstrapper } from '../../../core/bootstrapper.service';
declare var $:any;
import Swal from 'sweetalert2/src/sweetalert2.js'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  public PWAStatus:any;
  public languages$ = new BehaviorSubject<LocalizationWithLines[]>([]);
  public loading$ = new BehaviorSubject<boolean>(false);
  public selectedLanguage:any;

  constructor(private router: Router,
    public currentUser: CurrentUser,
    private toastr: ToastrService,
    private localizationsApi: Localizations,
    public myservice: Bootstrapper) {
    this.PWAStatus = this.isPWA();
   }

  ngOnInit(): void {
    
  }


public selectLang(langId){
  localStorage.setItem('defaultLanguage' , langId);
  this.myservice.changeLanguage(langId);  
 }

  public tutorRegistration(){
    this.router.navigate(['/join-as-tutor'], { replaceUrl: true });
  }
  public pricing(){
    this.router.navigate(['/pricing'], { replaceUrl: true });
  }
    private dashboard(){
    if(this.currentUser.hasRole('Tutor')){
      this.router.navigateByUrl('tutor/home');
    }else if(this.currentUser.hasRole('Student')){      
      this.router.navigateByUrl('student/home');
    }else{
      this.router.navigateByUrl('admin');
    }
  }

  public isPWA() {
    return ["fullscreen", "standalone", "minimal-ui"].some(
        (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
    );
  }

  public findTeacher(){
    if(this.currentUser.isTutor()){
      this.toastr.error('You Have no access to find Tutors');
     }else if(this.currentUser.isAdmin()){
      this.toastr.error('You Have no access to find Tutors');
     }else{
      this.router.navigate([RouteConstantsService.TUTORS_PAGE]);
    }
  }

}
  