import { Component, OnInit } from '@angular/core';
import { CurrentUser } from '@auth/current-user';
import { ActiveSubjects } from '@auth/active-subjects';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Localizations } from '@core/translations/localizations.service';
import { LocalizationWithLines } from '@core/types/localization-with-lines';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, finalize } from 'rxjs/operators';
import { Bootstrapper } from '@core/bootstrapper.service';
import { Injectable, Injector } from '@angular/core';
import { Translations } from '@core/translations/translations.service';
import { EmbedVideoService } from 'ngx-embed-video';
import { DomSanitizer , SafeResourceUrl} from '@angular/platform-browser';
import { SuccessStories } from '@auth/sucess-stories';
import { SubjectTS } from '@auth/subject';
import { ToastrService } from 'ngx-toastr';
declare var $;

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})


export class LandingPageComponent implements OnInit {
  public languages$ = new BehaviorSubject<LocalizationWithLines[]>([]);
  public loading$ = new BehaviorSubject<boolean>(false);
  public localizations$ = new BehaviorSubject<LocalizationWithLines[]>([]);
  public VideoLinks;
  public welcome_video;
  public how_it_works_video;
  public how_to_create_your_own_account_link;
  public create_account_content;
  public create_account_description;
  public how_to_find_a_tutor_link;
  public how_to_find_a_tutor_content;
  public how_to_find_a_tutor_description;
  public how_to_manage_schedule_link;
  public how_to_manage_schedule_content;
  public how_to_manage_schedule_description;
  public how_to_manage_wallet_link;
  public how_to_manage_wallet_content;
  public how_to_manage_wallet_description;
  public activeGetStartedVideoLink:SafeResourceUrl;
  public storylinks;
  public convertedStoryLinks =  new Array();
  public activeContent;
  public activeDescription;
  public activeSubjects = new Array();
  public tutorSearch:boolean = false;
  public selectedLanguage;
  public storyLinkStartIndex:number = 0;
  public storyLinkEndIndex:number = 5;
  public allSuccessStories;
  public selectedGetStartedVideo;

  constructor(protected injector: Injector,
             public currentUser: CurrentUser,
             private router: Router,
             private localizationsApi: Localizations,
             public myservice: Bootstrapper,
             protected i18n: Translations,
             public currentuser: CurrentUser,
             public activesubjects: ActiveSubjects,
             public successStories: SuccessStories,
             public sanitizer: DomSanitizer,
             public subjectselected: SubjectTS,
             private toastr: ToastrService
             ) {
        this.i18n = this.injector.get(Translations);
}

  ngOnInit(): void {

    $('#input_92_31').attr('readonly','readonly');

    this.selectedLanguage = localStorage.getItem('defaultLanguage');
    if(!this.selectedLanguage){
      this.selectedLanguage = 'english';
    }
    this.allSuccessStories = this.successStories.successStoriesLinks();
    this.activeSubjects = this.activesubjects.activeSubjects$.value;
    $('.results').css('display','none');
    $('.selectionSubject1').click(function(e) {
         $('#selectionSubject1').css('display','inherit');
    });
    $('.selectionSubject1Mobile').click(function(e) {
         $('#selectionSubject1Mobile').css('display','inherit');
    });
    $('.selectionSubject2').click(function(e) {
         $('#selectionSubject2').css('display','inherit');
    });
    $('.selectionSubject2Mobile').click(function(e) {
         $('#selectionSubject2Mobile').css('display','inherit');
    });
    $('.selectionSubject3').click(function(e) {
         $('#selectionSubject3').css('display','inherit');
    });
    $('.selectionSubject3Mobile').click(function(e) {
         $('#selectionSubject3Mobile').css('display','inherit');
    });
       $('document').ready(function () {
        $('#lightbox').click(function() {
          if (!$(this.target).is('#lightbox')) {
            $("#lightbox").hide();
          }
        });


        $("#nav ul li a[href^='#']").on('click', function(e) {
          e.preventDefault();
          $('html, body').animate({
            scrollTop: $(this.hash).offset().top
          }, 750, function(){
          window.location.hash = this.hash;
          });
        });
        $('#nav ul li').on('click', function(){
          $(this).siblings().removeClass('active');
          $(this).addClass('active');
        });

        var count = 1;
        var throttle = 750; // milliseconds
        var time = -1;

        $(window).bind('mousewheel', function(event) {
            var now = Date.now(); // in ms
            if (time !== -1 && now - time < throttle)
              return;
            time = now;

            if (event.originalEvent.wheelDelta >= 0) {

                var active_page = $(".nav.navbar-nav .active").attr( "page" );
                var prev_page =$( ".nav.navbar-nav .active" ).prev().attr( "page" );
                if(prev_page){
                  $( ".nav.navbar-nav .active" ).prev().addClass("prev_active");
                  $( ".nav.navbar-nav .active" ).removeClass("active");
                  $( ".nav.navbar-nav .prev_active" ).addClass("active");
                  $( ".nav.navbar-nav .prev_active" ).removeClass("prev_active");
                  $( ".nav.navbar-nav .active a" ).trigger( "click" );
                }
            }
            else {
                var active_page = $(".nav.navbar-nav .active").attr( "page" );
                var next_page =$( ".nav.navbar-nav .active" ).next().attr( "page" );
                if(next_page){
                  $( ".nav.navbar-nav .active" ).next().addClass("next_active");
                  $( ".nav.navbar-nav .active" ).removeClass("active");
                  $( ".nav.navbar-nav .next_active" ).addClass("active");
                  $( ".nav.navbar-nav .next_active" ).removeClass("next_active");
                  $( ".nav.navbar-nav .active a" ).trigger( "click" );
                }
            }

        });

        //$( ".nav.navbar-nav .active a" ).trigger( "click" );
        $(".nav.navbar-nav a").on("click", function() {
          var active_page = $(this).parent().attr( "page" );
          //$( ".pageSection" ).slideUp("slow");
         // $( "#"+active_page ).slideDown("slow");
          $([document.documentElement, document.body]).animate({ scrollTop: $( "#"+active_page).offset().top }, 500);
          console.log(active_page+' ------ current page');
          if(active_page == 'start-training' || active_page == 'become-tutor'){
            $('#nav').addClass("whiteBackground");
            $('.menus .userLogin').addClass("whiteBackground");
          }else{
            $('#nav').removeClass("whiteBackground");
            $('.menus .userLogin').removeClass("whiteBackground");
          }
          if(active_page == 'start-training' || active_page == 'become-tutor' || active_page == 'success-story'){
            $('.footer .col-xs-12.col-sm-6.col-md-4').addClass("whiteBackground");
          }else{
            $('.footer .col-xs-12.col-sm-6.col-md-4').removeClass("whiteBackground");
          }
        });
      });
      
      this.storylinks = this.allSuccessStories.slice(0,6);
      this.storylinks.slice([this.storyLinkStartIndex], [this.storyLinkEndIndex]);
      for(var link of this.storylinks){
        link.url = this.sanitizer.bypassSecurityTrustResourceUrl(link.link);
        this.convertedStoryLinks.push(link);
      }

      this.localizationsApi.all()
                .pipe(finalize(() => this.loading$.next(false)))
                .subscribe(response => {
                this.setLocalizations(response.localizations);
                });
      this.getVideoLinks();

  }

  private setLocalizations(localizations: LocalizationWithLines[]) {
        this.getVideoLinks();
        this.languages$.next(localizations);
    }

  private home(){
    if(this.currentUser.hasRole('Tutor')){
      this.router.navigateByUrl('tutor');
    }else if(this.currentUser.hasRole('Student')){      
      this.router.navigateByUrl('student');
    }else{
      this.router.navigateByUrl('admin');
    }
  }


  public selectLang(langId){
   localStorage.setItem('defaultLanguage' , langId);
   this.myservice.changeLanguage(langId);
   setTimeout(() => this.getVideoLinks(), 1000);
  }

  private getVideoLinks(){
   this.VideoLinks = this.i18n['localization'].lines;
   this.welcome_video = this.VideoLinks['welcome-video'];
   this.how_it_works_video = this.VideoLinks['how-it-works-video'];

   this.how_to_create_your_own_account_link = this.VideoLinks['how-to-create-your-own-account-link'];
   this.create_account_content = this.VideoLinks['how-to-create-your-own-account'];
   this.create_account_description = this.VideoLinks['how-to-create-your-own-account-description'];

   this.how_to_find_a_tutor_link = this.VideoLinks['how-to-find-a-tutor-link'];
   this.how_to_find_a_tutor_content = this.VideoLinks['how-to-find-a-tutor-account'];
   this.how_to_find_a_tutor_description = this.VideoLinks['how-to-find-a-tutor-account-description'];

   this.how_to_manage_schedule_link = this.VideoLinks['how-to-manage-schedule-link'];
   this.how_to_manage_schedule_content = this.VideoLinks['how-to-manage-schedule-account'];
   this.how_to_manage_schedule_description = this.VideoLinks['how-to-manage-schedule-account-description'];

   this.how_to_manage_wallet_link = this.VideoLinks['how-to-manage-wallet-link'];
   this.how_to_manage_wallet_content = this.VideoLinks['how-to-manage-wallet-account'];
   this.how_to_manage_wallet_description = this.VideoLinks['how-to-manage-wallet-account-description'];

   this.activeContent = this.create_account_content;
   this.activeDescription = this.create_account_description;
  if(this.selectedGetStartedVideo){
   this.viewVideo(this.selectedGetStartedVideo);
  }else{
   this.activeGetStartedVideoLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.how_to_create_your_own_account_link);
   }
  }

  public video(videoId){
          $("#lightbox").fadeIn(1000);
          $(this).hide();
          var videoURL = videoId;
          $('#video').prop('src', videoURL);

// When the close btn is clicked make the lightbox fade out in the span of 0.5 seconds and show the play btn
          $("#close-btn").on("click", function() {
          videoURL='';
          $('#video').prop('src', videoURL);
          $("#lightbox").fadeOut(500);
          $("#video_Btn,#video_Btn2,.playBtn").show(250);
          });
  }

  public viewVideo(idValue){
    $("ul.become-tutor-list li.active").removeClass("active");
    $("ul.become-tutor-list li[id=" + idValue + "]").addClass("active");
    this.selectedGetStartedVideo = idValue; 
    if(idValue=='findATutor'){
      let src = this.how_to_find_a_tutor_link;
      this.activeContent = this.how_to_find_a_tutor_content;
      this.activeDescription = this.how_to_find_a_tutor_description;
      this.activeGetStartedVideoLink = this.sanitizer.bypassSecurityTrustResourceUrl(src);
    }

    if(idValue=='manageSchedule'){
      let src = this.how_to_manage_schedule_link;
      this.activeContent = this.how_to_manage_schedule_content;
      this.activeDescription = this.how_to_manage_schedule_description;
      this.activeGetStartedVideoLink = this.sanitizer.bypassSecurityTrustResourceUrl(src);
    }

    if(idValue=='manageWallet'){
      let src = this.how_to_manage_wallet_link;
      this.activeContent = this.how_to_manage_wallet_content;
      this.activeDescription = this.how_to_manage_wallet_description;
      this.activeGetStartedVideoLink = this.sanitizer.bypassSecurityTrustResourceUrl(src);
    }

    if(idValue=='createAnAccount'){
      let src = this.how_to_create_your_own_account_link;
      this.activeContent = this.create_account_content;
      this.activeDescription = this.create_account_description;
      this.activeGetStartedVideoLink = this.sanitizer.bypassSecurityTrustResourceUrl(src);
    }
  }

  public inputSubject(value){
    if(value){
           $('#findATeacherBtn').prop('disabled', true);
           $('#findATeacherBtnMobile').prop('disabled', true);
           $('#findATeacherBtn1').prop('disabled', true);
           $('#findATeacherBtn1Mobile').prop('disabled', true);
           $('#findATeacherBtn2').prop('disabled', true);
           $('#findATeacherBtn2Mobile').prop('disabled', true);
    }
    this.initializeSubjects();
    this.activeSubjects = this.activeSubjects.filter(subject => {
      if (subject.subject_name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
         // $('.results').css('display', 'inherit');
        return true;
      }else{
         // $('.results').css('display', 'none');
      return false;
    }
  });
  }

  public checkForLogin(){
     this.myservice.changeLanguage(this.selectedLanguage);
  }

  public initializeSubjects(){
    this.activeSubjects = this.activesubjects.activeSubjects$.value;
  }

  public findTeacher(){
  if(this.currentUser.hasRole('Tutor')){
    this.toastr.error('You Have no access to find Tutors');
     }else if(this.currentUser.isAdmin()){
    this.toastr.error('You Have no access to find Tutors');
     }else{
    this.router.navigate(['/student/find-tutor']);
    }
  }

  public convertLink(i ,link){
    console.log(i);
    console.log(link);
  }

  public removeVideoURL(){
    this.activeGetStartedVideoLink = '';
  }

  // SELECT SUBJECT
  public selectSubject(subject , className){
     let subjectSelected = this.activeSubjects.find(x=>x.subject_name === subject);
     this.subjectselected.init(subjectSelected);
     localStorage.setItem('subject' , JSON.stringify(subjectSelected));
     $('.results').css('display','none');
     $('.'+className).val(subject);
     $('#findATeacherBtn').prop('disabled', false);
     $('#findATeacherBtnMobile').prop('disabled', false);
     $('#findATeacherBtn1').prop('disabled', false);
     $('#findATeacherBtn1Mobile').prop('disabled', false);
     $('#findATeacherBtn2').prop('disabled', false);
     $('#findATeacherBtn2Mobile').prop('disabled', false);
  }

  public loadMoreStories(){
  const lastItem = this.storylinks.length + 6;
  this.storylinks = this.allSuccessStories.slice(0 , lastItem);
  setTimeout(function(){ 
  var d = $('#successStories');
  d.animate({ scrollTop: d.prop("scrollHeight")}, 'slow')
}, 300);
  }

  public gotoSection(active_page) {
      $( ".pageSection" ).hide();
      $( "#"+active_page ).show();
      $("#nav li").removeClass('active');
      $("#nav li a").removeClass('whiteBackground1');
      $("#nav li[page='"+active_page+"']").addClass('active');
      if(active_page == 'become-tutor'){
          $('#nav').addClass("whiteBackground");
          $('.menus .userLogin').addClass("whiteBackground");
          $('.footer .col-xs-12.col-sm-6.col-md-4').addClass("whiteBackground");
      }else{
          $('#nav').removeClass("whiteBackground");
          $('.menus .userLogin').removeClass("whiteBackground");
          $('.footer .col-xs-12.col-sm-6.col-md-4').removeClass("whiteBackground");       
      }
  }
}
