import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../profile.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Bootstrapper } from '../../../core/bootstrapper.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.css']
})
export class CalenderComponent implements OnInit {
public code;
public url = '';
public connected = false;
public calendars;

public frmGroup = this.fb.group({
        calendar_id: ['',[Validators.required]]
    });
get calendar_id(){ return this.frmGroup.get('calendar_id');}
public errorMessages = {
    calendar_id :   [
      { type:'required',message:'Calendar ID is required'} ]
   }

  constructor(
  	private profileService: ProfileService,
  	private toastr: ToastrService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private bootstrapper: Bootstrapper,
    private fb: FormBuilder,
  	) {
  	this.code = this.route.snapshot.queryParams.code;
  	if ( this.code ) {
      // this.router.navigate(['student/student-account/calendar']);
  		this.connectGoogleCalendar();
  	} else {
  		this.code = this.bootstrapper.data.currentUser.google_calendar_token
  	}
  }

  ngOnInit(): void {
    let data = Object();
    Object.assign(data,{code:this.code});
    this.profileService.checkGoogleConnection(data)
        .subscribe(response => {          
          if ( response.status == 'failed') {
             this.url = response.link;
             this.connected = false;
           } else {
             this.connected = true;
             this.frmGroup.patchValue({
               calendar_id: response.google_calendar_id
             });
             this.calendars = response.calendars;
           }
        }, err => {

        });

  }

  public connectGoogleCalendar() {
  	let data = Object();
    Object.assign(data,{code:this.code});
  	this.profileService.connectGoogleCalendar(data)
        .subscribe(response => {
         	if ( response.status == 'success') {
         		this.toastr.success(response.message);
         		this.connected = true;
         	} else {
         		this.toastr.error(response.message);
         	}
         	// this.router.navigate([response.redirect]);
           window.location = response.redirect
        }, err => {
            this.connected = false;
            this.toastr.error('Invalid Details');
        });
  }

  public updateCalendarID() {
    this.profileService.updateCalendarID(this.frmGroup.value)
    .subscribe(response => {
      let res = JSON.parse(atob(response['data']));
      console.log(res)
      if ( res.status == 'success') {
        this.toastr.success(res.message);
      } else {
        this.toastr.error(res.message);
      }
    }, err => {
      this.toastr.error('Something went wrong');
    });
  }

  public disconnectGoogleCalendar() {
    let data = Object();
    Object.assign(data, {'action':'disconnect'});
    this.profileService.disconnectGoogleCalendar(data)
    .subscribe(response => {
      let res = JSON.parse(atob(response['data']));

      if ( res.status == 'success') {
        this.toastr.success(res.message);
        this.connected = false;
        this.url = res.link;
      } else {
        this.toastr.error(res.message);
      }
    }, err => {
      this.toastr.error('Something went wrong');
    });
  }

}