import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Settings} from './config/settings.service';
import {Translations} from './translations/translations.service';
import {APP_CONFIG, AppConfig} from './config/app-config';
import {Role} from './types/models/Role';
import {User} from './types/models/User';
import {LocalizationWithLines} from './types/localization-with-lines';
import {CurrentUser} from '@auth/current-user';
import {ActiveSubjects} from '@auth/active-subjects';
import {TeachingLevels} from '@auth/teaching-levels';
import {StudentAges} from '@auth/studentAges';
import {Currencies} from '@auth/currencies';
import {LocaleCurrency} from '@auth/localecurrency';
import {TutorTeachingService} from '@tutor/teaching/teaching.service';
import {SuccessStories} from '@auth/sucess-stories';
import {TeamMembers} from '@auth/TeamMembers';
import {GuestNews} from '@auth/GuestNews';
import {FooterPages} from '@auth/FooterPages';
import {LanguageLevels} from '@auth/languagesLevels';
import {LocaleTimeZoneTS} from '@auth/localetimezone';
import {PaymentDetail} from '@auth/paymentdetail';
import {WalletAmountTS} from '@auth/walletamount';
import {Subject} from "rxjs";
import { Router } from '@angular/router';

export function init_app(bootstrapper: Bootstrapper) {
    return () => bootstrapper.bootstrap();
}

export interface BootstrapData {
    settings: AppConfig;
    guests_role: Role|null;
    user: User|null;
    i18n?: LocalizationWithLines;
    [key: string]: any;
}

@Injectable({
    providedIn: 'root'
})
export class Bootstrapper {
    invokeEvent:Subject<any> = new Subject();
    protected http: HttpClient;
    protected settings: Settings;
    public currentUser: CurrentUser;
    protected i18n: Translations;
    public data: BootstrapData;
    public GBP;

    constructor(protected injector: Injector,
                public tutorteaching: TutorTeachingService,
                public activeSubjects: ActiveSubjects,
                public teachingLevels: TeachingLevels,
                public studentAges: StudentAges,
                public languagesLevels: LanguageLevels,
                public currencies: Currencies,
                public successStories: SuccessStories,
                public teamMembers: TeamMembers,
                public guestNews: GuestNews,
                public footerPages: FooterPages,
                public localecurrency: LocaleCurrency,
                private localetimezone: LocaleTimeZoneTS,
                private payment: PaymentDetail,
                private router: Router,
                private walletamount: WalletAmountTS) {
        this.http = this.injector.get(HttpClient);
        this.settings = this.injector.get(Settings);
        this.currentUser = this.injector.get(CurrentUser);
        this.i18n = this.injector.get(Translations);

        // merge all config provided by modules into single object
        this.injector.get(APP_CONFIG).forEach(providedConfig => {
            return this.settings.merge({vebto: providedConfig});
        });

    }

    /**
     * Bootstrap application with data returned from server.
     */
    public bootstrap(data?: string): Promise<any> {
        if ( ! data) data = window['bootstrapData'];

        // if we have bootstrap data in global scope, pass
        // it to the app and return self resolving promise
        if (data) {
            this.handleData(data);
            return new Promise(resolve => resolve(data));
        }

        let selectedLanguage = localStorage.getItem('defaultLanguage');
        if(!selectedLanguage){
            selectedLanguage = 'English';
        }

        // fetch bootstrap data from backend and return promise that
        // resolves once request is complete and data is passed to the app
        return new Promise((resolve, reject) => {
            const original  = encodeURIComponent(window.location.href);
            const url = this.settings.getBaseUrl() + 'secure/bootstrap-data-Language/' + selectedLanguage;
            this.http.get(url).subscribe(response => {
                this.handleData(response['data']);

                resolve(response['data']); 
            }, error => {
                //this.router.navigate(['errors']);

                console.log('bootstrap error', error);
                reject();
            });
        });
    }

    public changeLanguage(langId):Promise<any>{
             return new Promise((resolve, reject) => {
            const original  = encodeURIComponent(window.location.href);
            const url = this.settings.getBaseUrl() + 'secure/bootstrap-data-Language/' + langId;
            this.http.get(url).subscribe(response => {
                this.handleData(response['data']);
                return this.handleData(response['data']);
            }, error => {
                console.log('bootstrap error', error);
                reject();
            });
        });
    }

    /**
     * Handle specified bootstrap data.
     */
    protected handleData(encodedData: string): BootstrapData {
        // decode bootstrap data from server
        const data = JSON.parse(atob(encodedData)) as BootstrapData;
        
        // set all settings returned from server
        this.settings.setMultiple(data.settings);
        // set translations
        if (data.i18n) {
            this.i18n.setLocalization(data.i18n);
        }
        // set current user and default role for guests
        this.currentUser.init({
            guestsRole: data.guests_role,
            user: data.user,
        });
        
        this.localecurrency.init(data.localeCurrency);

        this.currencies.init(data.currencies);

      let GBP = data.currencies.find( x => {
       if(x.code == "USD"){
         return x;
       }
      });

        let walletDetails = data.walletDetails;

        let available_balance = (data.walletDetails/GBP.exchange_rate) * data.localeCurrency.exchange_rate;
        let walletDetailsConverted = this.math(available_balance);

        this.walletamount.init(walletDetailsConverted);
        
        this.localetimezone.init(data.localeTimeZone);

        this.activeSubjects.init(data.activeSubjects);

        this.teachingLevels.init(data.teachingLevels);

        this.studentAges.init(data.studentAges);

        this.languagesLevels.init(data.languagesLevels);

        this.teamMembers.init(data.ourTeam);

        let landingPageData = data.landingPage;
        
        if(landingPageData){

        this.guestNews.init(landingPageData.newsPage);

        this.footerPages.init(landingPageData.footerData);
            
        }
        
        if(data.user && data.user.roles['0']){
            this.tutorteaching.tutorTeachingProfile(data.user);
        }

        this.data = data;
        if(data.user){    
        this.invokeEvent.next(data.user);
        }

        if(data.paymentDetails){
            this.payment.init(data.paymentDetails);
        }
        return data;
    }

      public math(value){
    return (Math.round(value * 100) / 100).toFixed(2);
  }

}
