<section id="tutorDashboard" class="pageSectionBg">
<div id="menuwrapper" >
   <ul id="sidemenu">
      <li>
         <a class="noflyout" ><i class="fa fa-home" aria-hidden="true"></i><br/>
         <span trans="Home">Home</span>
         </a>
      </li>
      <li class="active">
         <a><img src="assets/images/tutor.png" height="35px"><br/>
         <span trans="Tutors">Tutors</span>
         </a>
      </li>
      <!-- <li>
         <a><img src="assets/images/bars.png" height="35px"><br/>
         <span trans="MORE">MORE</span>
         </a>
      </li> -->
   </ul>
</div>