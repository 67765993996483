<div class="row scroll_lowSec">
   <div>
      <form [formGroup]="subjectSelectionForm">
         <table class="table noBorderTable">
            <thead>
               <tr>
                  <th trans="Select Subject">Select Subject</th>
                  <th trans="Teaching Specialities">Teaching Specialities</th>
                  <th trans="Student Age">Student Age</th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td>
                     <div *ngIf="allowCustomSubjects == 1" class="form-group CCnr-height">
                        <input placeholder="Select" formControlName="subject_name" id="selectbasic"  list="subjects" class="form-control iconArrowDown" trans-placeholder="Select">
                        <datalist id="subjects">
                           <option *ngFor="let subject of activesubjects.activeSubjects$ | async" [value]="subject.subject_name">
                              {{subject.subject_name}}
                           </option>
                        </datalist>
                        <div *ngFor= "let error of errorMessages.subject_name">
                           <ng-container *ngIf="subject_name.hasError(error.type) && (subject_name.dirty || subject_name.touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                     <div *ngIf="allowCustomSubjects == 0" class="form-group CCnr-height">
                        <select formControlName="subject_name" class="form-control iconArrowDown">
                           <option value="" trans="Select">Select</option>
                           <option *ngFor="let subject of activesubjects.activeSubjects$ | async" [value]="subject.subject_name">
                              {{subject.subject_name}}
                           </option>
                        </select>
                        <div *ngFor= "let error of errorMessages.subject_name">
                           <ng-container *ngIf="subject_name.hasError(error.type) && (subject_name.dirty || subject_name.touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </td>
                  <td>
                     <div class="form-group CCnr-height">
                        <select formControlName="level"  id="selectbasic"  class="form-control iconArrowDown">
                           <option value="" trans="Select" selected>Select</option>
                           <option  *ngFor="let level of teachinglevels.teachingLevels$ | async" [value]="level.id">
                           {{level.level_name}}
                           </option>
                        </select>
                        <div *ngFor= "let error of errorMessages.level">
                           <ng-container *ngIf="level.hasError(error.type) && (level.dirty || level.touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </td>
                  <td>
                     <div class="form-group CCnr-height">
                        <select formControlName="student_age" id="selectbasic" name="choose your timezone" class="form-control iconArrowDown">
                           <option value="" selected>Select</option>
                           <option [value]="age.id" *ngFor="let age of studentages.studentAges$ | async">
                           {{age.student_age}}
                           </option>
                        </select>
                        <div *ngFor= "let error of errorMessages.student_age">
                           <ng-container *ngIf="student_age.hasError(error.type) && (student_age.dirty || student_age.touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </td>
                  <td>
                     <div class="form-group CCnr-height">
                        <input placeholder="Price per Hour($)" formControlName="price_per_hour" id="price_per_hour" trans-placeholder="Price per Hour($)">
                        <div *ngFor= "let error of errorMessages.price_per_hour">
                           <ng-container *ngIf="price_per_hour.hasError(error.type) && (price_per_hour.dirty || price_per_hour.touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </td>
                  <td class="addRow">
                     <i (click)="addSubjects()" class="fa fa-plus-circle" aria-hidden="true"></i>
                  </td>
               </tr>
                   <tr *ngFor="let subject of postSubjectsData; let i = index">
                  <td>
                     <div class="form-group CCnr-height">
                        <input  disabled  value="{{subject.subject_name}}"  class="form-control">
                     </div>
                  </td>
                  <td>
                     <div class="form-group CCnr-height">
                        <input  disabled value="{{subject.levelName}}"  class="form-control"> 
                     </div>
                  </td>
                  <td>
                     <div class="form-group CCnr-height">
                        <input  disabled value="{{subject.studentAge}}"  class="form-control"> 
                     </div>
                  </td>
                  <td>
                     <div class="form-group CCnr-height">
                        <input  disabled value="{{subject.price_per_hour}}"  class="form-control"> 
                     </div>
                  </td>
                  <td>
                     <div class="delRow">
                        <i (click)="tutorSubjectDelete(i)" class="fa fa-times" aria-hidden="true"></i>
                     </div>
                  </td>
                  </tr>
            </tbody>
         </table>
      </form>
      <div *ngIf=postSubjectsData.length?subjectSelectionFormComplete():false>
   </div>
   <div *ngIf=!postSubjectsData.length?subjectSelectionFormInComplete():false>
   </div>
   </div>
</div>