import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FooterPage} from '../core/types/models/FooterPage';

@Injectable({
    providedIn: 'root'
})
export class FooterPages {
	public footerPages$ = new BehaviorSubject<Partial<FooterPage[]>>([]);

	 public init(params) {
        this.footerPages$.next(params);
    }

    public pages(){
    	return this.footerPages$.value;
    }

}