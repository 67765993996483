import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationsModule } from '../core/translations/translations.module';
import { AthandModule } from '../athand/athand.module';
import { WebsiteRoutingModule } from './website-routing.module';
import { HomepageComponent } from './homepage/homepage.component';
import { TutorRegistrationComponent } from './tutor-registration/tutor-registration.component';
import { AboutComponent } from './tutor-registration/about/about.component';
import { AvailabilityComponent } from './tutor-registration/availability/availability.component';
import { DescriptionComponent } from './tutor-registration/description/description.component';
import { LanguageComponent } from './tutor-registration/language/language.component';
import { PhotoComponent } from './tutor-registration/photo/photo.component';
import { SubjectselectionComponent } from './tutor-registration/subjectselection/subjectselection.component';
import { VideoComponent } from './tutor-registration/video/video.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ScrollupComponent } from './scrollup/scrollup.component';
import { SubPageheaderComponent } from './header/sub-pageheader/sub-pageheader.component';
import { FilterPipe } from '@athand/pipes/filter.pipe';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PricingComponent } from './pricing/pricing.component';

@NgModule({
  declarations: [HomepageComponent, FilterPipe, TutorRegistrationComponent, AboutComponent, AvailabilityComponent, DescriptionComponent, LanguageComponent, PhotoComponent, SubjectselectionComponent, VideoComponent, HeaderComponent, FooterComponent, ScrollupComponent, SubPageheaderComponent, PrivacyPolicyComponent, TermsConditionsComponent, PricingComponent ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    WebsiteRoutingModule,
    TranslationsModule,
    AthandModule,
  ],
  exports:[HeaderComponent]
})
export class WebsiteModule { }
