<div class="row scroll_lowSec">
   <div>
      <form [formGroup]="languageForm">
         <table class="table noBorderTable">
            <thead>
               <tr>
                  <th trans="Select Language">Select Language</th>
                  <th trans="Level">Level</th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td>
                     <div class="form-group CCnr-height">
                        <select formControlName="language" class="form-control iconArrowDown">
                           <option value="" trans="Please select">Please select</option>
                           <option [value]="lang.name" *ngFor="let lang of languages">{{lang.name}}</option>
                        </select>
                        <div *ngFor= "let error of errorMessages.language">
                           <ng-container *ngIf="language.hasError(error.type) && (language.dirty || language.touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </td>
                  <td>
                     <div  class="form-group CCnr-height">
                        <select formControlName="languageLevel" class="form-control iconArrowDown">
                           <option value="" trans="Select">Select</option>
                           <option [value]="level.id" *ngFor="let level of languageLevels">
                           {{level.language_level}}
                           </option>
                        </select>
                        <div *ngFor= "let error of errorMessages.languageLevel">
                           <ng-container *ngIf="languageLevel.hasError(error.type) && (languageLevel.dirty || languageLevel.touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </td>
                  <td class="addRow">
                     <i (click)="addLanguage()" class="fa fa-plus-circle" aria-hidden="true"></i>
                  </td>
               </tr>
               <tr *ngFor="let language of postLanguageData; let i = index">
                  <td>
                     <div class="form-group CCnr-height">
                        <input disabled  value="{{language.language}}"  class="form-control">
                     </div>
                  </td>
                  <td>
                     <div class="form-group CCnr-height">
                        <input disabled value="{{language.levelName}}"  class="form-control"> 
                     </div>
                  </td>
                  <td>
                     <div class="delRow">
                        <i (click)="tutorLanguageDelete(i)" class="fa fa-times" aria-hidden="true"></i>
                     </div>
                  </td>
               </tr>
            </tbody>
         </table>
      </form>
      <div *ngIf=postLanguageData.length?languageFormComplete():false>
      </div>
      <div *ngIf=!postLanguageData.length?languageFormInComplete():false>
      </div>
   </div>
</div>