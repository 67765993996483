import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LocaleTimeZoneTS } from '../../../auth/localetimezone';
import * as moment from 'moment-timezone';
var timeZones = moment.tz.names();
declare var $;

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.css']
})
export class AvailabilityComponent implements OnInit {
    @Output() availabilityFormData: EventEmitter<any> = new EventEmitter();
    public error;
    public availabilityForm = this.fb.group({
		day: ['',Validators.required],
		start_time: ['',Validators.required],
		end_time: ['',Validators.required],
    defaulttimezone: ['',Validators.required]
    });
    public availability = new Array();   
    public timings = ["00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00"];
    public internalSelectedTimings;
    public id;
    public offsetTmz=[];
    public selectedDay;

    get day(){ return this.availabilityForm.get('day');}
    get start_time(){ return this.availabilityForm.get('start_time');}
    get end_time(){ return this.availabilityForm.get('end_time');}
    get defaulttimezone(){ return this.availabilityForm.get('defaulttimezone');}

    public errorMessages = {
           day :[{ type:'required',message:'Day is required'}],
           start_time :[{ type:'required',message:'Start time is required'}],
           end_time :[{ type:'required',message:'End time is required'}],
           defaulttimezone :[{ type:'required',message:'Time zone is required'}],

     }
  public selectedTiming = [];
  public pusheditems = {};
  public selectedTimeZone;
  constructor(private fb: FormBuilder,
              public localtimezone: LocaleTimeZoneTS) { }

  ngOnInit(): void {
    //Time zones
    for(var i in timeZones)
    {
      this.offsetTmz.push(timeZones[i]);
    }     
                this.availabilityForm.patchValue({
                    defaulttimezone:this.localtimezone.timezone()
                });
      
  }

    public timeZone($event){
    this.selectedTimeZone = $event;
    }

    public addRow(){
      this.availabilityForm.markAllAsTouched();
      if(this.availabilityForm.valid){ 
      if(this.availabilityForm.value['start_time']<this.availabilityForm.value['end_time']){
      this.error = "";
    	let SelectedTimes = this.calculateTime(this.availabilityForm.value['start_time'] , this.availabilityForm.value['end_time']);
      let internalSelectedTimings = this.calculateInternalTime(this.availabilityForm.value['start_time'] , this.availabilityForm.value['end_time']);
      if(this.pusheditems[this.availabilityForm.value.day]){
      for(let slot of internalSelectedTimings){
       this.pusheditems[this.availabilityForm.value.day].push(slot);
        }
        }else{
        this.pusheditems[this.availabilityForm.value.day] = internalSelectedTimings;
      }
      let id = this.availability.length;
      this.availabilityForm.value.id = id+1;
    	this.availabilityForm.value.selectedTiming = SelectedTimes;
      this.availability.push(this.availabilityForm.value);
      this.availabilityForm.value.formFilled = 1;
      this.selectedTimeZone = this.availabilityForm.value.defaulttimezone;
      this.availabilityForm.value.selectedTimeZone = this.selectedTimeZone;
      this.availabilityForm.value.timezone = this.selectedTimeZone;
      this.availabilityFormData.emit({availability:this.availability, timezone:this.selectedTimeZone});
      this.availabilityForm.get('day').reset();
      this.availabilityForm.get('start_time').reset();
      this.availabilityForm.get('end_time').reset();
      this.availabilityFormData.emit({availability:this.availability, timezone:this.selectedTimeZone});
      }else{
        this.error = "End time Should be greater than Start time";
      }
    }
    }
    
    public deleteRow(avail){
      this.availabilityForm.controls['day'].reset()
      var id = avail.id;
      var result = $.grep(this.availability, function(e){ 
           return e.id != id;  
      });
      this.availability = result;
      this.availabilityFormData.emit({availability:this.availability, timezone:this.selectedTimeZone});
      this.pusheditems[avail.day] = [];
      this.availability.filter(x=>{
        if(x.day == avail.day){
          let time = this.calculateInternalTime(x.start_time , x.end_time);
          this.pusheditems[avail.day] = this.pusheditems[avail.day].concat(time);
        }
      });
   }

    
    public calculateInternalTime(fromTime , toTime){
      fromTime = fromTime.split(":");
      toTime = toTime.split(":");
      let values = [];
      for(let i = fromTime['0'] ; i <= toTime['0'] ; i++){
            i = ('0' + i).slice(-2)
            let j = i+":00";
            values.push(j);
      }
      return values;
    }

   public calculateTime(fromTime , toTime){
      var timeStart = new Date("01/15/2021 " + fromTime).getHours();
      var timeEnd = new Date("01/15/2021 " + toTime).getHours();
      var hourDifference = timeEnd - timeStart;
      let values = [];
      let mainValues = [];
      for(let i = timeStart,j=0; i < timeEnd; i++,j++){
         var now = new Date();
         let addHour = now.setHours(new Date("12/20/2020 " + fromTime).getHours() + j);
         let addMinute = now.setMinutes(new Date("12/20/2020 " + fromTime).getMinutes());
         values.push(now.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}));
      }
      return values;
    }

  public availabilityFormComplete(){
    if(this.selectedTimeZone){
      	this.availabilityForm.value.formFilled = 1;
      	this.availabilityForm.value.selectedTimeZone = this.selectedTimeZone;
      	this.availabilityFormData.emit({availability:this.availability, timezone:this.selectedTimeZone});
    }
  }

  public selectAvailabilityDate(){
     this.selectedDay = $("#selectAvailabilityDate").val();
     this.getTimings(this.selectedDay);
      this.availabilityForm.get('start_time').reset();
      this.availabilityForm.get('end_time').reset();
  }

  public getTimings(selectedDay , startTime = '' , endTime = ''){
    if(this.pusheditems[selectedDay]){
    this.pusheditems[selectedDay] = this.pusheditems[selectedDay].filter(function(elem, index, self) {
    return index === self.indexOf(elem);
})
  }
    this.timings = ["00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00"];
         if(this.pusheditems[selectedDay]){
         for(let selectedTime of this.pusheditems[selectedDay]){
           let index = this.timings.indexOf(selectedTime);
           this.timings.splice(index , 1);
         }
       }else{
        this.timings = ["00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00"];
       }
  }

}


