import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { HomeService } from '../../home.service';

declare var $:any;
import * as _ from 'lodash';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.css']
})
export class PhotoComponent implements OnInit {
   
   public errorstatus = '';
   public fileToUpload: File = null;
   public fileToUploadIdProof: File = null;
   imageError: string;
   isImageSaved: boolean;
   isIdProofImageSaved: boolean;
   cardImageBase64: string;
   cardImageBase64IdProof: string;
   @Output() photoFormData: EventEmitter<any> = new EventEmitter();;
   public photoForm = this.fb.group({
   profile_photo:['',[Validators.required,RxwebValidators.extension({extensions:["jpg","jpeg","png","pdf"]})]], 
   id_proof:['',[Validators.required,RxwebValidators.extension({extensions:["jpg","jpeg","png","pdf"]})]], 
   image:[''],
   idProof:['']
    });

   get profile_photo(){ return this.photoForm.get('profile_photo');}
   get id_proof(){ return this.photoForm.get('id_proof');}


   public errorMessages = {
        profile_photo: [{ type:'required', message:'Provide profile photo'},{ type:'extension',message:'Invalid extension'}],
        id_proof: [{ type:'required', message:'ID Proof Required'},{ type:'extension',message:'Invalid extension'}],
   }

  
  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              public service: HomeService) { }

  ngOnInit(): void {
  }

  public photoFormComplete(){
    this.photoForm.value.cardImageBase64 = this.cardImageBase64;
    this.photoForm.value.cardImageBase64IdProof = this.cardImageBase64IdProof;
    this.photoForm.value.profileimage = this.photoForm.get('image').value;  
      if(this.photoForm.value.cardImageBase64 ){
         this.photoForm.value.formFilled = 1;
         this.photoFormData.emit(this.photoForm.value);
      }else if(this.photoForm.value.cardImageBase64 == null){
         this.photoForm.value.formFilled = 0;
         this.photoFormData.emit(this.photoForm.value);
      }
  }

  public photoFormInComplete(){
    this.photoForm.value.formFilled = 0;
    this.photoFormData.emit(this.photoForm.value);
  }

    fileChangeEvent(fileInput: any , id) {
        this.imageError = null;
        if (fileInput.target.files && fileInput.target.files[0]) {
            // Size Filter Bytes
            const max_size = 20971520;
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 15200;
            const max_width = 25600;

            if (fileInput.target.files[0].size > max_size) {
                this.imageError =
                    'Maximum size allowed is ' + max_size / 1000 + 'Mb';

                return false;
            }

            if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
              console.log(fileInput.target.files[0].type)
                this.imageError = 'Only Images are allowed ( JPG | PNG )';
                this.errorstatus = 'invalid';
                this.cardImageBase64 = null;
                this.photoForm.value.cardImageBase64 = null;
                return false;
              
            }
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    const img_height = rs.currentTarget['height'];
                    const img_width = rs.currentTarget['width'];

                    // console.log(img_height, img_width);


                    if (img_height > max_height && img_width > max_width) {
                        this.imageError =
                            'Maximum dimentions allowed ' +
                            max_height +
                            '*' +
                            max_width +
                            'px';
                        return false;
                    } else {
                        const imgBase64Path = e.target.result;
                        if(id == "uplodfile"){
                        this.cardImageBase64 = imgBase64Path;
                        this.isImageSaved = true;
                        this.fileToUpload = fileInput.target.files[0];
                        this.photoForm.value.cardImageBase64 = this.cardImageBase64;
                        this.photoForm.value.image = this.fileToUpload; 
                          this.photoForm.patchValue({
                            image: this.fileToUpload
                          });
                        }else{
                        this.cardImageBase64IdProof = imgBase64Path;
                        this.isIdProofImageSaved = true;
                        this.fileToUploadIdProof = fileInput.target.files[0];
                        this.photoForm.value.cardImageBase64IdProof = this.cardImageBase64IdProof;
                        this.photoForm.value.image = this.fileToUploadIdProof; 
                          this.photoForm.patchValue({
                            idProof: this.fileToUploadIdProof
                          });
                        }
                    }
                };
            };
           this.photoFormComplete();
            reader.readAsDataURL(fileInput.target.files[0]);
            $('#removeImage').show();
        }
    }

    removeImage() {
        this.photoForm.get('profile_photo').reset();
        this.isImageSaved = false;
        this.cardImageBase64 = null;
        this.fileToUpload = null;
        $('#removeImage').hide(); 
        $('#uplodfile').val("");
    }

    removeIdProofImage() {
        this.photoForm.get('id_proof').reset();
        this.isIdProofImageSaved = false;
        this.cardImageBase64IdProof = null;
        this.fileToUploadIdProof = null;
        $('#removeImageIdProof').hide(); 
        $('#idProofFile').val("");
    }
 

   public validate(){
    this.photoForm.markAllAsTouched();
   }
    
}
