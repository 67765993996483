import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LocaleCurrencyValues} from '../core/types/models/LocaleCurrency';


@Injectable({
    providedIn: 'root'
})
export class LocaleCurrency {

    constructor() {}
    public localeCurrencyDirectCode;
    public localeCurrencySymbol;
	public localeCurrency$ = new BehaviorSubject<Partial<LocaleCurrencyValues[]>>([]);
    changelocaleCurrency$ = this.localeCurrency$.asObservable();

	 public init(params) {
        this.localeCurrency$.next(params);
        this.localeCurrencyDirectCode = params.code;
        this.localeCurrencySymbol = params.symbol;
    }

    public localeCurrency(){
    	return this.localeCurrency$.value;
    }

    public localeCurrencyCode(){
    	return this.localeCurrency$.value['code'];
    }

    public currencyFromIp(){
        return this.localeCurrencyDirectCode;
    }

}