import { Injectable } from '@angular/core';
import { AppHttpClientService } from '../../core/http/app-http-client.service';
import { BehaviorSubject } from 'rxjs';
import { Bookings } from '../../core/types/models/Bookings';
import { LocaleTimeZoneTS } from '../../auth/localetimezone';

 interface SelectedUserID {
    id: number;
}

@Injectable({
  providedIn: 'root'
})

export class LessonsService {
  public scheduleData = new BehaviorSubject<any>(null);
  bookingData = this.scheduleData.asObservable();
  static CANCELLED = 'getstudentCancelled'
  static PENDINGACTIONS = 'studentPendingRequests';
  static COMPLETED = 'studentCompletedLessons';
  static OVERDUE = 'overDue';
  public monthValues = [10,11,12];
  public weekFirstDate = new Date(this.localetimezone.date());
  public weekSecondDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 1));
  public weekThirdDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 2));
  public weekFourthDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 3));
  public weekFifthDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 4));
  public weekSixthDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 5));
  public weekSeventhDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 6));
  public days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

	constructor(private http: AppHttpClientService,
              public localetimezone: LocaleTimeZoneTS){}

   public tutorName = new BehaviorSubject<Object>(null);
    sharedtutorName = this.tutorName.asObservable();

   public tutorId = new BehaviorSubject<Object>(null);
    sharedtutorId = this.tutorId.asObservable();

    public userdata = new BehaviorSubject(Array);
    shareduserdata = this.userdata.asObservable();

    public complaints = new BehaviorSubject(Array);
    sharedcomplaints = this.complaints.asObservable();

    public singlependinguser = new BehaviorSubject(Array);
    sharedsinglependinguser = this.singlependinguser.asObservable();

  public pendingActionsCount1 = new BehaviorSubject<Object>(null);
    sharedpendingActions = this.pendingActionsCount1.asObservable();

  public sourcePendingRequest = new BehaviorSubject<Object>(null);
   sharedsourcePendingRequest = this.sourcePendingRequest.asObservable();

     public sourceRequestReschedule = new BehaviorSubject<Object>(null);
   sharedsourceRequestReschedule = this.sourceRequestReschedule.asObservable();

  public cancelledRequests = new BehaviorSubject<Object>(null);
   sharedsourceCancelled = this.cancelledRequests.asObservable();

   public bookings = new BehaviorSubject<Object>(null);
    sharedBookings = this.bookings.asObservable();

   public bookingAccepted(id){
    this.bookings.next(id);
   }

    public sourcePendingRequest1(id){
      this.sourcePendingRequest.next(id);
    }

    public sourceRequestReschedule1(id){
      this.sourceRequestReschedule.next(id);
    }

    public sourceCanceled1(id){
      this.cancelledRequests.next(id);
    }

    public pendingActionsCount(length){
      this.pendingActionsCount1.next(length);
    }

    public userFilterName(name){
     this.tutorName.next(name);
    }

    public userFilterId(id){
     this.tutorId.next(id);
    }

    public userAlert(user) {
      this.userdata.next(user);
    }

    public opencomplaints(user) {
      this.complaints.next(user);
    }

    public singlependingstutor(singleuserdata) {
      this.singlependinguser.next(singleuserdata);
    }
    // Complete Lessons Data
    public studentLessonsData(){
     return this.http.get('studentlessonsData');
    }

    // OVERDUE
    public overDue(tutorId){
      if(tutorId){
      return this.http.get('overDue/'+tutorId)
      }else{
        return this.http.get('overDue');
      }
    }

      // Schedules Lessons
    public today_UpcomingLessons(tutorId){
        if(tutorId){
        return this.http.get('studentScheduledLessons/' + tutorId);
        }else{
        return this.http.get('studentScheduledLessons');
        }
      }

    public singlessspendingstutor(payload: object) {
      return this.http.post('singletutordata', payload);
    }

    public postStudentComment(payload){
      return this.http.post('postStudentComment' , payload);
    }

    public getStudentComments(){
      return this.http.get('getStudentComments');
    }

    public usercomplaint(id) {
      return this.http.get('getsinglecomplaints/' +id)
    }

    public selectedBookingDetails(payload){
      return this.http.post('getSelectedBookingDetailsStudent' , payload)
    }

    public rescheduleRequest(payload: object){
      return this.http.post('studentRescheduleRequest',payload);
    }

    public cancelLessons(){
      return this.http.get('studentCancelBookings');
    }

    public studentCancelled(tutorId){
      if(tutorId){
        return this.http.get('getstudentCancelled/'+ tutorId);
      }else{
        return this.http.get('getstudentCancelled');
      }

    }

    public cancelRequest(payload: object){
      return this.http.post('studentCancelRequest',payload);
    }

    // This Method Provides tutors availability
    public getTutorDates(payload){
      return this.http.post('getTutorDates', payload);
    }

    public cancelSchedule(payload) {
       return this.http.post('cancelSchedule', payload);
    }

    public acceptRescheduleRequest(payload) {
       return this.http.post('acceptRescheduleRequest', payload);
    }

    public acceptRequestReschedule(payload) {
       return this.http.post('acceptRequestRescheduleStudent', payload);
    }

    public manageSchedule(payload) {
      return this.http.post('acceptRescheduleRequest', payload);
    }

    public raiseTutorCancellation(payload) {
      return this.http.post('raiseTutorCancellation', payload);
    }

    public getNotification(){
      return this.http.get('stuNotifications');
    }

    public getAllNotifications(){
      return this.http.get('stuNotifications');
    }

    public getAllAdminSideNotifications(){
      return this.http.get('getAllAdminSideNotifications');
    }

        // This Mthod is used to reques the backend to upload the files
    public UploadFiles(files){
        return this.http.post('commentFileUpload',files);
    }

    public rateTutor(payload){
      return this.http.post('ratetutor' , payload);
    }

    public studentCompletedLessons(tutorId){
      if(tutorId){
        return this.http.get('studentCompletedLessons/' + tutorId);
      }else{
        return this.http.get('studentCompletedLessons');
      }
    }

    public acceptBookingReschedule(payload){
     return this.http.post('acceptBookingReschedule', payload);
    }

    public cancelDirectBooking(payload){
      return this.http.post('cancelDirectBooking', payload);
    }

    public cancelSingleStudentBooking(payload){
      return this.http.post('cancelSingleStudentBooking', payload);
    }

    public acceptAssignedDatesForPackage(payload){
      return this.http.post('acceptAssignedDatesForPackage'  , payload);
    }

            // Weekly Availability of selctedTutor
    public getWeeklyAvailability(payload: object){
        return this.http.post('getWeeklyAvailability' , payload);
    }

    public currentWeekDates(){
      let pusheditems = {};
    pusheditems[this.days[this.weekFirstDate.getDay()]] = this.weekFirstDate.getFullYear() + "-" + (this.getMonth(this.weekFirstDate)) + "-" + this.sliceDate(this.weekFirstDate.getDate());

      pusheditems[this.days[this.weekSecondDate.getDay()]] = this.weekSecondDate.getFullYear() + "-" + (this.getMonth(this.weekSecondDate)) + "-" + this.sliceDate(this.weekSecondDate.getDate());

      pusheditems[this.days[this.weekThirdDate.getDay()]] = this.weekThirdDate.getFullYear() + "-" + (this.getMonth(this.weekThirdDate)) + "-" + this.sliceDate(this.weekThirdDate.getDate());

      pusheditems[this.days[this.weekFourthDate.getDay()]] = this.weekFourthDate.getFullYear() + "-" + (this.getMonth(this.weekFourthDate)) + "-" + this.sliceDate(this.weekFourthDate.getDate());

      pusheditems[this.days[this.weekFifthDate.getDay()]] = this.weekFifthDate.getFullYear() + "-" + (this.getMonth(this.weekFifthDate)) + "-" + this.sliceDate(this.weekFifthDate.getDate());

      pusheditems[this.days[this.weekSixthDate.getDay()]] = this.weekSixthDate.getFullYear() + "-" + (this.getMonth(this.weekSixthDate)) + "-" + this.sliceDate(this.weekSixthDate.getDate());

      pusheditems[this.days[this.weekSeventhDate.getDay()]] = this.weekSeventhDate.getFullYear() + "-" + (this.getMonth(this.weekSeventhDate)) + "-" + this.sliceDate(this.weekSeventhDate.getDate());

      return pusheditems;
    }

    public sliceDate(date){
 return ('0' + date).slice(-2)
 }

    // This Method gives the exact Month Value
  public getMonth(month){
    let exactMonth = month.getMonth() + 1;
    if(!this.monthValues.includes(exactMonth)){
      exactMonth = ('0' + exactMonth).slice(-2);
      return exactMonth;
    }else{
      return exactMonth;
    }
  }

   public reschedulethisDates(payload: object){
    return this.http.post('reschedulethisDates' , payload);
   }

  public rescheduleOverDueDates(payload: object){
    return this.http.post('rescheduleOverDueDates' , payload);
   }


    public getStudentBooking(payload: object){
        return this.http.post('getStudentBookings', payload);
    }

  public readNotification(id){
        return this.http.get('read-notifications/' + id);
      }

   public updateCurrency(payload: object){
        return this.http.post('currencyUpdate',payload);
   }

   public alertBooking(id){
        return this.http.get('studentAlertBookingDetails/'+ id);
   }

   public getFirstMoreOrder(){
         return this.http.get('getFirstMoreOrder');
   }

   public lessonBoardStudent(payload){
    return this.http.post('lessonBoardStudent' , payload);
   }

   public onVideo(value){
    this.scheduleData.next(value);
   }
}