import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ForgotPasswordService } from './../forgot-password/forgot-password.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})


export class ForgotPassword {
	public code;

  constructor( private toastr: ToastrService,
  	private route: ActivatedRoute,
  	private forgotPasswordService: ForgotPasswordService,
  	private router: Router
  	) {

       this.route.params.subscribe(params => {
	       this.code = params['code'];
	    });

       let data = Object();
       Object.assign(data,{code:this.code});
       this.forgotPasswordService.userConfirm(data).subscribe(response=>{
       		if ( typeof response.error !== 'undefined' ) {
       			this.toastr.error(response.error);
       		} else {
       			this.toastr.success(response.success);
       		}
       		this.router.navigateByUrl('online-tutoring');
       });
	}


}
