<div class="paris">
   <app-scrollup></app-scrollup>
   <div class="all-area">
      <app-sub-pageheader></app-sub-pageheader>
      
      <div class="paris mt-5 pb-">
         <section id="contact" class="contact-area bg-gray ptb_100">
            <div class="container">
               <div class="row justify-content-center">
                  <div class="col-12 col-md-10 col-lg-6">
                     <!-- Section Heading -->
                     <div class="section-heading text-center">
                        <!-- Breamcrumb Content -->
                        <div class="breadcrumb-content d-flex flex-column align-items-center text-center">
                           <h3 trans="Register With Us">Register With Us</h3>
                           <ol class="breadcrumb">
                              <li class="breadcrumb-item"><a class="text-uppercase cursor" href="/"
                                    trans="HOME">HOME</a></li>
                              <li trans="REGISTRATION" class="breadcrumb-item active">REGISTRATION</li>
                           </ol>
                        </div>
                        
                     </div>

                  </div>
               </div>
               <div class="row">
                  <div class="col-12">
                     <!-- Contact Box -->
                     <div class="text-center">
                        <div id="msform">
                           <div class="checkout-area mb-0 pb-0">
                              <div class="checkout-process progressbar_po">
                                  <ul class="nav progressbar" role="tablist" id="progressbarnew">
                                      <li class="nav-item active mt-4">
                                          <h6> <a class="nav-link" id="pills-step1-tab" data-toggle="pill" href=""
                                                  role="tab" aria-controls="pills-step1" aria-selected="true">
                                                  <span class="number">1</span> <span trans="About">About</span>
                                              </a>
                                          </h6>
                                      </li>
                                      <li class="nav-item mt-4">
                                          <h6><a class="nav-link disabled" id="pills-step2-tab" data-toggle="pill" href=""
                                                  role="tab" aria-controls="pills-step2" aria-selected="false">
                                                  <span class="number">2</span><span trans="Photo">Photo</span>
                                              </a>
                                          </h6>
                                      </li>
                                      <li class="nav-item mt-4" trans="Language/Subject">
                                          <h6><a class="nav-link disabled" id="pills-step3-tab" data-toggle="pill" href=""
                                                  role="tab" aria-controls="pills-step3" aria-selected="false">
                                                  <span class="number">3</span> <span trans="Subject">Subject</span>
                                              </a>
                                          </h6>
                                      </li>
                                      <li class="nav-item mt-4">
                                          <h6><a class="nav-link disabled" id="pills-step3-tab" data-toggle="pill" href=""
                                                  role="tab" aria-controls="pills-step3" aria-selected="false">
                                                  <span class="number">4</span> <span trans="Availability">Availability</span>
                                              </a>
                                          </h6>
                                      </li>
                                  </ul>
                              </div>
                           </div>

                           <fieldset class="mt-5 mb-5 tab-home-new1 register_with_us">
                              <app-about (aboutFormData)="aboutFormData($event)"></app-about>
                              <button (click)="aboutValidation()" id="aboutFormValidation" type="button" name="next"
                                 class="validationNext btn btn-bordered mt-3 mt-sm-4" value="Next"
                                 trans="Next">Next</button>
                              <button id="aboutForm" type="button" name="next"
                                 class="next btn btn-bordered mt-3 mt-sm-4" value="Next" trans="Next">Next</button>
                           </fieldset>
                           <!-- PHOTO & Video -->
                           <fieldset class="mt-5 mb-5 tab-photo register_with_photo">
                              <app-photo (photoFormData)="photoFormData($event)"></app-photo>
                              <button (click)="photoValidation()" id="photoFormValidation" type="button" name="next"
                                 class="validationNext btn btn-bordered mt-3 mt-sm-4" value="Next"
                                 trans="Next">Next</button>
                              <button id="photoForm" type="button" name="next"
                                 class="validationNext next btn btn-bordered mt-3 mt-sm-4" value="Next"
                                 trans="Next">Next</button>
                              <button type="button" name="previous" class="previous btn btn-bordered mt-3 mt-sm-4"
                                 value="Previous" trans="Previous">Previous</button>
                           </fieldset>
                           <fieldset class="mt-5 mb-5 tab-subjectSelectionFormData register_with_subjectSelectionFormData">
                              <app-subjectselection (subjectSelectionFormData)="subjectSelectionFormData($event)">
                              </app-subjectselection>
                              <button (click)="subjectValidation()" id="subjectSelectionFormValidation" type="button"
                                 name="next" class="validationNext btn btn-bordered mt-3 mt-sm-4" value="Next"
                                 trans="Next">Next</button>
                              <button type="button" id="subjectSelectionForm" name="next"
                                 class="next btn btn-bordered mt-3 mt-sm-4" value="Next" trans="Next">Next</button>
                              <button type="subjectSelectionForm" name="previous"
                                 class="previous btn btn-bordered mt-3 mt-sm-4" value="Previous"
                                 trans="Previous">Previous</button>
                           </fieldset>
                           <fieldset class="mt-5 mb-5 tab-availabilityFormData register_with_availabilityFormData">
                              <app-availability (availabilityFormData)="availabilityFormData($event,$event)">
                              </app-availability>
                              <button [disabled]="loading$ | async" id="availabilitySelectionForm"
                                 (click)="registerTutor()" type="button" value="Register"
                                 class="validationNext btn btn-bordered mt-3 mt-sm-4" value="Next"
                                 trans="Register">Register<app-loader *ngIf="loading$ | async"></app-loader>
                              </button>
                              <button type="button" name="previous" class="previous btn btn-bordered mt-3 mt-sm-4"
                                 value="Previous" trans="Previous">Previous</button>
                           </fieldset>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>

      <app-footer></app-footer>
   </div>
</div>