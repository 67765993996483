import { Injectable } from '@angular/core';
import { AppHttpClientService } from '../core/http/app-http-client.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class HomeService {

  constructor(private http: AppHttpClientService) { }

  
      public tutorPostRegistration(payload: object){
        return this.http.post('tutorRegistration', payload);
      }

      public emailVailidation(payload: object){
        return this.http.post('emailVailidation', payload);
      } 

      public phoneVailidation(payload: object){
        return this.http.post('phoneVailidation', payload);
      } 
  
}
