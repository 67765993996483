import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LanguageListItem } from '../../../core/services/value-lists.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CurrentUser } from '../../../auth/current-user';
import { CountryListItem } from '../../../core/services/value-lists.service';
import { countries } from "country-flags-svg";
import { LanguageLevels } from '../../../auth/languagesLevels';
import  *  as  countriesList  from  '../../../athand/json/countries.json';
import  *  as  languages  from  '../../../athand/json/languages.json';
declare var $;

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {
  
  public languageLevels;
  public countryFlag;
  public userCountry;
  public countriesList = countries;
  public languagesData: any = (languages as any).default;
  @Output() languageFormData: EventEmitter<any> = new EventEmitter();
  public selectedLanguages:BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  public countries$ = new BehaviorSubject<CountryListItem[]>([]);
  public languages$ = new BehaviorSubject<LanguageListItem[]>([]);
  public countries: any = (countriesList as any).default;
  public languages: any = (languages as any).default;
  public languageForm = this.fb.group({
        language:['',Validators.required],
        languageLevel:['',Validators.required],
    });
  public postLanguageData = new Array();
  public errorMessages = {
        languageLevel: [{type:'required' , message:'Select Language Level'}],
        language: [ { type:'required',message:'Select A Language'}]
    }
  get language(){ return this.languageForm.get('language'); }
  get nativeLanguage(){ return this.languageForm.get('nativeLanguage'); }
  get languageLevel(){ return this.languageForm.get('languageLevel'); }

  constructor(private fb: FormBuilder,
              public languageslevels: LanguageLevels) { }

  ngOnInit(): void {
    $('#alsoSpeaks').on('keydown', function(e){
    if(e.keyCode === 38 || e.keyCode === 40) { //up or down
        e.preventDefault();
        return false;
    }
  });
    this.languageLevels = this.languageslevels.languageLevels();
  }


  public languageFormComplete(){
    this.languageForm.value.formFilled = 1;
    this.languageFormData.emit({languageForm:this.languageForm.value , data:this.postLanguageData});
  }

  public languageFormInComplete(){
    this.languageForm.value.formFilled = 0;
    this.languageFormData.emit(this.languageForm.value);
  }


  public validate(){
    this.languageForm.markAllAsTouched();
  }

  public addLanguage(){
          this.postLanguageData;
          this.languageForm.markAllAsTouched();
          if(this.languageForm.valid){
          this.languageLevels.find(x=>{
           if(x.id == this.languageForm.value.languageLevel){
            this.languageForm.value.levelName = x.language_level;
           }
          });
          const index = this.languages.findIndex(x => x.name === this.languageForm.value.language);
          if (index > -1) {
             this.languages.splice(index, 1);
          }
          this.postLanguageData.push(this.languageForm.value);
          this.languageForm.get('language').reset();
          this.languageForm.get('languageLevel').reset();
          }
  }

  public tutorLanguageDelete(id){
          if (id > -1) {
             this.postLanguageData.splice(id, 1);
          }
  }


}
