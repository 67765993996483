import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-datatable-norecords',
  templateUrl: './datatable-norecords.component.html',
  styleUrls: ['./datatable-norecords.component.css']
})
export class DatatableNorecordsComponent implements OnInit {

  public records = [];
  @Input()
  public set data(val){
  	console.log(val);
  this.records = val;
  }
  constructor() { }
  ngOnInit(): void {
  }

}
