import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { Currencies } from '@auth/currencies';
import { CountryListItem, ValueLists } from '@core/services/value-lists.service';
import { countries } from "country-flags-svg";
import { WebSiteService } from '../../website.service';
import { ToastrService } from 'ngx-toastr';
import { Settings } from '@core/config/settings.service';
import *  as  countriesCurrency from '@athand/json/countriesCurrency.json';
import *  as  Countries from '@athand/json/countries.json';
import { LocaleCurrency } from '@auth/localecurrency';
import { LocaleTimeZoneTS } from '@auth/localetimezone';
declare var $;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  @Output() aboutFormData: EventEmitter<any> = new EventEmitter();
  public countryFlag;
  public countryDialcode;
  public userCountry;
  public popularCurrencies;
  public countriesdata$ = new BehaviorSubject<CountryListItem[]>([]);
  public countriesList = countries;
  public error_message = '';
  public mobile_error_message = '';
  public success_message = '';
  public status;
  public dailcodeData;
  public countryCode;
  public allowedMaxPrice;
  public allowedMinPrice;
  public defaultSelectedCurrency;
  public GBP;
  public bannedCountries;
  public numberRegEx = /\-?\d*\.?\d{1,2}/;
  public currency: any = (countriesCurrency as any).default;
  public countries: any = (Countries as any).default;

  public givenChracters: number = 0;
  public givenHighlightChracters: number = 0;
  public givenDisplayName: number = 0;

  public checkPasswords(group: FormGroup) {
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;
    return password == confirmPassword ? null : { notSame: true }
  };

  public aboutForm = this.fb.group({
    first_name: ['', [Validators.required, Validators.minLength(2)]],
    last_name: ['', [Validators.required, Validators.minLength(2)]],
    phone: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
    gender: [''],
    email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\\.[a-zA-Z]{2,4}$")]],
    default_currency: ['', Validators.required],
    country: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(6)]],

    display_name: ['', Validators.required],
    description: ['', [Validators.required, Validators.minLength(80), Validators.maxLength(500)]],
    highlight: ['', [Validators.required, Validators.minLength(50), Validators.maxLength(500)]],
  }, { validators: this.checkPasswords });

  get first_name() { return this.aboutForm.get('first_name'); }
  get last_name() { return this.aboutForm.get('last_name'); }
  get phone() { return this.aboutForm.get('phone'); }
  get email() { return this.aboutForm.get('email'); }
  get gender() { return this.aboutForm.get('gender'); }
  get about() { return this.aboutForm.get('about'); }
  get default_currency() { return this.aboutForm.get('default_currency'); }
  get hourly_rate() { return this.aboutForm.get('hourly_rate'); }
  get password() { return this.aboutForm.get('password'); }
  get confirmPassword() { return this.aboutForm.get('confirmPassword'); }
  get country() { return this.aboutForm.get('country'); }

  get display_name() { return this.aboutForm.get('display_name'); }
  get description() { return this.aboutForm.get('description'); }
  get highlight() { return this.aboutForm.get('highlight'); }


  public errorMessages = {
    first_name: [{ type: 'required', message: 'First name is required' }, { type: 'minlength', message: 'Minimum two characters' }],
    last_name: [{ type: 'required', message: 'Last name is required' }, { type: 'minlength', message: 'Minimum two characters' }],
    email: [{ type: 'required', message: 'Email is required' }, { type: 'pattern', message: 'Enter valid mail' }],
    phone: [{ type: 'required', message: 'Number is requied' }, { type: 'pattern', message: 'Enter valid phone number' }],
    gender: [{ type: 'required', message: 'Select gender' }],
    password: [{ type: 'required', message: 'Password is required' }, { type: 'minlength', message: 'Minimum six characters required' }],
    confirmPassword: [{ type: 'required', message: 'Re-enter password is required' }, { type: 'notsame', message: 'New password and confirm new password should be same' }, { type: 'minlength', message: 'Minimum six characters required' }],
    default_currency: [{ type: 'required', message: 'Currency is reuired' }],
    country: [{ type: 'required', message: 'Select country' }],

    display_name: [{ type: 'required', message: 'Name is required' }],
    description: [{ type: 'required', message: 'Description is required' },
    { type: 'minlength', message: 'Minimum 80 characters required' },
    { type: 'maxlength', message: 'Only 500 characters' }],
    highlight: [{ type: 'required', message: 'Subject Expertise' },
    { type: 'minlength', message: 'Minimum 50 characters required' },
    { type: 'maxlength', message: 'Only 500 characters' }],

  }

  constructor(private fb: FormBuilder,
    public currencies: Currencies,
    private lists: ValueLists,
    private toastr: ToastrService,
    public service: WebSiteService,
    public settings: Settings,
    public localecurrency: LocaleCurrency,
    public localtimezone: LocaleTimeZoneTS) {
  }


  ngOnInit(): void {

    this.bannedCountries = this.settings.get('selected_banned_countries');

    $('form').on('focus', 'input[type=number]', function (e) {
      $(this).on('wheel.disableScroll', function (e) {
        e.preventDefault()
      })
    })
    $('form').on('blur', 'input[type=number]', function (e) {
      $(this).off('wheel.disableScroll')
    })

    $(".toggle-confirmPassword").click(function () {
      $(this).toggleClass("fa-eye-slash fa-eye");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });

    $(".toggle-password2").click(function () {
      $(this).toggleClass("fa-eye-slash fa-eye");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });

    this.aboutForm.patchValue({
      country: (this.bannedCountries.includes(this.localtimezone.country)) ? '' : this.localtimezone.country,
      default_currency: this.localecurrency.currencyFromIp(),
    });


    this.popularCurrencies = this.currencies.currencies();
    this.GBP = this.popularCurrencies.find(x => {
      if (x.code == "USD") {
        return x;
      }
    });

    let bannedCountries = [];
    if (this.bannedCountries) {
      for (let banned of JSON.parse(this.bannedCountries)) {
        this.countries.filter(country => {
          if (country.name == banned) {
            bannedCountries.push(country);
          }
        });
      }
      this.countries = this.countries.filter(val => !bannedCountries.includes(val));
      this.countriesdata$.next(this.countries);
      this.dailcodeData = this.countries;
    } else {
      this.countriesdata$.next(this.countries);
    }

    // this.selectCurrency(this.localecurrency.currencyFromIp());
  }

  validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }


  public selectCurrency(value) {
    this.aboutForm.get('hourly_rate').reset();
    this.popularCurrencies.find(currency => {
      if (currency['code'] == value) {
        this.allowedMaxPrice = (this.settings.get('tutor_max_per_hour') / this.GBP.exchange_rate) * currency.exchange_rate;
        this.allowedMinPrice = (this.settings.get('tutor_min_per_hour') / this.GBP.exchange_rate) * currency.exchange_rate;
        this.allowedMaxPrice = (Math.round(this.allowedMaxPrice * 100) / 100).toFixed();
        this.allowedMinPrice = (Math.round(this.allowedMinPrice * 100) / 100).toFixed();
        let minValue = +this.allowedMinPrice;
        let maxValue = +this.allowedMaxPrice;
        this.aboutForm.get('hourly_rate').setValidators([Validators.required, Validators.pattern(this.numberRegEx), Validators.min(minValue), Validators.max(maxValue)]);
      }
    });
  }

  public func() {
    this.userCountry = this.aboutForm.value.country;
    this.countriesList.find(country => {
      if (country['name'] == this.aboutForm.value.country) {
        this.countryFlag = country.flag;
        this.countryCode = country.iso2;
      }
    })
    this.dailcodeData.find(dialcode => {
      if (dialcode['name'] == this.aboutForm.value.country) {
        this.countryDialcode = dialcode.dial_code;
      }
    })

    this.aboutForm.value.countryFlag = this.countryFlag;
    this.aboutForm.value.dialcode = this.countryDialcode;
    this.aboutForm.value.countrycode = this.countryCode;
    this.aboutForm.value.formFilled = 1;
    this.aboutForm.value.selectedCountry = this.userCountry;
    this.aboutForm.value.countryFlag = this.countryFlag;
    this.aboutFormData.emit(this.aboutForm.value);
  }

  public aboutFormInComplete() {
    this.aboutForm.value.formFilled = 0;
    this.aboutFormData.emit(this.aboutForm.value);
  }

  public selectEmail(email) {
    let validEmail = Object();
    Object.assign(validEmail, { email: this.aboutForm.value.email });
    if (email.status == 'VALID' && this.aboutForm.value.email != "") {
      this.service.emailVailidation(validEmail).subscribe(response => {
        if (response.status == 'danger') {
          this.status = response.status;
          this.error_message = response.error;
          this.aboutForm.get('email').reset();
        } else if (response.status == 'success') {
          this.status = response.status;
        }
      })
    }

  }

  public selectNumber(phone) {
    var Number = phone.value;
    this.status = 'success';
    var UkNum = /^((\\+91-?)|0)?[0-9]{10}$/;
    if (UkNum.test(Number)) {
      // this.status = 'valid';
      let validPhone = Object();
      Object.assign(validPhone, { phone: this.aboutForm.value.phone });
      this.service.phoneVailidation(validPhone).subscribe(response => {
        if (response.status == 'phonedanger') {
          this.status = response.status;
          this.error_message = response.error;
          this.aboutForm.get('phone').reset();
        } else if (response.status == 'success') {
          this.status = response.status;
        }
      })
    }
  }

  public selectPassword() {
    if (this.aboutForm.value.password != this.aboutForm.value.confirmPassword) {
      this.status = 'checkpassword';
      this.mobile_error_message = 'Password and Re-enter password should be same';
    } else {
      this.status = 'true';
    }
  }

  public validate() {
    this.aboutForm.markAllAsTouched();
  }

  public togglePassword(id) {
    $('.' + id).toggleClass("fa-eye-slash fa-eye");
    if ($('#' + id).get(0).type == 'text') {
      $('#' + id).attr('type', 'password');
    } else {
      $('#' + id).attr('type', 'text');
    }
  }

  public countDisplayName(values) {
    let charactersEntered = values;
    this.givenDisplayName = charactersEntered.length;
  }

  public countCharacters(values) {
    let charactersEntered = values;
    this.givenChracters = charactersEntered.length;
  }

  public countHighlightCharacters(values){
    let charactersEntered = values;
    this.givenHighlightChracters = charactersEntered.length;
   }

   
 
  

}