import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUser } from '../../../auth/current-user';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

public userDetails;
public role;
  constructor(private router: Router,
  	 public currentuser: CurrentUser) { }

  ngOnInit(): void {
  	this.userDetails = this.currentuser.model$.value;
    this.role = this.userDetails.roles[0].name;
  }

    public lessons(){
		  if(this.userDetails.roles[0].name =='Tutor'){ 	
		    this.router.navigate(['tutor/tutor-lessons/students']);
		  }else if(this.userDetails.roles[0].name =='Student'){
		    this.router.navigate(['student/lessons/scheduled/sch']);
		  }else{
		    this.router.navigate(['admin/tutor-settings/teaching-levels']);
		  }
    }
}
