<header class="TT-headerWrapper TT-fixedTop">
    <div class="TT-brandIdentity clearfix">
      <div class="TT-brand d-flex">
        <a class="navbar-brand cursor mobile_res">
        <img src="assets/images/main-logo.png" class="TT-LogoDashboard" routerLink="/admin/home"/>
        </a>
         <div class="collapseToggle TT-ToggleFrame" *ngIf="currentuser.isLoggedIn()">
           <i id="toggleIcon" class="fa fa-bars TT-bars" aria-hidden="true"></i>
         </div>
       </div>
       
       <div class="TT-logoutGroup btn-group pull-right" *ngIf="currentuser.isLoggedIn()"> 
          <button class="btn btn-default btn-lg dropdown-toggle TT-faultBTN" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="{{currentuser.userAvater()}}" class="TT-userIcon" title="{{currentuser.fullName()}}"/>
            <label class="TT-System"> {{currentuser.fullName().length > 10 ? currentuser.userName() : currentuser.fullName() }} </label>
            <span class="caret"></span>
          </button>
          <ul class="dropdown-menu TT-dropDownProfile">
             <li *ngIf="currentuser.hasPermissions(['account.access'])" ><a routerLink="account">Profile</a></li>
             <li *ngIf="currentuser.hasPermissions(['account.access'])"><a routerLink="account">Change Password</a></li>
             <li><a (click)="auth.logOut()">Logout</a></li> 
           </ul>
        </div>
    </div>
  </header>
