import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ProfileService } from '../profile.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  public notification_settings;
  public notificationbSettings = this.fb.group({
        email: [''],
        lessons:[''],
    });


  constructor(private fb: FormBuilder,
  	          public profileService: ProfileService,
  	          private router: Router,
              private toastr: ToastrService) { }

  ngOnInit(): void {

     this.profileService.Notification()
        .subscribe(response => {
         this.notification_settings =  JSON.parse(atob(response['data']));
         let data = JSON.parse(atob(response['data']));
         this.notificationbSettings.patchValue({
           email: (data.email == 'true') ? data.email : '',
           lessons: (data.lessons == 'true') ? data.lessons : '',
         })
     });
  }

  public updateNotificationSettings(){
   this.profileService.updateNotificationSettings(this.notificationbSettings.value)
        .subscribe(response => {
           this.notification_settings = JSON.parse(atob(response['data']));
           this.toastr.success('Record updated successfully');
        });
  }

  public removeNotificationSettings(){
   this.notificationbSettings.patchValue({
     email: '',
     lessons: '',
   })
   this.profileService.updateNotificationSettings(this.notificationbSettings.value)
        .subscribe(response => {
           this.notification_settings = JSON.parse(atob(response['data']));

           this.toastr.success('Record updated successfully');
        });
  }

}