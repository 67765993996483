import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProfileService } from '../profile.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-auto-confirmation',
  templateUrl: './auto-confirmation.component.html',
  styleUrls: ['./auto-confirmation.component.css']
})
export class AutoConfirmationComponent implements OnInit {
	public notification_settings;
  public notificationbSettings = this.fb.group({
        auto_confirm: [''],
    });

  constructor(private fb: FormBuilder,
  	          public profileService: ProfileService,
  	          private router: Router,
              private toastr: ToastrService) { }

  ngOnInit(): void {
  	this.profileService.Notification()
        .subscribe(response => {
         	this.notification_settings =  JSON.parse(atob(response['data']));

         	this.notificationbSettings.patchValue({
         		auto_confirm: this.notification_settings.auto_confirm
         	});
        });
  }

  public updateAutoconfirmSettings(){
   this.profileService.updateAutoconfirmSettings(this.notificationbSettings.value)
        .subscribe(response => {
           this.notification_settings = JSON.parse(atob(response['data']));
           this.toastr.success('Record Updated Successfully');
        });
  }
}
