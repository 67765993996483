import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserConfirmService } from './../user-confirm/user-confirm.service';

@Component({
  selector: 'app-user-confirm',
  templateUrl: './user-confirm.component.html',
  styleUrls: ['./user-confirm.component.css']
})


export class ActivateUser {
	public code;

  constructor( private toastr: ToastrService,
  	private route: ActivatedRoute,
  	private userConfirmService: UserConfirmService,
  	private router: Router
  	) {
       console.log(this.route);

       this.route.params.subscribe(params => {
	       this.code = params['code'];
	    });

       let data = Object();
       Object.assign(data,{code:this.code});
       this.userConfirmService.userConfirm(data).subscribe(response=>{
       		if ( typeof response.error !== 'undefined' ) {
       			this.toastr.error(response.error);
       		} else {
       			this.toastr.success(response.success);
       		}
       		this.router.navigateByUrl('online-tutoring');
       });

	}
}
