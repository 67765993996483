import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { ProfileService } from '../profile/profile.service';
var timeZones = moment.tz.names();

@Component({
  selector: 'app-time-zones',
  templateUrl: './time-zones.component.html',
  styleUrls: ['./time-zones.component.css']
})
export class TimeZonesComponent implements OnInit {

  @Input()
  public set tmz(value) {
    this.availabilityForm.patchValue({
      timezone: value,
    });
  }

  @Output() selectedTimeZone = new EventEmitter();
  public offsetTmz = [];
  public availabilityForm = this.fb.group({
    timezone: ['', Validators.required],
  });
  constructor(private fb: FormBuilder,
    private profileService: ProfileService,) { }

  ngOnInit(): void {
    this.profileService.getTimezones().subscribe(response => {      
      for (var i in response['timezones']) {        
        this.offsetTmz.push({name: i, value: response['timezones'][i]});        
      }
    });
  }

  public timeZone(value) {
    this.selectedTimeZone.emit(value);
  }

}
