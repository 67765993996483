<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<app-header></app-header>

<div class="wrapper mt-4 ">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <div class="table basic">
          <br>
          <h2 class="heading"><b>Free to Sign Up</b></h2>
          <h3 class="heading-senod"><b>Pay Per Lesson</b></h3>
          <br>
          <br>
          <div class="package-name"></div>
          <ul class="features">
            <li>
              <span class="list-name">Schedule One on One Lessons.</span>
              <span class="icon check"><i class="fa fa-check" aria-hidden="true"></i></span>
            </li>
            <li>
              <span class="list-name">Purchase Package Lessons at a Discount.</span>
              <span class="icon check"><i class="fa fa-check" aria-hidden="true"></i></span>
            </li>
            <li>
              <span class="list-name">Enroll in Group Lessons.</span>
              <span class="icon check"><i class="fa fa-check" aria-hidden="true"></i></span>
            </li>
            <li>
              <span class="list-name">Message Tutors Directly.</span>
              <span class="icon check"><i class="fa fa-check" aria-hidden="true"></i></span>
            </li>

          </ul>
          <div class="btn">

            <a id="staticBackdrop" data-backdrop="static" tabindex="-1" data-toggle="modal" data-target="#loginModal"
              trans="Registration Now"> <button>Register Now</button>
            </a>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-lg-6">
        <div class="table premium new-table-premium">
          <!-- <div class="ribbon"><span>Recommend</span></div> -->
          <div class="price-section">
            <div class="price-area">
              <div class="inner-area">
                <span class="text" style="color:#fff;">$</span>
                <span class="price" style="color:#fff;"> 49/</span>
                <p class="month">Per Month</p>
              </div>
            </div>
          </div>
          <div class="package-name"></div>
          <ul class="features">
            <li>
              <span class="list-name">Teach Multiple Subjects.</span>
              <span class="icon check"><i class="fa fa-check" aria-hidden="true"></i></span>
            </li>
            <li>
              <span class="list-name">Video Conferencing For Tutoring Sessions.</span>
              <span class="icon check"><i class="fa fa-check" aria-hidden="true"></i></span>
            </li>
            <li>
              <span class="list-name">Accept Payments Securely.</span>
              <span class="icon check"><i class="fa fa-check" aria-hidden="true"></i></span>
            </li>
            <li>
              <span class="list-name">Message Students Directly.</span>
              <span class="icon check"><i class="fa fa-check" aria-hidden="true"></i></span>
            </li>
          </ul>
          <div class="btn">

            <button (click)="tutorRegistration()">Register Now</button>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>