import { Injectable } from '@angular/core';
import { AppHttpClientService } from '../../core/http/app-http-client.service';
import { BehaviorSubject } from 'rxjs';
import { TutorResume } from '../../core/types/models/TutorResume';
import { TutorVideo } from '../../core/types/tutormodels/TutorVideo';
import { Subjects } from '../../core/types/models/Subjects';
import { UsersEducations } from '../../core/types/models/UsersEducations';
import { TutorAvailability } from '../../core/types/tutormodels/TutorAvailability';
import { TeachingLevels } from '../../core/types/tutormodels/TeachingLevels';
import { CurrentUser } from '../../auth/current-user';
 interface SelectedUserID {
    id: number;
}

@Injectable({
  providedIn: 'root'
})

export class TutorTeachingService {
  static BASE_URI = 'pages';
  static BASE_URI_PC = 'page-categories-list';

  public resume$ = new BehaviorSubject<Partial<TutorResume>>(null);
  public availability$ = new BehaviorSubject<Partial<TutorAvailability[]>>([]);
  public video$ = new BehaviorSubject<Partial<TutorVideo>>(null);
  public selectedUserId$ = new BehaviorSubject<Partial<SelectedUserID>>(null);
  public subjects$ = new BehaviorSubject<Subjects[]>([]);
  public tutorEducations$ = new BehaviorSubject<UsersEducations[]>([]);
  public teachingLevels$ = new BehaviorSubject<TeachingLevels[]>([]);
  public closeModal = new BehaviorSubject([]);
     closeSubjectModal = this.closeModal.asObservable();
     closePackageModal = this.closeModal.asObservable();
	// static BASE_URI = 'tutor_teaching';

  constructor(private http: AppHttpClientService,
              public user: CurrentUser) { }

      public all(){
         return this.http.get('tutor_teaching');
      }

      public getCertificates(){
        return this.http.get('getCertificates');
      }

      public createEducation(payload: object){
        return this.http.post('tutor_teaching', payload);
      }

      public createCertificate(payload: object){
        return this.http.post('createCertificate', payload);
      }

      public getTeachingLevels(){
      	 return this.http.get('getTeachingLevels');
      }

      public addLanguage(payload: object){
        return this.http.post('addLanguage',payload)
      }

      public getTutorLanguages(){
        return this.http.get('getTutorLanguages');
      }

      public deleteLanguage(payload: object){
        return this.http.post('deleteTutorLanguage',payload)
      }




      ////////////////////////////////////// RESUME /////////////////////////////////////

      public getResumeData(id){
        return this.http.get('getResumeData/' + id);
      }

      public getTutorResumeData(){
        return this.http.get('getTutorResumeData');
      }

      public updateResume(payload: object){
        return this.http.post('updateTutorResume', payload);
      }

      public tutorResume(params: { resume?: TutorResume})
      {
        this.setResumeObject(params['resume']);
      }

      public setResumeObject(resume)
      {
        Object.keys(resume).forEach(key => {
          this.resume$.next({...this.resume$.value, [key]: resume[key]});
        })
      }

      public setAvailabilityObject(availability)
      {
        Object.keys(availability).forEach(key => {
          this.availability$.next({...this.availability$.value, [key]: availability[key]});
        })
      }

      public tutorTeachingProfile(user)
      {
        if(user.roles['0']['name'] === 'Tutor'){
          if(user['resume']){
            this.setResumeObject(user['resume']);
          }
          if(user['video']){
            this.setVideoObject(user['video']);
          }
          if(user['availability']){
            this.setAvailabilityObject(user['availability']);
          }

          if(user['education']){
            this.setEducationObject(user['education']);
          }

          if(user['subjects']){
            this.setSubjectsObject(user['subjects']);
          }

          if(user['TeachingLevels']){
            this.setTeachingLevel(user['TeachingLevels']);
          }
        }
      }

      public addnewAvailability(payload: object){
        return this.http.post('newavailability', payload);
      }

      public updatenewAvailability(payload: object){
        return this.http.post('updateAvailability', payload);
      }

      public deleteAvailability(payload: object){
        return this.http.post('deleteAvailability', payload);
      }

      public getResumeValues()
      {
        if(!this.resume$.value){
          return false;
        }else{
          return true;
        }
      }

      public updateResumeStatus(payload)
      {
       return this.http.post('updateResumeStatus', payload);
      }

      public getExistingTutorSlots(){
        return this.http.get('getExistingTutorSlots');
      }

      public getTutorTimeZone(){
        return this.http.get('getTutorTimeZone');
      }







      /////////////////////////////////////// VIDEO //////////////////////////////////////

      public updateVideo(payload: object){
        return this.http.post('updateVideo', payload);
      }


      public setVideoObject(video)
      {
        Object.keys(video).forEach(key => {
          this.video$.next({...this.video$.value, [key]: video[key]});
        })
      }

      public getVideoValues()
      {
        if(!this.video$.value){
          return false;
        }else{
          return true;
        }
      }

      public updateVideoStatus(payload)
      {
        return this.http.post('updateVideoStatus', payload);
      }











      ///////////////////////////////////////// ADMIN TEACHING PROFILE /////////////////////

      public getSelectedTutorProfile(id){
        return this.http.get('getSelectedTutorProfile/'+id);
      }

      public updateAvailability(payload )
      {
        return this.http.post('updateAvailability', payload );
      }

      ///////////////////////////////////////// EDUCATION //////////////////////////////////
      public setEducationObject(education)
      {
        Object.keys(education).forEach(key => {
          this.tutorEducations$.next({...this.tutorEducations$.value, [key]: education[key]});
        })
      }

      public updateEducations(response)
      {
        if(!this.tutorEducations$.value){
          return false;
        }else{
          return true;
        }
      }

      public getEducationData(id)
      {
        return this.http.get('getEducationData/' + id);
      }

      public admnTutorEducationStatus(payload){
        return this.http.post('admnTutorEducationStatus', payload);
      }

      public tutorEducationDelete(payload){
        return this.http.post('tutorEducationDelete', payload);
      }

    public tutorCertificateDelete(payload){
        return this.http.post('tutorCertificateDelete', payload);
      }






      ///////////////////////////////////////// SUBJECTS ///////////////////////////////////

      public addTutorSubject(payload: object){
        return this.http.post('addTutorSubject', payload);
      }

      public tutorSubjectEdit(payload: object){
        return this.http.post('editTutorSubject', payload);
      }

      public getTutorSubjects(){
        return this.http.get('getTutorSubjects');
      }



      public setSubjectsObject(subjects)
      {
        Object.keys(subjects).forEach(key => {
          this.subjects$.next({...this.subjects$.value, [key]: subjects[key]});
        })
      }

      public getSubjectsData(id)
      {
        return this.http.get('getSubjectsData/' + id);
      }


      public admnTutorSubjectStatus(payload){
        return this.http.post('admnTutorSubjectStatus', payload);
      }

      public tutorSubjectDelete(payload){
        return this.http.post('tutorSubjectDelete', payload);
      }




      ///////////////////////////////////////// PACKAGE ////////////////////////////////////

      public getTutorPackages(){
        return this.http.get('getTutorPackage');
      }
      public addTutorPackage(payload)
      {
        return this.http.post('addTutorPackage', payload);
      }

      public updateTutorPackage(payload)
      {
        return this.http.post('updateTutorPackage', payload);
      }

      public setPackageObject(packages){
        //  Object.keys(packages).forEach(key => {
        //   this.packages$.next({...this.packages$.value, [key]: packages[key]});
        // })
      }

      public admnTutorPackageStatus(payload){
        return this.http.post('admnTutorPackageStatus', payload);
      }


      public getPackagesData(id)
      {
        return this.http.get('getPackagesData/' + id);
      }

      public tutorPackageDelete(payload){
        console.log(payload);
        return this.http.post('tutorPackageDelete',payload);
      }


      ///////////////////////////////////////// AVAILABILITY ////////////////////////////////

      public getAvailabilityValues()
      {
        if(!this.availability$.value){
          return false;
        }else{
          return true;
        }
      }

      public getAvailabilityData(id)
      {
        return this.http.get('getAvailabilityData/' + id);
      }

      public updateAvailabilityStatus(payload)
      {
        return this.http.post('updateAvailabilityStatus', payload);
      }


      ///////////////////////////////////////// TEACHING LEVELS ///////////////////////////////

      public setTeachingLevel(teachingLevels$)
      {
        Object.keys(teachingLevels$).forEach(key => {
          this.teachingLevels$.next({...this.teachingLevels$.value, [key]: teachingLevels$[key]});
        })
      }

      public getLevels(){
        return this.http.get('getLevelsList');
      }

      public getTimings(day){
        return this.http.get('getStartEndTimings/'+day);
      }


      public getEditTimings(id){
        return this.http.get('getEditTimings/'+id);
      }

      ///////////////////////////////////////// Group Classes ////////////////////////////////////
      public getTutorGroupclasses(){
        return this.http.get('getTutorGroupclasses');
      }
      public addTutorGroupclass(payload)
      {
        return this.http.post('addTutorGroupclass', payload);
      }

      public updateTutorGroupclass(payload)
      {
        return this.http.post('updateTutorGroupclass', payload);
      }

      public admnTutorGroupclassStatus(payload){
        return this.http.post('admnTutorGroupclassStatus', payload);
      }

      public getGroupclassesData(id)
      {
        return this.http.get('getGroupclassesData/' + id);
      }

      public tutorGroupclassDelete(payload){
        return this.http.post('tutorGroupclassDelete',payload);
      }

      public getGroupclass(id)
      {
        return this.http.get('getGroupclass/' + id);
      }

      ///////////////////////////////////////// Group Class Batches ////////////////////////////////////
      public getTutorGroupclassBatches(group_class_id){
        return this.http.get('getTutorGroupclassBatches/' + group_class_id);
      }
      public addTutorGroupclassBatch(payload)
      {
        return this.http.post('addTutorGroupclassBatch', payload);
      }
      public tutorGroupclassBatchDelete(payload){
        return this.http.post('tutorGroupclassBatchDelete',payload);
      }

}
