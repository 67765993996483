<section id="tutorDashboard" class="pageSectionBg main">
   <div *ngIf="currentuser.isLoggedIn()" id="menuwrapper" class="sidebar d-flex">
      <ul id="sidemenu" class="tt-sideArea">
         <li routerLink="home" routerLinkActive="active" title="DASHBOARD">
            <a class="noflyout"><i class="fa fa-home" aria-hidden="true"></i>
               <span trans="DASHBOARD">DASHBOARD</span>
            </a>
         </li>
         <li routerLink="find-tutor" routerLinkActive="active" title="FIND TUTORS">
            <a>
               <i class="fa fa-male" aria-hidden="true"></i>
               <span trans="FIND TUTORS">FIND TUTORS</span>
            </a>
         </li>
         <li routerLink="lessons" routerLinkActive="active" title="LESSONS">
            <a>
               <i class="fa fa-calendar" aria-hidden="true"></i>
               <span trans="LESSONS">LESSONS</span>
            </a>
         </li>
         <li routerLink="student-chat" routerLinkActive="active" title="CHATBOX">
            <a class="alertsModule">
               <i class="fa fa-comments-o" aria-hidden="true"></i>
               <span trans="CHATBOX">CHATBOX</span>
            </a>
         </li>

         <li routerLink="student-payment" routerLinkActive="active" title="TRANSACTIONS">
            <a>
               <i class="fa fa-usd" aria-hidden="true"></i>
               <span trans="TRANSACTIONS">TRANSACTIONS</span>
            </a>
         </li>
         <li routerLink="student-account" routerLinkActive="active" title="PROFILE">
            <a>
               <i class="fa fa-user" aria-hidden="true"></i>
               <span trans="PROFILE">PROFILE</span>
            </a>
         </li>
         <li routerLink="/student/student-alert" routerLinkActive="active" title="ALERTS">
            <a class="alertsModule">
               <i class="fa fa-bell" aria-hidden="true"></i>
               <span trans="ALERTS">ALERTS</span>
            </a>
         </li>
      </ul>
   </div>

   <div aria-hidden="true" aria-labelledby="alertModallLabel" role="dialog" tabindex="-1" id="alertModal"
      class="modal fade">
      <div class="modal-dialog mt-200">
         <div *ngIf="alertMessage" class="modal-content bd-25">
            <div class="modal-header">
               <button aria-hidden="true" data-dismiss="modal" class="close" type="button" #closeSuccessModal>×</button>
               <h4 class="modal-title linkLabel-green-dark">{{alertMessage['purpose']}}</h4>
            </div>
            <div class="modal-body padding50 text-center">
               <h4 class="linkLabel"><i class="fa fa-4x fa-check-square green" aria-hidden="true"></i></h4>
               <h4 class="linkLabel">{{alertMessage['notification']}}</h4>
               <button (click)="viewSchedules()" type="submit" class="btn btn-primary">View Schedule</button>
            </div>
         </div>
      </div>
   </div>
</section>
