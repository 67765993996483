import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CurrentSession} from '../core/types/models/CurrentSession';

@Injectable({
    providedIn: 'root'
})
export class CurrentSessionTS {
	public currentsession$ = new BehaviorSubject<Partial<CurrentSession[]>>([]);

	 public init(params) {
        this.currentsession$.next(params);
    }

    public session(){
    	return this.currentsession$.value;
    }

}