<div class="container-padding TT-tabNavBar">
   <nav class="navbar navbar-default TT-navBarDefaultTab">
      <div class="collapse navbar-collapse TT-navbarCollapseTab" id="bs-example-navbar-collapse-1">
       <ul class="nav navbar-nav TT-navNavTabb">
          <li routerLink="settings" routerLinkActive="selectedTeaching" class="TT-listingArea mb-2">
           <a trans="Account Settings" class="TT-navLinkArea">Account Settings</a>
          </li>
          <li routerLink="payment-methods" routerLinkActive="selectedTeaching" class="TT-listingArea mb-2">
           <a trans="Payment Methods" class="TT-navLinkArea">Payment Methods</a>
          </li>
          <li *ngIf="role == 'Student'" routerLink="auto-confirmation" routerLinkActive="selectedTeaching" class="TT-listingArea mb-2">
           <a trans="Auto Confirmation" class="TT-navLinkArea">Auto Confirmation</a>
          </li>
          <li *ngIf="currentuser.isRegistrationCompleted() && role == 'Student'" class="TT-listingArea">
           <div (click)="lessons()"> 
            <a trans="Manage Lessons" class="TT-navLinkArea">Manage Lessons</a>
           </div>  
          </li>
          <li routerLink="notifications" routerLinkActive="selectedTeaching" class="TT-listingArea" >
            <a trans="Notifications" class="TT-navLinkArea">Notifications</a>
         </li>
        </ul>
      </div>
     </nav>
   </div>

