import {Injectable,ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {CurrentUser} from '../auth/current-user';
import {Settings} from '../core/config/settings.service';
import { BehaviorSubject } from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {Translations} from '../core/translations/translations.service';
import {User} from '../core/types/models/User';
import {AppHttpClientService} from '../core/http/app-http-client.service';
import {Subject} from "rxjs";
import {TutorTeachingService} from './../tutor/teaching/teaching.service';
import {CurrentSessionTS} from '../auth/currentsession';
import { ToastrService } from 'ngx-toastr';

import {FindTutor} from './../student/findtutor/findtutor.service';
@Injectable({
    providedIn: 'root',
})

export class SocialAuthService {
     invokeEvent:Subject<any> = new Subject();
     public closeModal = new BehaviorSubject([]);
     closeLoginModal = this.closeModal.asObservable();
     closeForgotModal = this.closeModal.asObservable(); 
          
     public openModal = new BehaviorSubject([]);
     openResetModal = this.openModal.asObservable();


   

    /**
     * Instance of extraCredentialsModal.
     */
    // private extraCredentialsModal: MatDialogRef<RequestExtraCredentialsModalComponent>;

    /**
     * Social login popup window height.
     */
    private windowHeight = 550;

    /**
     * Social login popup window width.
     */
    private windowWidth = 650;

    /**
     * Resolve for latest social login or connect call.
     */
    private resolve: Function;

    constructor(
        protected httpClient: AppHttpClientService,
        public currentUser: CurrentUser,
        protected router: Router,
        public settings: Settings,
        protected auth: AuthService,
        protected i18n: Translations,
        public tutorteaching: TutorTeachingService,
        private currentsession: CurrentSessionTS,
        private findtutor: FindTutor,
        private toastr: ToastrService,
    ) {
        this.listenForMessageFromPopup();
    }

    /**
     * Log user in with specified social account.
     */
    public loginWith(serviceName: string , role: string): Promise<User> {
                        let x = 'sam';
                this.sharedCloseModal(x);
        return this.openNewSocialAuthWindow('secure/auth/social/' + serviceName + '/login/' + role);
    }

    /**
     * Connect specified social account to current user.
     */
    public connect(serviceName: string): Promise<User> {
        return this.openNewSocialAuthWindow('secure/auth/social/' + serviceName + '/connect');
    }

    /**
     * Disconnect specified social account from current user.
     */
    public disconnect(serviceName: string) {
        return this.httpClient.post('auth/social/' + serviceName + '/disconnect');
    }

    /**
     * Handle social login callback, based on returned status.
     */
    public socialLoginCallback(status: string, data = null) {
        console.log(data);
        if ( ! status) return;
        switch (status.toUpperCase()) {
            case 'SUCCESS':
            this.modalClose('modalClose');
                this.currentUser.assignCurrent(data['user']);
                this.tutorteaching.tutorTeachingProfile(data['user']);
                if(this.currentUser.hasRole('Tutor')){
                this.router.navigate([this.auth.getRedirectUri()]);
                break;
                }
                if(this.currentUser.hasRole('Student')){
                this.router.navigate([this.auth.getRedirectUri()]);
                break;
                }
            case 'SUCCESS_CONNECTED':
                if (this.resolve) this.resolve(data.user);
                break;
            case 'ALREADY_LOGGED_IN':
                this.router.navigate([this.auth.getRedirectUri()]);
                break;
            case 'REQUEST_EXTRA_CREDENTIALS':
                this.showRequestExtraCredentialsModal({credentials: data});
                break;
            case 'ERROR':
                const message = data ? data : this.i18n.t('An error occurred. Please try again later');
        }
    }

    public modalClose(value){
        this.invokeEvent.next({some:value})
    }

    /**
     * Open extra credentials modal and subscribe to modal events.
     */
    public showRequestExtraCredentialsModal(config: Object) {
        // this.extraCredentialsModal = this.modal.open(RequestExtraCredentialsModalComponent, config);
        // this.extraCredentialsModal.componentInstance.onSubmit$.subscribe(credentials => {
        //     if ( ! credentials) return;
        //     this.sendExtraCredentialsToBackend(credentials);
        // });
    }

    /**
     * Send specified credentials to backend and handle success/error.
     */
    public sendExtraCredentialsToBackend(data: Object) {
        // this.httpClient.post('auth/social/extra-credentials', data).subscribe(response => {
        //     this.currentUser.assignCurrent(response['data']);
        //     this.extraCredentialsModal.close();
        //     this.router.navigate([this.auth.getRedirectUri()]).then(() => {
        //         this.toast.open('Accounts connected');
        //     });
        // }, this.extraCredentialsModal.componentInstance.handleErrors.bind(this.extraCredentialsModal.componentInstance));
    }

    /**
     * Open new browser window with given url.
     */
    private openNewSocialAuthWindow(url: string): Promise<User> {
        const left = (screen.width / 2) - (this.windowWidth / 2);
        const top = (screen.height / 2) - (this.windowHeight / 2);

        return new Promise(resolve => {
            this.resolve = resolve;

            window.open(
                url,
                'Authenticate Account',
                'menubar=0, location=0, toolbar=0, titlebar=0, status=0, scrollbars=1, width=' + this.windowWidth
                + ', height=' + this.windowHeight + ', ' + 'left=' + left + ', top=' + top
            );
        });
    }

    /**
     * Listen for "message" event from social login popup
     * window and call "socialLoginCallback" once received
     */
    private listenForMessageFromPopup() {
        window.addEventListener('message', e => {
                let x = 'sa';
                this.sharedCloseModal(x);
            if(e.data.status==='SUCCESS'){
            // if (e.data.type !== 'social-auth' || this.settings.getBaseUrl().indexOf(e.origin) === -1) return;
            this.socialLoginCallback(e.data.status, e.data['callbackData']);
            }else if(e.data.status === 'ERROR'){
           this.toastr.error(e.data.callbackData['account_status']);
            }
        }, false);
    }

    public sharedCloseModal(x){
      this.closeModal.next(x);
    }

    public sharedOpenModal(x){
      this.openModal.next(x);
    }

    /**
     * Disconnect specified social account from current user.
     */
    public forgotPassword(payload: object) {
        return this.httpClient.post('forgot-password', payload);
    }

    public resetPassword(payload: object) {
        return this.httpClient.post('user/reset-password', payload);
    }

    //registration Form
    public registrationForm(payload: object) {
        return this.httpClient.post('registration-form', payload);
    }

    public registrationEmail(payload: object) {
        return this.httpClient.post('registration-email', payload);
    }

}
