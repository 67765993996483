import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Injectable, Injector } from '@angular/core';
import { Translations } from '@core/translations/translations.service';
import { ActiveSubjects } from '@auth/active-subjects';
import { Router } from '@angular/router';
import { SubjectTS } from '@auth/subject';
import { ToastrService } from 'ngx-toastr';
import { CurrentUser } from '@auth/current-user';
import { TeamMembers } from '@auth/TeamMembers';
import { GuestNews } from '@auth/GuestNews';
import { RouteConstantsService } from '@services/routeConstants/route-constants.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalizationWithLines } from '../../core/types/localization-with-lines';
import { Localizations } from '../../core/translations/localizations.service';
import { distinctUntilChanged, finalize } from 'rxjs/operators';
import { WebSiteService } from '../website.service';
declare var $:any;

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})

export class HomepageComponent implements OnInit, AfterViewInit {
  
  public guestNewsPage:any;
  public PWAStatus:any;
  public VideoLinks:any;
  public welcome_video:any;
  public welcome_video_full:any;
  public activeSubjects = new Array();
  public ourTeam:any;
  public pageRoute:any;
  public languages$ = new BehaviorSubject<LocalizationWithLines[]>([]);
  public loading$ = new BehaviorSubject<boolean>(false);

  public countryData = [];
  public alsoSpeaksData = [];
  public nativeSpeakersData = [];
  public teachingLevels = [];
  public ageData = [];
  public studentsCount = 0;
  public tutorsCount = 0;

  constructor(protected injector: Injector,
              protected i18n: Translations,
              public activesubjects: ActiveSubjects,
              public subjectselected: SubjectTS,
              private router: Router,
              public currentuser: CurrentUser,
              private toastr: ToastrService,
              public team: TeamMembers,
              public guestNews: GuestNews,
              private localizationsApi: Localizations,
              public webSiteService: WebSiteService) {
      
      this.PWAStatus = this.isPWA();

      this.webSiteService.getHomepageData().subscribe(response => {
        if(response){
          let data = JSON.parse(atob(response['data']));
          this.ageData = data['ages'];
          this.nativeSpeakersData = data['nativeLanguage'];
          this.teachingLevels = data['teachingLevels'];
          this.studentsCount = data['studentsCount']
          this.tutorsCount = data['tutorsCount']
        }
      });
   }

  ngOnInit(): void {
    // $('#input_92_31').attr('readonly','readonly');
    $('#selectionSubject').css('display','none');
    $(document).on('click', function (event) {
      if (!$(event.target).closest('.selectionSubject').length) {
        $('#selectionSubject').css('display','none');
      }
    });
    if(!this.PWAStatus){
      $(".jarallax").attr("data-jarallax-video",this.welcome_video);
    }
    
    $(".data-fancybox").attr("href",this.welcome_video_full);
    this.activeSubjects = this.activesubjects.activeSubjects$.value;
    $('.selectionSubject').click(function(e) {
         $('#selectionSubject').css('display','initial');
    });   

    $('.selectionSubject').keyup(function(e) {
        let search = e.target.value.toLowerCase();

        $('.subjectsli a').each(function(ele){
          if($(this).text().toLowerCase().includes(search)) {
            $(this).show()
          } else {
            $(this).hide()
          }
        })

        
    });
    
  }

ngAfterViewInit(): void{
  
  }

  ngAfterContentInit(): void{
    this.loadExternalScript('homePageAssets/js/popper.min.js');
    this.loadExternalScript('homePageAssets/js/bootstrap.min.js');
    this.loadExternalScript('homePageAssets/js/typed.js');
    setTimeout(()=>{                           
      this.loadExternalScript('homePageAssets/js/active.js');
    }, 1500);
  }

  public loadExternalScript(url: string) {
  const body = <HTMLDivElement> document.body;
  const script = document.createElement('script');
  script.innerHTML = '';
  script.src = url;
  script.async = true;
  script.defer = true;
  body.appendChild(script);
}

  // SELECT SUBJECT
  public selectSubject(subject , className){
     let subjectSelected = this.activeSubjects.find(x=>x.subject_name === subject);
     this.subjectselected.init(subjectSelected);
     localStorage.setItem('subject' , JSON.stringify(subjectSelected));
     $('.results').css('display','none');
     $('.'+className).val(subject);
     $('#findATeacherBtn').prop('disabled', false);
     this.findTeacher();
  }

  public findTeacher(){
    if(this.currentuser.isTutor()){
      this.toastr.error('You Have no access to find Tutors');
     }else if(this.currentuser.isAdmin()){
      this.toastr.error('You Have no access to find Tutors');
     }else{
      this.router.navigate([RouteConstantsService.TUTORS_PAGE]);
    }
  }


  public tutorProfile(userName){
  this.router.navigate(['student/profile/'+userName]);
  }

  public isPWA() {
    return ["fullscreen", "standalone", "minimal-ui"].some(
        (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
    );
  }
  
}
