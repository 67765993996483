import { Injectable } from '@angular/core';
import { AppHttpClientService } from '../../core/http/app-http-client.service';
import { BehaviorSubject } from 'rxjs';
import { AccountSetting } from '../../core/types/models/AccountSetting';


@Injectable({
  providedIn: 'root'
})

export class ProfileService {
  public setting$ = new BehaviorSubject<Partial<AccountSetting>>(null);
  static more = 'more';
  constructor(private http: AppHttpClientService) { }

      ////////////////////////////////////// ACCOUNT SETTING /////////////////////////////////////
      public Notification(){
       return this.http.get('NotificationSettings');
      }

      public updateSetting(payload: object , current_role){
        if ( current_role == 'Student' ) {
        return this.http.post('updateStuSetting', payload);
         } else if (current_role == 'Tutor'){
        return this.http.post('updateSetting', payload);
         }else{
        return this.http.post('updateAdminSetting', payload);
         }
      }
      
      public getIdProof(){
        return this.http.get('getIdProof');
      }

      public update2faSetting(payload: object){
        return this.http.post('update2faSetting', payload);
      }

      public updateNotificationSettings(payload: object){
        return this.http.post('updateNotificationSettings', payload);
      }

      public updateAutoconfirmSettings(payload: object){
        return this.http.post('updateAutoconfirmSettings', payload);
      }

      public updateTaxSettings(payload: object){
        return this.http.post('updateTaxSettings', payload);
      }

      // Payment Methods

      public paymentDetails(){
       return this.http.get('paymentDetails');
      }

      public vatDetails(){
       return this.http.get('vatDetails');
      }

      public updatePaymentMethods(payload: object){
        return this.http.post('updatePaymentMethods', payload);
      }

      public PaymentDelete(payload: object){
        return this.http.post('PaymentDelete', payload);
      }

      public tutorDashboard(payload: object) {
        return this.http.post('tutorDashboard', payload);
      }

      public getPageCategories() {
        return this.http.get('getPageCategories');
      }

      public getPageData(title){
        return this.http.get('more/'+title);
      }

      public Dashboard() {
        return this.http.get('Dashboard');
      }

      public connectGoogleCalendar(payload: object) {
         return this.http.post('connect-google-calendar', payload);
      }

      public checkGoogleConnection(payload: object) {
        return this.http.post('checkGoogleConnection', payload);
      }

      public paymentMakePrimary(payload: object) {
        return this.http.post('paymentMakePrimary', payload);
      }

      public updateCalendarID(payload: object) {
         return this.http.post('updateCalendarID', payload);
      }

      public disconnectGoogleCalendar(payload: object) {
        return this.http.post('disconnectGoogleCalendar', payload);
      }

      public updateVerificationId(payload: object){
        return this.http.post('updateVerificationId' , payload);
      }

      public uniqueValidation(payload: object){
        return this.http.post('uniqueValidation', payload);
      } 

      public renewWithStripe(payload: object){
        return this.http.post('renewWithStripe' , payload);
      }

      public renewWithPaypal(payload: object) {
        return this.http.post('renewWithPaypal' , payload);
      }

      public getTimezones() {
          return this.http.get('get-time-zones');
      }

      public paypal_subscription(){
        return this.http.post('paypal_subscription');
      }
      
      public paypalsubscription(){
        return this.http.get('paypalsubscription');
      }
     
}
