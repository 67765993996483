<section id="become-tutor" class="pageSection">
    <div class="container content">
        <div class="col-md-12 text-left main-head-title padding0">
            <div class="success-title">
                <h1 class="linkLabel" trans="Become a tutor">
                    Become a tutor
                </h1>
                <p class="linkLabel">
                    Earn a living with My Tutor Platform
                </p>
           
          
            </div>
        </div>
        <div id="msform">
            <div class="progressbar_po">
                <ul id="progressbar">
                    <li class="active" trans="About">
                        About
                    </li>
                    <li class="" trans="Photo">
                        Photo
                    </li>
                    <li class="" trans="Description">
                        Description
                    </li>
                    <li class="" trans="Video">
                        Video
                    </li>
                    <li class="" trans="Language">
                        Language
                    </li>
                    <li class="" trans="Subject">
                        Subject
                    </li>
                    <li class="last" trans="Availability">
                        Availability
                    </li>
                </ul>
            </div>

            <!-- ABOUT -->
<fieldset>
<app-about (aboutFormData)="aboutFormData($event)"></app-about>
<input (click)="aboutValidation()" id="aboutFormValidation" type="button" name="next" class="btnRight action-button btn btn-primary new aboutForm" value="Next" />
<input id="aboutForm" type="button" name="next" class="next action-button btn btn-primary new aboutForm" value="Next" />
</fieldset>

            <!-- PHOTO -->
<fieldset>
<app-photo (photoFormData)="photoFormData($event)"></app-photo>
<input (click)="photoValidation()" id="photoFormValidation" type="button" name="next" class="btnRight action-button btn btn-primary new" value="Next" />
<input id="photoForm" type="button" name="next" class="next action-button btn btn-primary new" value="Next" />
<input type="button" name="previous" class="previous action-button btn btn-primary new" value="Previous" />        
</fieldset>

<fieldset>
    <app-description (resumeFormData)="resumeFormData($event)"></app-description>
    <input (click)="resumeValidation()" id="resumeFormValidation" type="button" name="next" class="btnRight action-button btn btn-primary new" value="Next" />
    <input id="resumeForm" type="button" name="next" class="next action-button btn btn-primary new" value="Next" />
    <input type="button" name="previous" class="previous action-button btn btn-primary new" value="Previous" />
</fieldset>

<fieldset>
    <app-video (videoFormData)="videoFormData($event)"></app-video>
    <input (click)="videoValidation()" id="videoFormValidation" type="button" name="next" class="btnRight action-button btn btn-primary new" value="Next" />
    <input type="button" id="videoForm" name="next" class="next action-button btn btn-primary new" value="Next">
    <input type="button" name="previous" class="previous action-button btn btn-primary new" value="Previous">
</fieldset>

<fieldset>
    <app-language (languageFormData)="languageFormData($event)"></app-language>
    <input (click)="languageValidation()" id="languageFormValidation" type="button" name="next" class="btnRight action-button btn btn-primary new" value="Next" />
    <input type="button" id="languageForm" name="next"  class="next action-button btn btn-primary new" value="Next">
    <input type="button" name="previous" class="previous action-button btn btn-primary new" value="Previous">
</fieldset>

<fieldset>
    <app-subjectselection (subjectSelectionFormData)="subjectSelectionFormData($event)"></app-subjectselection>
    <input (click)="subjectValidation()" id="subjectSelectionFormValidation" type="button" name="next" class="btnRight action-button btn btn-primary new" value="Next" />
    <input type="button" id="subjectSelectionForm" name="next"  class="next action-button btn btn-primary new" value="Next">
    <input type="subjectSelectionForm" name="previous" class="previous action-button btn btn-primary new" value="Previous">
</fieldset>

<fieldset>
    <app-availability (availabilityFormData)="availabilityFormData($event,$event)"></app-availability>
    <input type="button" id="availabilityForm" (click)="registerTutor()" class="btnRight action-button btn btn-primary new" value="Register">
    <input type="button" name="previous" class="previous action-button btn btn-primary new" value="Previous">
</fieldset>

</div>
</div>