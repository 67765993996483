<div id="paris" class="paris">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header></app-header>
        <div class="paris">

            <div class="banner">
                <div class="searching">
                    <h1 class="text-white text-center text-white">Connect
                        With Tutors Around The World</h1>
                    <div class="input-group searchDD">
                        <input id="input_92_31" trans-placeholder="Select Subject..." class="searchBar form-control selectionSubject" placeholder="Select Subject...">
                        <ul id="selectionSubject" class="results searchDD2">
                            <li class="w-100 subjectsli" *ngFor="let subject of activeSubjects"
                                (click)="selectSubject(subject.subject_name , 'selectionSubject')">
                                <a class="text-secondary">{{subject.subject_name}}</a>
                            </li>
                        </ul>
                        <div class="input-group-append">
                            <button (click)="findTeacher()" id="findATeacherBtn" trans="Find a tutor"
                                class="btn btn-bordered-white" type="submit">Find a tutor</button>
                        </div>
                    </div>
                </div>
            </div>

            <section id="about">
                <div class="container mt-4 mb-4">
                    <div class="row">
                        <div class="col-12 col-lg-12">
                            <h2 class="main-heading text-center mb-4" trans="Benefits Of Our Tutor Platform">Benefits Of
                                Our Tutor Platform</h2>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-7">
                            <div class="row mt-5">
                                <div class="world-connect">
                                    <div class="col-12 col-lg-12">
                                        <div class="info-box mt-4">
                                            <img src="assets/images/homepage/one.png" alt="" class="img-fluid" />
                                            <div class="ms-4">
                                                <h6
                                                    trans="One-on-One personalized tutoring and group sessions utilizing the platform’s video conferencing">
                                                    One-on-One personalized tutoring and group sessions utilizing the
                                                    platform’s video conferencing</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-12">
                                        <div class="info-box mt-4">
                                            <img src="assets/images/homepage/personal.png" alt="" class="img-fluid" />
                                            <div class="ms-4">
                                                <h6 trans="Students learn from skilled tutors across the world">Students
                                                    learn from skilled tutors across the world</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-12">
                                        <div class="info-box mt-4">
                                            <img src="assets/images/homepage/flexible.png" alt="" class="img-fluid" />
                                            <div class="ms-4">
                                                <h6 trans="Attend classes virtually at your convenience">
                                                    Attend classes virtually at your convenience</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-12">
                                        <div class="info-box mt-4">
                                            <img src="assets/images/homepage/graduation.jpg" alt="" class="img-fluid" />
                                            <div class="ms-4">
                                                <h6
                                                    trans="Tutors can work from home, teach multiple subjects, and grow their business">
                                                    Tutors can work from home, teach multiple subjects, and grow their
                                                    business</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-12 col-lg-5">
                            <img src="assets/images/homepage/about.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section id="how_it_works">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-12">
                            <h2 class="main-heading text-center mb-4" trans="How It Works?">How It Works ?</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <h3 class="how-it-work-heading" trans="Students">Students</h3>
                            <div class="number">
                                <span class="one"> <b>1 </b></span>
                                <div class="content-new">
                                    <span style="color: #000 !important;"><b trans="Find Tutors:">Find Tutors:</b>
                                        <span
                                            trans="Search for your learning needs by using our platform based on criteria like Subject, Level, and Available times">
                                            Search for your learning needs by using our platform based on criteria like Subject, Level, and Available times
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="number">
                                <span class="one"> <b>2</b></span>
                                <div class="content-new">
                                    <span style="color: #000 !important;"><b trans="Select Tutors:">Select Tutors:</b>
                                        <span trans="Click on the tutor profiles and select the tutor that matches your needs">
                                            Click on the tutor profiles and select the tutor that matches your needs</span>
                                        </span>
                                </div>
                            </div>
                            <div class="number">
                                <span class="one"> <b> 3</b></span>
                                <div class="content-new">
                                    <span style="color: #000 !important;"><b trans="Book Lessons:">Book Lessons:</b>
                                        <span trans="Book a lesson, package of classes, or group class by making a secure payment to the Tutor">
                                            Book a lesson, package of classes, or group class by making a secure payment to the Tutor

                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="number">
                                <span class="one"> <b> 4</b></span>
                                <div class="content-new">
                                    <span style="color: #000 !important;"><b trans="Attend and Learn:">Attend and Learn: 
                                        </b><span trans="Build your skills and knowledge to help you achieve your academic and career goals">
                                            Build your skills and knowledge to help you achieve your academic and career goals 
                                        </span></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <h3 class="how-it-work-heading" trans="Tutors">Tutors</h3>
                            <div class="number">
                                <span class="one"><b>1</b></span>
                                <div class="content-new">
                                    <span style="color: #000 !important;"><b trans="Create Profile:">Create Profile:</b>
                                        <span
                                            trans="Create a profile to highlight your skills, work experience, degrees, and certifications">
                                            Create a profile to highlight your skills, work experience, degrees, and certifications</span></span>
                                </div>
                            </div>
                            <div class="number">
                                <span class="one"><b>2</b></span>
                                <div class="content-new">
                                    <span style="color: #000 !important;"><b trans="Add Subjects and Availability:">Add Subjects and Availability:</b><span
                                            trans="Add the subjects you will teach and set your available days and times">
                                             Add the subjects you will teach and set your available days and times</span></span>
                                </div>
                            </div>
                            <div class="number">
                                <span class="one"><b>3</b></span>
                                <div class="content-new">
                                    <span style="color: #000 !important;"><b trans="Configure Payment Options:">Configure Payment Options:</b>
                                        <span trans="Add your PayPal or Stripe account information to accept secure payments directly from students">
                                            Add your PayPal or Stripe account information to accept secure payments directly from students
                                        </span></span>
                                </div>
                            </div>
                            <div class="number">
                                <span class="one"><b>4</b></span>
                                <div class="content-new">
                                    <span style="color: #000 !important;"><b trans="Teach and Earn:">Teach and Earn:</b><span
                                            trans="Complete instructing the booked lessons to earn income">
                                            Complete instructing the booked lessons to earn income
                                        </span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <app-footer></app-footer>
    </div>
</div>