import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer , SafeResourceUrl} from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  
   @Output() videoFormData: EventEmitter<any> = new EventEmitter();;
	public videoForm = this.fb.group({
        video_URL: ['',[Validators.required,Validators.pattern('^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$')]]
    });
	public errorMessages = {
        video_URL : [ { type:'required',message:'URL is Required'},{ type:'pattern',message:'Provide Youtube embedded URL'}]
    }

  public finalUrl;
  public checkurl;
  public provider;
  public videoId;
  public error_message = '';
  public status ='';
  public thumbnail;

  public liveIFrame:SafeResourceUrl;
  get video_URL(){ return this.videoForm.get('video_URL'); }

  constructor(private fb: FormBuilder,
  	          public sanitizer: DomSanitizer,
              private toastr: ToastrService,) { }

  ngOnInit(): void {
  }

    public onSearchChange(searchValue: string)
    {
      var provider = searchValue.match(/https:\/\/(:?www.)?(\w*)/)[2],
        id;
        this.provider = provider;
    if(provider == "youtube") {
       var videoid = searchValue.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);

        if(videoid != null){
          this.videoId = videoid[1];
          var url = 'https://www.youtube.com/embed/'+videoid[1];
          var thumbnail = 'https://img.youtube.com/vi/'+videoid[1]+'/0.jpg';
          this.thumbnail = thumbnail;
          this.checkurl = url;
          this.videoType(url);
          this.status = '';
        }
    } else if (provider == "player") {
         var url =searchValue;
         this.videoType(url);
         this.status = ''
    } else { 
        this.status = "failed"
        this.error_message = 'parseVideoURL() takes a YouTube or Vimeo URL';  
    }
    return {
        provider : provider,
        id : id
    }
      
    }

    public videoType($url)
    {
      let url = $url;
      if($url.search('youtube') > 0){
        this.finalUrl = $url;
      this.liveIFrame = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        return true;
      }else if($url.search('vimeo') > 0){
        this.finalUrl = $url;
      	this.liveIFrame = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        return true;
      }else {
        return false;
      }
    }

  public videoFormComplete(){

    if(this.provider =="youtube" ){
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var match = this.checkurl.match(regExp);
            if (match && match[2].length == 11) {
                 this.status = '';
                this.videoForm.value.formFilled = 1;
                this.videoForm.value.video_URL = this.finalUrl;
                this.videoForm.value.thumbnail = this.thumbnail;
                this.videoFormData.emit(this.videoForm.value);
            }else{
              this.status = "invalid"
              this.error_message = 'Provide a valid youtube url';
              this.videoForm.value.formFilled = 0;
              this.videoFormData.emit(this.videoForm.value); 
            }
    }else if(this.provider == "vimeo"){
        this.status = ''
       this.videoForm.value.formFilled = 1;
      this.videoForm.value.video_URL = this.finalUrl;
      this.videoFormData.emit(this.videoForm.value);
    }
    
  }

  public videoFormInComplete(){
    this.videoForm.value.formFilled = 0;
    this.videoFormData.emit(this.videoForm.value);
  }

  public validate(){
    this.videoForm.markAllAsTouched();
  }

}
