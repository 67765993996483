import { Component, OnInit } from '@angular/core';
import { FooterPages } from '../../auth/FooterPages';
import { Router } from '@angular/router';
import { Bootstrapper } from '../../core/bootstrapper.service';
import { LocalizationWithLines } from '../../core/types/localization-with-lines';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  
  public googleLink:any;
  public facebookLink:any;
  public instagramLink:any;
  public twitterLink:any;
  public youtubeLink:any;
  public pageRoute:any;
  public footerCategories:any;
  public socialMediaLinks:any;
  public selectedLanguage:any;
  public languages$ = new BehaviorSubject<LocalizationWithLines[]>([]);
  public loading$ = new BehaviorSubject<boolean>(false);
  public PWAStatus:any;
  public currentYear = new Date().getFullYear()  // returns the current year
  constructor(public footerPages: FooterPages,
              private router: Router,
              public myservice: Bootstrapper, 
              ) { 
    
    this.PWAStatus = this.isPWA();
}

  ngOnInit(): void {
        
  }


  public isPWA() {
    return ["fullscreen", "standalone", "minimal-ui"].some(
        (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
    );
  }

}
