import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { WebSiteService } from '../../website.service';

import { DomSanitizer , SafeResourceUrl} from '@angular/platform-browser';

declare var $:any;
import * as _ from 'lodash';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.css']
})
export class PhotoComponent implements OnInit {
   
   public errorstatus = '';
   public fileToUpload: File = null;
   public fileToUploadIdProof: File = null;
   imageError: string;
   isImageSaved: boolean;
   isIdProofImageSaved: boolean;
   cardImageBase64: string;
   cardImageBase64IdProof: string;
   @Output() photoFormData: EventEmitter<any> = new EventEmitter();;
   public photoForm = this.fb.group({
   profile_photo:['',[Validators.required,RxwebValidators.extension({extensions:["jpg","jpeg","png","pdf"]})]], 
   video_URL: ['',[Validators.pattern('^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$')]],
   image:[''],
   idProof:['']
    });

   get profile_photo(){ return this.photoForm.get('profile_photo');}
   get id_proof(){ return this.photoForm.get('id_proof');}

   public liveIFrame:SafeResourceUrl;
  get video_URL(){ return this.photoForm.get('video_URL'); }
   
   public finalUrl;
   public checkurl;
   public provider;
   public videoId;
   public error_message = '';
   public status ='';
   public thumbnail;

   public errorMessages = {
        profile_photo: [{ type:'required', message:'Provide profile photo'},{ type:'extension',message:'Invalid extension'}],
        id_proof: [{ type:'required', message:'ID proof required'},{ type:'extension',message:'Invalid extension'}],
        video_URL : [ { type:'required',message:'URL is required'},{ type:'pattern',message:'Provide youtube embedded URL'}]
   }

  
  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              public sanitizer: DomSanitizer,
              public service: WebSiteService) { }
              

  ngOnInit(): void {
  }

  public photoFormComplete(){
    this.photoForm.value.cardImageBase64 = this.cardImageBase64;
    this.photoForm.value.cardImageBase64IdProof = this.cardImageBase64IdProof;
    this.photoForm.value.profileimage = this.photoForm.get('image').value;  
      if(this.photoForm.value.cardImageBase64 ){
         this.photoForm.value.formFilled = 1;
         this.photoFormData.emit(this.photoForm.value);
      }else if(this.photoForm.value.cardImageBase64 == null){
         this.photoForm.value.formFilled = 0;
         this.photoFormData.emit(this.photoForm.value);
      }
//videoformcomplete---------
      if(this.provider =="youtube" ){
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var match = this.checkurl.match(regExp);
            if (match && match[2].length == 11) {
                 this.status = '';
                this.photoForm.value.formFilled = 1;
                this.photoForm.value.video_URL = this.finalUrl;
                this.photoForm.value.thumbnail = this.thumbnail;
                this.photoFormData.emit(this.photoForm.value);
            }else{
              this.status = "invalid"
              this.error_message = 'Provide a valid youtube url';
              this.photoForm.value.formFilled = 0;
              this.photoFormData.emit(this.photoForm.value); 
            }
    }else if(this.provider == "vimeo"){
        this.status = ''
       this.photoForm.value.formFilled = 1;
      this.photoForm.value.video_URL = this.finalUrl;
      this.photoFormData.emit(this.photoForm.value);
    }
    //-----------------
  }

  public photoFormInComplete(){
    this.photoForm.value.formFilled = 0;
    this.photoFormData.emit(this.photoForm.value);
  }

    fileChangeEvent(fileInput: any , id) {
        this.imageError = null;
        if (fileInput.target.files && fileInput.target.files[0]) {
            // Size Filter Bytes
            const max_size = 20971520;
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 15200;
            const max_width = 25600;

            if (fileInput.target.files[0].size > max_size) {
                this.imageError =
                    'Maximum size allowed is ' + max_size / 1000 + 'Mb';

                return false;
            }

            if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
              console.log(fileInput.target.files[0].type)
                this.imageError = 'Only Images are allowed ( JPG | PNG )';
                this.errorstatus = 'invalid';
                this.cardImageBase64 = null;
                this.photoForm.value.cardImageBase64 = null;
                return false;
              
            }
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    const img_height = rs.currentTarget['height'];
                    const img_width = rs.currentTarget['width'];

                    // console.log(img_height, img_width);


                    if (img_height > max_height && img_width > max_width) {
                        this.imageError =
                            'Maximum dimentions allowed ' +
                            max_height +
                            '*' +
                            max_width +
                            'px';
                        return false;
                    } else {
                        const imgBase64Path = e.target.result;
                        if(id == "uplodfile"){
                        this.cardImageBase64 = imgBase64Path;
                        this.isImageSaved = true;
                        this.fileToUpload = fileInput.target.files[0];
                        this.photoForm.value.cardImageBase64 = this.cardImageBase64;
                        this.photoForm.value.image = this.fileToUpload; 
                          this.photoForm.patchValue({
                            image: this.fileToUpload
                          });
                        }else{
                        this.cardImageBase64IdProof = imgBase64Path;
                        this.isIdProofImageSaved = true;
                        this.fileToUploadIdProof = fileInput.target.files[0];
                        this.photoForm.value.cardImageBase64IdProof = this.cardImageBase64IdProof;
                        this.photoForm.value.image = this.fileToUploadIdProof; 
                          this.photoForm.patchValue({
                            idProof: this.fileToUploadIdProof
                          });
                        }
                    }
                };
            };
           this.photoFormComplete();
            reader.readAsDataURL(fileInput.target.files[0]);
            $('#removeImage').show();
        }
    }

    removeImage() {
        this.photoForm.get('profile_photo').reset();
        this.isImageSaved = false;
        this.cardImageBase64 = null;
        this.fileToUpload = null;
        $('#removeImage').hide(); 
        $('#uplodfile').val("");
    }

    removeIdProofImage() {
        this.photoForm.get('id_proof').reset();
        this.isIdProofImageSaved = false;
        this.cardImageBase64IdProof = null;
        this.fileToUploadIdProof = null;
        $('#removeImageIdProof').hide(); 
        $('#idProofFile').val("");
    }
 

   public validate(){
    this.photoForm.markAllAsTouched();
   }

   public validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  public videoType($url)
    {
      let url = $url;
      if($url.search('youtube') > 0){
        this.finalUrl = $url;
      this.liveIFrame = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        return true;
      }else if($url.search('vimeo') > 0){
        this.finalUrl = $url;
        this.liveIFrame = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        return true;
      }else {
        return false;
      }
    }
    
  public onSearchChange(searchValue: string)
  {
    if ( searchValue && this.validURL(searchValue) ) {
    var provider = searchValue.match(/https:\/\/(:?www.)?(\w*)/)[2],
      id;
      this.provider = provider;
  }
  if(provider == "youtube") {
     var videoid = searchValue.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);

      if(videoid != null){
        this.videoId = videoid[1];
        var url = 'https://www.youtube.com/embed/'+videoid[1];
        var thumbnail = 'https://img.youtube.com/vi/'+videoid[1]+'/0.jpg';
        this.thumbnail = thumbnail;
        this.checkurl = url;
        this.videoType(url);
        this.status = '';
      }
  } else if (provider == "player") {
       var url =searchValue;
       this.videoType(url);
       this.status = ''
  } else { 
      this.status = "failed"
      this.error_message = 'parseVideoURL() takes a YouTube or Vimeo URL';  
  }
  return {
      provider : provider,
      id : id
  }
    
  }
}