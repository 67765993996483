import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree,Router,Route } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUser } from '../../auth/current-user';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard implements CanActivate {
  constructor(
        private currentUser: CurrentUser,
        private router: Router
    ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.currentUser.hasRole('Tutor')){
      this.router.navigate(['/tutor']);
    }
    else if(this.currentUser.hasRole('Student')){
      this.router.navigate(['/student']);
    }
    else if(this.currentUser.hasRole('Admin')){
      this.router.navigate(['/admin']);
    }
    else{
      return true;
    
    }
  }
  
}
