import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TutorRegistrationComponent } from './tutor-registration/tutor-registration.component';
import { HomepageComponent } from './homepage/homepage.component';
import { GuestGuard } from '@athand/guards/guest-guard.service';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';

const routes: Routes = [

{ path:'join-as-tutor',component: TutorRegistrationComponent, canLoad: [GuestGuard] },
{ path:'online-tutoring',component: HomepageComponent, canLoad: [GuestGuard]},
{ path:'privacy-policy',component: PrivacyPolicyComponent, canLoad: [GuestGuard]},
{ path:'terms-conditions',component: TermsConditionsComponent, canLoad: [GuestGuard]}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WebsiteRoutingModule { }
