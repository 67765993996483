import {ErrorHandler} from '@angular/core';
import * as Raven from 'raven-js';
import {Settings} from '../config/settings.service';
import {ignoredErrors} from './ignored-errors';
import {RavenOptions} from 'raven-js';

export function noBackendErrorHandlerFactory (settings: Settings) {
    return new NoBackendErrorHandler(settings);
}

export class NoBackendErrorHandler extends ErrorHandler {

	    /**
     * Whether sentry error logger is already installed.
     */
    protected installed = false;

    /**
     * RavenErrorHandler Constructor.
     */
    constructor(protected settings: Settings) {
        super();
    }

}