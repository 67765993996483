<form [formGroup]="photoForm">
   <div class="row">
      <div class="col-md-6">
         <label trans="Profile Photo">
            Profile Photo
         </label>
         <div class="img-space">
            <ng-container *ngIf="isImageSaved; else elseTemplate">
               <img class="img-responsive" [src]="cardImageBase64" class="Tutors_profile_img" />
            </ng-container>
            <ng-template #elseTemplate>
               <img src="assets/images/dummy.png"  class="Tutors_profile_img">
            </ng-template>
         </div>
         <div class="rmwbtn_profile">
            <label  for="selectbasic" class="pull-right">*PNG,JPG,JPEG</label>
            <div id="removeImage">
               <a class="btn btn-danger" (click)="removeImage()" *ngIf="isImageSaved" trans="Remove">Remove</a>
            </div>
            <span>
               <input formControlName="profile_photo" class="form-control" accept="image/x-png,image/gif,image/jpeg" (change)="fileChangeEvent($event,'uplodfile')" id="uplodfile" class="fileInputProfile" type="file">
               <div class="error-status" *ngIf="errorstatus =='invalid'"><small>{{imageError}}</small></div>
               <div *ngIf="errorstatus == ''">
                  <div *ngFor= "let error of errorMessages.profile_photo">
                     <ng-container *ngIf="profile_photo.hasError(error.type) && (profile_photo.touched)">
                        <small class="error-message">{{error.message}}</small>
                     </ng-container>
                  </div>
               </div>
            </span>
         </div>
      </div>
      <!-- <div class="col-md-6">

         <div class="img-space">
            <ng-container *ngIf="isIdProofImageSaved; else elseTemplate">
               <img class="img-responsive" [src]="cardImageBase64IdProof" class="Tutors_profile_img" />
            </ng-container>
            <ng-template #elseTemplate>
               <img src="assets/images/dummy.png"  class="Tutors_profile_img">
            </ng-template>
         </div>
         <div class="rmwbtn_profile">
            <label  for="selectbasic" class="pull-right">*PNG,JPG,JPEG</label>
            <div id="removeImageIdProof">
               <a class="btn btn-danger" (click)="removeIdProofImage()" *ngIf="isIdProofImageSaved">Remove</a>
            </div>
            <span>
               <input formControlName="id_proof" class="form-control" accept="image/x-png,image/gif,image/jpeg" (change)="fileChangeEvent($event,'idProofFile')" id="idProofFile" class="fileInputProfile" type="file">
               <div class="error-status" *ngIf="errorstatus =='invalid'"><small>{{imageError}}</small></div>
               <div *ngIf="errorstatus == ''">
                  <div *ngFor= "let error of errorMessages.id_proof">
                     <ng-container *ngIf="id_proof.hasError(error.type) && (id_proof.touched)">
                        <small class="error-message">{{error.message}}</small>
                     </ng-container>
                  </div>
               </div>
            </span>
         </div>
      </div> -->
   </div>
   <div *ngIf=photoForm.valid?photoFormComplete():false>
   </div>
   <div *ngIf=!photoForm.valid?photoFormInComplete():false>
   </div>
</form>