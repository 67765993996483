<form [formGroup]="aboutForm" class="From_Height web-view">
   <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
         <div class="form-group">
            <label for="first" trans="First Name">
            First Name
            </label>
            <input formControlName="first_name" type="text" class="form-control" placeholder="First Name" trans-placeholder="First Name">
            <div *ngFor= "let error of errorMessages.first_name">
               <ng-container *ngIf="first_name.hasError(error.type) && (first_name.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
         <div class="form-group">
            <label for="last" trans="Last Name">
            Last Name
            </label>
            <input formControlName="last_name" type="text" class="form-control" placeholder="Last Name" trans-placeholder="Last Name">
            <div *ngFor= "let error of errorMessages.last_name">
               <ng-container *ngIf="last_name.hasError(error.type) && (last_name.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div>
   </div>
   <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
         <div class="form-group show_eye2">
            <label for="password" trans="Password">
            Password
            </label>
            <input autocomplete="new-password" type="password" class="form-control" formControlName="password" (blur) = "selectPassword()" id="password1" placeholder="Password" trans-placeholder="Password">
             <span toggle="#password1" class="fa fa-fw fa-eye toggle-password1"></span>
            <div *ngFor= "let error of errorMessages.password">
               <ng-container *ngIf="password.hasError(error.type) && (password.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
         <div class="form-group show_eye2">
            <label for="password1" trans="Re-enter Password">
             Re-enter Password
            </label>
             <input type="password" class="form-control" formControlName="password1" (blur) = selectPassword() id="password2"  placeholder="Re-enter Password" trans-placeholder="Re-enter Password">
              <span toggle="#password2" class="fa fa-fw fa-eye toggle-password2"></span>
             <div class="error-status" *ngIf="status=='checkpassword'"><small>{{error_message}}</small></div>
            <div *ngFor= "let error of errorMessages.password1">
               <ng-container *ngIf="password1.hasError(error.type) && (password1.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div>
   </div>
   <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
         <div class="form-group">
                     <label for="Country" trans="Country">Country</label>
                     <select formControlName="country" class="form-control iconArrowDown">
                        <option value="" trans="Please select">Please select</option>
                        <option [value]="count.name" *ngFor="let count of countriesdata$ | async">{{count.name}}({{count.dial_code}})</option>
                     </select>
                    <div *ngFor= "let error of errorMessages.country">
                        <ng-container *ngIf="country.hasError(error.type) && (country.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
         <div class="form-group">
            <label for="phone" trans="Mobile Number">
            Mobile Number
            </label>
             <input (keydown)="validateNumber($event)" formControlName="phone" type="number" (blur)=selectNumber(phone) class="form-control numbers"  placeholder="Mobile Number" trans-placeholder="Mobile Number" minlength="10">

              <div class="error-status" *ngIf="status=='phonedanger'"><small>{{error_message}}</small></div>
            <div *ngFor= "let error of errorMessages.phone">
               <ng-container *ngIf="phone.hasError(error.type) && (phone.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div>
   </div>
   <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
         <div class="form-group">
            <label for="email" trans="Email Address">
            Email Address
            </label>
            <input formControlName="email" type="email" (blur)=selectEmail(email) class="form-control" id="email" placeholder="Email address" trans-placeholder="Email address">
            <div class="error-status" *ngIf="status=='danger'"><small>{{error_message}}</small></div>
            <div *ngFor= "let error of errorMessages.email">
               <ng-container *ngIf="email.hasError(error.type) && (email.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
         <div class="form-group">
            <label for="gen" trans="Gender">
            Gender
            </label>
            <select formControlName="gender" class="form-control iconArrowDown">
               <option value="" trans="Please select"> Please select</option>
               <option value="Male" trans="Male">Male</option>
               <option value="Female" trans="Female">Female</option>
            </select>
            <div *ngFor= "let error of errorMessages.gender">
               <ng-container *ngIf="gender.hasError(error.type) && (gender.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div>
   </div>
   <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
         <div class="form-group">
            <label for="last" trans="Currency">Currency</label>
            <select (input)="selectCurrency($event.target.value)" formControlName="default_currency" class="iconArrowDown form-control Currency_drop">
            <option value="" trans="Please select">Please select</option>
            <option [value]="currency.code" *ngFor="let currency of popularCurrencies">{{currency.code}}-{{currency.symbol}}</option>
            </select>
            <div *ngFor= "let error of errorMessages.default_currency">
               <ng-container *ngIf="default_currency.hasError(error.type) && (default_currency.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
         <div class="form-group">
            <label for="Hourly Rate" trans="Price per Hour">Price per Hour</label>
            <label class="pull-right" *ngIf="allowedMinPrice">{{allowedMinPrice}} -  {{allowedMaxPrice}}</label>
            <input type="number" formControlName="hourly_rate" class="form-control" placeholder="Price per hour">
            <div *ngFor= "let error of errorMessages.hourly_rate">
               <ng-container *ngIf="hourly_rate.hasError(error.type) && (hourly_rate.touched)">
                  <small *ngIf="hourly_rate.errors.required" class="error-message">{{error.message}}</small>
                  <small *ngIf="hourly_rate.errors.min" class="error-message">{{error.message}} - {{allowedMinPrice}}</small>
                  <small *ngIf="hourly_rate.errors.max" class="error-message">{{error.message}} - {{allowedMaxPrice}}</small>
               </ng-container>
            </div>
         </div>
      </div>
   </div>
   <div *ngIf=aboutForm.valid?func():false>
   </div>
   <div *ngIf=!aboutForm.valid?aboutFormInComplete():false>
   </div>
</form>



<!-- mobile view start -->


<!-- <form [formGroup]="aboutForm" class="From_Height mobile-view">
   <div class="row">
      <div class="col-sm-12 col-xs-12">
         <div class="form-group">
            <label for="first">
            First Name
            </label>
            <input formControlName="first_name" type="text" class="form-control" placeholder="First Name">
            <div *ngFor= "let error of errorMessages.first_name">
               <ng-container *ngIf="first_name.hasError(error.type) && (first_name.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div>
      <div class="col-sm-12 col-xs-12">
         <div class="form-group">
            <label for="last">
            Last Name
            </label>
            <input formControlName="last_name" type="text" class="form-control" placeholder="Last Name">
            <div *ngFor= "let error of errorMessages.last_name">
               <ng-container *ngIf="last_name.hasError(error.type) && (last_name.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div>
   </div>
   <div class="row">
      <div class="col-sm-12 col-xs-12">
         <div class="form-group">
            <label for="password">
            Password
            </label>
            <input type="password"class="form-control" formControlName="password" (blur) = "selectPassword()" placeholder="Password">
           
            <div *ngFor= "let error of errorMessages.password">
               <ng-container *ngIf="password.hasError(error.type) && (password.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div>
      <div class="col-sm-12 col-xs-12">
         <div class="form-group">
            <label for="password">
             Re-enter Password
            </label>
             <input type="password" class="form-control" formControlName="password1" (blur) = selectPassword() placeholder="Password">
             <div class="error-status" *ngIf="status=='checkpassword'"><small>{{error_message}}</small></div>
            <div *ngFor= "let error of errorMessages.password1">
               <ng-container *ngIf="password1.hasError(error.type) && (password1.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div>
   </div>
   <div class="row">
      <div class="col-sm-12 col-xs-12">
         <div class="form-group">
                     <label for="nativeLanguage">Country</label>
                     <select formControlName="country" class="form-control iconArrowDown">
                        <option value=""></option>
                        <option [value]="count.name" *ngFor="let count of countriesList ">{{count.name}}</option>
                     </select>

                    <div *ngFor= "let error of errorMessages.country">
                        <ng-container *ngIf="country.hasError(error.type) && (country.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
      </div>
      <div class="col-sm-12 col-xs-12">
         <div class="form-group">
            <label for="phone">
            Mobile Number
            </label>
             <input  formControlName="phone" type="number" (blur)=selectNumber(phone) class="form-control"  placeholder="Mobile" >
              <div class="error-status" *ngIf="status=='invalid'"><small>{{error_message}}</small></div>
            <div *ngIf="status == 'valid'">
            <div *ngFor= "let error of errorMessages.phone">
               <ng-container *ngIf="phone.hasError(error.type) && (phone.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
           </div>
         </div>
      </div>
   </div>
   <div class="row">
      <div class="col-sm-12 col-xs-12">
         <div class="form-group">
            <label for="email">
            Email address
            </label>
            <input formControlName="email" type="email" (blur)=selectEmail(email) class="form-control" id="email" placeholder="">
            <div class="error-status" *ngIf="status=='danger'"><small>{{error_message}}</small></div>
            <div *ngFor= "let error of errorMessages.email">
               <ng-container *ngIf="email.hasError(error.type) && (email.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div>
      <div class="col-sm-12 col-xs-12">
         <div class="form-group">
            <label for="gen">
            Gender
            </label>
            <select formControlName="gender" class="form-control iconArrowDown">
               <option value=""></option>
               <option value="Male">Male</option>
               <option value="Female">Female</option>
            </select>
            <div *ngFor= "let error of errorMessages.gender">
               <ng-container *ngIf="gender.hasError(error.type) && (gender.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div>
   </div>
   <div class="row">
      <div class="col-sm-12 col-xs-12">
         <div class="form-group">
            <label for="last">Currency</label>
            <select formControlName="default_currency" class="iconArrowDown form-control Currency_drop">
            <option [value]="currency.code" *ngFor="let currency of popularCurrencies">{{currency.code}}-{{currency.symbol}}</option>
            </select>
            <div *ngFor= "let error of errorMessages.default_currency">
               <ng-container *ngIf="default_currency.hasError(error.type) && (default_currency.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div>
      <div class="col-sm-12 col-xs-12">
         <div class="form-group">
            <label for="Hourly Rate">Price per Hour</label>
            <input type="number" min="0"  formControlName="hourly_rate" class="form-control" min="0" max="100">
            <div *ngFor= "let error of errorMessages.hourly_rate">
               <ng-container *ngIf="hourly_rate.hasError(error.type) && (hourly_rate.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div>
   </div>
   <div *ngIf=aboutForm.valid?func():false>
   </div>
   <div *ngIf=!aboutForm.valid?aboutFormInComplete():false>
   </div>
</form> -->