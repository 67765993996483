import { Component, OnInit } from '@angular/core';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { FormBuilder, Validators } from '@angular/forms';
import { ProfileService } from '../profile.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
declare var $;

@Component({
  selector: 'app-account-verification',
  templateUrl: './account-verification.component.html',
  styleUrls: ['./account-verification.component.css']
})
export class AccountVerificationComponent implements OnInit {
  
   public idForm = this.fb.group({
   id_proof:['',[Validators.required,RxwebValidators.extension({extensions:["jpg","jpeg","png","pdf"]})]], 
   image:['']
    });
  public imageError:string;
  public error;
  public errorstatus;
  public cardImageBase64:any;
  public fileToUpload:any;
  public isImageSaved:boolean;
  get id_proof(){ return this.idForm.get('id_proof');}
  public errorMessages = {
        id_proof: [{ type:'required', message:'ID Proof Required'},{ type:'extension',message:'Invalid extension'}],
   }

  constructor(private fb: FormBuilder,public profileService: ProfileService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.profileService.getIdProof().subscribe(response=>{
      let idProof = JSON.parse(atob(response['data']));
      this.cardImageBase64 = idProof.id_proof_url;
      this.isImageSaved = true; 
    });
  }


      fileChangeEvent(fileInput: any) {
        this.imageError = null;
        if (fileInput.target.files && fileInput.target.files[0]) {
            // Size Filter Bytes
            const max_size = 20971520;
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 15200;
            const max_width = 25600;
            // console.log(fileInput.target.files && fileInput.target.files[0]);

            if (fileInput.target.files[0].size > max_size) {
                this.imageError =
                    'Maximum size allowed is ' + max_size / 1000 + 'Mb';

                return false;
            }

        console.log(fileInput.target.files && fileInput.target.files[0]);
            if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
                this.imageError = 'Only Images are allowed ( JPG | PNG )';
                this.errorstatus = 'invalid';
                this.cardImageBase64 = null;
                this.idForm.value.cardImageBase64 = null;
                return false;
              
            }

            const reader = new FileReader();
            reader.onload = (e: any) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    const img_height = rs.currentTarget['height'];
                    const img_width = rs.currentTarget['width'];

                    if (img_height > max_height && img_width > max_width) {
                        this.imageError =
                            'Maximum dimentions allowed ' +
                            max_height +
                            '*' +
                            max_width +
                            'px';
                        return false;
                    } else {
                        const imgBase64Path = e.target.result;
                        this.cardImageBase64 = imgBase64Path;
                        this.isImageSaved = true;
                        this.fileToUpload = fileInput.target.files[0];
                        this.idForm.value.cardImageBase64 = this.cardImageBase64;
                        this.idForm.value.image = this.fileToUpload;
                          this.idForm.patchValue({
                            image: this.fileToUpload
                          });
                    }
                };
            };
            reader.readAsDataURL(fileInput.target.files[0]);
            $('#removeImage').show();
        }
    }

     removeImage() {
        this.idForm.get('id_proof').reset();
        this.isImageSaved = false;
        this.cardImageBase64 = null;
        this.fileToUpload = null;
        $('#removeImage').hide(); 
        $('#uplodfile').val("");
    }

    public updateVerification(){
      this.idForm.markAllAsTouched();
      this.idForm.value.cardImageBase64 = this.cardImageBase64;
      this.idForm.value.idImage = this.idForm.get('image').value;  
      if(this.idForm.valid){
        this.profileService.updateVerificationId(this.idForm.value).subscribe(response=>{
           if(response['status'] == 'success'){
            this.toastr.success('Verification Id Submitted successFully');
           }
        });
      }
    }
}
