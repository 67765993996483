import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivationStart } from '@angular/router';
import { AuthGuard } from '@athand/guards/auth-guard.service';
import { StudentGuard } from '@athand/guards/student-guard.service';
import { GuestGuard } from '@athand/guards/guest-guard.service';
import { DashboardGuard } from '@athand/guards/dashboard-guard.guard';
import { LandingPageComponent } from './home/landing-page/landing-page.component';
import { ActivateUser } from './home/user-confirm/user-confirm.component';
import { ForgotPassword } from './home/forgot-password/forgot-password.component';
import { AdminComponent } from './admin/admin.component';
import { TutorComponent } from './tutor/tutor.component';
import { StudentComponent } from './student/student.component';
import { TutorsListComponent } from './home/tutors-list/tutors-list.component';
import { ErrorsComponent } from './home/errors/errors.component';
import { HomepageComponent } from './website/homepage/homepage.component';
import { TutorRegistrationComponent } from './website/tutor-registration/tutor-registration.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { map, filter, mergeMap } from 'rxjs/operators';
import { addDynamicPath } from '@services/dynamicRoutes/dynamicPaths';
import { PrivacyPolicyComponent } from '../app/website/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from '../app/website/terms-conditions/terms-conditions.component';
import { PricingComponent } from './website/pricing/pricing.component';
import { PaypalsubscriptionComponent } from '../../src/app/admin/paypalsubscription/paypalsubscription.component';

const routes: Routes = [
{path: '' , redirectTo: 'online-tutoring', pathMatch:'full' },
{path: 'online-tutoring/:action/:value', component:HomepageComponent},
{path: 'landing-page', component:LandingPageComponent},
{path: 'user/activate/:code', component:ActivateUser},
{path: 'user/forgot-password/:code', component:ForgotPassword},
{path: 'tutors-list' , component:TutorsListComponent},
{path: 'errors' , component:ErrorsComponent},
{path: 'student', component:StudentComponent , loadChildren: () => import('./student/student.module').then(m => m.StudentModule)},
{path: 'tutor', component:TutorComponent , loadChildren: () => import('./tutor/tutor.module').then(m => m.TutorModule), canLoad: [AuthGuard]},
{path: 'admin', component:AdminComponent , loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canLoad: [AuthGuard]},
{path: 'online-tutoring', component: HomepageComponent , loadChildren: () => import('./website/website.module').then(m => m.WebsiteModule),canActivate: [DashboardGuard]},
{ path:'join-as-tutor',component: TutorRegistrationComponent },
{ path:'privacy-policy',component: PrivacyPolicyComponent},
{ path:'terms-conditions',component: TermsConditionsComponent},
{ path:'pricing',component:PricingComponent},
{ path:'PaypalsubscriptionComponent',component:PaypalsubscriptionComponent},
{path: '**', redirectTo: '/online-tutoring'},

];


@NgModule({
  imports: [RouterModule.forRoot(routes , { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { 

constructor(public router: Router , private bpo: BreakpointObserver, private activeRoute: ActivatedRoute){
     }
   }
