import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup , FormControl} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '@auth/auth.service';
import { Bootstrapper } from '@core/bootstrapper.service';
import { CurrentUser } from '@auth/current-user';
import { Router, ActivatedRoute } from '@angular/router';
import { Settings } from '@core/config/settings.service';
import { SocialAuthService } from '../social-auth.service';
import { ViewChild, ElementRef} from '@angular/core';
import { ToastComponent } from '@core/ui/toast/toast.component';
import { ToastrService } from 'ngx-toastr';
import { countries } from "country-flags-svg";
import  *  as  Countries from  '@athand/json/countries.json';
import  *  as  countriesCurrency from  '@athand/json/countriesCurrency.json';
import { LocaleTimeZoneTS } from '@auth/localetimezone';
import {finalize} from 'rxjs/operators';
import { Translations } from '../../core/translations/translations.service';
declare var $;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

@ViewChild('closeLoginModal') closeLoginModal: ElementRef;
@ViewChild('closeForgotModal') closeForgotModal: ElementRef;
@ViewChild('closeresetPasswordModal') closeresetPasswordModal: ElementRef;

public countryFlag;
public countryDialcode;
public userCountry;
public loading$ = new BehaviorSubject(false);
public otpLoading$ = new BehaviorSubject(false);
public countriesList = countries;
public currency: any = (countriesCurrency as any).default;
public countriesdata$ = (Countries as any).default;
public registrationForm = this.fb.group({
        surname:['',],
        name: ['',[Validators.required]],
        last_name: ['',[Validators.required]],
        phone: ['',[Validators.required,Validators.minLength(10),Validators.maxLength(10),Validators.pattern('^[0-9]{10}$')]],
        regemail: ['',[Validators.required,Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\\.[a-zA-Z]{2,4}$")]],
        regpassword: ['',[Validators.required,Validators.minLength(6)]],
        country: ['',[Validators.required]],
    });

public loginForm = this.fb.group({
        email: ['',[Validators.required,Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\\.[a-zA-Z]{2,4}$")]],
        password: ['',[Validators.required,Validators.minLength(6)]],
        otp:['',Validators.minLength(4)],
        remember: [true],
    });


get email(){ return this.loginForm.get('email');}
get password(){ return this.loginForm.get('password');}
get otp(){ return this.loginForm.get('otp');}

//registrationForm
get surname(){ return this.registrationForm.get('surname');}
get name(){ return this.registrationForm.get('name');}
get last_name(){ return this.registrationForm.get('last_name');}
get phone(){ return this.registrationForm.get('phone');}
get regemail(){ return this.registrationForm.get('regemail');}
get loginEmail(){ return this.loginForm.get('loginEmail');}
get regpassword(){ return this.registrationForm.get('regpassword');}
get country(){ return this.registrationForm.get('country');}


public errorMessages = {
  email :[ 
    { type:'required',message: this.i18n.t(':field is required', {field: this.i18n.t('Email')})}, 
    { type:'pattern',message: this.i18n.t('Enter valid Email')} ],
  password :[ 
    { type:'required',message: this.i18n.t(':field is required', {field: this.i18n.t('Password')})}, 
    { type:'minlength',message: 'Minimum 6'} ],
  forgot_email : [ 
    { type:'required',message: this.i18n.t(':field is required', {field: this.i18n.t('Email')})}, 
    { type:'pattern',message: this.i18n.t('Enter valid Email')} ],
  new_password : [ 
    { type:'required',message: this.i18n.t(':field is required', {field: this.i18n.t('Password')})}, 
    { type:'minlength',message: this.i18n.t('Password should be greater than 6 characters')} ],
  confirm_new_password :   [ 
    { type:'required',message: this.i18n.t(':field is required', {field: this.i18n.t('Password')})}, 
    { type:'minlength',message: this.i18n.t('Password should be greater than 6 characters')}, 
    { type:'notsame',message: this.i18n.t('New password and confirm new password should be same')}],

  otp : [{type:'minlength',message: this.i18n.t('OTP should be minimum 4 digits')}],
  name: [{ type:'required',message: this.i18n.t(':field is required', {field: this.i18n.t('First name')})}],
  last_name: [{ type:'required',message: this.i18n.t(':field is required', {field: this.i18n.t('Last name')})}],
  country: [{ type:'required',message: this.i18n.t(':field is required', {field: this.i18n.t('Country')})}],
  phone: [ { type:'required',message: this.i18n.t(':field is required', {field: this.i18n.t('Phone number')})},
  {type:'minlength', message: this.i18n.t('Minimum :number', {number: 10})},
  { type:'pattern',message: this.i18n.t('Enter valid phone number')}],
  regemail :[ { type:'required',message: this.i18n.t(':field is required', {field: this.i18n.t('Email')})}, { type:'pattern',message:this.i18n.t('Enter valid Email')} ],

  regpassword :[ { type:'required',message: this.i18n.t(':field is required', {field: this.i18n.t('Password')})}, { type:'minlength',message: this.i18n.t('Minimum :number', {number: 6})} ],
}


public forgotForm = this.fb.group({
        forgot_email: ['',[Validators.required,Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]]
    });
get forgot_email(){ return this.forgotForm.get('forgot_email');}

public resetForm = this.fb.group({
        new_password: ['',[Validators.required,Validators.minLength(6)]],
        confirm_new_password: ['',[Validators.required,Validators.minLength(6)]],
        confirmation_code: ['', [Validators.required]]
    }, { validators: this.checkPasswords });

get new_password(){ return this.resetForm.get('new_password');}
get confirm_new_password(){ return this.resetForm.get('confirm_new_password');}

public error_message = '';
public success_message = '';

public loginNameModal:string = 'Login';
public action = '';
public value = '';
public showReset = false;
public showOtp = false;
public logindata;
public loginUserdata;
public roleId;
public enable2FA = false;
public status = '';
public dailcodeData = this.countriesdata$;
public countryCode;
public timezone;
public checkPasswords(group: FormGroup) { // here we have the 'passwords' group
  const password = group.get('new_password').value;
  const confirmPassword = group.get('confirm_new_password').value;

  return password === confirmPassword ? null : { notSame: true }
}

public myCompOneObj = new ToastComponent();
  constructor(private fb: FormBuilder,
              public auth: AuthService,
              private formBuilder: FormBuilder,
              private bootstrapper: Bootstrapper,
              private router: Router,
              public currentUser: CurrentUser,
              public settings: Settings,
              public socialAuth: SocialAuthService,
              private toastr: ToastrService,
              private activeRoute: ActivatedRoute,
              private localetimezone: LocaleTimeZoneTS,
              protected i18n: Translations,
              ) {
    this.timezone = this.localetimezone.timezone();
    this.socialAuth.invokeEvent.subscribe(value => this.closeModal());
    this.socialAuth.closeLoginModal.subscribe(closeModal => {});
    this.enable2FA = true;
    this.activeRoute.params.subscribe( params =>{
          this.action = params['action'];
          this.value = params['value'];
          if ( params['action'] == 'resetpassword' ) {
            this.resetForm.patchValue({confirmation_code: params['value']});
          }
    });

    this.bootstrapper.invokeEvent.subscribe(value => {
      if(value){
         this.closeModal();
      }
    });
    this.registrationForm.patchValue({
        country:this.localetimezone.country
    });
    // this.registrationForm.patchValue({country: 'United States'});
 }

  ngOnInit(): void {

  $('.numbers').keyup(function () {
    this.value = this.value.replace(/[^0-9\.]/g,'');
});

    this.resetLoginForm();

     $('.otp').css('display','none');
    if ( this.action == 'resetpassword' ) {
      this.showReset = true;
    }
  }

   validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];

    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }

  public togglePassword(id) {
    $('.'+id).toggleClass("fa-eye-slash fa-eye");
    if($('#'+id).get(0).type == 'text'){
      $('#'+id).attr('type', 'password');
    }else{
    $('#'+id).attr('type', 'text');
    }
  }
  public toogleRegpassword(){
    $('#toggle-regpassword').toggleClass("fa-eye-slash fa-eye");
    if($('#regpassword').get(0).type == 'text'){
      $('#regpassword').attr('type', 'password');
    }else{
    $('#regpassword').attr('type', 'text');
  }
  }

  public login(){
   this.loginForm.markAllAsTouched();
   if(this.loginForm.valid){
   let selectedLanguage = localStorage.getItem('defaultLanguage');
   this.loginForm.value.selectedLanguage = selectedLanguage;
   if(this.enable2FA == true){
    (this.loginForm.value.resendotp == 'yes') ? this.otpLoading$.next(true) : this.loading$.next(true);
      this.auth.login(this.loginForm.value)
            .pipe(finalize(() => (this.loginForm.value.resendotp == 'yes') ? this.otpLoading$.next(false) : this.loading$.next(false)))
            .subscribe(response => {
                this.logindata = JSON.parse(atob(response.data));
                if ( this.logindata.status == 'success') {
                  let role = typeof this.logindata.role !== 'undefined' ? this.logindata.role : this.logindata.user.roles[0].name;
                  
                  if( role !='Admin'){
                    $('.otp').css('display','inherit');
                    this.showOtp = true;
                    $("#emaillogin").prop("disabled", true);
                    $("#password").prop("disabled", true);
                    this.toastr.success('Success', this.logindata.message); // 2 Mins
                  } else{
                    this.toastr.success(this.i18n.t('Login Successful'));
                    this.bootstrapper.bootstrap(response.data);
                    this.closeModal();                    
                    this.router.navigate([this.auth.getRedirectUri()]);
                }
              } else {
                  if ( typeof this.logindata.status === 'undefined' ) {
                    this.toastr.success(this.i18n.t('Login Successful'));
                    this.bootstrapper.bootstrap(response.data);
                    this.closeModal();
                    this.router.navigate([this.auth.getRedirectUri()]);
                  } else {
                    this.toastr.error(this.logindata.status, this.logindata.message); // 2 Mins
                  }
              }
            }, err => {
              console.log(err);
              if(err.messages){
              (err.messages) ? this.toastr.error(err.messages.message) : null;
              }else{  
              this.toastr.error(err.status, this.i18n.t('Invalid details'));
              }
            });
        }else{
          if(this.loginForm.valid){
          this.auth.login(this.loginForm.value)
              .subscribe(response=>{
                this.logindata = JSON.parse(atob(response.data));
              if ( this.logindata.status == 'failed' ) {
                 this.toastr.error(this.logindata.message);
              } else {
                   this.toastr.success(this.i18n.t('Login Successful'));
                  
                   this.bootstrapper.bootstrap(response.data);
                   this.closeModal();
                   this.router.navigate([this.auth.getRedirectUri()]).then(() => {
                   });

                 }

            }, err => {
              console.log(err);
            (this.loginForm.value.resendotp == 'yes') ? this.otpLoading$.next(false) : this.loading$.next(false)
            this.toastr.error(err.status, this.i18n.t('Invalid details'));
            });
        }
    }
  }
}

public resetLoginForm(){
this.loginForm.reset();
this.registrationForm.reset();
}

public resetPasswordForm(){
  this.resetForm.reset();
}

  public closeModal(){
    this.closeLoginModal.nativeElement.click();
    this.closeForgotModal.nativeElement.click();
    this.showReset = false;
    $('.otp').css('display','none');
    this.showOtp = false;
  }

  public clearResetModal(){
    this.resetForm.reset();
    this.closeresetPasswordModal.nativeElement.click();
    $('#resetPasswordModal').hide()
  }

  public closeModals() {
    this.closeLoginModal.nativeElement.click();
    this.closeForgotModal.nativeElement.click();
    this.closeresetPasswordModal.nativeElement.click();
    this.showReset = false;
  }
  public forgetModals(){
    this.forgotForm.reset();
  }
  public closeForgotPasswordModal(){
    this.closeForgotModal.nativeElement.click();
  }

  public closeLoginFormModal(){
    this.closeLoginModal.nativeElement.click();
  }

  public conform(){
    this.auth.conformotp(this.loginForm.value)
    .subscribe(response=>{
                this.logindata = JSON.parse(atob(response.data));
                this.toastr.success(this.i18n.t('Login Successful'));
                this.bootstrapper.bootstrap(response.data);
                this.closeModal();
                this.router.navigate([this.auth.getRedirectUri()]).then(() => {
                });
            }, err => {
              console.log(err);
            this.toastr.error(err.status, this.i18n.t('Invalid details'));
            });
  }

  public resend(){
     this.loginForm.value.resendotp = 'yes';
     this.login();
  }
  public forgotPassword() {
    this.loading$.next(true);
    this.socialAuth.forgotPassword(this.forgotForm.value)
            .subscribe(response => {
              if ( typeof response.error !== 'undefined' ) {
                 this.toastr.error(response.error);
               } else {
                 this.toastr.success(response.success);
                  this.closeModal();
                  this.forgotForm.reset();
               }
            this.loading$.next(false);
            }, err => {
            this.loading$.next(false);
              console.log(err);
              this.toastr.error(err.status, this.i18n.t('Invalid details'));
            });
  }
  public resetPassword() {
    this.resetForm.patchValue({confirmation_code: this.value})
    this.socialAuth.resetPassword(this.resetForm.value)
            .subscribe(response => {
              if ( typeof response.error !== 'undefined' ) {
                 this.toastr.error(response.error);
                 this.error_message = response.error;
               } else if ( typeof response.expired !== 'undefined' ) {
                 this.toastr.error(response.expired);
                 this.error_message = response.expired;
               } else {
                 this.toastr.success(response.success);
                 this.success_message = response.success;
                 this.router.navigateByUrl('online-tutoring');
               }
            }, err => {
              console.log(err);
              this.toastr.error(err.status, this.i18n.t('Invalid details'));
            });
  }

  public register(){
    this.registrationForm.markAllAsTouched();
    if(this.registrationForm.valid){
      this.userCountry = this.registrationForm.value.country;
        this.countriesList.find(country=>{
        if(country['name'] == this.registrationForm.value.country){
          this.countryFlag = country.flag;
          this.countryCode = country.iso2;
        }
       })
       this.dailcodeData.find(dialcode=>{
         if(dialcode['name'] == this.registrationForm.value.country){
          this.countryDialcode = dialcode.dial_code;
        }
       })
       this.currency.find(currency=>{
          if(currency['country'] == this.registrationForm.value.country){
            this.registrationForm.value.defaultCurrency = currency['currency_code'];
          }
       });
    this.registrationForm.value.countryFlag = this.countryFlag;
    this.registrationForm.value.dialcode = this.countryDialcode;
    this.registrationForm.value.countrycode = this.countryCode;
    this.registrationForm.value.timezone = this.timezone;
    this.loading$.next(true);
    this.socialAuth.registrationForm(this.registrationForm.value)
            .pipe(finalize(() => this.loading$.next(false)))
            .subscribe(response => {
              if(response.status == 'failed') this.toastr.error(response.error);

              if ( typeof response.error !== 'undefined' ) {
                 this.status = response.status;
                 this.error_message = response.error;
               } else {
                 if(response.status = 'success'){
                   this.toastr.success(this.i18n.t('Registered Successfully'));
                   this.currentUser.assignCurrent(response.user);
                    if (this.currentUser.hasRole('Student')) {
                      this.router.navigate([this.auth.getRedirectUri()]);
                    }
                   this.closeModal();
                 }
               }
            }, err => {
              console.log(err);
              this.toastr.error(err.status, this.i18n.t('Invalid details'));
          });
          }
  }

  public onCountryChange(event){
    this.countryDialcode = event.dialCode;

  }

  public loginName(value){
    this.loginNameModal = value;
    console.log('hasda')
   if(value == 'Register'){
    $('.register_login').css('display','block');
    $('.login_login').css('display','none');
    this.registrationForm.patchValue({country: 'United States'});
  }else{
    $('.register_login').css('display','none');
    $('.login_login').css('display','block');

  }
}
  public selectNumber(phone){
    var Number = phone.value;
    var UkNum = /^((\\+91-?)|0)?[0-9]{10}$/;
     if(UkNum.test(Number)){
        this.status = 'valid';
        return;
    }else{
      this.status = 'invalid';
      this.error_message = this.i18n.t('Please enter a valid mobile number');
    }
  }
}
