import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, Route, NavigationEnd, Event } from '@angular/router';
import { CurrentUser } from '../../auth/current-user';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})


export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    public current_url = '';

    constructor(
        private currentUser: CurrentUser,
        private router: Router,
        private location: Location,
        private toastr: ToastrService
    ) {
        
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.handle(state.url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.handle(state.url);
    }

    canLoad(route: Route) {
        
        if(this.currentUser.hasRole('Tutor')){
            if(route.path='tutor')
            return this.handle(route.path);
        }else if(this.currentUser.hasRole('Student')){            
            if(route.path='student')
            return this.handle(route.path);
        }else{
          return this.handle(route.path);
        }

        this.router.navigate(['/online-tutoring']);
    }

    private handle(url: string) {
        console.log('handle:' + url)
        if (this.currentUser.isLoggedIn()) {
            if (this.currentUser.isTutor()) {
                if (this.currentUser.isValid() == 'no') {
                    this.router.events.subscribe((event: Event) => {
                        if (event instanceof NavigationEnd) {                
                            console.log(event.url)
                            if (event.url != '/tutor/home' && event.url != '/login' && event.url != '/online-tutoring' ) {
                                this.toastr.error('Your subscription plan expired. Please renew your plan.');
                                this.router.navigate(['/tutor/home']);
                            }
                        }
                    });                    
                }
            }
            return true;
        }

        this.currentUser.redirectUri = url;

        this.router.navigate(['/login']);

        return false;
    }
}
