import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup} from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { BehaviorSubject, empty, Observable } from 'rxjs';
import { openUploadWindow } from '@athand/uploads/utils/open-upload-window';
import { UploadInputTypes } from '@athand/uploads/upload-input-config';
import { CurrentUser } from '@auth/current-user';
import { finalize } from 'rxjs/operators';
import { randomString } from '@core/utils/random-string';
import { LocaleCurrency } from '@auth/localecurrency';
import { Users } from '@auth/users.service';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from '../profile.service';
import { countries } from "country-flags-svg";
import { CountryListItem, ValueLists } from '@core/services/value-lists.service';
import { LocaleTimeZoneTS } from '@auth/localetimezone';
import { LocalizationWithLines } from '@core/types/localization-with-lines';
import { Localizations } from '@core/translations/localizations.service';
import { Bootstrapper } from '@core/bootstrapper.service';
import { Currencies } from '@auth/currencies';
import { Settings } from '@core/config/settings.service';
import * as moment from 'moment-timezone';
import  *  as  Countries from  '@athand/json/countries.json';
var timeZones = moment.tz.names();
import  *  as  countriesCurrency from  '@athand/json/countriesCurrency.json';
declare var $:any;

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.css']
})

export class ProfileEditComponent implements OnInit {
  @Input() defaultValue: string;
  @Input() backendUri: string;
  @Input() type: string;
  public languages$ = new BehaviorSubject<LocalizationWithLines[]>([]);
  public error_message = '';
  public success_message = '';
  public avatar$;
  public image$ = new BehaviorSubject(null);
  public loading$ = new BehaviorSubject<boolean>(false);
  public userDetails;
  public countriesList = countries;
  public countriesdata$ = new BehaviorSubject<CountryListItem[]>([]);
  public countryFlag;
  public countryiso2;
  public countryDialcode;
  public userCountry;
  public service_name;
  public bannedCountries;
  public otp_status;
  public button = 'Send';
  public dailcodeData;
  public profile;
  public profilecountryCode;
  public selectedTimeZone;
  public status;
  public dataBaseDefaultLanguage;
  public dataBaseDefaultCurrency;
  public currency: any = (countriesCurrency as any).default;
  public localeCurrency;
  public current_role;
  public offsetTmz=[];
  isValidDate:any;
  public popularCurrencies;
  public countries:any = (Countries as any).default;
  error:any={isError:false,errorMessage:''};

  public enablle2FA = false;

  public checkPasswords(group: FormGroup) {
   // here we have the 'passwords' group
  const password1 = group.get('password1').value;
  const password2 = group.get('password2').value;
  const sms_opt_in = group.get('sms_opt_in').value;

  return password1 == password2 ? null : { notSame: true }
  }
  
  public accountSettingsForm = this.fb.group({
    first_name: ['',[Validators.required]],
    last_name: ['',[Validators.required]],
    phone: ['',[Validators.required,Validators.pattern('^[0-9]{10}$')]],
    gender: [''],
    country: ['',[Validators.required]],
    date_of_birth: [''],
    email: ['',[Validators.required,Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
    password1: ['',[Validators.minLength(6)]],
    password2: ['',[Validators.minLength(6)]],
    sms_opt_in: [''],
    // username: ['',[Validators.required ,Validators.pattern('^[0-9A-Za-z_.-]+$')]],
    defaultLanguage: [''],
    default_currency: [''],
    }, { validators: this.checkPasswords });



    get first_name(){ return this.accountSettingsForm.get('first_name');}
    get last_name(){ return this.accountSettingsForm.get('last_name');}
    get phone(){ return this.accountSettingsForm.get('phone');}
    get email(){ return this.accountSettingsForm.get('email');}
    
    get gender(){ return this.accountSettingsForm.get('gender');}
    get date_of_birth(){ return this.accountSettingsForm.get('date_of_birth');}
    get password1(){ return this.accountSettingsForm.get('password1');}
    get password2(){ return this.accountSettingsForm.get('password2');}
    get country(){ return this.accountSettingsForm.get('country'); }
    get defaultLanguage(){ return this.accountSettingsForm.get('defaultLanguage');}
    get default_currency(){ return this.default_currency.get('default_currency');}

    get sms_opt_in(){ return this.accountSettingsForm.get('sms_opt_in')}

    // 2FA Settings
    public account2faSettingsForm = this.fb.group({
      countryCode: [Validators.required],
      mobile: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern('^[0-9]{10}$')
        ]
      ]
    });
    get countryCode(){ return this.account2faSettingsForm.get('countryCode');}
    get mobile(){ return this.account2faSettingsForm.get('mobile');}
   

    public errorMessages = {
           first_name :[{ type:'required',message:'First Name is required'}],
           last_name :[{ type:'required',message:'Last Name is required'}],
           email :[{ type:'required',message:'Email is required'},{ type:'pattern',message: 'Enter valid mail'}],
           phone :[{ type:'required',message:'Number is requied'},{ type:'pattern',message: 'Number should be equal to 10 digits'}],
           gender :[{ type:'required',message:'Select gender'}],
           date_of_birth :[{ type:'required',message:'Date of birth is required'}],
           password1 : [{ type:'minlength',message: 'Password should be greater than 6 characters'} ],
           password2 : [{ type:'minlength',message: 'Password should be greater than 6 characters'},{ type:'notsame',message:'New password and confirm new password should be same'} ],
           country: [{type:'required' , message:'Select country'}],
          //  username :[{ type:'required',message:'Username is required'},{type:'pattern',message:'Allowed Numbers,Letters,Underscores'}],
           countryCode :[
            { type:'required',message:'Please select country code'}
          ],
          mobile :[
            { type:'required',message:'Phone Number is required field'},
            { type:'pattern',message: 'Enter valid Phone Number allowed 10 digits'}
          ],
          verification_method :[
            { type:'required',message:'password is required'}
          ],
          
          defaultLanguage: [
            { type:'required',message:'Default Language is required'}
            ],
          sms_opt_in: [
            { type:'required',message:'Please select your option'}
          ]
    }

  constructor(private fb: FormBuilder,
              private profileService: ProfileService,
              public currentuser: CurrentUser,
              private users: Users,
              private lists: ValueLists,
              private currentUser: CurrentUser,
              public currencies: Currencies,
              private toastr: ToastrService,
              public settings: Settings,
              private localetimezone: LocaleTimeZoneTS,
              private localizationsApi: Localizations,
              private bootstrapper: Bootstrapper,
              public localecurrency: LocaleCurrency) { 
                
              }

  ngOnInit(): void {
    this.bannedCountries = this.settings.get('selected_banned_countries');
    this.removeBannedCountries();
    
    for(var i in timeZones)
    {
       this.offsetTmz.push(timeZones[i]);
    }

     this.popularCurrencies = this.currencies.currencies();

    

    this.userDetails = this.currentuser.model$.value;
    this.avatar$ = this.userDetails.avatar;
    let default_language = this.userDetails.default_language;
    if ( ! default_language ) {
      default_language = 'english'
    }

    let default_currency = this.userDetails.default_currency;
    if ( ! default_currency ) {
      default_currency = 'USD'
    }
    this.accountSettingsForm.patchValue({
      first_name:this.userDetails.first_name,
      last_name:this.userDetails.last_name,
      email:this.userDetails.email,
      id:this.userDetails.id,
      phone:this.userDetails.phone,
      gender: (this.userDetails.gender) ? this.userDetails.gender : '',
      date_of_birth: this.userDetails.date_of_birth,
      // username: this.userDetails.username,
      country: (this.bannedCountries.includes(this.userDetails.country)) ? '' : this.userDetails.country,
      timezone: this.userDetails.timezone,
      defaultLanguage : default_language,
      default_currency : default_currency,
      sms_opt_in: this.userDetails.sms_opt_in,
    });
    
    this.dataBaseDefaultCurrency = default_currency;

    if ( this.userDetails.timezone ) {
      
      this.selectedTimeZone = this.userDetails.timezone;
      
    } else {
      this.selectedTimeZone = 'America/Adak';
    }

    this.current_role = this.userDetails.role;

    

    if (this.userDetails.social_profiles) {
     
    }

  }



    validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }

  public togglePassword(id) {
    $('.'+id).toggleClass("fa-eye fa-eye-slash");
    if($('#'+id).get(0).type == 'text'){
      $('#'+id).attr('type', 'password');
    }else{
    $('#'+id).attr('type', 'text');
    }
  }

    // This Method will update the user Avatar
  public openAvatarUploadDialog() {
        this.loading$.next(true);
        openUploadWindow({types: [UploadInputTypes.image]}).then(files => {
            this.users.uploadAvatar(this.currentUser.get('id'), files)
                .pipe(finalize(() => this.loading$.next(false)))
                .subscribe(response => {
                  if(response.status == 'danger'){
                    this.toastr.error('Invalid file extension');

                  }else{
                    this.currentUser.set('avatar', response.user.avatar);
                    this.avatar$ = response.user.avatar;
                    console.log(response.user.avatar);
                    this.toastr.success('Avatar Updated');
                  }
                }, err => {
                    const key = Object.keys(err.messages)[0];
                    this.toastr.error(err.messages[key]);
                });
        });
    }


    private updateValue(value?: string) {
        this.image$.next(value + `?v=${randomString(8)}`);
    }

    /*
    Update User Profile
    */
   public updateSettings(){

    this.accountSettingsForm.markAllAsTouched();
    if (this.accountSettingsForm.value.password1!= this.accountSettingsForm.value.password2) {
                 this.toastr.error('New password and Confirm new password should be same');
               }
    this.isValidDate = this.validateDates(this.accountSettingsForm.value.date_of_birth);
    if(this.accountSettingsForm.valid && this.isValidDate){
    this.userCountry = this.accountSettingsForm.value.country;

    this.countriesList.find(country=>{
      if(country['name'] == this.accountSettingsForm.value.country){
        this.countryFlag = country.flag;
        this.profilecountryCode = country.iso2;
      }
    })
       if ( this.dailcodeData ) {
         this.dailcodeData.find(dialcode=>{
           if(dialcode['code'] == this.profilecountryCode){
            this.countryDialcode = dialcode.dial_code;
          }
         })
       }
    this.accountSettingsForm.value.countryFlag = this.countryFlag;
    this.accountSettingsForm.value.dialcode = this.countryDialcode;
    this.accountSettingsForm.value.countrycode = this.profilecountryCode;
    this.accountSettingsForm.value.timezone = this.selectedTimeZone;
    this.loading$.next(true);
      this.profileService.updateSetting(this.accountSettingsForm.value , this.currentuser.isRole())
        .pipe(finalize(() => this.loading$.next(false)))
        .subscribe(response => {
           if(response.status == 'failed'){
                this.toastr.error(response.error);
           }else{            
           this.profile = JSON.parse(atob(response['data']));
           this.currentuser.model$.next(this.profile.user);
           let locale = this.profile.locale;
           this.localetimezone.init(locale);
           this.accountSettingsForm.patchValue({
              first_name: this.profile.user.first_name,
              last_name: this.profile.user.last_name,
              email: this.profile.user.email,
              id: this.profile.user.id,
              country: (this.bannedCountries.includes(this.profile.user.country)) ? '' : this.profile.user.country,
              phone: this.profile.user.phone,
              gender:  (this.profile.user.gender) ? this.profile.user.gender : '',
              date_of_birth:  this.profile.user.date_of_birth,
              // username:  this.profile.user.username,
              timezone: this.profile.user.timezone,
              defaultLanguage: this.profile.user.default_language,
              default_currency: this.profile.user.default_currency,
              sms_opt_in: this.profile.user.sms_opt_in
        });
           
          this.bootstrapper.bootstrap(this.profile.languageChange);
          localStorage.setItem('defaultLanguage' , this.profile.defaultLanguage);

          if(this.dataBaseDefaultCurrency != this.profile.user.default_currency){
     this.popularCurrencies.find(x => {
         if(x.code == this.profile.user.default_currency){
              return this.localecurrency.init(x);
         }
          });
         }
         this.selectedTimeZone = this.profile.user.timezone;
         this.dataBaseDefaultLanguage = this.profile.user.defaultLanguage;
         this.dataBaseDefaultCurrency = this.profile.user.default_currency;
         this.toastr.success('Profile Updated Successfully');
           }
        }, err => {
            this.toastr.error('Invalid Details');
        });
    }
  }

  public onCountryChange(event){
    this.countryDialcode = event.dialCode;

  }

  public update2faSettings(){
    this.account2faSettingsForm.markAllAsTouched();
    this.profileService.update2faSetting(this.account2faSettingsForm.value)
        .subscribe(response => {
           response = JSON.parse(atob(response['data']));
           if ( typeof response.status !== 'undefined' && response.status == 'otp_sent' ) {
             this.toastr.success(response.message);
             this.otp_status = 'sent';
             this.button = 'Verify';
           } else if(typeof response.status !== 'undefined' && response.status == 'otp_wrong') {
             this.otp_status = 'sent';
             this.account2faSettingsForm.patchValue({
               'otp': ''
             });
             this.toastr.error(response.message);
           } else if(typeof response.status !== 'undefined' && response.status == 'max_attempts') {
             this.otp_status = 'sent';
             this.account2faSettingsForm.patchValue({
               'otp': ''
             });
             this.toastr.error(response.message);
           } else if(typeof response.status !== 'undefined' && response.status == 'failed') {
             this.toastr.error(response.message);
           } else {
             this.otp_status = '';
             this.account2faSettingsForm.patchValue({
               'otp': ''
             });
             //this.account2faSettingsForm.reset();
             this.account2faSettingsForm.get('otp').clearValidators();
             this.toastr.success(response.message);
           }
        });
  }

  public resendOTP() {
    this.account2faSettingsForm.patchValue({
     'otp': ''
   });
    this.account2faSettingsForm.reset();
    this.update2faSettings();
  }

    public timeZone($event){
      this.selectedTimeZone = $event;
    }

    public validateDates(sDate: string){
    this.isValidDate = true;
    this.error={isError:false,errorMessage:''};

    if((sDate) > (this.localetimezone.onlyDate())){
      this.error_message='Date of birth should be less than current date.';
      this.isValidDate = false;
      this.status = 'date';
    }else{
      this.error_message = '';
      this.status = ''
      this.isValidDate = true;
    }
    return this.isValidDate;
  }

  public countryChange(value){
           this.currency.find(currency=>{
          if(currency['country'] == value){
    this.accountSettingsForm.patchValue({
      default_currency : currency['currency_code'],
    });
          }
       });
  }

  /*
  Check Username Email Phone Number Unique Validation
  */
    public uniqueValidation(inputName){

    let validationValue = this.accountSettingsForm.get(inputName).value;
    console.log(validationValue);
    if(validationValue && validationValue != 'null'){

    const param = {
      key: inputName,
      [inputName]: this.accountSettingsForm.get(inputName).value
    }

    this.profileService.uniqueValidation(param).subscribe((data: any) =>{
      if(data.status == 'failed'){
            this.toastr.error(data.error);
            this.accountSettingsForm.get(inputName).reset();
      }else{
         
      }
    } , error => {
      // console.log(error);
    });
  }
  } 




  /*Remove Banned Countries from the countries list*/
  public removeBannedCountries(){
    let bannedCountries = [];
    if(this.bannedCountries){
    for(let banned of JSON.parse(this.bannedCountries)){
        this.countries.filter(country =>{
          if(country.name == banned){
             bannedCountries.push(country);
           }
         });
    }
    this.countries = this.countries.filter(val => !bannedCountries.includes(val));
         this.countriesdata$.next(this.countries);
         this.dailcodeData = this.countries;
       }else{
        this.countriesdata$.next(this.countries);
       }
  }

}