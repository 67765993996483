import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
var $window = $(window);
var offset = 300;
var duration = 500;

@Component({
  selector: 'app-scrollup',
  templateUrl: './scrollup.component.html',
  styleUrls: ['./scrollup.component.css']
})
export class ScrollupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadExternalScript('../../../homePageAssets/js/scrollTop.js');
  }

public loadExternalScript(url: string) {
  const body = <HTMLDivElement> document.body;
  const script = document.createElement('script');
  script.innerHTML = '';
  script.src = url;
  script.async = true;
  script.defer = true;
  body.appendChild(script);
}

}
