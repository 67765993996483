import { Injectable } from '@angular/core';
import { AppHttpClientService } from '../../core/http/app-http-client.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LessonsMenuService {

  constructor(private http: AppHttpClientService) { }
  static Subjects_BASE_URI= 'subjects';
  static Lessons_BASE_URI= 'lessons';

  // pendingTutors
	 public createSpeciality(payload: object) {
        return this.http.post('teachinglevels/admin-side', payload);
    }

      public teachingLevels(){
      	return this.http.get('adminGetTeachingLevels');
      }

      public updateSubjectStatus(payload){
      	return this.http.post('updateSubjectStatus', payload);
      }

      public deleteSpeciality(payload: object){
        return this.http.post('deleteSpeciality', payload);
      }

      public createStudentAge(payload: object){
        return this.http.post('createStudentAge/admin-side', payload);
      }


      public deleteStudentAge(payload: object){
        return this.http.post('deleteStudentAge', payload);
      }

      public lessonBoardSetting(payload: object){
        return this.http.post('lessonBoardSetting', payload);
      }

      public getlessonBoardSetting(){
        return this.http.get('getlessonBoardSetting');
      }
     public updateScheduleSettings(payload: object){
       return this.http.post('updateScheduleSettings',payload)
     }
     public getScheduleSettings(){
       return this.http.get('getScheduleSettings')
     }

     public addEducationLevel(payload){
      return this.http.post('addEducationLevel', payload);
     }

     public addLanguageLevel(payload){
      return this.http.post('addLanguageLevel', payload);
     }

    public addCertificateLevel(payload){
      return this.http.post('addCertificateLevel', payload);
     }

     public getLevelsList(){
      return this.http.get('getLevelsList');
     }

     public deleteLevel(payload){
      return this.http.post('deleteLevel',payload);
     }

     public addSubject(payload){
      return this.http.post('addSubject',payload);
     }

     public deleteSubject(payload){
      return this.http.post('deleteSubject',payload);
     }

     public lessonDataCount(){
      return this.http.get('lessonDataCount');
     }

      public getFirstMoreOrder(){
         return this.http.get('getFirstMoreOrder');
   }

    public getNotification(){
      return this.http.get('stuNotifications');
    }

    public clearLog(payload){
      return this.http.post('log/delete', payload);
    }

    public readNotification(id){
        return this.http.get('read-notifications/' + id);
      }

}
