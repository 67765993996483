import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../core/types/models/User';
import { UploadedFile } from '@athand/uploads/uploaded-file';
import { AppHttpClientService } from '../core/http/app-http-client.service';
import { BackendResponse } from '../core/types/backend-response';
import { FileEntry } from '@athand/uploads/types/file-entry';

@Injectable({
    providedIn: 'root',
})
export class Tutors {
    static BASE_URI = 'tutors';

    constructor(private http: AppHttpClientService) {}

        public create(payload: object) {
        return this.http.post('aboutTutor', payload).subscribe();
    }

}
