<div class="contact-box">
   <form id="contact-form" [formGroup]="resumeForm">
      <div class="row">
         <div class="col-md-3">
            <div class="form-group">
               <div class="img-space">
                  <ng-container *ngIf="isImageSaved; else elseTemplate">
                     <img class="img-responsive Tutors_profile_img2" [src]="cardImageBase64" />
                  </ng-container>
                  <ng-template #elseTemplate>
                     <img src="assets/images/dummy.png" class="Tutors_profile_img2">
                  </ng-template>
               </div>
            </div>
         </div>
         <!-- disp_panelHeight -->
         <div class="col-md-9">
            <div class="form-group">
               <label for="last" trans="Display Name">
               Display Name
               </label>
               <input (input)="countDisplayName($event.target.value)" maxlength="35" formControlName="display_name" type="text" class="form-control" placeholder="Display Name" trans-placeholder="Display Name" id="last">
               <div *ngFor= "let error of errorMessages.display_name">
                  <ng-container *ngIf="display_name.hasError(error.type) && (display_name.touched)">
                     <small class="error-message">{{error.message}}</small>
                  </ng-container>
               </div>
               <div>
                  <span>20-35 .{{givenDisplayName}} </span> <span trans="Characters Currently.">Characters Currently.</span>
               </div>
            </div>
            <div class="form-group">
               <label for="last" trans="About the tutor">
               About the tutor
               </label>
               <br>
               <textarea class="form-control" maxlength="500" (input)="countCharacters($event.target.value)" formControlName="description" placeholder="About the tutor" trans-placeholder="About the tutor"></textarea>
               <div *ngFor= "let error of errorMessages.description">
                  <ng-container *ngIf="description.hasError(error.type) && (description.touched)">
                     <small class="error-message">{{error.message}}</small>
                  </ng-container>
               </div>
               <div>
                  <span>80-500 . {{givenChracters}} </span><span trans="Characters Currently.">Characters Currently.</span>
               </div>
            </div>
            <div class="form-group">
               <label for="last" trans="Subject Expertise">
                  Subject Expertise
               </label>
               <br>
               <textarea class="form-control" maxlength="500" (input)="countHighlightCharacters($event.target.value)" formControlName="highlight" placeholder="Subject Expertise" trans-placeholder="Subject Expertise"></textarea>
               <div *ngFor= "let error of errorMessages.highlight">
                  <ng-container *ngIf="highlight.hasError(error.type) && (highlight.touched)">
                     <small class="error-message">{{error.message}}</small>
                  </ng-container>
               </div>
               <div>
                  <span>50-500 . {{givenHighlightChracters}} </span> <span trans="Characters Currently.">Characters Currently.</span>
               </div>
            </div>
         </div>
      </div>
      <div *ngIf=resumeForm.valid?resumeFormComplete():false>
      </div>
      <div *ngIf=!resumeForm.valid?resumeFormInComplete():false>
      </div>
   </form>
</div>