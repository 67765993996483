<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
   <form [formGroup]="accountSettingsForm"> 
      <div class="row m-auto">
         <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h4 class="TT-AccSettings" trans="Account Settings">Account Settings</h4>
         </div> -->
         <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <div class="row m-auto">
               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="first_name" trans="First Name">
                        First Name
                     </label>
                     <input formControlName="first_name" type="text" class="TT-FieldGroup" placeholder="First Name"
                        trans-placeholder="First Name" />
                     <div *ngFor="let error of errorMessages.first_name">
                        <ng-container *ngIf="first_name.hasError(error.type) && (first_name.touched)">
                           <small class="TT-errorUpdate">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>

               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="last_name" trans="Last Name"> Last Name </label>
                     <input type="text" formControlName="last_name" class="TT-FieldGroup" placeholder="Last Name"
                        trans-placeholder="Last Name">
                     <div *ngFor="let error of errorMessages.last_name">
                        <ng-container *ngIf="last_name.hasError(error.type) && (last_name.touched)">
                           <small class="TT-errorUpdate">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>

               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="country" trans="Country"> Country </label>
                     <select formControlName="country" (input)="countryChange($event.target.value)"
                        class="TT-FieldGroup TT-SelectIcon">
                        <option value="" trans="Please Select">Please Select</option>
                        <option [value]="count.name" *ngFor="let count of countriesdata$ | async">
                           {{count.name}}({{count.dial_code}})
                        </option>
                     </select>
                     
                     <div *ngFor="let error of errorMessages.country">
                        <ng-container *ngIf="country.hasError(error.type) && (country.touched)">
                           <small class="TT-errorUpdate">{{error.message}}</small>
                        </ng-container>
                     </div> 
                  </div>
               </div>

               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="phone" trans="Phone Number"> Phone Number </label>
                     <input (keydown)="validateNumber($event)" (blur)="uniqueValidation('phone')"
                        formControlName="phone" type="number" class="TT-FieldGroup" placeholder="Phone Number"
                        trans-placeholder="Phone Number">
                     <div *ngFor="let error of errorMessages.phone">
                        <ng-container *ngIf="phone.hasError(error.type) && (phone.touched)">
                           <small class="TT-errorUpdate">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>

               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="email" trans="Email
                     Address"> Email Address </label>
                     <input formControlName="email" type="email" class="TT-FieldGroup" id="email" placeholder="Email"
                        trans-placeholder="Email" (blur)="uniqueValidation('email')" />
                     <div *ngFor="let error of errorMessages.email">
                        <ng-container *ngIf="email.hasError(error.type) && (email.touched)">
                           <small class="TT-errorUpdate">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>

               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="date_of_birth" trans="Date of Birth"> Date of Birth </label>
                     <input formControlName="date_of_birth" type="date" class="TT-FieldGroup" placeholder="Date of Birth" trans-placeholder="Date of Birth">
                     <div class="TT-errorUpdate" *ngIf="status=='date'"><small>{{error_message}}</small></div>
                     <div *ngFor="let error of errorMessages.date_of_birth">
                        <ng-container *ngIf="date_of_birth.hasError(error.type) && (date_of_birth.touched)">
                           <small class="TT-errorUpdate">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>

               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="gender" trans="Gender"> Gender </label>
                     <select formControlName="gender" name="gender" class="TT-FieldGroup TT-SelectIcon">
                        <option value="" trans="Please Select">Please Select</option>
                        <option value="Female" trans="Female"> Female </option>
                        <option value="Male" trans="Male"> Male </option>
                     </select>
                     <div *ngFor="let error of errorMessages.gender">
                        <ng-container *ngIf="gender.hasError(error.type) && (gender.touched)">
                           <small class="TT-errorUpdate">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>

               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="timezone" trans="Time Zone"> Time Zone </label>
                     <app-time-zones [tmz]="selectedTimeZone" (selectedTimeZone)="timeZone($event)"
                        placeholder="Please Select" trans-placeholder="Please Select"></app-time-zones>
                     <input type="hidden" formControlName="defaultLanguage" class="TT-FieldGroup" id="language">
                     <input type="hidden" formControlName="default_currency" class="TT-FieldGroup"
                        id="default_currency">
                  </div>
               </div>
            </div>
         </div>

         <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div class="TT-uploadPicture">
               <div class="TT-pictureCover">
                  <img *ngIf="!avatar$" src="assets/images/dummy.png" alt="" class="TT-pictureArea">
                  <img *ngIf="avatar$" [src]="avatar$" alt="" class="TT-pictureArea">
                  <button (click)="openAvatarUploadDialog()" class="btn TT-uploadPic"> Upload a Photo
                  </button>
                  <span class="TT-formatType" trans="JPG or PNG format">JPG or PNG format
                     <span trans="Maximum 2 MB" class="TT-formatType TT-highlighter">Maximum 2 MB</span></span>
               </div>
            </div>
         </div>

         <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h4 class="TT-AccSettings" trans="Update Password">Update Password</h4>
         </div>

         <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row m-auto">
               <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="password1" trans="New Password"> New Password </label>
                     <div class="TT-eyeCover">
                        <span (click)="togglePassword('password')" class="fa fa-fw fa-eye-slash TT-eyeIconMoon"></span>
                        <input id="password" formControlName="password1" type="password" class="TT-FieldGroup"
                           placeholder="New Password" trans-placeholder="New Password" autocomplete="off">
                     </div>
                     <div *ngFor="let error of errorMessages.password1">
                        <ng-container *ngIf="password1.hasError(error.type) && (password1.touched)">
                           <small class="TT-errorUpdate"> {{error.message}} </small>
                        </ng-container>
                     </div>
                  </div>
               </div>

               <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="password2" trans="Confirm Password"> Confirm Password </label>
                     <div class="TT-eyeCover">
                        <span (click)="togglePassword('confirmPassword')"
                           class="fa fa-fw fa-eye-slash TT-eyeIconMoon"></span>
                        <input id="confirmPassword" formControlName="password2" type="password" class="TT-FieldGroup"
                           placeholder="Confirm Password" trans-placeholder="Confirm Password" autocomplete="off">
                     </div>
                     <div *ngFor="let error of errorMessages.password2">
                        <ng-container *ngIf="password2.hasError(error.type) && (password2.touched)">
                           <small class="TT-errorUpdate"> {{error.message}} </small>
                        </ng-container>
                     </div>
                  </div>
               </div>

               <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <button (click)="updateSettings()" type="submit" class="btn TT-primaryBTN TT-topMargin" trans="Update"> Update
                     </button>
                  </div>
               </div>

            </div>
         </div>
      </div>
   </form>
</div>