import { NgModule } from '@angular/core';
import { ToastComponent } from './toast/toast.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

@NgModule({
	imports: [],
	declarations: [ToastComponent, ConfirmModalComponent],
    exports : [ToastComponent]
})

export class UiModule {
   constructor(){
   	
   }

}