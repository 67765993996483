import { Component, OnInit, ViewChild } from '@angular/core';
import { DescriptionComponent } from './description/description.component';
import { AboutComponent } from './about/about.component';
import { SubjectselectionComponent } from './subjectselection/subjectselection.component';
import { AvailabilityComponent } from './availability/availability.component';
import { LanguageComponent } from './language/language.component';
import { PhotoComponent } from './photo/photo.component';
import { VideoComponent } from './video/video.component';
import { WebSiteService } from '../website.service';
import { AuthService } from '@auth/auth.service';
import { CurrentUser } from '@auth/current-user';
import { SocialAuthService } from '../../athand/social-auth.service';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Translations } from '@core/translations/translations.service';
import { Settings } from '@core/config/settings.service';
import { Injectable, Injector } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-tutor-registration',
  templateUrl: './tutor-registration.component.html',
  styleUrls: ['./tutor-registration.component.css']
})
export class TutorRegistrationComponent implements OnInit {
  
  @ViewChild(AboutComponent) aboutFormComponent: AboutComponent;
  @ViewChild(LanguageComponent) languageFormComponent: AboutComponent;
  @ViewChild(SubjectselectionComponent) subjectFormComponent: AboutComponent;
  @ViewChild(PhotoComponent) photoFormComponent: AboutComponent;
  @ViewChild(VideoComponent) videoFormComponent: AboutComponent;
  @ViewChild(DescriptionComponent) description: DescriptionComponent;
  @ViewChild(AvailabilityComponent) availabilityFormComponent: AvailabilityComponent;

  public aboutForm;
  public photoForm;
  public error_message:string;
  public resumeForm;
  public videoForm;
  public availabilityForm;
  public languageForm;
  public photoFile: any;
  public subjectForm;
  public timeZoneForm;
  public translations;
  public loading$ = new BehaviorSubject(false);
  constructor(public service: WebSiteService,
              private toastr: ToastrService,
              protected injector: Injector,
              public auth: AuthService,
              protected i18n: Translations,
              public currentuser: CurrentUser,
              private router: Router,
              public settings: Settings,
              public socialAuth: SocialAuthService) { 
  this.i18n = this.injector.get(Translations);
  this.translations = this.i18n['localization'].lines;
}

  ngOnInit(): void {
    this.loadExternalScript('../../../homePageAssets/js/easingPlugin.js');
  }

  public loadExternalScript(url: string) {
  const body = <HTMLDivElement> document.body;
  const script = document.createElement('script');
  script.innerHTML = '';
  script.src = url;
  script.async = true;
  script.defer = true;
  body.appendChild(script);
}

  public aboutFormData(aboutFormData){
    if(aboutFormData.formFilled==0){
     $('#aboutForm').css('display','none');
     $('#aboutFormValidation').css('display','inherit');
    }else{
    this.aboutForm = aboutFormData;
     $('#aboutForm').css('display','inherit');
     $('#aboutFormValidation').css('display','none');
    }
  }

  public photoFormData(photoFormData){
    if(photoFormData.formFilled==0){
     $('#photoForm').css('display','none');
     $('#photoFormValidation').css('display','inherit');
    }else{
      this.photoForm = photoFormData;
      // this.description.userPhoto(photoFormData.cardImageBase64);
     $('#photoForm').css('display','inherit');
     $('#photoFormValidation').css('display','none');
    }
  }

  public resumeFormData(resumeFormData){
    if(resumeFormData.formFilled==0){
     $('#resumeForm').css('display','none');
     $('#resumeFormValidation').css('display','inherit');
    }else{
    this.resumeForm = resumeFormData;
     $('#resumeForm').css('display','inherit');
     $('#resumeFormValidation').css('display','none');
    }
  } 

  public validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  public videoFormData(videoFormData){   
    if(videoFormData.formFilled==0){
      /*
      if ( videoFormData.video_URL && ! this.validURL(videoFormData.video_URL) ) {
      $('#videoForm').css('display','none');
      } else {
        $('#videoForm').show();
      }
      */
     $('#videoFormValidation').css('display','inherit');
    }else{
    this.videoForm = videoFormData;
     $('#videoForm').css('display','inherit');
     $('#videoFormValidation').css('display','none');
    }
  }


  public languageFormData(languageFormData){
    if(languageFormData.formFilled==0){
     $('#languageForm').css('display','none');
     $('#languageFormValidation').css('display','inherit');
    }else{
      this.languageForm = languageFormData.data;
     $('#languageForm').css('display','inherit');
     $('#languageFormValidation').css('display','none');
    }
  }

  public subjectSelectionFormData(subjectFormData){
    if(subjectFormData.formFilled==0){
     $('#subjectSelectionForm').css('display','none');
     $('#subjectSelectionFormValidation').css('display','inherit');
    }else{
      this.subjectForm = subjectFormData.data;
     $('#subjectSelectionForm').css('display','inherit');
     $('#subjectSelectionFormValidation').css('display','none');
    }
  }


  public availabilityFormData(availability , timezone){
    if(availability.availability.length > 0 && timezone.timezone!= undefined){
      this.availabilityForm = availability;
      this.timeZoneForm = timezone.timezone;
     $('#availabilitySelectionForm').css('display','inherit');
     $('#availabilitySelectionFormValidation').css('display','none'); 
    }else{
     $('#availabilitySelectionForm').css('display','none');
     $('#availabilitySelectionFormValidation').css('display','inherit');
    }
  }

  public aboutValidation(){
   this.aboutFormComponent.validate();
  }

  public photoValidation(){
   this.photoFormComponent.validate();
  }

  public resumeValidation(){
   this.description.validate();
  }

  public videoValidation(){
   this.videoFormComponent.validate();
  }

  public languageValidation(){
   this.toastr.error((this.translations['add_atleast_one_language']) ? this.translations['add_atleast_one_language'] : "Add Atleast One Language");
   this.languageFormComponent.validate();
   
  }

  public subjectValidation(){
   this.toastr.error((this.translations['add_atleast_one_subject']) ? this.translations['add_atleast_one_subject'] : "Add Atleast One Subject");
   this.subjectFormComponent.validate();
  }

  public availabilityValidation(){
   this.toastr.error((this.translations['add_atleast_one_available_time']) ? this.translations['add_atleast_one_available_time'] : "Add Atleast One Available Time");
   this.availabilityFormComponent.validate();    
  }


  public registerTutor(){
    if(this.availabilityForm && this.availabilityForm != undefined){
         this.loading$.next(true);
     let registrationData = Object();
     Object.assign(registrationData , {about:this.aboutForm});
     Object.assign(registrationData , {photo:this.photoFile});
     Object.assign(registrationData , {resume:this.resumeForm});
     Object.assign(registrationData , {video:this.videoForm});
     Object.assign(registrationData , {availability:this.availabilityForm});
     Object.assign(registrationData , {timezone:this.timeZoneForm});
     Object.assign(registrationData , {languages:this.languageForm});
     Object.assign(registrationData , {avatarimage:this.photoForm});
     Object.assign(registrationData , {subjects:this.subjectForm});
     this.service.tutorPostRegistration(registrationData).subscribe(response =>{
              if ( typeof response.error !== 'undefined' ) {
                 this.toastr.error(response.error);
                 this.error_message = response.error;
               } else {
                  if(response.status = 'success'){
                     this.toastr.success('Registered Sucessfully');
                      this.currentuser.assignCurrent(response.user);
                      if (this.currentuser.hasRole('Tutor')) {
                        this.router.navigate([this.auth.getRedirectUri()]);
                      }
                  }
               }
               this.loading$.next(false);
            }, err => {
               this.loading$.next(false);
              console.log(err);
              this.toastr.error(err.status, 'Invalid details');
          });
   }else{
     this.availabilityValidation();
   }
  }
}
