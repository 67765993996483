import './polyfills';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import device from "current-device";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule ,{ preserveWhitespaces: true }).then(ref => {
	  // Ensure Angular destroys itself on hot reloads.
  if (window['ngRef']) {
    window['ngRef'].destroy();
  }
  window['ngRef'] = ref;
  
}).catch(err => console.error(err));

if(device.mobile()){
 if(device.orientation == 'portrait'){
 device.onChangeOrientation(newOrientation => {
  if(newOrientation == 'landscape'){
    document.getElementById("maindiv").style.display = "none";
  }
  else{
   document.getElementById("maindiv").style.display = "initial";
  }
   
  });

  }
  }
  


