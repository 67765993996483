import { Injectable } from '@angular/core';
import { AppHttpClientService } from '@core/http/app-http-client.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class FindTutor {
    static tutors = 'findtutor';
    static profile = 'profile';

	constructor(private http: AppHttpClientService){}
   
    public tutorInfo = new BehaviorSubject(Array);
    sharedtutorDetails = this.tutorInfo.asObservable();

    public tutorid = new BehaviorSubject(null);
    sharedTutoId = this.tutorid.asObservable(); 

    public displayNameData = new BehaviorSubject(Array);
    shareddisplayNameData = this.displayNameData.asObservable();

    public userData = new BehaviorSubject(Array);
    shareduserData = this.userData.asObservable();

    public tutorDetails(tutor){
     this.tutorInfo.next(tutor);
    }

    public tutorId(id){
        this.tutorid.next(id);
    }

    public validateLesson(lessonData){
        return this.http.post('validateLessons' , lessonData);
    }

    //public directCancelationsss(bookingid) {
      //  this.schedulelistcomponent.directCancelation(bookingid);
    //}

    public displayNameDataSent(displayname){
     this.displayNameData.next(displayname);
    }

    public userDataSent(userdata){
     this.userData.next(userdata);
    }

    public sessionDetails(sessionId){
    	// this.sessionINfo.next(sessionId);
    }

    public subjectDetails(subjectId){
    	return this.http.get('subjectFilterInfo/' + subjectId );
    }


	public getActiveSubjects(){
		return this.http.get('actsubjects');
	}

	public findSubjectTutor(findSubjectTutor){
		return this.http.post('findSubjectTutor' , findSubjectTutor);
	}

	public getTutors(){
		return this.http.get('getTutors');
	}

    public getTutorsList(){
        return this.http.get('getTutorsList');
    }

	public searchFilterTutors(values){
		return this.http.get('getFilterTutors/' + values);
	}

	// Lessons
	public bookLesson(payload: object){
		return this.http.post('bookLesson', payload);
	}

	// Message Modal send Request
	public sendMessage(payload: object){
		return this.http.post('sendStuMessage', payload);
	}

	// Booking With Wallet
	public bookLessonwithWallet(payload: object){
		return this.http.post('bookLessonwithWallet',payload);
	} 

	public makeFavourite(makeFavourite){
		return this.http.post('makeFavourite' , makeFavourite);
	}


	public teachingLevels(){
      	return this.http.get('getOnlyTeachingLevels');
    } 

    public bookWithStripe(payload: object){
    	return this.http.post('bookWithStripe' , payload);
    }

    // Weekly Availability of selctedTutor
    public getWeeklyAvailability(payload: object){
        return this.http.post('getWeeklyAvailability' , payload);
    }

    public getStudentBooking(payload: object){
        return this.http.post('getStudentBookings', payload);
    }

    public userWalletBalance(currency , symbol){
        return this.http.get('userWalletBalance/'+currency +'/'+symbol);
    }

    /*Get Packages Related To the subjects*/
    public getSubjectRelatedPackages(subjectId , tutorId, ptype){
        return this.http.get('packages/'+subjectId + '/' + tutorId + '/' + ptype);
    }

    public checkCurrencyChange(payload: object){
        return this.http.post('checkCurrencyChange' , payload);
    }

}