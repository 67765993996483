import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { HttpErrorHandler } from './errors/http-error-handler.service';
import { Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { throwError, BehaviorSubject, of } from "rxjs";
import { transformAngularUploadEvent, UploadEvent } from '@athand/uploads/utils/upload-progress-event';
import { UploadFileResponse } from '@athand/uploads/uploads-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BackendErrorResponse } from '@core/types/backend-error-response';

export interface HttpError {
    uri: string;
    messages: {[key: string]: string};
    type: 'http';
    status: number;
    originalError: Error;
}
@Injectable({
  providedIn: 'root'
})


export abstract class AppHttpClientService {
	static prefix = 'secure';

  constructor(public httpClient: HttpClient, public errorHandler: HttpErrorHandler ) { }

      public post<T>(uri: string, params: object = null): Observable<any> {

        return this.httpClient.post<T>(this.prefixUri(uri), params)
                              .pipe(catchError(err => this.handle(err, uri)));
    }

        /**
     * Prefix specified uri with backend API prefix.
     */
    private prefixUri(uri: string) {
        if (uri.indexOf('://') > -1) return uri;
        return AppHttpClientService.prefix + '/' + uri;
    }

        public get<T>(uri: string, params = {}, options: object = {}): Observable<T> {
        const httpParams = this.generateHttpParams(params);
        return this.httpClient.get<T>(this.prefixUri(uri), {params: httpParams, ...options})
            .pipe(catchError(err => this.handle(err, uri)));
    }

     /**
     * Handle http request error.
     */
    public handle(response: HttpErrorResponse, uri?: string): Observable<never> {
        const body = this.parseJson(response.error);
        const error = {
            uri,
            messages: body.messages,
            type: 'http',
            status: response.status,
            originalError: new Error(response.message)
        };

        if (response.status === 403 || response.status === 401 || response.status === 422) {
            this.errorHandler.handle403Error(body);
        }

        return throwError(error as HttpError);
    }

        /**
     * Redirect user to login page or show toast informing
     * user that he does not have required permissions.
     */
    public abstract handle403Error(response: BackendErrorResponse);

        /**
     * Parse JSON without throwing errors.
     */
    public parseJson(json: string|BackendErrorResponse): BackendErrorResponse {
        let original: BackendErrorResponse;

        if (typeof json !== 'string') {
            original = json;
        } else {
            try {
                original = JSON.parse(json);
            } catch (e) {
                original = this.getEmptyErrorBody() as any;
            }
        }

        if ( ! original || ! original.messages) {
            return this.getEmptyErrorBody();
        }

        Object.keys(original.messages).forEach(key => {
            const message = original.messages[key];
            original.messages[key] = Array.isArray(message) ? message[0] : message;
        });

        return original;
    }

    public getEmptyErrorBody(): BackendErrorResponse {
        return {status: 'error', messages: {}};
    }



        /**
     * Generate http params for GET request.
     */
    private generateHttpParams(params: object|null) {
        let httpParams = new HttpParams();
        if ( ! params) return httpParams;

        Object.keys(params).forEach(key => {
            let value = params[key];
            if (value == null) value = '';
            httpParams = httpParams.append(key, value);
        });

        return httpParams;
    }


        public put<T>(uri: string, params: object = {}): Observable<T> {
        params = this.spoofHttpMethod(params, 'PUT');
        return this.httpClient.post<T>(this.prefixUri(uri), params)
            .pipe(catchError(err => this.errorHandler.handle(err, uri)));
    }

        /**
     * Spoof http method by adding '_method' to request params.
     */
    private spoofHttpMethod(params: object|FormData, method: 'PUT'|'DELETE'): object|FormData {
        if (params instanceof FormData) {
            (params as FormData).append('_method', method);
        } else {
            params['_method'] = method;
        }

        return params;
    }

        public delete<T>(uri: string, params: object = {}): Observable<T> {
        params = this.spoofHttpMethod(params, 'DELETE');
        return this.httpClient.post<T>(this.prefixUri(uri), params)
            .pipe(catchError(err => this.errorHandler.handle(err, uri)));
    }

        public postWithProgress(uri: string, params: object = {}): Observable<UploadEvent> {
        const req = new HttpRequest('POST', this.prefixUri(uri), params, {
            reportProgress: true
        });

        const now = Date.now();

        return this.httpClient.request(req).pipe(
            catchError(err => this.errorHandler.handle(err, uri)),
            map((e: HttpEvent<UploadFileResponse>) => transformAngularUploadEvent(e, now)),
            filter(e => !!e)
        );
    }



        public newPost<T>(uri: string, params: object = null){
        this.httpClient.post<T>(this.prefixUri(uri), params)
                              .pipe(catchError(err => this.errorHandler.handle(err, uri)))
                              .subscribe(response => {
                                return JSON.parse(atob(response['data']));
                              });
    }


            public newGet<T>(uri: string, params = {}, options: object = {}){
        const httpParams = this.generateHttpParams(params);
        this.httpClient.get<T>(this.prefixUri(uri), {params: httpParams, ...options})
            .pipe(catchError(err => this.errorHandler.handle(err, uri)))
            .subscribe(response => {
                return JSON.parse(atob(response['data']));
            });
    }





}


	