import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class RatingComponent implements OnInit {
   
  public ratingValue;
  public reviewValue;

  @Input()
  public set rating(val){
   this.ratingValue = val; 
  }

  @Input()
  public set review(val){
    this.reviewValue = val
  }
  constructor() { }

  ngOnInit(): void {
  }

}
