import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoleService } from '../roles/role.service';
import { LessonsMenuService } from '../lessons-menu/lessons-menu.service';
import { CurrentUser } from '../../auth/current-user';

declare var $:any;

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

  public firstPageRoute:any;
  public notifications = new Array();
  public usernotifications: any;
  public usernotifications_read: any;
  public usernotifications_read_count: any;
  public usernotifications_unread: any;
  public usernotifications_unread_count: any;
  public usermessages_unread_count: any;

  constructor(
  	      private router: Router,
  	      private roleApi: RoleService,
          private service: LessonsMenuService,
          public currentuser: CurrentUser) { }

  ngOnInit(): void {

      this.service.getFirstMoreOrder().subscribe(response=>{
              let data = response['data'];
              if(data['categoryFirst']){
              this.firstPageRoute = data['categoryFirst'];
              }
       });
        // this.getNotifications();
       $(document).on('click', '.collapseToggle', function (event) {
        if (!$(event.target).closest('#alerts').length) {
                $("#alert-box-alerts").removeClass("activeAlert");
                $("#alerts").removeClass("active");
           }
      });

      /*
      $('.collapseToggle').on('click', function() {
        $(".tt-sideArea").toggleClass('sidebar--Collapse');
        $('.pageSectionBg').toggleClass('pageSectionBg-collapsable');	
        $(".tt-sideArea li").click(function(){
          $(".tt-sideArea").removeClass("sidebar--Collapse");
        });
      });
      */

  }

  public removeAlert(){
        if($("#alert-box-alerts").hasClass("activeAlert")){
          $("#alert-box-alerts").removeClass("activeAlert");
          $("#alerts").removeClass("active");
        }
        this.getNotifications();
    }

// alerts
  public openAlerts(){

    if($("#alert-box-alerts").hasClass("activeAlert")){
          $("#alert-box-alerts").removeClass("activeAlert");
          $("#alerts").removeClass("active");
        }else{
          $("#alert-box-alerts").addClass("activeAlert");
          $("#alerts").addClass("active");
        }
        this.getNotifications();
  }



    public more(){
    if(this.firstPageRoute){
    this.router.navigate(['admin/more/'+this.firstPageRoute]);
    }else{
       this.router.navigate(['more']);
    }
  }


  public getNotifications(){
      this.service.getNotification().subscribe(response=>{
        let data =JSON.parse(atob(response['notifications']));
        this.usernotifications = data['notifications'];
        this.usernotifications_read = this.usernotifications.is_read;
        this.usernotifications_read_count = this.usernotifications.is_read.length;

        this.usernotifications_unread = this.usernotifications.is_unread;
        this.usernotifications_unread_count = this.usernotifications.is_unread.length;
        this.usermessages_unread_count = data['inboxCount'];
      });
  }
  public validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  }

  public viewAlert(notification){

    (notification.redirect_url && this.validateUrl(notification.redirect_url)) ? this.router.navigate([notification.redirect_url]) : '';

   if(notification.is_read == 0){
        this.service.readNotification(notification.id).subscribe(response=>{
        this.usernotifications =JSON.parse(atob(response['notifications']));
        this.usernotifications_read = this.usernotifications.is_read;

        // this.usernotifications_read_count = this.usernotifications.is_read.length;
        this.usernotifications_read_count = (this.usernotifications.is_read) ? this.usernotifications.is_read.length : 0;

        this.usernotifications_unread = this.usernotifications.is_unread;
        this.usernotifications_unread_count = this.usernotifications.is_unread.length;
      });
      }
   this.removeAlert();
  }

  public humanize(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  public displayTextLimit(text) {
    if ( text.length > 150 ) {
      return text.substring(0, 150) + '...';
    } else {
      return text;
    }
  }
}
