import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Tutors } from '../../auth/tutors.service';
import { Settings } from '../../core/config/settings.service';
import { SocialAuthService } from '../../athand/social-auth.service';
import { CurrentUser } from '../../auth/current-user';
import { HomeService } from '../home.service';
import { ToastrService } from 'ngx-toastr';
import { DescriptionComponent } from './description/description.component';
import { AboutComponent } from './about/about.component';
import { SubjectselectionComponent } from './subjectselection/subjectselection.component';
import { LanguageComponent } from './language/language.component';
import { PhotoComponent } from './photo/photo.component';
import { VideoComponent } from './video/video.component';
import { Bootstrapper } from '../../core/bootstrapper.service';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { BehaviorSubject } from 'rxjs';
declare var $;

@Component({
  selector: 'app-become-a-tutor',
  templateUrl: './become-a-tutor.component.html',
  styleUrls: ['./become-a-tutor.component.css']
})

export class BecomeATutorComponent implements OnInit {
  
  @ViewChild(AboutComponent) aboutFormComponent: AboutComponent;
  @ViewChild(LanguageComponent) languageFormComponent: AboutComponent;
  @ViewChild(SubjectselectionComponent) subjectFormComponent: AboutComponent;
  @ViewChild(PhotoComponent) photoFormComponent: AboutComponent;
  @ViewChild(VideoComponent) videoFormComponent: AboutComponent;
  @ViewChild(DescriptionComponent) description: DescriptionComponent;
  public error_message = '';
  public success_message = '';
  public aboutForm;
  public photoForm;
  public resumeForm;
  public videoForm;
  public availabilityForm;
  public languageForm;
  public subjectForm;
  public timeZoneForm;
  public photoFile;
  public responseData;
  public allowedMaxPrice;
  public loading$ = new BehaviorSubject(false);
  constructor(
              public tutors: Tutors,
              public settings: Settings,
              public socialAuth: SocialAuthService,
              public currentuser: CurrentUser,
              public service: HomeService,
              private toastr: ToastrService,
              private bootstrapper: Bootstrapper,
              private router: Router,
              public auth: AuthService
              ) { }

  ngOnInit(): void {
    this.script();
    $('#availabilityForm').prop('disabled',true);
   }

  public aboutFormData(aboutFormData){
    if(aboutFormData.formFilled==0){
     $('#aboutForm').css('display','none');
     $('#aboutFormValidation').css('display','inherit');
    }else{
    this.aboutForm = aboutFormData;
     $('#aboutForm').css('display','inherit');
     $('#aboutFormValidation').css('display','none');
    }
  }

  public photoFormData(photoFormData){
    if(photoFormData.formFilled==0){
     $('#photoForm').css('display','none');
     $('#photoFormValidation').css('display','inherit');
    }else{
      this.photoForm = photoFormData;
      this.description.userPhoto(photoFormData.cardImageBase64);
     $('#photoForm').css('display','inherit');
     $('#photoFormValidation').css('display','none');
    }
  }

  public resumeFormData(resumeFormData){
    if(resumeFormData.formFilled==0){
     $('#resumeForm').css('display','none');
     $('#resumeFormValidation').css('display','inherit');
    }else{
    this.resumeForm = resumeFormData;
     $('#resumeForm').css('display','inherit');
     $('#resumeFormValidation').css('display','none');
    }
  } 

  public videoFormData(videoFormData){
    if(videoFormData.formFilled==0){
     $('#videoForm').css('display','none');
     $('#videoFormValidation').css('display','inherit');
    }else{
    this.videoForm = videoFormData;
     $('#videoForm').css('display','inherit');
     $('#videoFormValidation').css('display','none');
    }
  }


  public languageFormData(languageFormData){
    if(languageFormData.formFilled==0){
     $('#languageForm').css('display','none');
     $('#languageFormValidation').css('display','inherit');
    }else{
      this.languageForm = languageFormData.data;
     $('#languageForm').css('display','inherit');
     $('#languageFormValidation').css('display','none');
    }
  }

  public subjectSelectionFormData(subjectFormData){
    if(subjectFormData.formFilled==0){
     $('#subjectSelectionForm').css('display','none');
     $('#subjectSelectionFormValidation').css('display','inherit');
    }else{
      console.log(subjectFormData.data);
      this.subjectForm = subjectFormData.data;
     $('#subjectSelectionForm').css('display','inherit');
     $('#subjectSelectionFormValidation').css('display','none');
    }
  }


  public availabilityFormData(availability , timezone){
    if(availability.availability.length > 0 && timezone.timezone!= undefined){
      this.availabilityForm = availability;
      this.timeZoneForm = timezone.timezone;
     $('#availabilityForm').prop('disabled',false);
    }else{
     $('#availabilityForm').prop('disabled',true);  
    }
  }

  public aboutValidation(){
   this.aboutFormComponent.validate();
  }

  public photoValidation(){
   this.photoFormComponent.validate();
  }

  public resumeValidation(){
   this.description.validate();
  }

  public videoValidation(){
   this.videoFormComponent.validate();
  }

  public languageValidation(){
   this.languageFormComponent.validate();
  }

  public subjectValidation(){
   this.subjectFormComponent.validate();
  }


  public registerTutor(){
     this.loading$.next(true);
     let registrationData = Object();
     Object.assign(registrationData , {about:this.aboutForm});
     Object.assign(registrationData , {photo:this.photoFile});
     Object.assign(registrationData , {resume:this.resumeForm});
     Object.assign(registrationData , {video:this.videoForm});
     Object.assign(registrationData , {availability:this.availabilityForm});
     Object.assign(registrationData , {timezone:this.timeZoneForm});
     Object.assign(registrationData , {languages:this.languageForm});
     Object.assign(registrationData , {avatarimage:this.photoForm});
     Object.assign(registrationData , {subjects:this.subjectForm});
     console.log(registrationData);
     this.service.tutorPostRegistration(registrationData).subscribe(response =>{
              if ( typeof response.error !== 'undefined' ) {
                 this.toastr.error(response.error);
                 this.error_message = response.error;
               } else {
                  if(response.status = 'success'){
                     this.toastr.success('Registered Sucessfully');
                      this.currentuser.assignCurrent(response.user);
                      if (this.currentuser.hasRole('Tutor')) {
                        this.router.navigate([this.auth.getRedirectUri()]);
                      }
                  }
               }
               this.loading$.next(false);
            }, err => {
               this.loading$.next(false);
              console.log(err);
              this.toastr.error(err.status, 'Invalid details');
          });
  }
  
  public script(){
                                    //jQuery time
                                    var current_fs, next_fs, previous_fs; //fieldsets
                                    var left, opacity, scale; //fieldset properties which we will animate
                                    var animating; //flag to prevent quick multi-click glitches
                                    $(".next").click(function(){
                                    if(animating) return false;
                                    animating = false;
                                    current_fs = $(this).parent();
                                    next_fs = $(this).parent().next();
                                    //activate next step on progressbar using the index of next_fs
                                    $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");
                                    //show the next fieldset
                                    next_fs.show(); 
                                    //hide the current fieldset with style
                                    current_fs.animate({opacity: 0}, {
                                    step: function(now, mx) {
                                    //as the opacity of current_fs reduces to 0 - stored in "now"
                                    //1. scale current_fs down to 80%
                                    scale = 1 - (1 - now) * 0.2;
                                    //2. bring next_fs from the right(50%)
                                    left = (now * 50)+"%";
                                    //3. increase opacity of next_fs to 1 as it moves in
                                    opacity = 1 - now;
                                    current_fs.css({'transform': 'scale('+scale+')'});
                                    next_fs.css({'left': left, 'opacity': opacity});
                                    }, 
                                    duration: 500, 
                                    complete: function(){
                                    current_fs.hide();
                                    animating = false;
                                    }, 
                                    //this comes from the custom easing plugin
                                    easing: 'easeOutQuint'
                                    });
                                    });
                                    $(".previous").click(function(){
                                    if(animating) return false;
                                    animating = false;
                                    current_fs = $(this).parent();
                                    previous_fs = $(this).parent().prev();
                                    //de-activate current step on progressbar
                                    $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");
                                    //show the previous fieldset
                                    previous_fs.show(); 
                                    //hide the current fieldset with style
                                    current_fs.animate({opacity: 0}, {
                                    step: function(now, mx) {
                                    //as the opacity of current_fs reduces to 0 - stored in "now"
                                    //1. scale previous_fs from 80% to 100%
                                    scale = 0.8 + (1 - now) * 0.2;
                                    //2. take current_fs to the right(50%) - from 0%
                                    left = ((1-now) * 50)+"%";
                                    //3. increase opacity of previous_fs to 1 as it moves in
                                    opacity = 1 - now;
                                    current_fs.css({'left': left});
                                    previous_fs.css({'transform': 'scale('+scale+')', 'opacity': opacity});
                                    }, 
                                    duration: 500, 
                                    complete: function(){
                                    current_fs.hide();
                                    animating = false;
                                    }, 
                                    //this comes from the custom easing plugin
                                    easing: 'easeOutQuint'
                                    });
                                    });
                                    $(".submit").click(function(){
                                    return false;
                                    })
  }
}
