import { Injectable } from '@angular/core';
import { AppHttpClientService } from '../../core/http/app-http-client.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ForgotPasswordService {
  constructor(private http: AppHttpClientService) { }

  public userConfirm(payload: object) {
  	return this.http.post('user/activate', payload);
  }

}
