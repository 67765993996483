import { Component, OnInit } from '@angular/core';
declare var $;
@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  	        $(function() {
     var alert = $(".alert-container");
     alert.hide();
     $("a").click(function(e) {
     e.preventDefault();
     alert.slideDown();
     window.setTimeout(function() {
      alert.slideUp();
    }, 2500);
  });
});

  }

}
