<form [formGroup]="videoForm">
   <div class="row">
      <div class="col-md-6">
         <p trans="Paste a link of your video">
            Paste a link of your video
         </p>
         <p class="fs-sub-link">
            <span trans="Learn how to upload to">Learn how to upload to </span><a href="/blog/How-To-Record-Profile-Video" trans="Youtube">Youtube</a><span trans="or"> or </span><a href="/blog/How-To-Record-Profile-Video" trans="Vimeo">Vimeo</a><br/>
         </p>
         <input (input)="onSearchChange($event.target.value)" formControlName="video_URL" type="text" class="form-control" placeholder="https://www.youtube.com/watch?v=cN7XiJBaYHY">
          <div class="error-message" *ngIf="(video_URL.touched)&& (status=='invalid')||(video_URL.touched)&&(status=='failed')"><small>{{error_message}}</small></div>
         <div *ngFor= "let error of errorMessages.video_URL">
            <ng-container *ngIf="video_URL.hasError(error.type) && (video_URL.touched)">
               <small class="error-message" *ngIf="status ==''" >{{error.message}}</small>
            </ng-container>
         </div>
      </div>
      <div *ngIf="liveIFrame" class="col-md-6 ">
         <div class="videoContainer">
         <iframe  width="250" height="200"  class="responsive-iframe" [src]="liveIFrame"></iframe>
      </div>
      </div>
   </div>
      <div *ngIf=videoForm.valid?videoFormComplete():false>
   </div>
   <div *ngIf=!videoForm.valid?videoFormInComplete():false>
   </div>
</form>

