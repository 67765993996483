import {AppConfig} from './core/config/app-config';


export const TUTORAPP_CONFIG: AppConfig = {

	 auth: {
        redirectUri: '/',
        adminRedirectUri: '/admin',
    },

};
 