import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Subject} from '../core/types/models/Subject';

@Injectable({
    providedIn: 'root'
})
export class SubjectTS {
	public subject$ = new BehaviorSubject<Partial<Subject[]>>([]);

	 public init(params) {
        this.subject$.next(params);
    }

    public subject(){
    	return this.subject$.value;
    }

}