import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LocaleTimeZone} from '../core/types/models/LocaleTimeZone';
import  *  as  countriesCurrency from  '@athand/json/countriesCurrency.json';
import {LocaleCurrency} from './localecurrency';

@Injectable({
    providedIn: 'root'
})
export class LocaleTimeZoneTS {

    constructor(private localecurrency: LocaleCurrency) {}
    public currency: any = (countriesCurrency as any).default;
    public localeTime;
    public localeDate;
    public localeTimeZone;
    public convertedTime;
    public timePMAM;
    public now;
    public onlyDateLocale;
    public onlyTime;
    public countryName;
	public localetimezone$ = new BehaviorSubject<Partial<LocaleTimeZone[]>>([]);
    changelocaleTime$ = this.localetimezone$.asObservable();

	 public init(params) {
        // console.log(params) 
        let currentDateAndTime = params['date'];
         this.onlyDateLocale = params['onlyDate'];
         this.localeTimeZone = params['timezone'];
         this.onlyTime = params['onlyTime'];
         this.country = params['country'];
         this.localeTime = new Date(currentDateAndTime).getTime();
         this.localeDate = new Date(this.onlyDateLocale);
                    setInterval(() => {
          this.now = new Date().getTime();
          this.localetimezone$.next(this.now);
        }, 1);
           //         setInterval(() => {             
           //   this.localeTime = new Date(this.localeTime+10).getTime();
           //   this.localetimezone$.next(this.localeTime);
           // },10);
    }

    public timezone(){
    	return this.localeTimeZone;    
    }

    public changeCurrency(){
this.currency.find(currency=>{
    if(currency['country'] == this.country){
        this.localecurrency.init(currency);
          }
       });
    }

    public time(){
      return this.localeTime;
    }

    public timeAMPM(){
        return this.onlyTime;
    }


    public date(){
        return this.localeDate;
    }

    public country(){
        return this.countryName;
    }

    public onlyDate(){
        return this.onlyDateLocale;
    }

    public timeZoneConvert(timeZone){
          var d = new Date(this.localeTime); /* midnight in China on April 13th */
          var convertedTime = d.toLocaleTimeString('en-US', { timeZone: timeZone });
            let time   = convertedTime.split(":");
            let hour   = Number(time[0]) * 60 * 60 * 1000;
            let minute = Number(time[1]) * 60 * 1000;
            let secondConverted = time[2].slice(0, -3);
            let second = Number(secondConverted) * 1000;
            this.convertedTime = hour + minute + second;
          return this.convertedTime;
     }

}