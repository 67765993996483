<div class="row scroll_lowSec">
   <div>
      <form [formGroup]="availabilityForm">
         <div class="col-md-6">
            <div class="form-group">
               <label for="defaulttimezone" trans="TimeZone" style="
               color: #000;">
               Time Zone
               </label>
               <select  formControlName="defaulttimezone"  (input)="timeZone($event.target.value)" class="form-control" id="gmtSelect" style="border: 1px solid #ccc;">
                  <option value="">Please select</option>
                  <option value="{{tmz}}" *ngFor="let tmz of offsetTmz">{{tmz}}</option>
               </select>
            </div>
            <div *ngFor= "let error of errorMessages.defaulttimezone">
               <ng-container *ngIf="defaulttimezone.hasError(error.type) && (defaulttimezone.dirty || defaulttimezone.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
         <table class="table noBorderTable CCnr-height">
            <thead>
               <p *ngIf="error" class="error-message">{{error}}</p>
               <tr>
                  <th class="col-md-4" trans="Day">Day</th>
                  <th class="col-md-4" trans="Start Time">Start Time</th>
                  <th class="col-md-4" trans="End Time">End Time</th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td>
                     <select (input)="selectAvailabilityDate()" id="selectAvailabilityDate" formControlName="day" class="form-control">
                        <option value="" trans="Please select">Please select</option>
                        <option value="Sunday" trans="Sunday">Sunday</option>
                        <option value="Monday" trans="Monday">Monday</option>
                        <option value="Tuesday" trans="Tuesday">Tuesday</option>
                        <option value="Wednesday" trans="Wednesday">Wednesday</option>
                        <option value="Thursday" trans="Thursday">Thursday</option>
                        <option value="Friday" trans="Friday">Friday</option>
                        <option value="Saturday" trans="Saturday">Saturday</option>
                     </select>
                     <div *ngFor= "let error of errorMessages.day">
                        <ng-container *ngIf="day.hasError(error.type) && (day.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </td>
                  <td>
                     <select id="selectbasic" formControlName="start_time" class="form-control">
                        <option value="" trans="Please select"> Please select</option>
                        <option *ngFor="let time of timings" value="{{time}}">{{time}}</option>
                     </select>
                     <div *ngFor= "let error of errorMessages.start_time">
                        <ng-container *ngIf="start_time.hasError(error.type) && (start_time.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </td>
                  <td>
                     <select id="selectbasic" formControlName="end_time" class="form-control">
                        <option value="" trans="Please select"> Please select</option>
                        <option *ngFor="let time of timings" value="{{time}}">{{time}}</option>
                     </select>
                     <div *ngFor= "let error of errorMessages.end_time">
                        <ng-container *ngIf="end_time.hasError(error.type) && (end_time.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </td>
                  <td class="addRow">
                     <button (click)="addRow()">
                     <i class="fa fa-plus-circle" aria-hidden="true"></i>
                     </button>
                  </td>
               </tr>
               <tr *ngFor="let avail of availability">
                  <td>
                     <input disabled value="{{avail.day}}" class="form-control">
                  </td>
                  <td>
                     <input disabled value="{{avail.start_time}}" class="form-control">
                  </td>
                  <td>
                     <input disabled value=" {{avail.end_time}}" class="form-control">
                  </td>
                  <td class="delRow" (click)="deleteRow(avail)">
                     <i class="fa fa-times" aria-hidden="true"></i>
                  </td>
               </tr>
            </tbody>
         </table>
      </form>
   </div>
</div>