<section id="tutorDashboard" class="pageSectionBg">
   <div id="menuwrapper" class="sidebar d-flex">
      <ul id="sidemenu" class="tt-sideArea">
         <li routerLink="home" routerLinkActive="active" title="DASHBOARD">
            <a trans="Login" class="tt-sidelayer">
              <i class="fa fa-home" aria-hidden="true"></i>
              <span trans="DASHBOARD" class="menuText">DASHBOARD</span>
            </a>
         </li>
         <li *ngIf="currentuser.isRegistrationCompleted()" routerLink="tutor-lessons" routerLinkActive="active" title="LESSONS">
           <a class="tt-sidelayer">             
             <i aria-hidden="true" class="fa fa-calendar"></i>
             <span trans="LESSONS">LESSONS</span>
           </a>
         </li>
         <li *ngIf="currentuser.isRegistrationCompleted()" routerLink="tutor-chat" routerLinkActive="active" title="CHATBOX">
           <a class="tt-sidelayer">
            <i aria-hidden="true" class="fa fa-comments-o"></i>
            <span trans="CHATBOX">CHATBOX</span>
           </a>
         </li>
         <li *ngIf="currentuser.isRegistrationCompleted()" routerLink="tutor-payment/transactions" routerLinkActive="active" title="TRANSACTIONS">
           <a class="tt-sidelayer">
            <i aria-hidden="true" class="fa fa-usd"></i>
            <span trans="TRANSACTIONS">TRANSACTIONS</span>
           </a>
         </li>
         <li routerLink="teaching" routerLinkActive="active" title="TEACHING">
           <a class="tt-sidelayer">
             <i aria-hidden="true" class="fa fa-male"></i>
             <span trans="TEACHING">TEACHING</span>
           </a>
         </li>
         <li routerLink="account" routerLinkActive="active" title="ACCOUNT">
            <a class="tt-sidelayer">
              <i class="fa fa-user" aria-hidden="true"></i>
              <span trans="ACCOUNT">ACCOUNT</span>
            </a>
         </li>
         <li *ngIf="currentuser.isRegistrationCompleted()" routerLink="tutor-payment/settings" routerLinkActive="active" title="PAYMENT SETTINGS">
            <a class="tt-sidelayer">
             <i aria-hidden="true" class="fa fa-gear"></i>
             <span>PAYMENT SETTINGS</span>
            </a>
          </li>
         <li routerLink="/tutor/tutor-alerts"  routerLinkActive="active" title="ALERTS">
            <a class="tt-sidelayer">
              <i class="fa fa-bell" aria-hidden="true"></i>
              <span trans="ALERTS">ALERTS</span>
            </a>
         </li>        
      </ul>
   </div>   
   
   <div aria-hidden="true" aria-labelledby="alertModallLabel" role="dialog" tabindex="-1" id="alertModal" class="modal fade" >
      <div class="modal-dialog mt-200">
         <div *ngIf="alertMessage" class="modal-content bd-25">
            <div class="modal-header">
               <button aria-hidden="true" data-dismiss="modal" class="close" type="button" #closeSuccessModal>×</button>
               <h4 class="modal-title linkLabel-green-dark">{{alertMessage['purpose']}}</h4>
            </div>
            <div class="modal-body padding50 text-center">
               <h4 class="linkLabel"><i class="fa fa-4x fa-check-square green" aria-hidden="true"></i></h4>
               <h4 class="linkLabel">{{alertMessage['notification']}}</h4>
               <button (click)="viewSchedules()" type="submit" class="btn btn-primary" trans="View Schedule">View Schedule</button>
            </div>
         </div>
      </div>
   </div>
</section>