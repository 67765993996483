import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ACTSubjects} from '../core/types/models/ActiveSubjects';

@Injectable({
    providedIn: 'root'
})
export class ActiveSubjects {
	public activeSubjects$ = new BehaviorSubject<Partial<ACTSubjects[]>>([]);

	 public init(params) {
        this.activeSubjects$.next(params);
    }

    public subjects(){
    	return this.activeSubjects$.value;
    }

}