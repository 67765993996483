<div class='col-sm-9 rightCol'>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-9">
            <h4 class="title" trans="Account Verification">Account Verification</h4> </div>
      </div>
   </div>
   <div class='content-container customTabs'>
      <div class="row formspace">
         <div class="col-sm-12">
            <h5 class="form-subhead">
               <b>
               </b>
            </h5>
            <div class="row formspace">
               <div class="col-sm-4 confirmation_txt">
                  <p trans="Account Verification" class="ml-15">Account Verification </p>
                  <br> </div>
            </div>
            <div class="col-md-offset-0 col-md-9 margintop30">
               <form [formGroup]="idForm">
                  <div class="row">
                     <div class="col-md-6">
                        <div class="img-space">
                           <ng-container *ngIf="isImageSaved; else elseTemplate"> <img class="img-responsive" [src]="cardImageBase64" class="Tutors_profile_img" /> </ng-container>
                           <ng-template #elseTemplate> <img src="assets/images/dummy.png" class="Tutors_profile_img"> </ng-template>
                        </div>
                        <div class="rmwbtn_profile">
                           <label for="selectbasic" class="pull-right">*PNG,JPG,JPEG</label>
                           <!-- <div id="removeImage"> <a class="btn btn-danger" (click)="removeImage()" *ngIf="isImageSaved">Remove</a> </div>  -->
                           <span>
                              <input formControlName="id_proof" class="form-control" accept="image/x-png,image/gif,image/jpeg" (change)="fileChangeEvent($event)" id="uplodfile" class="fileInputProfile" type="file" class="mb-40">
                                 <div *ngFor= "let error of errorMessages.id_proof">
                                    <ng-container *ngIf="id_proof.hasError(error.type) && (id_proof.touched)">
                                       <small class="error-message">{{error.message}}</small>
                                    </ng-container>
                                 </div>
                              <div class="error-status" *ngIf="errorstatus =='invalid'"><small>{{imageError}}</small></div>
                              <div *ngIf="errorstatus == ''">
                              </div>
                           </span>
                        </div>
                     </div>
                  </div>
               <button (click)="updateVerification()" type="submit" class="btn btn-outline" trans="Update Image">Update Image</button>
               </form>
            </div>
         </div>
      </div>
   </div>
</div>