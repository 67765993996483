import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DatatableService} from '../datatable.service';
import {Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
declare var $;

@Component({
  selector: 'app-datatable-footer-new',
  templateUrl: './datatable-footer-new.component.html',
  styleUrls: ['./datatable-footer-new.component.css'] ,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatatableFooterNewComponent implements OnInit {
  public perPageControl = new FormControl(15);
  public from: number;
  public to: number;
  public last_page: number;
  public totalRecords: number;
  public havePrevious: boolean;
  public haveNext: boolean;
  private changeRef: Subscription;
  public current_page:number;
  public perPage;
  public pages = [];
  p: number = 10;

  @Input()
  public set gettotalRecords(val){
    this.totalRecords = val;
  }

  constructor(
  	    public datable: DatatableService<any>,
        private cd: ChangeDetectorRef,
        ) { }

  ngOnInit(): void {
    this.changeRef = this.datable.paginator.response$
    .pipe(filter(r => !!r?.pagination), map(r => r.pagination))
    .subscribe(pagination => {
        this.resetPagination();
        this.to = pagination.per_page * pagination.current_page;
        this.perPage = pagination.per_page;
        this.from = (this.to - pagination.per_page) || 1;
        this.totalRecords = pagination.total;
        this.havePrevious = pagination.current_page > 1;
        this.haveNext = pagination.current_page < pagination.last_page;
        this.last_page = pagination.last_page;
        this.current_page = pagination.current_page;
        this.cd.markForCheck();
        this.setPages();


    if(this.havePrevious == false){
      $('.previous').css('display','none');
    }else{
      $('.previous').css('display','inline');
    }
    
    if(this.haveNext == false){
      $('.next').css('display','none'); 
    }else{
      $('.next').css('display','inline'); 
    }
    });

    this.perPageControl.valueChanges
    .subscribe(perPage => {
        this.datable.paginator.changePerPage(perPage);
    });
  }
   
   public resetPagination(){
    this.pages = [];
   }


      public setPages(){
      let numberOfPages = Math.ceil(this.totalRecords / this.perPage);
      for (let i = 1; i <= numberOfPages; i++) {
        this.pages.push(i);
      }
    }

  ngOnDestroy() {
    if (this.changeRef)  {
    this.changeRef.unsubscribe();
    }
      this.datable.destroy();
  }

    public previousPage() {
        this.datable.paginator.previousPage();
    }

    public nextPage() {
        this.datable.paginator.nextPage();
    }

    public numSequence(lastNumber:number){
      return new Array(lastNumber);
    }

    public checkCurrentPage(page){
      if(page==this.current_page){
        return true;
      }
    }

    public goToPage(page){
         this.current_page = page;
         this.datable.paginator.gotoPage(page);
    }

    public displayedPages(){
            // if currentPage is page 1
      if (this.current_page === 1) {
        return this.pages.slice(this.current_page - 1, this.current_page + 4);
      }
      // if current_page is the last page
      else if (this.current_page === this.pages.length) {
        return this.pages.slice(this.current_page - 5, this.current_page + 1);
      }
      // if current_page is between 4-7
      else if (this.current_page >= 4 && this.current_page <= 7) {
        return this.pages.slice(this.current_page - 2, this.current_page + 1);
      }
      // if current_page more than 7
      else if (this.current_page > 7) {
        return this.pages.slice(this.current_page - 4, this.current_page + 1);
      }
      // if current_page less than 4
      else {
        return this.pages.slice(this.current_page - 2, this.current_page + 3);
      }
    }

}
