import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';


@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
public login()
{
  this.router.navigate(['/join-as-tutor'], { replaceUrl: true });
}
public tutorRegistration(){
  this.router.navigate(['/join-as-tutor'], { replaceUrl: true });
}
}
