<footer *ngIf="!PWAStatus">
    <div class="footer-top text-center">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-12 text-center">
                    <img routerLink="/online-tutoring" src="assets/images/logo_white.png" alt="" style="width:50%;">
                    <p>My Tutor Platform is an online educational technology platform that connects Tutors to Students to help learn and grow their skills and knowledge.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row terms-conditions">
            <div class="col-12 col-lg-6">
                <div class=" text-center">
                    <p class="mb-0">Copyright@{{currentYear}}. All rights Reserved</p>
                </div>
            </div>
            <div class="col-12 col-lg-6">
<div class="privacy-policy">
<a href="privacy-policy" class="footer_links">Privacy Policy</a>
<a href="terms-conditions" class="footer_links">Terms Of Use</a>
</div>

            </div>
        </div>
    </div>
</footer>