import { Component, OnInit, OnDestroy } from '@angular/core';
import { CurrentUser } from './auth/current-user';
import { Router } from '@angular/router';
import { fromEvent, Observable, Subscription, interval, timer } from 'rxjs';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { BackendErrorResponse } from './core/types/backend-error-response';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})
export class AppComponent implements OnInit, OnDestroy {
  title = 'angular-video';


  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;
  httpResponse: any;


  constructor(
    public currentUser: CurrentUser,
    private router: Router,
    private location: Location,
    private http: HttpClient,
  ) {
  }


  ngOnInit() {
    
    /**
    * Get the online/offline status from browser window
    */
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      console.log('Online...');
      this.location.back();
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      if (this.connectionStatus == 'offline') {
        this.router.navigate(['errors']);

      }
    }));
    

  }

  ngOnDestroy() {

  }
}
