import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Currency} from '../core/types/models/Currency';

@Injectable({
    providedIn: 'root'
})
export class Currencies {
	public currencies$ = new BehaviorSubject<Partial<Currency[]>>([]);

	 public init(params) {
        this.currencies$.next(params);
    }

    public currencies(){
    	return this.currencies$.value;
    }

}