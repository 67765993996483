<div>
    <div class="well well-sm teacher_head">
        <h4 trans=" Paypal Subscription" style="color: black;">
            Paypal Subscription
        </h4>
    </div>

    <div class='content-container customTabs'>
        <div class="row formspace">
            <div class="col-sm-12 mb-50">
                <table class="table lesson-table bottomSec">
                    <tbody>
                        <tr>
                            <th>Plan</th>
                            <th>Product</th>
                            <th>Type</th>
                            <th>Description</th>
                            <th>Status</th>
                        </tr>
                        <tr *ng-for="let items of data">
                            <td>{{items.plan}}</td>
                            <td>{{items.product}}</td>
                            <td>{{items.type}}</td>
                            <td>{{items.description</td>
                            <td>
                                <button>
                                    <select name="status" >
                                        <option value="Active">Active</option>
                                        <option value="In-Active">In-Active</option>
                                    </select>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
