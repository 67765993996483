<header class="section header-area">
   <div id="appo-header" class="main-header-area main-header-area_bacground_color">
      <div class="container-fluid">
         <nav class="navbar navbar-expand-md navbar-light" >
            <!-- Logo -->
            <a class="navbar-brand mobile_res">
            <img routerLink="/online-tutoring" id="logo" class="logo navLogo" src="assets/images/logo_white.png">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#appo-menu">
            <span class="navbar-toggler-icon"></span>
            </button>
            <!-- Appo Menu -->
            <div class="collapse navbar-collapse" id="appo-menu">
               <!-- Header Items -->
               <ul class="navbar-nav header-items ml-auto appo-menu_padding">
                  <li class="nav-item">
                     <a routerLink="/online-tutoring" class="nav-link scroll" trans="HOME">HOME </a>
                  </li>
                  <li class="nav-item">
                     <a routerLink="/student/find-tutor"  class="nav-link scroll cursor" trans="Need a Tutor?">Need a Tutor?</a>
                  </li>
                   <li *ngIf="currentUser.isLoggedIn()" class="nav-item">
                     <a (click)="dashboard()" class="nav-link scroll cursor" trans="Dashboard">Dashboard </a>
                  </li>
                  <li *ngIf="!currentUser.isLoggedIn()" class="nav-item ">
                     <a routerLink="/join-as-tutor" class="nav-link scroll" trans="Join as Tutor">Join as Tutor </a>
                  </li>
                  <li *ngIf="!currentUser.isLoggedIn()" class="nav-item">
                     <a class="nav-link scroll cursor" data-toggle="modal" data-target="#loginModal" trans="Login">Login </a>
                  </li>
               </ul>
            </div>
         </nav>
      </div>
   </div>
</header>

<app-login></app-login>
