import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LanguageLevel} from '../core/types/models/LangaugeLevel';

@Injectable({
    providedIn: 'root'
})
export class LanguageLevels {
	public languageLevels$ = new BehaviorSubject<Partial<LanguageLevel[]>>([]);

	 public init(params) {
        this.languageLevels$.next(params);
    }

    public languageLevels(){
    	return this.languageLevels$.value;
    }

}