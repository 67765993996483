<section id="tutorDashboard" class="pageSectionBg">
   <div id="menuwrapper" class="sidebar d-flex">
      <ul id="sidemenu" class="tt-sideArea">
         <li routerLink="home" routerLinkActive="active" title="DASHBOARD">
          <a class="tt-sidelayer">
            <i class="fa fa-home" aria-hidden="true"></i>
            <span trans="DASHBOARD" class="menuText">DASHBOARD</span>
          </a>
         </li>
         <li *ngIf="currentuser.hasPermissions(['account.access'])" routerLink="account" routerLinkActive="active" title="ACCOUNT">
           <a class="tt-sidelayer">
            <i class="fa fa-user" aria-hidden="true"></i>
            <span trans="ACCOUNT">ACCOUNT</span>
           </a>
         </li>
         <li *ngIf="currentuser.hasPermissions(['teachers.access'])" routerLink="tutors" routerLinkActive="active" title="TUTORS">
          <a class="tt-sidelayer">
            <i class="fa fa-male" aria-hidden="true"></i>
            <span trans="TUTORS">TUTORS</span>
          </a>
         </li>
         <li *ngIf="currentuser.hasPermissions(['students.access'])" routerLink="students" routerLinkActive="active" title="STUDENTS">
          <a class="tt-sidelayer">
            <i class="fa fa-graduation-cap" aria-hidden="true"></i>
            <span trans="STUDENTS">STUDENTS</span>
          </a>
         </li>
         <li *ngIf="currentuser.hasPermissions(['inbox.access'])" routerLink="chat"  routerLinkActive="active" title="CHATBOX">
          <a class="tt-sidelayer">
            <i class="fa fa-comments-o" aria-hidden="true"></i>
            <span trans="CHATBOX">CHATBOX</span>
          </a>
         </li>
         <li *ngIf="currentuser.hasPermissions(['lessons.access'])" routerLink="lessons" routerLinkActive="active" title="LESSONS">
          <a class="tt-sidelayer">
            <i class="fa fa-calendar" aria-hidden="true"></i>
            <span trans="LESSONS">LESSONS</span>
          </a>
         </li>
         <li *ngIf="currentuser.hasPermissions(['finance.access'])" routerLink="finance" routerLinkActive="active" title="TRANSACTIONS">
          <a class="tt-sidelayer">
            <i class="fa fa-usd" aria-hidden="true"></i>
            <span trans="TRANSACTIONS">TRANSACTIONS</span>
          </a>
         </li>
         <li *ngIf="currentuser.hasPermissions(['templates.access'])" routerLink="templates" routerLinkActive="active" title="TEMPLATES">
          <a class="tt-sidelayer">
            <i class="fa fa-file-text-o" aria-hidden="true"></i>
            <span>TEMPLATES</span>
          </a>
         </li>
         <li routerLinkActive="active" routerLink="alerts" id="alerts" title="ALERTS">
           <a class="alertsModule">
              <i class="fa fa-bell" aria-hidden="true"></i>
              <span trans="ALERTS">ALERTS</span>
           </a>
         </li>
         <li *ngIf="currentuser.hasPermissions(['users.view'])" routerLink="users" routerLinkActive="active" title="USERS">
            <a class="noflyout">         
            <i class="fa fa-users" aria-hidden="true"></i>
            <span trans="USERS">USERS</span>
            </a>
         </li>
         <li *ngIf="currentuser.hasPermissions(['roles.view'])" routerLink="roles" routerLinkActive="active" title="ROLES">
            <a class="noflyout">
            <i class="fa fa-cogs" aria-hidden="true"></i>
            <span trans="ROLES" >ROLES</span>
            </a>
         </li>
      </ul>
   </div>
</section>