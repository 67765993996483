<div class="contact-box">
   <form id="contact-form" [formGroup]="photoForm">
      <div class="row">
         <div class="col-12 col-md-12">
            <p class="tabs-below-text-profile-photo" trans="Upload your profile photo and video link">Upload your
               profile photo and video link</p>
         </div>
      </div>
      <div class="row">
         <div class="col-12 col-md-6">
            <div class="form-group">
               <label trans=" Profile Photo">
                  Profile Photo
               </label>
               <div class="img-space">
                  <ng-container *ngIf="isImageSaved; else elseTemplate">
                     <img class="img-responsive" [src]="cardImageBase64" class="Tutors_profile_img" />
                  </ng-container>
                  <ng-template #elseTemplate>
                     <img src="assets/images/dummy.png" class="Tutors_profile_img">
                  </ng-template>
               </div>
               <div class="rmwbtn_profile">
                  <label for="selectbasic" class="pull-right">*PNG,JPG,JPEG</label>
                  <div id="removeImage">
                     <a class="btn btn-danger" (click)="removeImage()" *ngIf="isImageSaved"><span
                           trans="Remove">Remove</span></a>
                  </div>
                  <span>
                     <input formControlName="profile_photo" class="form-control"
                        accept="image/x-png,image/gif,image/jpeg" (change)="fileChangeEvent($event,'uplodfile')"
                        id="uplodfile" type="file">
                     <div class="error-status" *ngIf="errorstatus =='invalid'"><small>{{imageError}}</small></div>
                     <div *ngIf="errorstatus == ''">
                        <div *ngFor="let error of errorMessages.profile_photo">
                           <ng-container *ngIf="profile_photo.hasError(error.type) && (profile_photo.touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </span>
               </div>
            </div>
         </div>
         <!-- <div class="col-12 col-md-6">
            <div class="form-group">

               <div class="img-space">
                  <ng-container *ngIf="isIdProofImageSaved; else elseTemplate">
                     <img class="img-responsive" [src]="cardImageBase64IdProof" class="Tutors_profile_img" />
                  </ng-container>
                  <ng-template #elseTemplate>
                     <img src="assets/images/dummy.png"  class="Tutors_profile_img">
                  </ng-template>
               </div>
               <div class="rmwbtn_profile">
                  <label  for="selectbasic" class="pull-right">*PNG,JPG,JPEG</label>
                  <div id="removeImageIdProof">
                     <a class="btn btn-danger" (click)="removeIdProofImage()" *ngIf="isIdProofImageSaved">Remove</a>
                  </div>
                  <span>
                     <input formControlName="id_proof" class="form-control" accept="image/x-png,image/gif,image/jpeg" (change)="fileChangeEvent($event,'idProofFile')" id="idProofFile" type="file">
                     <div class="error-status" *ngIf="errorstatus =='invalid'"><small>{{imageError}}</small></div>
                     <div *ngIf="errorstatus == ''">
                        <div *ngFor= "let error of errorMessages.id_proof">
                           <ng-container *ngIf="id_proof.hasError(error.type) && (id_proof.touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </span>
               </div>
            </div>
         </div> -->
      </div>
      <div class="row">
         <div class="col-md-6 form-group">
            <!-- <p trans="Paste a link of your video">
               Paste a link of your video
            </p> -->
            <p class="fs-sub-link">
               <span trans="Learn how to upload to">Learn how to upload to </span><a
                  href="/blog/How-To-Record-Profile-Video">Youtube</a> or 
                  <a href="/blog/How-To-Record-Profile-Video">video</a><br />
                  <!-- <a href="/blog/How-To-Record-Profile-Video">Vimeo</a><br /> -->
            </p>
            <input (input)="onSearchChange($event.target.value)" formControlName="video_URL" type="text"
               class="form-control" placeholder="https://www.youtube.com/watch?v=-lNfZOpIbg8 *">
            <div class="error-message"
               *ngIf="(video_URL.touched)&& (status=='invalid')||(video_URL.touched)&&(status=='failed')">
               <small>{{error_message}}</small></div>
            <div *ngFor="let error of errorMessages.video_URL">
               <ng-container *ngIf="video_URL.hasError(error.type) && (video_URL.touched)">
                  <small class="error-message" *ngIf="status ==''">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
         <div *ngIf="liveIFrame" class="col-md-6 ">
            <div class="videoContainer">
               <iframe width="250" height="200" class="responsive-iframe" [src]="liveIFrame"></iframe>
            </div>
         </div>
      </div>
      <div *ngIf=photoForm.valid?photoFormComplete():false>
      </div>
      <div *ngIf=!photoForm.valid?photoFormInComplete():false>
      </div>
   </form>
</div>