import { Component, OnInit, ViewChild , ElementRef } from '@angular/core';
import { Currencies } from '@auth/currencies';
import { LocaleCurrency } from '@auth/localecurrency';
import { CurrentUser } from '@auth/current-user';
import { Echo } from 'laravel-echo-ionic';
import { Router } from '@angular/router';
import { LessonsService } from '../lessons/lessons.service';
import { Bootstrapper } from '@core/bootstrapper.service';
import { LocalizationWithLines } from '@core/types/localization-with-lines';
import { Localizations } from '@core/translations/localizations.service';
import { distinctUntilChanged, finalize } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { Translations } from '@core/translations/translations.service';
import { BehaviorSubject, Observable } from 'rxjs';
declare var $;

@Component({
  selector: 'app-student-sidenav',
  templateUrl: './student-sidenav.component.html',
  styleUrls: ['./student-sidenav.component.css'],
  host: {
    "(window:click)": "onClick()"
  }
})
export class StudentSidenavComponent implements OnInit {
  @ViewChild('closeSuccessModal') closeSuccessModal: ElementRef;
  @ViewChild('alertModal') alertModal: ElementRef;
  public loading$ = new BehaviorSubject<boolean>(false);
  echo: any = null;
  public localeCurrency;
  public popularCurrencies;
  public notifications = new Array();
  public usernotifications;
  public usernotifications_read;
  public usernotifications_read_count;
  public usernotifications_unread;
  public usernotifications_unread_count;
  public usermessages_unread_count;
  public host_socket;
  public alertMessage;
  public firstPageRoute;
  public user;
  public isMenuOpen = false;
  public menuActive : any='';
  public languages$ = new BehaviorSubject<LocalizationWithLines[]>([]);
  constructor(protected injector: Injector,
              public currencies: Currencies,
  	          public localecurrency: LocaleCurrency,
              public currentuser: CurrentUser,
              private router: Router,
              public service: LessonsService,
              private bootstrapper: Bootstrapper,
              private localizationsApi: Localizations,
              protected i18n: Translations,
              public myservice: Bootstrapper) {
        this.i18n = this.injector.get(Translations);
        this.user = this.injector.get(CurrentUser);
        this.host_socket = this.bootstrapper.data.host_socket;

        // this.echo = new Echo({
        //     broadcaster: 'socket.io',
        //     host: this.host_socket,
        // });

        // this.echo.channel('laravel_database_private-user.'+this.currentuser.userId())
        //     .listen('UserEvent', (data) => {
        //    if(data['to_type'] != "App\\ChatModels\\Conversation"){
        //     this.alertMessage = data;
        //     this.alertModal.nativeElement.click();
        //    }
        // });
        

  this.localecurrency.changelocaleCurrency$.subscribe(changeCurrency => this.changeCurrency(changeCurrency));
  this.user.isLoggedIn$.subscribe(val =>{
    this.getNotifications();
  });
 }

  ngOnInit(): void {
       this.service.getFirstMoreOrder().subscribe(response=>{
              let data = response['data'];
              this.firstPageRoute = data['categoryFirst'];
       });

    this.localizationsApi.all()
                .pipe(finalize(() => this.loading$.next(false)))
                .subscribe(response => {
                this.setLocalizations(response.localizations);
                });

      $(".multiselect-dropdown").click(function(){
          $(".container.CC-main").addClass("overlay-add");
      });

$(document).on('click', function (event) {
  if (!$(event.target).closest('#alerts').length) {
          $("#alert-box-alerts").removeClass("activeAlert");
          $("#alerts").removeClass("active");
     }
});


  	this.localeCurrency = this.localecurrency.localeCurrencyCode();
  	this.popularCurrencies = this.currencies.currencies();
    this.getNotifications();

  }
  private setLocalizations(localizations: LocalizationWithLines[]) { 
    this.languages$.next(localizations);
    }

    public removeAlert(){
        if($("#alert-box-alerts").hasClass("activeAlert")){
          $("#alert-box-alerts").removeClass("activeAlert");
          $("#alerts").removeClass("active");
        }
        this.getNotifications();
    }

  public selectedCurrency(value){
    if(this.currentuser.isLoggedIn$){
    let currency = Object();
    Object.assign(currency , {default_currency:value});
    this.service.updateCurrency(currency).subscribe(response =>{
      if(response.status == 'success'){
        this.currentuser.model$.value['default_currency'] = value;
        this.changeFrontentCurreny(value);
      }
    });
   }
  }

  public changeFrontentCurreny(value){
    this.popularCurrencies.find(x => {
      if(x.code == value){
         return this.localecurrency.init(x);
      }
    });
  }

  public more(){
    if(this.firstPageRoute){
    this.router.navigate(['student/more/'+this.firstPageRoute]);
    }else{
       this.router.navigate(['more']);
    }
  }

  public LandingPage(){
    this.router.navigate(['online-tutoring']);
  }

  public getNotifications(){
    
    if(this.currentuser.isRegistrationCompleted()== true){
      this.service.getNotification().subscribe(response=>{
        let data =JSON.parse(atob(response['notifications']));
        
        this.usernotifications = data['notifications'];
        this.usernotifications_read = this.usernotifications.is_read;
        this.usernotifications_read_count = this.usernotifications.is_read.length;
        this.usernotifications_unread = this.usernotifications.is_unread;
        this.usernotifications_unread_count = this.usernotifications.is_unread.length;
        this.usermessages_unread_count = data['inboxCount'];
      });
    } else {
      this.usermessages_unread_count = 0;
      this.usernotifications_unread_count = 0;
    }
  }


// alerts
  public openAlerts(){
        if($("#alert-box-alerts").hasClass("activeAlert")){
          $("#alert-box-alerts").removeClass("activeAlert");
          $("#alerts").removeClass("active");
        }else{
          $("#alert-box-alerts").addClass("activeAlert");
          $("#alerts").addClass("active");
        }
        this.getNotifications();
  }

    private selectLang(langId){
      
      localStorage.setItem('defaultLanguage' , langId);
      
      this.myservice.changeLanguage(langId);
    }

     public viewSchedules(){
        this.closeSuccessModal.nativeElement.click();
        this.router.navigate(['student/lessons/scheduled/sch']);
     }
public validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  }

  public viewAlert(notification){

    (notification.redirect_url && this.validateUrl(notification.redirect_url)) ? this.router.navigate([notification.redirect_url]) : this.router.navigate(['student/lessons/scheduled/sch']);

   if(notification.is_read == 0){
        this.service.readNotification(notification.id).subscribe(response=>{
        this.usernotifications =JSON.parse(atob(response['notifications']));
        this.usernotifications_read = this.usernotifications.is_read;
        // this.usernotifications_read_count = this.usernotifications.is_read.length;
        this.usernotifications_read_count = (this.usernotifications.is_read) ? this.usernotifications.is_read.length : 0;

        this.usernotifications_unread = this.usernotifications.is_unread;
        this.usernotifications_unread_count = this.usernotifications.is_unread.length;
      });
      }
   this.removeAlert();
  }

  public changeCurrency(currency){
     this.localeCurrency = currency.code;
    $("#currencyDropDown").val(currency.code);
  }

  public moreMobile(){
  this.router.navigate(['/student/more-mobile']);
  this.menuActive = 'more';
}

 // public moreOption(){
 //  if($('.dropup').hasClass('open')){
 //     $('.dropup').removeClass('open')
 //  }else{
 //     $('.dropup').addClass('open')
 //  }
 // }

 public toggleMenu($event){
  $event.stopPropagation();
  this.isMenuOpen = !this.isMenuOpen;
  this.menuActive = 'more';
 }

 onClick(){
   this.isMenuOpen = false;
 }


public humanize(str) {
  var i, frags = str.split('_');
  for (i=0; i<frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

public displayTextLimit(text) {
  if ( text.length > 150 ) {
    return text.substring(0, 150) + '...';
  } else {
    return text;
  }
}

public menuSelect(id:any){
  this.menuActive = id;

}

}
