import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Team} from '../core/types/models/TeamMembers';

@Injectable({
    providedIn: 'root'
})
export class TeamMembers {
	public teamMembers$ = new BehaviorSubject<Partial<Team[]>>([]);

	 public init(params) {
        this.teamMembers$.next(params);
    }

    public team(){
    	return this.teamMembers$.value;
    }

}