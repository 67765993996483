import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TeachingLevel} from '../core/types/models/TeachingLevels';

@Injectable({
    providedIn: 'root'
})
export class TeachingLevels {
	public teachingLevels$ = new BehaviorSubject<Partial<TeachingLevels[]>>([]);

	 public init(params) {
        this.teachingLevels$.next(params);
    }

    public subjects(){
    	return this.teachingLevels$.value;
    }

}