import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouteConstantsService {

	/*
	STUDENT Module ALL Route Constants
	*/

	    /*Web Routes*/
	public static STUDENT: string = 'student/';
	public static TUTORS_PAGE: string = RouteConstantsService.STUDENT + 'find-tutor';
	public static TUTOR_PROFILE: string = 'profile';

	    /*Mobile Routes*/
	public static TUTORS_MOBILE: string = 'm_tutors'; 


  constructor() { }
}
