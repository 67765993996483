import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.css']
})
export class DescriptionComponent implements OnInit {
   
   @Output() resumeFormData: EventEmitter<any> = new EventEmitter();
   get display_name(){ return this.resumeForm.get('display_name');}
   get description(){ return this.resumeForm.get('description');}
   get highlight(){ return this.resumeForm.get('highlight');}
   isImageSaved: boolean;
   cardImageBase64: string;
   public resumeForm = this.fb.group({
     display_name: ['',Validators.required],
     description:   ['',[ Validators.required,
                        Validators.minLength(80), 
                        Validators.maxLength(500)]],
     highlight:   ['',[ Validators.required,
                        Validators.minLength(50), 
                        Validators.maxLength(500)]],                   
    });
   public errorMessages = {
        display_name : [{ type:'required',message:'Name is Required'}],
        description  : [{ type:'required' ,message:'Description is Required'},
                         { type:'minlength',message:'Minimum 80 Characters Required'},
                         { type:'maxlength',message:'Only 500 Characters'}],
        highlight    : [{ type:'required' ,message:'Highlight is Required'},
                         { type:'minlength',message:'Minimum 50 Characters Required'},
                         { type:'maxlength',message:'Only 500 Characters'}],
   }
   public givenChracters:number = 0;
   public givenHighlightChracters:number = 0;
   public givenDisplayName:number = 0;


  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
  }

  public countCharacters(values){
   let charactersEntered = values;
   this.givenChracters = charactersEntered.length; 
  }

   public countHighlightCharacters(values){
   let charactersEntered = values;
   this.givenHighlightChracters = charactersEntered.length;
  }

  public resumeFormComplete(){
    this.resumeForm.value.formFilled = 1;
    this.resumeFormData.emit(this.resumeForm.value);
  }

  public resumeFormInComplete(){
    this.resumeForm.value.formFilled = 0;
    this.resumeFormData.emit(this.resumeForm.value);
  }

  public userPhoto(image){
    this.isImageSaved = true;
    this.cardImageBase64 = image;
  }

  public validate(){
    this.resumeForm.markAllAsTouched();
  }

  public countDisplayName(values){
   let charactersEntered = values;
   this.givenDisplayName = charactersEntered.length;
  }

}
