<div class="m-auto row">
   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <form [formGroup]="paymentMethods">
         <div *ngIf="payment_methods.length > 0">
         <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h4 class="TT-AccSettings" *ngIf="role == 'Student'">Payment Methods</h4>
            <h4 class="TT-AccSettings" *ngIf="role == 'Tutor'">Payment Method</h4>
            <p *ngIf="role == 'Tutor'">Credit card to be charged for monthly subscription payments to My Tutor Platform</p>
         </div>

         <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <p></p>
         </div>

         <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <ul class="d-flex">
               <li *ngFor="let request of payment_methods" class="TT-cardDetails">
                  <i class="fa fa-credit-card" aria-hidden="true"></i> {{request.card_mask}}, &nbsp;
                  <lable class="TT-expiryDetailsCard">[{{request.expiry_date}}]</lable>
                  <span class="TT-textPrimary" *ngIf="request.is_default == 1">
                     &nbsp;&nbsp;&nbsp;&nbsp;Primary
                  </span>
                  <span class="TT-textPrimary" *ngIf="request.is_default == 0">
                     &nbsp;&nbsp;&nbsp;&nbsp;<span (click)="makePrimary(request.id)" trans="Make Primary">Make
                        Primary</span>
                  </span>
                  <span class="TT-highlighterTexting" (click)="Delete(request.id)">
                     &nbsp;&nbsp;&nbsp;&nbsp;[Remove]
                  </span>
               </li>
            </ul>
         </div>
      </div>

         <div *ngIf="role == 'Tutor' && payment_methods.length == 0">
         <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h4 class="TT-AccSettings" *ngIf="role == 'Student'">Add Payment Methods</h4>
            <h4 class="TT-AccSettings" *ngIf="role == 'Tutor'">Add Payment Method</h4>
            <p *ngIf="role == 'Tutor'">Add credit card to be charged for monthly subscription payments to My Tutor Platform</p>
         </div>

         <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div class="form-group TT-formGroup">
               <label for="card_number" trans="Card Number" class="TT-lable">Card Number</label>
               <input maxlength="16" pattern="\d{16}" (keydown)="validateNumber($event)" formControlName="card_number"
                  class="TT-FieldGroup" placeholder="0000000000000000" />
               <div *ngFor="let error of errorMessages.card_number">
                  <ng-container *ngIf="card_number.hasError(error.type) && (card_number.dirty || card_number.touched)">
                     <small class="TT-highlighterTexting">{{error.message}}</small>
                  </ng-container>
               </div>
            </div>
         </div>

         <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div class="form-group TT-formGroup">
               <label trans="Month" class="TT-lable">Month</label>
               <select class="TT-FieldGroup TT-SelectIcon" formControlName="month">
                  <option value='' trans="Select Month">Select Month</option>
                  <option value='01' trans="Janaury">Janaury</option>
                  <option value='02' trans="February">February</option>
                  <option value='03' trans="March">March</option>
                  <option value='04' trans="April">April</option>
                  <option value='05' trans="May">May</option>
                  <option value='06' trans="June">June</option>
                  <option value='07' trans="July">July</option>
                  <option value='08' trans="August">August</option>
                  <option value='09' trans="September">September</option>
                  <option value='10' trans="October">October</option>
                  <option value='11' trans="November">November</option>
                  <option value='12' trans="December">December</option>
               </select>
               <div *ngFor="let error of errorMessages.month">
                  <ng-container *ngIf="month.hasError(error.type) && (month.dirty || month.touched)">
                     <small class="TT-highlighterTexting">{{error.message}}</small>
                  </ng-container>
               </div>
            </div>
         </div>

         <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div class="form-group TT-formGroup">
               <label trans="Year" for="year" class="TT-lable">Year</label>
               <select class="TT-FieldGroup TT-SelectIcon" formControlName="year">
                  <option value='' trans="Select Year">Select Year</option>
                  <option *ngFor="let year of years" value="{{year}}">{{year}}</option>
               </select>
               <div *ngFor="let error of errorMessages.year">
                  <ng-container *ngIf="year.hasError(error.type) && (year.dirty || year.touched)">
                     <small class="TT-highlighterTexting">{{error.message}}</small>
                  </ng-container>
               </div>
            </div>
         </div>

         <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div class="form-group TT-formGroup">
               <label for="security_code" trans="Security Code" class="TT-lable">Security Code</label>
               <input maxlength="3" pattern="\d{3}" formControlName="security_code" class="TT-FieldGroup" placeholder="CVV" trans-placeholder="CVV" />
                  <div *ngFor="let error of errorMessages.security_code">
                     <ng-container
                     *ngIf="security_code.hasError(error.type) && (security_code.dirty || security_code.touched)">
                     <small class="TT-highlighterTexting">{{error.message}}</small>
                  </ng-container>
               </div>
               <span class="TT-qotation" trans="(3 digits displayed behind the card)">
                  (&nbsp;&nbsp; 3 digits displayed behind the card &nbsp;&nbsp;)
               </span>
            </div>
         </div>

         <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <span class="TT-paraText">
               You agree to authorize the use of the Credit Card for payments on the platform which are processed
               securely by 
            </span>&nbsp;&nbsp;<i class="fa fa-cc-stripe fa-2x" aria-hidden="true"></i>
         </div>
         
         <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div class="form-group TT-formGroup clearfix">
               <button (click)="updatePaymentMethods()" type="submit" trans="Add" class="btn TT-primaryBTN TT-autoWidth TT-topZero pull-right">Add</button>
            </div>
         </div>
      </div>

      <div *ngIf="role == 'Student'">
         <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h4 class="TT-AccSettings" *ngIf="role == 'Student'">Add Payment Methods</h4>
            <h4 class="TT-AccSettings" *ngIf="role == 'Tutor'">Add Payment Method</h4>
            <p *ngIf="role == 'Tutor'">Add credit card to be charged for monthly subscription payments to My Tutor Platform</p>
         </div>

         <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div class="form-group TT-formGroup">
               <label for="card_number" trans="Card Number" class="TT-lable">Card Number</label>
               <input maxlength="16" pattern="\d{16}" (keydown)="validateNumber($event)" formControlName="card_number"
                  class="TT-FieldGroup" placeholder="0000000000000000" />
               <div *ngFor="let error of errorMessages.card_number">
                  <ng-container *ngIf="card_number.hasError(error.type) && (card_number.dirty || card_number.touched)">
                     <small class="TT-highlighterTexting">{{error.message}}</small>
                  </ng-container>
               </div>
            </div>
         </div>

         <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div class="form-group TT-formGroup">
               <label trans="Month" class="TT-lable">Month</label>
               <select class="TT-FieldGroup TT-SelectIcon" formControlName="month">
                  <option value='' trans="Select Month">Select Month</option>
                  <option value='01' trans="Janaury">Janaury</option>
                  <option value='02' trans="February">February</option>
                  <option value='03' trans="March">March</option>
                  <option value='04' trans="April">April</option>
                  <option value='05' trans="May">May</option>
                  <option value='06' trans="June">June</option>
                  <option value='07' trans="July">July</option>
                  <option value='08' trans="August">August</option>
                  <option value='09' trans="September">September</option>
                  <option value='10' trans="October">October</option>
                  <option value='11' trans="November">November</option>
                  <option value='12' trans="December">December</option>
               </select>
               <div *ngFor="let error of errorMessages.month">
                  <ng-container *ngIf="month.hasError(error.type) && (month.dirty || month.touched)">
                     <small class="TT-highlighterTexting">{{error.message}}</small>
                  </ng-container>
               </div>
            </div>
         </div>

         <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div class="form-group TT-formGroup">
               <label trans="Year" for="year" class="TT-lable">Year</label>
               <select class="TT-FieldGroup TT-SelectIcon" formControlName="year">
                  <option value='' trans="Select Year">Select Year</option>
                  <option *ngFor="let year of years" value="{{year}}">{{year}}</option>
               </select>
               <div *ngFor="let error of errorMessages.year">
                  <ng-container *ngIf="year.hasError(error.type) && (year.dirty || year.touched)">
                     <small class="TT-highlighterTexting">{{error.message}}</small>
                  </ng-container>
               </div>
            </div>
         </div>

         <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
           <div class="form-group TT-formGroup">
             <label for="security_code" trans="Security Code" class="TT-lable">Security Code</label>
               <input maxlength="3" pattern="\d{3}" formControlName="security_code" class="TT-FieldGroup" placeholder="CVV" trans-placeholder="CVV" />
                <div *ngFor="let error of errorMessages.security_code">
                   <ng-container
                     *ngIf="security_code.hasError(error.type) && (security_code.dirty || security_code.touched)">
                     <small class="TT-highlighterTexting">{{error.message}}</small>
                  </ng-container>
               </div>
               <span class="TT-qotation" trans="(3 digits displayed behind the card)">
                  (&nbsp;&nbsp; 3 digits displayed behind the card &nbsp;&nbsp;)
               </span>
            </div>
         </div>

         <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <span class="TT-paraText">
               You agree to authorize the use of the Credit Card for payments on the platform which are processed
               securely by 
            </span>&nbsp;&nbsp;<i class="fa fa-cc-stripe fa-2x" aria-hidden="true"></i>
         </div>
         
         <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div class="form-group TT-formGroup clearfix">
               <button (click)="updatePaymentMethods()" type="submit" trans="Add" class="btn TT-primaryBTN TT-autoWidth TT-topZero pull-right">Add</button>
            </div>
         </div>
      </div>

      </form>
   </div>
</div>