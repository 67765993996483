import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from '@athand/profile/profile.service';


@Component({
  selector: 'app-paypalsubscription',
  templateUrl: './paypalsubscription.component.html',
  styleUrls: ['./paypalsubscription.component.css']
})
export class PaypalsubscriptionComponent implements OnInit {
public data;
  constructor(
    public router: Router,
    private profileService: ProfileService,
  ) {

   }

  ngOnInit(): void {
    this.profileService.paypalsubscription().subscribe(response => {})
  }

}
