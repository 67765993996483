<div class='col-sm-9 rightCol'>
    <div class="well well-sm chat-head">
        <div class="row">
            <div class="col-sm-12">
                <h4 class="title" trans="Notifications Settings">
                    Notifications Settings
                </h4>
            </div>
        </div>
    </div>

    <form  [formGroup]="notificationbSettings">
        <div class='content-container customTabs'>
            <div class="row formspace">

                <div class="col-sm-8">
                    <h5 class="form-subhead">
                        <b>

                        </b>
                    </h5>

                    <div class="row formspace">
                        <h5 trans="Email Notifications">
                            Email Notifications
                        </h5>
                        <br>
                        <div class="row col-md-12">
                            <div class="col-md-1 col-xs-2">
                                <label class="control control--checkbox">
                                    <input formControlName="email" type="checkbox" value="false">
                                        <div class="control__indicator">
                                        </div>
                                    </label>
                                </div>
                                <div class="col-md-10 col-xs-9">
                                   <span trans="Lesson Scheduling"> Lesson Scheduling</span>
                                    <p>
                                        <small class="greyColor" trans="Alerts about new lessons and schedule changes.">
                                            Alerts about new lessons and schedule changes.
                                        </small>
                                    </p>
                                </div>
                            </div>
                                    

                            <div class="row col-md-12">
                                <h5 trans="SMS Notifications">
                                    SMS Notifications
                                </h5>
                            </div>
                            <div class="row col-md-12">
                                <div class="col-md-1 col-xs-2">
                                    <label class="control control--checkbox">
                                        <input formControlName="lessons" type="checkbox"value="false">
                                            <div class="control__indicator">
                                            </div>
                                        </label>
                                    </div>
                                    <div class="col-md-10 col-xs-9">
                                        <span trans="Lesson and Messages">Lesson and Messages</span>
                                        <p>
                                            <small class="greyColor" trans="notfctn-text2">
                                                SMS Alerts about tutors first response to your requests
                                                and trail lesson remainders.
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                                
                                                
                                                    <div class="col-md-12 title3 mb-40">
                                                        <button (click)="updateNotificationSettings()" type="submit" class="btn btn-save sp_10" trans="Save Settings">
                                                            Save Settings
                                                        </button>
                                                        <button type="submit" class="btn btn-redoutline" (click)="removeNotificationSettings()" trans="Unsubscribe from all">
                                                            Unsubscribe from all
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

