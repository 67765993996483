<div class="contact-box">
      <form id="contact-form" [formGroup]="availabilityForm">
         <div class="row">
            <div class="col-12 col-md-12">
               <p class="tabs-below-text-availability" trans="Add your availability. Once you select click on plus(+) symbol to add.">Add your availability. Once you select click on plus(+) symbol to add.</p>
            </div>
         </div>
         <div class="col-md-6">
               <label for="defaulttimezone" trans="Time Zone" style="
               color: #666;">
               Time Zone
               </label>
            <div class="form-group">
               <select  formControlName="defaulttimezone"  (input)="timeZone($event.target.value)" class="form-control" id="gmtSelect">
                  <option value="" trans="Please Select">Please Select</option>
                  <option value="{{tmz.name}}" *ngFor="let tmz of offsetTmz">{{tmz.value}}</option>
               </select>
               
            </div>
            <div *ngFor= "let error of errorMessages.defaulttimezone">
               <ng-container *ngIf="defaulttimezone.hasError(error.type) && (defaulttimezone.dirty || defaulttimezone.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
         <table class="table noBorderTable CCnr-height">
            <thead>
               <p *ngIf="error" class="error-message">{{error}}</p>
               <tr>
                  <th class="col-md-4" trans="Day">Day</th>
                  <th class="col-md-4" trans="Start Time">Start Time</th>
                  <th class="col-md-4" trans="End Time">End Time</th>
                  
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td class="form-group">
                     <select (input)="selectAvailabilityDate()" id="selectAvailabilityDate" formControlName="day" class="form-control">
                        <option value="" trans="Please Select">Please Select</option>
                        <option value="Sunday" trans="Sunday">Sunday</option>
                        <option value="Monday" trans="Monday">Monday</option>
                        <option value="Tuesday" trans="Tuesday">Tuesday</option>
                        <option value="Wednesday" trans="Wednesday">Wednesday</option>
                        <option value="Thursday" trans="Thursday">Thursday</option>
                        <option value="Friday" trans="Friday">Friday</option>
                        <option value="Saturday" trans="Saturday">Saturday</option>
                     </select>
                     <div *ngFor= "let error of errorMessages.day">
                        <ng-container *ngIf="day.hasError(error.type) && (day.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </td>
                  <td class="form-group">
                     <select id="selectbasic" formControlName="start_time" class="form-control">
                        <option value="" trans="Please Select"> Please Select</option>
                        <option *ngFor="let time of timings" value="{{time}}">{{time}}</option>
                     </select>
                     <div *ngFor= "let error of errorMessages.start_time">
                        <ng-container *ngIf="start_time.hasError(error.type) && (start_time.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </td>
                  <td class="form-group">
                     <select id="selectbasic" formControlName="end_time" class="form-control">
                        <option value="" trans="Please Select"> Please Select</option>
                        <option *ngFor="let time of timings" value="{{time}}">{{time}}</option>
                     </select>
                     <div *ngFor= "let error of errorMessages.end_time">
                        <ng-container *ngIf="end_time.hasError(error.type) && (end_time.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </td>
                  <td class="addRow">
                     <i (click)="addRow()" class="fa fa-plus-circle" aria-hidden="true" style="font-size: 20px !important;
                     padding-top: 15px;"></i>
                  </td>
               </tr>
               <tr *ngFor="let avail of availability">
                  <td>
                     <input disabled value="{{avail.day}}" class="form-control">
                  </td>
                  <td>
                     <input disabled value="{{avail.start_time}}" class="form-control">
                  </td>
                  <td>
                     <input disabled value=" {{avail.end_time}}" class="form-control">
                  </td>
                  <td class="delRow" (click)="deleteRow(avail)">
                     <i class="fa fa-times" aria-hidden="true"></i>
                  </td>
               </tr>
            </tbody>
         </table>
      </form>
   <div *ngIf=availability.length?availabilityFormComplete():false>
   </div>
   <div *ngIf=!availability.length?availabilityFormInComplete():false>
   </div>
</div>