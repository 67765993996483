import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {BackendErrorResponse} from '../../types/backend-error-response';
import {HttpErrorHandler} from '../../errors/http-error-handler.service';
import {Translations} from '../../translations/translations.service';
import {CurrentUser} from '@auth/current-user';
import {Settings} from '../../config/settings.service';
import {CommonMessages} from '../../ui/common-messages.enum';
import Swal from 'sweetalert2/src/sweetalert2.js'

interface Backend403Response extends BackendErrorResponse {
    showUpgradeButton: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class BackendHttpErrorHandler extends HttpErrorHandler {
    constructor(
        protected i18n: Translations,
        protected currentUser: CurrentUser,
        protected router: Router,
        protected zone: NgZone,
        protected settings: Settings,
    ) {
        super(i18n);
    }

    /**
     * Redirect user to login page or show toast informing
     * user that he does not have required permissions.
     */
    public handle403Error(response: BackendErrorResponse) {
            let message = (response.messages.message) ? response.messages.message : CommonMessages.NoPermissions;
        // if user doesn't have access, navigate to login page
        if (this.currentUser.isLoggedIn()) {
            Swal.fire('Oops...', message, 'error');
            this.currentUser.assignCurrent();
            this.router.navigate(['/login']);
        } else {
            Swal.fire('Oops...', message, 'error');
            this.router.navigate(['/login']);
        }
      }
    }