import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActiveSubjects } from '../../../auth/active-subjects';
import { StudentAges } from '../../../auth/studentAges';
import { TeachingLevels } from '../../../auth/teaching-levels';
import { TutorTeachingService } from '../../../tutor/teaching/teaching.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Settings } from '../../../core/config/settings.service';
import { ToastrService } from 'ngx-toastr';

interface TeachingLevel {
  level_name: string;
  student_age: string;
}


@Component({
  selector: 'app-subjectselection',
  templateUrl: './subjectselection.component.html',
  styleUrls: ['./subjectselection.component.css']
})
export class SubjectselectionComponent implements OnInit {

  public subjectSelectionForm = this.fb.group({
    subject_name: ['', Validators.required],
    level: ['', Validators.required],
    student_age: ['', Validators.required],
    price_per_hour: ['', Validators.required, Validators.pattern('^[0-9]$')]
  });
  @Output() subjectSelectionFormData: EventEmitter<any> = new EventEmitter();
  public teachingLevelArray;
  public studentAges;
  public subjects;
  public allowCustomSubjects;
  public maxSubjectsToTeach: number;
  public teachingLevels$ = new BehaviorSubject<TeachingLevel[]>([]);
  public postSubjectsData = new Array();
  public errorMessages = {
    subject_name: [{ type: 'required', message: 'Name is required' }],
    level: [{ type: 'required', message: 'Select Level' }],
    student_age: [{ type: 'required', message: 'Student Age required' }],
    price_per_hour: [{ type: 'required', message: 'Price per hour required' }, { type: 'pattern', message: 'Price should be a number' }]
  }

  get subject_name() { return this.subjectSelectionForm.get('subject_name'); }
  get level() { return this.subjectSelectionForm.get('level'); }
  get student_age() { return this.subjectSelectionForm.get('student_age'); }
  get price_per_hour() { return this.subjectSelectionForm.get('price_per_hour'); }

  constructor(private fb: FormBuilder,
    public activesubjects: ActiveSubjects,
    public studentages: StudentAges,
    public teachinglevels: TeachingLevels,
    private techingService: TutorTeachingService,
    public settings: Settings,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.allowCustomSubjects = this.settings.get('tutor_allow_custom_subjects');
    this.maxSubjectsToTeach = this.settings.get('tutor_max_subjects_teach');
  }


  public addSubjects() {
    this.subjectSelectionForm.markAllAsTouched();
    if (this.subjectSelectionForm.valid) {
      if (this.postSubjectsData.length > 0) {
        let subjectExist = this.postSubjectsData.find(x => x.subject_name == this.subjectSelectionForm.value.subject_name
          && x.level == this.subjectSelectionForm.value.level
          && x.student_age == this.subjectSelectionForm.value.student_age);
        if (!subjectExist) {
          this.subjectAddition();
        } else {
          this.toastr.error("Same Subject With Same Level Cannot Add");
        }
      } else {
        this.subjectAddition();
      }
    }
  }

  public subjectAddition() {
    if (this.postSubjectsData.length < this.maxSubjectsToTeach) {
      this.activesubjects.activeSubjects$.value.find(x => {
        if (x['subject_name'] == this.subjectSelectionForm.value.subject_name) {
          this.subjectSelectionForm.value.subjectId = x['id'];
        }
      });
      this.teachinglevels.teachingLevels$.value.find(x => {
        if (x['id'] == this.subjectSelectionForm.value.level) {
          this.subjectSelectionForm.value.levelName = x['level_name'];
        }
      });
      this.studentages.studentAges$.value.find(x => {
        if (x['id'] == this.subjectSelectionForm.value.student_age) {
          this.subjectSelectionForm.value.studentAge = x['student_age'];
        }
      });
      this.postSubjectsData.push(this.subjectSelectionForm.value);
      this.subjectSelectionForm.get('subject_name').reset();
      this.subjectSelectionForm.get('level').reset();
      this.subjectSelectionForm.get('student_age').reset();
      this.subjectSelectionForm.get('price_per_hour').reset();
    } else {
      this.toastr.error("Tutor Can add only" + this.maxSubjectsToTeach + "subjects");
      this.subjectSelectionForm.reset();
    }
  }

  public tutorSubjectDelete(id) {
    if (id > -1) {
      this.postSubjectsData.splice(id, 1);
    }
  }

  public getlevelName(levelId) {
    this.postSubjectsData[levelId]['level'];
    this.teachinglevels.teachingLevels$.value.find(x => {
      if (x['id'] == '' + this.postSubjectsData[levelId]['level']) {
        let name = '' + x['level_name'];
        return name;
      }
    });
  }

  public validate() {
    this.subjectSelectionForm.markAllAsTouched();
  }

  public subjectSelectionFormComplete() {
    this.subjectSelectionForm.value.formFilled = 1;
    this.subjectSelectionFormData.emit({ subjectForm: this.subjectSelectionForm.value, data: this.postSubjectsData });
  }

  public subjectSelectionFormInComplete() {
    this.subjectSelectionForm.value.formFilled = 0;
    this.subjectSelectionFormData.emit(this.subjectSelectionForm.value);
  }

}

