<div class="TT-templateDraft">
  <div class="row m-auto">
    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-6 col-xs-12">
       <div class="TT-AccSettings TT-spacers" *ngIf="!showSelectedActions || !numberOfRowsSelected">
         <ng-content select="[leftContent]"></ng-content>
       </div>
       <div class="TT-AccSettings TT-spacers" *ngIf="showSelectedActions && numberOfRowsSelected">
         {{numberOfRowsSelected}} {{ pluralResourceName }} {{ 'Selected' | trans }}
       </div>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
      <div class="clearfix">
        <div class="TT-formGroup" *ngIf="!showSelectedActions || !numberOfRowsSelected">
          <app-datatable-header-search-input placeholder="Search {{ pluralResourceName }}"></app-datatable-header-search-input>
        </div>
        <div class="TT-formGroup" *ngIf="showSelectedActions && numberOfRowsSelected">
          <ng-content select="[rightContent]"></ng-content>
        </div>
       </div>
     </div>
  </div>
</div>       

<!-- <div class="row">
<div class="col-sm-8">
    <div class="default-left-content" *ngIf="!showSelectedActions || !numberOfRowsSelected">
        <ng-content select="[leftContent]"></ng-content>
    </div>
    <div class="selected-left-content capitalize" *ngIf="showSelectedActions && numberOfRowsSelected">
        {{numberOfRowsSelected}} {{ pluralResourceName }} {{ 'Selected' | trans }}
    </div>
</div>
<div class="col-sm-4">
    <div class="default-right-content" *ngIf="!showSelectedActions || !numberOfRowsSelected">
        <app-datatable-header-search-input placeholder="Search {{ pluralResourceName }}"></app-datatable-header-search-input>
    </div>
    <div class="selected-right-actions" *ngIf="showSelectedActions && numberOfRowsSelected">
        <ng-content select="[rightContent]"></ng-content>
    </div>
</div>
</div> -->