<div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
   aria-hidden="true">
   <div class="modal-dialog modal-md modal-lg topMargin">
      <div class="modal-content">
         <div class="modal-body">
            <button type="button" class="close popup_model new-close-model-postions" (click)="resetLoginForm()" #closeLoginModal data-dismiss="modal"
            aria-hidden="true">
            ×
         </button>
         <!-- ================= login start===================== -->
         <section class="login_login">
            <div class="row">
               <div class="col-lg-6">
                  <div class="background">
                     <h2 style="color:#fff;">Register</h2>
                     <p style="color:#fff;">To keep connected with us please login with your personal info</p>
                     <div class="buttons-new">
                           <button _ngcontent-eru-c56="" type="submit" id="loginsub" trans="Register" (click)="loginName('Register')"
                              class="btn btn-primary new"
                              style="float: left;background-color: #fff !important;color: #000;margin: auto;">Register
                           </button>
                     </div>
                  </div>
               </div>
               <div class="col-md-6 loginDesign mt-5">
                  
                  <div class="tab-content mt-5">
                     <div class="tab-pane active" id="Login">
                        <form [formGroup]="loginForm" role="form" class="form-horizontal">
                           <div class="form-group email">
                              <label for="email" class="col-sm-2 control-label" trans="Email">
                                 Email
                              </label>
                              <div class="col-sm-10">
                                 <input formControlName="email" id="emaillogin" type="email" class="form-control"
                                    trans-placeholder="Email" placeholder="Email" />
                              </div>
                              <div *ngFor="let error of errorMessages.email">
                                 <ng-container *ngIf="email.hasError(error.type) && (email.touched)">
                                    <small class="error-message">{{error.message}}</small>
                                 </ng-container>
                              </div>
                           </div>
                           <div class="form-group password">
                              <label for="exampleInputPassword1" class="col-sm-2 control-label" trans="Password">
                                 Password
                              </label>
                              <div class="col-sm-10 show_eye">
                                 <input formControlName="password" id="password" type="password" class="form-control"
                                    trans-placeholder="Password" placeholder="Password" autocomplete="new-password" />
                                 <span class="fa fa-fw fa-eye-slash password" style="padding-bottom: 3px;
                              "
                                    (click)="togglePassword('password')"></span>
                              </div>
                              <div *ngFor="let error of errorMessages.password">
                                 <ng-container *ngIf="password.hasError(error.type) && (password.touched)">
                                    <small class="error-message">{{error.message}}</small>
                                 </ng-container>
                              </div>
                           </div>
                           <div class="form-group otp" *ngIf="showOtp">
                              <label for="otp" class="col-sm-2 control-label" trans="OTP">
                                 OTP
                              </label>
                              <div class="col-sm-10">
                                 <input formControlName="otp" type="otp" class="form-control" placeholder="OTP" />
                              </div>
                              <div *ngFor="let error of errorMessages.otp">
                                 <ng-container *ngIf="otp.hasError(error.type) && (otp.touched)">
                                    <small class="error-message">{{error.message}}</small>
                                 </ng-container>
                              </div>
                           </div>
                           <div class="row">
                              <div class="col-sm-2">
                              </div>
                              <div class="col-sm-6">
                                 <label class="control control--checkbox primaryLabel new-primaryLabel-padding" trans="Remember Me">
                                    Remember Me
                                    <input formControlName="remember" type="checkbox" checked="checked" />
                                    <div class="control__indicator">
                                    </div>
                                 </label>
                              </div>
                              <div class="col-sm-6 Otp_block">
                                 <button [disabled]="otpLoading$ | async" type="Resend" id="resend" (click)="resend()"
                                    class="btn btn-primary otp" trans="Resend">
                                    Resend
                                    <app-loader *ngIf="otpLoading$ | async"></app-loader>
                                 </button>
                              </div>
                           </div>
                           <div class="row">
                              <div class="col-sm-4 text-right">
                                 <button (click)="login()" [disabled]="loading$ | async" type="submit" id="loginsub"
                                    class="btn btn-primary new login_mobile" trans="Login">
                                    Login
                                    <app-loader *ngIf="loading$ | async"></app-loader>
                                 </button>
                              </div>
                              <div class="col-sm-8 text-right">
                                 <a (click)="closeLoginFormModal()" data-toggle="modal" data-target="#forgotModal"
                                    class="linkLabel textLine2" href="javascript:;">
                                    <small trans="Forgot Password?" class="forgot_password">
                                       Forgot Password?
                                    </small>
                                 </a>
                              </div>
                           </div>
                        </form>
                     </div>
                     
                     <h3 class="title">Or</h3>
                  </div>
                  <div *ngIf="settings.get('facebook_login') || settings.get('google_login')" class="col-md-6">
                     <div class="row text-center sign-with">
                        <div class="col-md-12 text-center">
                           <div class="btn-login-row">
                              <a (click)="socialAuth.loginWith('facebook')" *ngIf="settings.get('facebook_login')"
                                 class="social-icon no-style facebook" class="btn btn-social btn-primary fb-btn br0 new-color-primary-color">
                                 <img src="assets/images/f.png" class="signup_btn" alt="main-logo">
                                 <span trans="Login with Facebook">Login With Facebook</span>
                              </a>
                              <a (click)="socialAuth.loginWith('google')" *ngIf="settings.get('google_login')"
                                 class="social-icon no-style google" class="btn btn-social btn-google-new br0">
                                 <img src="assets/images/g.png" class="signup_btn" alt="main-logo">
                                 <span trans="Login with Google">Login With Google</span>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </section>
         <!-- ================= login End===================== -->
          <!-- ================= Register start===================== -->
          <section class="register_login">
            <div class="row">
               <div class="col-md-6 loginDesign">
                  
                  <div class="tab-content">
                      
                     <div class="tab-pane active" id="Registration">
                        <form role="form" (ngSubmit)="register()" [formGroup]="registrationForm"
                           class="form-horizontal">
                           <div class="form-group">
                              <label for="name" class="col-sm-2 control-label" trans="Name">
                                 Name
                              </label>
                              <div class="col-sm-10">
                                 <div class="row">
                                    <div class="col-md-6 padding-top">
                                       <input formControlName="name" type="text" class="form-control"
                                          placeholder="First Name" trans-placeholder="First Name" />
                                       <div *ngFor="let error of errorMessages.name">
                                          <ng-container *ngIf="name.hasError(error.type) && (name.touched)">
                                             <small class="error-messageName">{{error.message}}</small>
                                          </ng-container>
                                       </div>
                                    </div>
                                    <div class="col-md-6">
                                       <input formControlName="last_name" type="text" class="form-control"
                                          placeholder="Last Name" trans-placeholder="Last Name" />
                                       <div *ngFor="let error of errorMessages.last_name">
                                          <ng-container *ngIf="last_name.hasError(error.type) && (last_name.touched)">
                                             <small class="error-messageName">{{error.message}}</small>
                                          </ng-container>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="form-group">
                              <label for="regemail" class="col-sm-2 control-label" trans="Email">
                                 Email
                              </label>
                              <div class="col-sm-10">
                                 <input formControlName="regemail" type="email" class="form-control" id="email"
                                    trans-placeholder="Email" placeholder="Email" />
                              </div>
                              <div class="error-message" *ngIf="status=='emaildanger'"><small>{{error_message}}</small>
                              </div>
                              <div>
                                 <div *ngFor="let error of errorMessages.regemail">
                                    <ng-container *ngIf="regemail.hasError(error.type) && (regemail.touched)">
                                       <small class="error-message">{{error.message}}</small>
                                    </ng-container>
                                 </div>
                              </div>
                           </div>
                           <div class="form-group">
                              <label for="phone" class="col-sm-2 control-label" trans="Mobile">
                                 Mobile
                              </label>
                              <div class="col-sm-10">
                                 <div class="row">
                                    <div class="col-md-6 padding-top">
                                       <select formControlName="country" class="form-control iconArrowDown">
                                          <option value="" trans="Please select">Please select</option>
                                          <option [value]="count.name" 
                                             *ngFor="let count of countriesdata$"
                                             >
                                             {{count.name}}({{count.dial_code}})</option>
                                       </select>
                                       <div *ngFor="let error of errorMessages.country">
                                          <ng-container *ngIf="country.hasError(error.type) && (country.touched)">
                                             <small class="error-message error-message-mobile">{{error.message}}</small>
                                          </ng-container>
                                       </div>
                                    </div>
                                    <div class="col-md-6">
                                       <input (keydown)="validateNumber($event)" formControlName="phone" type="number"
                                          (blur)=selectNumber(phone) class="form-control numbers" id="phone"
                                          placeholder="Mobile" trans-placeholder="Mobile" />
                                    </div>
                                    <div class="error-message" *ngIf=" status=='invalid' || status=='phonedanger'">
                                       <small>{{error_message}}</small>
                                    </div>
                                    <div *ngIf="status==''">
                                       <div *ngFor="let error of errorMessages.phone">
                                          <ng-container *ngIf="phone.hasError(error.type) && (phone.touched)">
                                             <small class="error-message error-message-mobile">{{error.message}}</small>
                                          </ng-container>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="form-group">
                              <label for="password" class="col-sm-2 control-label" trans="Password">
                                 Password
                              </label>
                              <div class="col-sm-10 show_eye">
                                 <input autocomplete="new-password" formControlName="regpassword" type="password"
                                    class="form-control" id="regpassword" trans-placeholder="Password"
                                    placeholder="Password" />
                                 <span (click)="toogleRegpassword()" toggle="#regpassword"
                                    class="fa fa-fw fa-eye-slash toggle-regpassword" id="toggle-regpassword"></span>
                              </div>
                              <div *ngFor="let error of errorMessages.regpassword">
                                 <ng-container *ngIf="regpassword.hasError(error.type) && (regpassword.touched)">
                                    <small class="error-message">{{error.message}}</small>
                                 </ng-container>
                              </div>
                           </div>
                           <div class="row">
                              <div class="col-sm-2">
                              </div>
                              <div class="col-sm-10">
                                 <button [disabled]="loading$ | async" type="submit" id="registersub"
                                    class="btn btn-primary new btn-md" style="margin:20px 10px; background-color: #5863cb !important;
                                    color: #fff !important;">
                                    Register
                                    <app-loader *ngIf="loading$ | async"></app-loader>
                                 </button>

                                 <button (click)="closeModals()" type="button"
                                    class="btn btn-primary new btn-md cancel_btn" style="background-color: #5863cb !important;
                                    color: #fff !important;">
                                    Close
                                 </button>
                              </div>
                           </div>
                        </form>
                     </div>
                     <h3 class="title">Or</h3>
                  </div>
                  <div *ngIf="settings.get('facebook_login') || settings.get('google_login')" class="col-md-6">
                     <div class="row text-center sign-with">
                        <div class="col-md-12 text-center">
                           <div class="btn-login-row">
                              <a (click)="socialAuth.loginWith('facebook')" *ngIf="settings.get('facebook_login')"
                                 class="social-icon no-style facebook" class="btn btn-social btn-primary fb-btn br0 new-color-primary-color">
                                 <img src="assets/images/f.png" class="signup_btn" alt="main-logo">
                                 <span trans="Login with Facebook">Login With Facebook</span>
                              </a>
                              <a (click)="socialAuth.loginWith('google')" *ngIf="settings.get('google_login')"
                                 class="social-icon no-style google" class="btn btn-social btn-google-new br0">
                                 <img src="assets/images/g.png" class="signup_btn" alt="main-logo">
                                 <span trans="Login with Google">Login With Google</span>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-6">
                  <div class="background">
                     <h2 style="color:#fff;">Login</h2>
                     <p style="color:#fff;">To keep connected with us please login with your personal info</p>
                     <div class="buttons-new">
                           <button _ngcontent-eru-c56="" type="submit" id="loginsub" trans="Login"(click)="loginName('Login')"
                              class="btn btn-primary new"
                              style="float: left;background-color: #fff !important;color: #000;margin: auto;">Login
                           </button>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <!-- ================= Register End===================== -->
         </div>
      </div>
   </div>
</div>


<div class="modal fade" id="forgotModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
   aria-hidden="true">
   <div class="modal-dialog modal-lg modal-sm topMargin">
      <div class="modal-content">
         <div class="modal-header">
            <button #closeForgotModal type="button" class="close" (click)="forgetModals()" data-dismiss="modal"
               aria-hidden="true">
               x
            </button>

            <h4 class="modal-title md-title" id="myModalLabel" trans="Forgot Your Password?">
               Forgot Your Password?
            </h4>
         </div>
         <div class="modal-body forgot-password-padding">
            <div class="row">
               <div class="col-sm-1 col-md-1 col-lg-1"></div>
               <div class="col-sm-10 col-md-10 col-lg-10">
                  <span *ngIf="error_message" class="alert alert-danger">{{error_message}}</span>
                  <span *ngIf="success_message" class="alert alert-success">{{success_message}}</span>
                  <form (ngSubmit)="forgotPassword()" [formGroup]="forgotForm" role="form" class="form-horizontal">
                     <div class="form-group">
                        <div class="col-sm-12">
                           <small trans="forgotpassword_instruction" class="instructions"
                              trans="To receive a link to reset your password, please enter your email address.">To
                              receive a link to reset your password, please enter your email address.</small>
                        </div>
                        <br /><br />
                        <label for="forgot_email" class="col-sm-2 control-label" trans="Email">
                           Email
                        </label>
                        <div class="col-sm-10">
                           <input formControlName="forgot_email" type="email" class="form-control"
                              trans-placeholder="Email" placeholder="Email" />
                        </div>
                        <div *ngFor="let error of errorMessages.forgot_email">
                           <ng-container *ngIf="forgot_email.hasError(error.type) && (forgot_email.touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                     <div class="row send-instructions">
                        <div class="col-sm-4 col-md-4 col-lg-4 text-right send-instructions-inner">
                           <button [disabled]="loading$ | async" type="submit" class="btn btn-primary new butn_forgot"
                              [disabled]="!forgotForm.valid" trans="Send Instructions">
                              Send Instructions
                           </button>
                        </div>
                        <div class="col-sm-8 col-md-8 col-lg-8 text-right send-instructions-inner">
                           <a (click)="closeForgotPasswordModal()" data-toggle="modal" data-target="#loginModal"
                              class="linkLabel textLine2" href="javascript:;">
                              <small trans="Login" class="btn btn-primary mt-2 butn_forgot_login" >
                                 Login
                              </small>
                           </a>
                        </div>
                     </div>
                  </form>
               </div>
               <div class="col-md-1"></div>
            </div>
         </div>
      </div>
   </div>
</div>
