// declare const Pusher: any;
import { Injectable } from '@angular/core';
// import { environment } from '../../environment/environment';
import {environment} from 'environments/environment';
// import { Pusher } from 
import Pusher from 'pusher-js';
// declare const Pusher: any;

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  pusher: any;
  channel: any;
  constructor() {
    //this.pusher = new Pusher(environment.pusher.key, {
      //cluster: environment.pusher.cluster,
      // encrypted: true
    //});
    // this.channel = this.pusher.subscribe('reach-chat'); 

    //Pusher.logToConsole = true;

     this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      //encrypted: true,
    });

    // var channel = pusher.subscribe('reach-chats');
    // channel.bind('message', function(data) {
    //   alert(JSON.stringify(data));
    // });
   }

   createChannel(studentId,tutorId){
       this.channel = this.pusher.subscribe('reach_chat_'+studentId+'_'+tutorId);
       //this.channel = this.pusher.subscribe('reach-chats');
     //this.channel = this.pusher.subscribe('reach-chats');
   }

   createCommonChannel(bookingId){
    this.channel = this.pusher.subscribe('reach_chat_'+bookingId);
   }
}
