import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, Route } from '@angular/router';
import { CurrentUser } from '../../auth/current-user';

@Injectable({
    providedIn: 'root',
})

export class GuestGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private currentUser: CurrentUser,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.handle(state.url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.handle(state.url);
    }

    canLoad(route: Route) {

       if (this.currentUser.isLoggedIn()) {
        console.log(this.currentUser.redirectUri);
            return false;
        }else{
            return true;
        }
    }

    private handle(url: string) {
        if (this.currentUser.isLoggedIn()) {
            return true;
        }

        this.currentUser.redirectUri = url;

        this.router.navigate(['/login']);

        return false;
    }
}
