import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {GuestsNews} from '../core/types/models/GuestsNews';

@Injectable({
    providedIn: 'root'
})
export class GuestNews {
	public guestNews$ = new BehaviorSubject<Partial<GuestsNews[]>>([]);

	 public init(params) {
        this.guestNews$.next(params);
    }

    public news(){
    	return this.guestNews$.value;
    }

}