<div class='col-sm-12 rightCol'>
    <div class="well well-sm chat-head">
        <div class="row">
            <div class="col-sm-9">
                <h4 class="title" trans="Calender">
                    Calender 
                </h4>
            </div>
        </div>
    </div>

        <div class='content-container customTabs'>
            <div class="row formspace">
                <div class="col-sm-12">
                    <div class="row formspace">
                        <div class="col-md-9">
                            <div class="col-md-3 ">
                                    <img src="assets/images/glogo.png" alt="Glogo" height="100">
                            </div>
                            <div class="col-md-6"><br>

                                <p class="paD20 confirmation_txt">
                                    Connect your Google Calendar and synchronize  all your My Tutor Platform lessons with your
                                personal schedule</p>
                            </div>
                            <div class="col-md-3">
                                <br>
                                <span *ngIf="connected" trans="Google Calendar Connected">Google Calendar Connected</span>

                                <span *ngIf="connected">&nbsp;|&nbsp;
                                    <span  trans="Disconnect" (click)="disconnectGoogleCalendar()" style="cursor: pointer;">Disconnect</span>
                                </span>
                                    <a type="submit" class="btn btn-save1 btn-big" [href]="url" *ngIf="!connected" target="_blank">

                                    <div class="TT-formGroup" style="display:flex;">
                                    <span trans="Connect Google Calendar" class="btn TT-primaryBTN pull-right TT-topSpaceZero" style="width: auto;">Connect Google Calendar</span>
                                </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12" *ngIf="connected">
                    
                    <form  [formGroup]="frmGroup">
                        <div class="col-sm-12">
                            <div class="row formspace mar-40">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="calendar_id" trans="Calendar ID">Calendar ID</label>
                                        <!-- <input formControlName="calendar_id" type="text" class="form-control r" placeholder="Calendar ID"> -->
                                        <select formControlName="calendar_id" class="form-control iconArrowDown">
                                            <option value="" trans="Please select">Please select</option>
                                            <option [value]="calendar.id" *ngFor="let calendar of calendars">{{calendar.title}}</option>
                                         </select>

                                        <!-- <p>Click <a href="https://docs.simplecalendar.io/find-google-calendar-id/" target="_blank">here</a> to know how to find Calendar ID</p> -->
                                    </div>
                                    <div *ngFor= "let error of errorMessages.calendar_id">
                                        <ng-container *ngIf="calendar_id.hasError(error.type) && (calendar_id.dirty || calendar_id.touched)">
                                        <small class="error-message">{{error.message}}</small>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="col-md-offset-0 col-md-9 margintop30">
                                    <button (click)="updateCalendarID()" type="submit" class="btn btn-primary new" trans="Update">
                                        Update
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

</div>