<div class="container-fluid">
   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="row">
         <nav aria-label="..." class="clearfix">
            <ul class="TT-pagination pull-right" role="tablist">
               <li class="TT-listPage">
                  <button type="button" [disabled]="!havePrevious || (datable.paginator.loading$ | async)" (click)="previousPage()" mat-icon-button class="btn btn-outline-secondary TT-prev">
                  <span aria-hidden="true" class="TT-icon" trans="Previous">←</span> Previous</button>
               </li>
               <li class="TT-listPage" (click)="goToPage(1)" *ngIf="current_page > 3">
                 <a aria-controls="tab1" class="TT-pageUpdate" data-toggle="tab" href="#tab1" role="tab">{{pages[0]}}</a>
               </li>    
               <li class="TT-listPage" *ngIf="current_page > 3">
                 <a aria-controls="tab1" data-toggle="tab" href="#tab1" role="tab">...</a>
               </li> 
               <li class="TT-listPage" *ngFor="let pageNumber of displayedPages()" (click)="goToPage(pageNumber)" [ngClass]="{'active' : (current_page === pageNumber)}">
                 <a aria-controls="tab1" class="TT-pageUpdate" data-toggle="tab" href="#tab1" role="tab">{{pageNumber}}</a>
               </li> 
               <li class="TT-listPage" *ngIf="current_page < pages.length - 2 && pages.length > 5">
                 <a>...</a>
               </li> 
               <li class="TT-listPage" *ngIf="current_page < pages.length - 2 && pages.length > 5" (click)="goToPage(pages.length)">
                 <a aria-controls="tab1" data-toggle="tab" href="#tab1" role="tab">{{pages[pages.length - 1]}}</a>
               </li> 
               <li class="TT-listPage">
                 <button type="button" [disabled]="!haveNext || (datable.paginator.loading$ | async)" (click)="nextPage()" mat-icon-button class="btn btn-outline-secondary TT-prev" trans="Next">Next 
                  <span aria-hidden="true" class="TT-icon">→</span>
                 </button>
               </li>
            </ul>
         </nav>
      </div>
   </div>
</div>