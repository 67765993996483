import { Injectable } from '@angular/core';
import { AppHttpClientService } from '../http/app-http-client.service';
import { BackendResponse } from '../types/backend-response';
import { Localization } from '../../core/types/models/Localization';

export interface Currency {
    name: string;
    decimal_digits: number;
    symbol: string;
    code: string;
}

export interface Timezone {
    text: string;
    value: string;
}

export interface SelectOptionLists {
    countries?: CountryListItem[];
    timezones?: {[key: string]: Timezone[]};
    languages?: LanguageListItem[];
    localizations?: Localization[];
    currencies?: {[key: string]: Currency};
    [key: string]: any;
}

export interface CountryListItem {
    name: string;
    dial_code?: string;
    code: string;
}

export interface LanguageListItem {
    name: string;
    nativeName?: string;
    code: string;
}

@Injectable({
    providedIn: 'root',
})
export class ValueLists{
    static BASE_URI = 'value-lists';
    constructor(private httpClient: AppHttpClientService) {
    }

    public get(names: (keyof SelectOptionLists | string)[], params: object = {}): BackendResponse<SelectOptionLists> {
        return this.httpClient.get(`${ValueLists.BASE_URI}/${names.join(',')}`, params);
    }

    public clearCache() {
        // this.httpClient.clearCache();
    }
}
