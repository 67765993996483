import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_CONFIG } from '@core/config/app-config';
import { TUTORAPP_CONFIG } from './tutorapp-config';
import { Bootstrapper } from '@core/bootstrapper.service';
import { CoreModule } from '@core/core.module';
import { TutorPlatformBootstrapper } from './bootstrapper.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslationsModule } from '@core/translations/translations.module';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { TutorComponent } from './tutor/tutor.component';
import { ToastrModule } from 'ngx-toastr';
import { LandingPageComponent } from './home/landing-page/landing-page.component';
import { AthandModule } from './athand/athand.module';
import { BecomeATutorComponent } from './home/become-a-tutor/become-a-tutor.component';
import { AboutComponent } from './home/become-a-tutor/about/about.component';
import { PhotoComponent } from './home/become-a-tutor/photo/photo.component';
import { DescriptionComponent } from './home/become-a-tutor/description/description.component';
import { VideoComponent } from './home/become-a-tutor/video/video.component';
import { AvailabilityComponent } from './home/become-a-tutor/availability/availability.component';
import { LanguageComponent } from './home/become-a-tutor/language/language.component';
import { SubjectselectionComponent } from './home/become-a-tutor/subjectselection/subjectselection.component';
import { ErrorsComponent } from './home/errors/errors.component';
import { ScrollDirective } from './athand/chat/scroll.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PlatformModule } from '@angular/cdk/platform';
import { DatatableModule } from '@core/datatable/datatable.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { environment } from '../environments/environment';
import { WebsiteModule } from './website/website.module';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { SocketService } from '@services/socket.service';
import { PusherService } from './pusher.service';



@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    BecomeATutorComponent,
    AboutComponent,
    PhotoComponent,
    DescriptionComponent,
    VideoComponent,
    AvailabilityComponent,
    LanguageComponent, 
    SubjectselectionComponent,
    ErrorsComponent,
    
  ],
  imports: [
    ScrollingModule,
    PlatformModule,
    NgxDropzoneModule,
    Ng2TelInputModule,
    AthandModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslationsModule,
    DatatableModule,
    WebsiteModule,

    ToastrModule.forRoot({
    timeOut: 3000,
    positionClass: 'toast-top-right',
    preventDuplicates: true,

    }),
    CoreModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    
  ],
 
  providers:[
  {provide: APP_CONFIG , useValue: TUTORAPP_CONFIG, multi: true},
  {provide: Bootstrapper, useClass: TutorPlatformBootstrapper},
  {provide: ScrollDirective, useClass: ScrollDirective},
  // {provide: SocketService, useClass: SocketService},
  {provide: PusherService, useClass: PusherService},
  
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
