import { NgModule, ModuleWithProviders, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Bootstrapper, init_app } from './bootstrapper.service';
import { CurrentUser } from '../auth/current-user';
import { Settings } from './config/settings.service';
import { UiModule } from './ui/ui.module';
import { ravenErrorHandlerFactory } from './errors/raven-error-handler';
import { APP_CONFIG, DEFAULT_APP_CONFIG } from './config/app-config';
import { HttpErrorHandler } from './http/errors/http-error-handler.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MetaTagsInterceptor } from './meta/meta-tags-interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpModule } from './http/http.module';
import { TranslationsModule } from './translations/translations.module';
import { BackendHttpErrorHandler } from './http/errors/backend-http-error-handler.service';

@NgModule({
  declarations: [],
  imports: [
         CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        HttpClientModule,
        UiModule,
        HttpModule,
        TranslationsModule,
  ],exports:[
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        HttpClientModule,
        UiModule,
  ]
})

export class CoreModule { 

    static forRoot(): ModuleWithProviders<CoreModule>{
        return {
            ngModule: CoreModule,
            providers: [
                Bootstrapper,
                CurrentUser,
                {
                    provide: APP_CONFIG,
                    useValue: DEFAULT_APP_CONFIG,
                    multi: true,
                },
                {
                    provide: HttpErrorHandler,
                    useClass: BackendHttpErrorHandler,
                },
                {
                    provide: APP_INITIALIZER,
                    useFactory: init_app,
                    deps: [Bootstrapper],
                    multi: true,
                },
                {
                    provide: ErrorHandler,
                    useFactory: ravenErrorHandlerFactory,
                    deps: [Settings, CurrentUser],
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: MetaTagsInterceptor,
                    multi: true,
                },
            ]
        };
    }
    }
