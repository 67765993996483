import {     
	ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild } from '@angular/core';

@Component({
  selector: 'app-datatable-header',
  templateUrl: './datatable-header.component.html',
  styleUrls: ['./datatable-header.component.css']
})
export class DatatableHeaderComponent implements OnInit {
  @Input() showSelectedActions = true;
  @HostBinding('class.rows-selected') public numberOfRowsSelected: number;
  
  constructor() { }

  ngOnInit(): void {
  }

}
