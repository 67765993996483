import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {Translations} from '../../translations/translations.service';
import {Settings} from '../../config/settings.service';



@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent implements OnInit {
    constructor(
        private i18n: Translations,
        public settings: Settings,
    ) {}

    ngOnInit() {

    }

    public confirm() {
        // this.dialogRef.close(true);
    }

    public close() {
        // this.dialogRef.close(false);
    }
}
