import { Injectable } from '@angular/core';
import { AppHttpClientService } from '../../core/http/app-http-client.service';
import { BehaviorSubject } from 'rxjs';
import { Bookings } from '../../core/types/models/Bookings';


@Injectable({
  providedIn: 'root'
})

export class TutorLessonsService {
     public scheduleData = new BehaviorSubject<any>(null);
     bookingData = this.scheduleData.asObservable();
   static PENDINGACTIONS = 'tutorPendingActionLessons';
   static CANCELLED = 'tutorCancelledLessons';
   static OVERDUE = 'tutorOverdueLessons';
   static COMPLETED = 'tutorCompletedLessons';
   public livebookingRequestsCount = new BehaviorSubject<Object>(null);
   sharedbookingRequestsCount = this.livebookingRequestsCount.asObservable();
   public livependingRequestsCount = new BehaviorSubject<Object>(null);
   sharedpendingRequestsCount = this.livependingRequestsCount.asObservable();
   public livetotalStudentsCount = new BehaviorSubject<Object>(null);
   sharedtotalStudentsCount = this.livetotalStudentsCount.asObservable();
   public livecompletedLessonsCount = new BehaviorSubject<Object>(null);
   sharedcompletedLessonsCount = this.livecompletedLessonsCount.asObservable();
   public livescheduledLessonsCount = new BehaviorSubject<Object>(null);
   sharedscheduledLessonsCount = this.livescheduledLessonsCount.asObservable();
   public livecancelledLessonsCount = new BehaviorSubject<Object>(null);
   sharedcancelledLessonsCount = this.livecancelledLessonsCount.asObservable();

   public sourceIdBookingRequest = new BehaviorSubject<Object>(null);
   sharedSourceIdBokingRequest = this.sourceIdBookingRequest.asObservable();

   public sourceCoderequestReschedule = new BehaviorSubject<Object>(null);
   sharedsourceCoderequestReschedule = this.sourceCoderequestReschedule.asObservable();


  public sourceDirectCancelletion = new BehaviorSubject<Object>(null);
   sharedsourceCodeDirectCancellaton = this.sourceDirectCancelletion.asObservable();

   public sourceCancellationRequest = new BehaviorSubject<Object>(null);
  sharedsourceCancellationRequest = this.sourceCancellationRequest.asObservable();

  public livegroupbookingsCount = new BehaviorSubject<Object>(null);
   sharedgroupbookingsCount = this.livegroupbookingsCount.asObservable();

	constructor(private http: AppHttpClientService){}

  public rescheduleBookings = new BehaviorSubject<Object>(null);
    sharedRescheduleBookings = this.rescheduleBookings.asObservable();

   public rescheduleAccepted(id){
    this.rescheduleBookings.next(id);
   }

   public directCancellation(id){
    this.sourceDirectCancelletion.next(id);
   }

   public cancellationRequest(id){
    this.sourceCancellationRequest.next(id);
   }

     public studentName = new BehaviorSubject<Object>(null);
    sharedstudentName = this.studentName.asObservable();

   public studentId = new BehaviorSubject<Object>(null);
    sharedstudentId = this.studentId.asObservable();

    public userFilterName(name){
     this.studentName.next(name);
    }

    public userFilterId(id){
     this.studentId.next(id);
    }

      //COMPLETE LESSONS DATA
      public tutorLessonsData(){
         return this.http.get('tutorLessonsData');
      }

      public rescheduleBooking(id){
        this.sourceCoderequestReschedule.next(id);
      }

      public sourceBookingRequest(id){
      this.sourceIdBookingRequest.next(id);
      }

      // BOOKING REQUESTS constructor
      public bookingRequestsCount(count){
       this.livebookingRequestsCount.next(count);
      }

      // BOOKING REQUESTS constructor
      public groupbookingsCount(count){
        this.livegroupbookingsCount.next(count);
       }

      public pendingRequestsCount(count){
       this.livependingRequestsCount.next(count);
      }

      public totalStudentsCount(count){
       this.livetotalStudentsCount.next(count);
      }

      public completedLessonsCount(count){
       this.livecompletedLessonsCount.next(count);
      }

      public cancelledLessonsCount(count){
       this.livecancelledLessonsCount.next(count);
      }

      public scheduledLessonsCount(count){
       this.livescheduledLessonsCount.next(count);
      }

      //Students
      public tutorActiveStudents(){
           return this.http.get('tutorActiveStudents');
      }


      // Booking Requests
	   public tutorbookingRequest(payload: Object){
         return this.http.post('tutorbookingRequest' , payload);
      }

      public tutorAcceptBooking(payload: Object){
         return this.http.post('tutorAcceptBooking', payload);
      }

      public tutorRejectBooking(payload: Object){
         return this.http.post('tutorRejectBooking' , payload);
      }

      public cancelDirectBookingTutor(payload: Object){
         return this.http.post('cancelDirectBookingTutor' , payload);
      }

      public cancelSingleBooking(payload: Object){
         return this.http.post('cancelSingleBooking' , payload);
      }

      public tutorCancelledLessons(id){
        if(id){
      	 return this.http.get('tutorCancelledLessons/' + id);
        }else{
         return this.http.get('tutorCancelledLessons');
        }
      }

      public tutorCompletedLesson(){
      	 return this.http.get('tutorCompletedLesson');
      }

      public getVideoSettings(){
           return this.http.get('getVideoSettings');
      }

      public UpdateCustomUrl(payload: Object)
     {
       return this.http.post('UpdateCustomUrl',payload);

     }

      public tutorPendingActionLessons(id){
        if(id){
      	  return this.http.get('tutorPendingActionLessons/' +id);
        }else{
          return this.http.get('tutorPendingActionLessons');
        }
      }

      public getStudentPendingActions(id){
         return this.http.get('getSelectedStudentpendingActions/' + id);
      }

      //Schudule Lessons
      public tutorScheduleLessons(payload: Object){
         return this.http.post('tutorScheduleLessons' , payload);
      }

            //Schudule Lessons
      public tutorSchedules(payload: Object){
         return this.http.post('tutorSchedules' , payload);
      }



      // SCHEDULES OF TUTOR
      public tutorCompletedLessons(id){
        if(id){
      	  return this.http.get('tutorCompletedLessons/' +id);
        }else{
          return this.http.get('tutorCompletedLessons');
        }
      }

      public OverdueLessonsTutor(id){
        if(id){
          return this.http.get('tutorOverdueLessons/' +id);
        }else{
          return this.http.get('tutorOverdueLessons');
        }
      }

      public getStudentSchedules(payload){
         return this.http.post('getSelectedStudentSchedules/' + payload.studentId, payload);
      }

      // acceptRescheduleRequest
      public acceptRescheduleRequest(payload){
         return this.http.post('tutorAcceptRescheduleRequest' , payload);
      }

      public acceptCancellationRequest(payload){
         return this.http.post('acceptCancellationRequest' , payload);
      }

      public rejectCancellation(payload){
         return this.http.post('rejectRequest' , payload);
      }

      public getTutorDates(payload) {
         return this.http.post('getTutorDates' , payload);
      }

      public updateStatus(payload) {
         return this.http.post('tutorUpdateSchedule', payload);
      }

      public updateRescheduleRequest(payload) {
         return this.http.post('updateRescheduleRequest', payload);
      }

      public updateCancelRequest(payload) {
         return this.http.post('updateCancelRequest', payload);
      }

    public getNotification(){
      return this.http.get('userNotifications');
    }

     public getAllNotifications(){
      return this.http.get('getAllNotifications');
    }

    public getBookingDetails(id){
         return this.http.get('getBookingDetails/' + id);
      }

          public selectedBookingDetails(payload){
      return this.http.post('getTuSelectedBookingDetails' , payload)
    }

    public postTutorComment(payload){
      return this.http.post('postTutorComment' , payload);
    }

           // This Mthod is used to reques the backend to upload the files
    public UploadFiles(files){
        return this.http.post('commentTuFileUpload',files);
    }

        // Weekly Availability of selctedTutor
    public getWeeklyAvailability(payload: object){
        return this.http.post('getWeeklyAvailability' , payload);
    }

    public getStudentBooking(payload: object){
        return this.http.post('getStudentBookings', payload);
    }

    public assignDatesForPackage(payload: object){
        return this.http.post('assignDatesForPackage' , payload);
    }

    public assignRescheduleDatesforSchedule(payload: object){
        return this.http.post('assignRescheduleDatesforSchedule' , payload);
    }

    public assignRescheduleDatesforSingleBooking(payload: object){
      return this.http.post('assignRescheduleDatesforSingleBooking', payload);
    }

    // Message Modal send Request
      public sendMessage(payload: object){
        return this.http.post('send-message', payload);
      }

      public readNotification(id){
        return this.http.get('read-notifications/' + id);
      }

    public updateCurrency(payload: object){
        return this.http.post('currencyUpdate',payload);
   }

      public getFirstMoreOrder(){
         return this.http.get('getFirstMoreOrder');
   }
    public onVideo(value){
    this.scheduleData.next(value);
   }

   public pushNotification(payload: object){
    console.log(payload);
    return this.http.post('send-web-notification',payload);
    //return this.http.post('assignRescheduleDatesforSingleBooking', payload);
   }

   // Group Bookings
   public tutorgroupBookings(payload: Object){
    return this.http.post('tutorgroupBookings' , payload);
 }


}