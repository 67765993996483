import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AthandRoutingModule } from './athand-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '../core/ui/ui.module';
import { TimeZonesComponent } from './time-zones/time-zones.component';
import { MediaImageComponent } from './media-image/media-image.component';
import { ProfileComponent } from './profile/profile.component';
import { SidenavComponent } from './profile/sidenav/sidenav.component';
import { PaymentMethodsComponent } from './profile/payment-methods/payment-methods.component';
import { AutoConfirmationComponent } from './profile/auto-confirmation/auto-confirmation.component';
import { CalenderComponent } from './profile/calender/calender.component';
import { NotificationsComponent } from './profile/notifications/notifications.component';
import { TranslationsModule } from '../core/translations/translations.module';
import { RatingComponent } from './rating/rating.component';
import { CurrencyRangeValidatorDirective } from './validators/currency-range/currency-range-validator.directive';
import { ProfileEditComponent } from './profile/profile-edit/profile-edit.component';
import { AccountVerificationComponent } from './profile/account-verification/account-verification.component';
import { LoaderComponent } from './loader/loader.component';
import { VideocallComponent } from './videocall/videocall.component';
import { BackComponent } from './back/back.component';


@NgModule({
  declarations: [LoginComponent, TimeZonesComponent, MediaImageComponent, ProfileComponent, SidenavComponent , PaymentMethodsComponent, AutoConfirmationComponent, CalenderComponent, NotificationsComponent,  RatingComponent, CurrencyRangeValidatorDirective, ProfileEditComponent, AccountVerificationComponent, LoaderComponent, BackComponent],
  imports: [
    CommonModule,
    AthandRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslationsModule,
    UiModule  ],
  exports : [LoginComponent,TimeZonesComponent ,MediaImageComponent,ProfileComponent,RatingComponent,LoaderComponent,BackComponent]
})
export class AthandModule { }
